@import '@assets/reuse-mixin.scss';

.animated-banner {
  &__container {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }

  &__background {
    &-slider {
      margin: auto;
      position: relative;
      width: 100%;
      height: 600px;
      display: grid;
      overflow: hidden;

      .main-banner__background {
        height: 100%;
        padding-bottom: 0;
        @include media-breakpoint-up(sm) {
          video {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transform: unset;
            object-fit: contain;
          }
        }
        @include media-breakpoint-up(lg) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &-track {
      display: flex;
      width: max-content;
      animation: sliderScrollRightToLeft 30s linear infinite;
    }

    &-slide {
      height: 600px;
      display: flex;
      align-items: center;
      width: 280px;
      @include media-breakpoint-up(sm) {
        width: 480px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .1s;
      }
    }

    &__video {
      .slider-video {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &__content {
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 20px;
      background-color: rgba(0, 0, 0, .3);
    }

    @include media-breakpoint-up(lg) {
      padding: 40px 20px;
    }

    &.content-top {
      .animated-banner__content-container {
        @include media-breakpoint-up(md) {
          justify-content: flex-start;
        }
      }
    }

    &.content-middle {
      .animated-banner__content-container {
        @include media-breakpoint-up(md) {
          justify-content: center;
        }
      }
    }

    &.content-bottom {
      .animated-banner__content-container {
        @include media-breakpoint-up(md) {
          justify-content: flex-end;
        }
      }
    }

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      
      @include media-breakpoint-up(lg) {
        width: 80%;
        padding-bottom: 180px;
      }
      @include media-breakpoint-up(xl) {
        width: 70%;
      }
    }

    &-icon {
      margin: 20px 0 0;
      max-width: 35px;

      @include media-breakpoint-up(md) {
        margin: 0 0 60px 0;
      }
    }

    &-text {
      padding: 20px 15px 19px;
      color: $gray-darker;
      font-size: $h4-font-size;
      text-align: center;

      @include media-breakpoint-up(md) {
        color: $white;
        padding: 0;
        margin-bottom: 35px;
      }

      &__title {
        color: $gray-darker;
        margin-bottom: 11px;
        font-weight: $font-weight-normal;
        
        @include media-breakpoint-up(md) {
          color: $white;
          margin-bottom: 20px;
        }
      }

      &__desc {
        margin-bottom: 0;
      }
    }

    &-cta__btn {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px 0 15px;
      background-color: $white;
      // border-top: 1px solid $gray-light;
      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 0;
        border-top: 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: transparent;
        position: unset;
      }

      &__item {
        flex: 1;
        cursor: pointer;
        // width: 100%;
        width: 280px;

        &:not([href]):not([tabindex]) {
          color: $white;

          &:hover {
            color: $white;
          }
        }

        @include media-breakpoint-up(sm) {
          max-width: 400px;
        }

        @include media-breakpoint-up(md) {
          max-width: unset;
          flex: unset;
          // min-width: 300px;
        }

        @include media-breakpoint-up(lg) {
          width: 320px;
        }

        &:not(:last-child) {
          margin-bottom: 7px;
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-right: 60px;
          }
        }
      }
    }
  }
}