@import '@assets/reuse-mixin.scss';

// prize item colors
//$spin-prize-bg-color: (#15374f, #b4365a, #07aba3, #77e3f1, #faebc9, #f7bd45, #00bcb1, #f92c2a);
//$spin-prize-text-color: (#ebe4d2, #ffd980, #fcefcb, #423e43, #867a5e, #5b4b25, #fcefcb, #ffd980);

// wheel size
$wheel-desktop: 434px;
$wheel-mobile: 326px;

.spin-wheel {
  width: 100%;
  position: relative;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__shadow {
    width: calc(#{$wheel-mobile} - 19px);
    height: calc(#{$wheel-mobile} - 19px);
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.38) inset 5px 10px 5px;

    @include media-breakpoint-up(xl) {
      width: calc(#{$wheel-desktop} - 28px);
      height: calc(#{$wheel-desktop} - 28px);
    }
  }

  &__border {
    width: calc(#{$wheel-mobile} + 10px);
    height: calc(#{$wheel-mobile} + 10px);
    position: absolute;

    @include media-breakpoint-up(xl) {
      width: calc(#{$wheel-desktop} + 15px);
      height: calc(#{$wheel-desktop} + 15px);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__arrow {
    position: absolute;
    filter: drop-shadow(0px 6px 2px rgba(0, 0, 0, 0.7));
    top: 0;
    width: 45px;
    z-index: 2;

    @include media-breakpoint-up(xl) {
      width: 60px;
    }
  }

  &__container {
    width: $wheel-mobile;
    height: $wheel-mobile;
    background-color: #ccc;
    border-radius: 50%;
    border: 10px solid transparent;
    background-clip: padding-box;
    position: relative;
    overflow: hidden;
    transition: all ease 2.5s;

    @include media-breakpoint-up(xl) {
      width: $wheel-desktop;
      height: $wheel-desktop;
      border: 15px solid transparent;
    }
  }

  &__mask {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 100%;
    position: absolute;
  }

  &__prize {
    height: 50%;
    width: 130px;
    position: absolute;
    transform-origin: bottom;
    transform: translateX(-50%);
    left: 88px;

    // filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.5));
    //@for $colIndex from 1 through length($spin-prize-bg-color) {
    //  &:nth-child(#{length($spin-prize-bg-color)}n + #{$colIndex}) {
    //    .spin-wheel__prize__shape {
    //      background-color: nth($spin-prize-bg-color, $colIndex);
    //    }
    //  }
    //}
    //@for $colIndex from 1 through length($spin-prize-text-color) {
    //  &:nth-child(#{length($spin-prize-text-color)}n + #{$colIndex}) {
    //    .spin-wheel__prize__shape {
    //      color: nth($spin-prize-text-color, $colIndex);
    //    }
    //  }
    //}
    @include media-breakpoint-up(xl) {
      width: 170px;
      left: 117px;
    }

    &:first-child {
      left: 50%;
    }

    &__shape {
      clip-path: polygon(100% 0, 50% 100%, 0 0);
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__label {
      word-wrap: break-word;
      text-align: center;
      font-weight: $font-weight-bold;
      margin-bottom: 60px;
      width: 80px;
      font-size: $font-size-small;

      @include media-breakpoint-up(xl) {
        width: 100px;
        font-size: $h5-font-size;
        margin-bottom: 100px;
      }
    }
  }

  &__btn {
    outline: none;
    border: none;
    background-color: transparent;
    position: absolute;
    filter: drop-shadow(4px 7px 11px #000);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 125px;
    height: 125px;
    cursor: default !important;
    padding-left: 0;
    padding-right: 0;
    z-index: 2;

    @include media-breakpoint-up(xl) {
      width: 166px;
      height: 166px;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}