@import '@assets/reuse-mixin.scss';

.appointment-btn {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    display: block;
    min-width: 168px;

    &.btn-link {
      text-transform: initial;
      margin-top: 18px;
      padding: 0;
    }
  }
}