@import '../../../../assets/reuse-mixin';

.main-logo-wrap {
  display: inline-block;
  width: 75px;

  @include media-breakpoint-up(md) {
    width: 130px;
  }

  .main-logo {
    display: inline-block;
    padding: 0px;
  
    &__image {
      width: 100%;
      height: 100%;

      &--dark-mode {
        display: none;
      }
    }
  }
}