@import "@assets/reuse-mixin.scss";

.member-event-change {
  .form-title {
    text-align: center;
  }

  .event-registration__form {
    .btn-back {
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
  }
}