@import '../../../../assets/reuse-mixin.scss';

.carousel-detail {
  padding-top: 56px;

  @include media-breakpoint-up(xl) {
    padding-top: 72px;
  }

  &.carousel-image {
    padding-top: 0;
  }

  & ~ .carousel-detail {
    padding: 40px 0 4px 0;

    @include media-breakpoint-up(xl) {
      padding: 72px 0 32px 0;
    }
  }

  &.--one-slide {
    .carousel-detail__list {
      margin-right: 0;
    }
  }

  &__list {
    position: relative;
    margin-right: -20px;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    color: $gray-darker;
    top: 42%;
    width: 12px;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .swiper-button-prev:after, 
  .swiper-button-next:after {
    font-size: 2rem;

    @include media-breakpoint-up(xl) {
      font-size: 2.4rem;
    }
  }

  .swiper-button-prev {
    left: -24px;

    @include media-breakpoint-up(xl) {
      left: -30px;
    }
  }

  .swiper-button-next {
    right: -24px;

    @include media-breakpoint-up(xl) {
      right: -30px;
    }
  }

  .swiper-button-lock {
    display: none;
  }
};