@import '@assets/reuse-mixin.scss';

.suggest-freeship {
  max-width: 560px;
  margin: 0 auto;
  
  &__title {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &__description {
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  &__btn {
    margin-top: 100px;

    @include media-breakpoint-up(lg) {
      margin-top: 125px;
    }

    .btn {
      width: 200px;

      @include media-breakpoint-up(lg) {
        width: 240px;
      }
    }
  }
}
