@import '@assets/reuse-mixin.scss';

.cart-total {
  background-color: $os-orange-lighter;
  margin-top: 18px;
  color: $gray-darker;
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(lg) {
    margin-top: 24px;
  }

  ul.cart-total__total-amount__last-list {
    border-top: 1px solid $gray-dark;
    padding-top: 16px;
    margin-top: 16px;
  }

  &__total-amount {
    @include media-breakpoint-up(lg) {
      order: 2;
    }

    &.cart-summary__total-amount {
      padding: 24px $grid-gutter-width/2;

      @include media-breakpoint-up(lg) {
        padding: 32px $grid-gutter-width/2;
      }
    }

    &__total-price {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
      letter-spacing: 1.2px;
  
      @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
        letter-spacing: 1.75px;
      }
      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
        letter-spacing: 1.2px;
      }
      @include media-breakpoint-up(xl) {
        font-size: $h2-font-size;
        letter-spacing: 1.75px;
      }
    }
  
    .group-contents {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;
  
      @include media-breakpoint-up(md) {
        margin-top: 5px;
      }

      &:first-child {
        margin-top: 0;
      }
  
      // &--has-sub {
      //   margin-bottom: 5px;
      // }

      &--align-baseline {
        &.group-contents {
          align-items: baseline;
        }
      }
  
      .sub-text {
        font-size: $font-size-smaller;
        color: $secondary;
        cursor: pointer;
        margin-top: 5px;
  
        @include media-breakpoint-up(md) {
          font-size: $font-size-large;
        }
      }
  
      .contents-left {
        max-width: 250px;
  
        @include media-breakpoint-up(md) {
          max-width: 610px;
        }
  
        &__description,
        a {
          font-size: $font-size-smaller;
          letter-spacing: 1.6px;
  
          @include media-breakpoint-up(md) {
            font-size: $font-size-small;
          }

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
          }

          @include media-breakpoint-up(xl) {
            font-size: $h5-font-size;
          }
        }
  
        &__description {
          display: inline;
          padding-right: 10px;
          color: $gray-dark;
          font-weight: $font-weight-light;

          &__text {
            margin-right: 6px;
          }
        }
      }
  
      .text-line-through {
        color: $gray-dark;
      }
    }
  }

  label {
    margin-bottom: 0;
  }
}