@import '@assets/reuse-mixin.scss';

.two-col-image-text {
  @include media-breakpoint-up(lg) {
    // display: flex;
    // margin-bottom: 50px;
  }

  &__wrap {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .video-icon {
    background: url('../../assets/images/icons/icon-video.png') 0 0 no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: text-top;
    margin-right: 12px;
  }

  &__image,
  &__content {
    width: 100%;

    @include media-breakpoint-up(lg) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &__image {
    order: 1;
    position: relative;
    overflow: hidden;
    height: 400px;

    @include media-breakpoint-up(lg) {
      height: auto;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &:hover {
      img {
        transform: scale(1.02);
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease-out;
    }
  }

  &__content {
    order: 2;
    width: auto;
    // padding: $grid-gutter-width $grid-gutter-width/2;
    padding: 25px $grid-gutter-width/2;
    // line-height: 24px;
    // letter-spacing: 5px;

    [lang='zh-HK'] & {
      @include media-breakpoint-down(md) {
        padding: 30px $grid-gutter-width/2;
      }
    }

    @include media-breakpoint-up(md) {
      padding-left: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(lg) {
      padding: 30px;
      // margin: auto 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: unset;
    }

    @include media-breakpoint-up(xxl) {
      padding: 90px;
    }

    &__title {
      font-weight: $font-weight-bold;
      // font-size: $h5-font-size + 0.2;
      margin-bottom: 0;
    }

    &__desc {
      font-size: $font-size-base;

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }

      [lang='zh-HK'] & {
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
          margin-top: 10px;
        }
      }
    }

    .two-col-image-text__content__list {
      margin-top: 24px;

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 32px;
      }

      &-item {
        margin-bottom: 24px;
        
        @include media-breakpoint-up(sm) {
          width: 33.33%;
          padding-right: 24px;

          &:nth-child(1),
          &:nth-child(2) {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            min-width: 33.33%;
          }
        }

        &__img {
          margin-bottom: 12px;

          img {
            max-width: 64px;
            height: auto;
          }
        }

        &__title {
          font-size: $font-size-large;
          color: $gray-darker;
          font-weight: $font-weight-normal;

          @include media-breakpoint-up(xl) {
            font-size: $h5-font-size;
          }
        }

        &__txt {
          font-size: $font-size-base;
          color: $gray-darker;
          margin: 0;
        }
      }
    }

    p {
      margin-top: 16px;

      @include media-breakpoint-up(xl) {
        margin-top: 24px;
      }
    }

    .btn {
      cursor: pointer;
      margin-top: 24px;

      @include media-breakpoint-up(xl) {
        margin-top: 32px;
      }
    }
  }

  &--content-left {
    .two-col-image-text {
      &__content {
        @include media-breakpoint-up(lg) {
          padding-right: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xl) {
          padding-right: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xxl) {
          padding-right: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
        }
      }
    }
  }

  &--content-right {
    .two-col-image-text {
      &__image {
        order: 2;
      }

      &__content {
        order: 1;

        @include media-breakpoint-up(lg) {
          padding-left: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xl) {
          padding-left: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xxl) {
          padding-left: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
        }
      }
    }
  }

  &--text-light {
    color: $white;

    .two-col-image-text__content__title,
    .two-col-image-text__content__list-item__title,
    .two-col-image-text__content__list-item__txt {
      color: inherit;
    }

    .btn-outline-with-icon {
      @include button-outline-variant($white, $gray-darker, $gray-lightest, $gray-lightest);
    }
  }

  &.text-align-center {
    text-align: center;
  }

  &.text-align-left {
    text-align: left;
  }

  &.text-align-right {
    text-align: right;
  }

  &.not-full-width {
    .two-col-image-text__content {
      @include media-breakpoint-up(lg) {
        padding: 30px;
      }
  
      @include media-breakpoint-up(xxl) {
        padding: 90px;
      }
    }
  }

  &.has-top-padding {
    margin-top: 50px;
  }

  &.has-bottom-padding {
    margin-bottom: 50px;
  }
}