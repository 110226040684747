@import '@assets/reuse-mixin.scss';

.quick-access {
  position: fixed;
  bottom: 68px;
  right: 24px;
  z-index: $HEADER_STICK_ZINDEX;

  &__icon {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $os-orange;
    border: 4px solid $os-orange;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    

    &:hover {
      border-color: $os-orange-dark;
    }
    
    &.active {
      border-color: $os-orange-dark;
      transform: rotate(45deg);
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__wrap {
    position: relative;

    .overlay-black{
      top: $CP_HEADER_HEIGHT +$CP_DEMO_STATUS;
    }
  }

  &__overlay {
    z-index: $PRODUCT_DETAIL_HEADER_ZINDEX;
  }
}

.access-listing {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  
  &.show {
    bottom: 100%;
    opacity: 1;
    visibility: visible;
  }

  >li {
    margin-bottom: 20px;
    cursor: pointer;

    &.active-access {
      .qA-popup {
        right: 0;
        opacity: 1;
        visibility: visible;

        &--center {
          right: 50%;
        }
      }
    }
  }

  &__icon {
    width: 53px;
    height: 53px;
    background-color: $white;
    border: 2px solid $os-orange-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: $os-orange;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}