@import '@assets/reuse-mixin.scss';

.warranty-product-page {
  &__button {
    margin-bottom: 36px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }

    .my-account-section__back-link {
      margin-bottom: 0;

      &:hover {
        text-decoration: underline;
      }
    }
    
    &__register {
      margin-bottom: 18px;
      text-align: center;

      @include media-breakpoint-up(lg) {
        order: 2;
      }
    }
  }

  .col-empty {
    @include media-breakpoint-up(lg) {
      order: 3;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: calc(100% / 3);
    }
  }

  &__list {
    &__wrap {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;

        .warranty-product {
          width: 50%;

          &:nth-child(odd) {
            padding-right: 20px;
          }

          &:nth-child(even) {
            padding-left: 20px;
          }
        }
      }
    }
  }
}