@import '@assets/reuse-mixin.scss';

.image-main {
    position: relative;
    
    &__background {
        overflow: hidden;
        height: 0;
        padding-bottom: 850/1920 * 100%;
    
        @include media-breakpoint-up(md) {
            padding-bottom: 850/1920 * 100%;
        }
    
        img,
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
    
        &.no-ratio {
          height: auto;
          padding-bottom: 0;
    
          img,
          video {
            position: static;
            width: 100%;
            height: auto;
          }
        }
      }
      &.skeleton__item {
        border-bottom: 1px solid $gray-200;
    
        .loading__default {
          @include centerer(true, true)
        }
      }
}