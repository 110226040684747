@import '@assets/reuse-mixin.scss';

.coupon-component {
  margin-bottom: 6px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 12px;
    padding-top: 10px;
  }

  &__title {
    // margin-bottom: 18px;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
    }
  }

  &__item {
    padding: 10px 16px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: $os-orange-lighter;
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    margin: 0 18px 12px 0;
    cursor: pointer;
    color: $black;

    @include media-breakpoint-up(lg) {
      padding: 8px 16px;
      font-size: $font-size-small;
    }
  }
}