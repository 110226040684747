@import '@assets/reuse-mixin.scss';

.wishlist-widget {
  &__content {
    &__product-info {
      display: flex;
      align-items: center;

      &__image {
        width: 140px;
        height: 0;
        padding-bottom: 140px;
        position: relative;

        @include media-breakpoint-up(xl) {
          width: 200px;
          padding-bottom: 200px;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__text {
        width: calc(100% - 140px);

        @include media-breakpoint-up(lg) {
          padding-left: 20px;
        }

        @include media-breakpoint-up(xl) {
          width: calc(100% - 200px);
          padding-left: 40px;
        }

        @include media-breakpoint-up(xxl) {
          padding-left: 60px;
        }
      }

      &__name,
      &__color,
      .price--selling {
        margin-bottom: 0;
        font-size: $font-size-large;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h3-font-size - 0.2;
        }
      }

      &__name {
        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
        }
      }

      &__price {
        margin-top: 18px;

        @include media-breakpoint-up(lg) {
          margin-top: 8px;
        }

        .price {
          &--selling {
            display: inline-block;
            margin-right: 16px;

            @include media-breakpoint-up(md) {
              display: block;
            }
          }

          &--old {
            font-size: $font-size-smaller;
            color: $gray;
            text-decoration: line-through;

            @include media-breakpoint-up(lg) {
              font-size: $font-size-large;
            }
          }
        }
      }
    }
  }

  &.skeleton__item {
    position: relative;
    .loading {
      @include centerer(true, true)
    }
  }
}