@import '@assets/reuse-mixin.scss';

.reviews-summary {
  padding: 0 20px;
  text-align: center;
  color: $gray-darker;
  margin-bottom: 32px;

  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    &__star {
      .review-star-rating {
        .rating-star-icon {
          width: 20px;
          height: 20px;

          @include media-breakpoint-up(sm) {
            width: 24px;
            height: 24px;
          }

          @include media-breakpoint-up(md) {
            width: 26px;
            height: 26px;
          }

          @include media-breakpoint-up(lg) {
            width: 28px;
            height: 28px;
          }
        }
      }
    }

    &__text {
      font-size: $h4-font-size;
      font-weight: $font-weight-normal;
      margin-left: 16px;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
      }

      &__star-total {
        color: $gray;
      }
    }
  }

  &__note {
    margin: 12px 0 0;
    letter-spacing: 0.8px;
  }

  &__rating-total {
    margin: 8px 0 0;
    color: $gray;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }
  }

  &__label {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    margin: 18px 0 0;

    @include media-breakpoint-up(lg) {
      margin: 24px 0 0;
      font-size: $font-size-large;
    }
  }

  &__note-share {
    margin: 8px 0 0;
  }

  &__btn {
    margin: 24px 0 0;
  }

  //ReviewsSummaryShimmer
  &__title {
    .text-line-heading-center {
      width: 80% !important;
    }
  }

  &__rating {
    .text-line-30-center {
      width: 85% !important;
    }
  }

  &__note {
    .text-line-20-center {
      width: 20% !important;
    }
  }

  &__label {
    .text-line-20-center {
      width: 30% !important;
    }
  }

  &__note-share {
    .text-line-20-center {
      width: 85% !important;
    }
  }

  &__btn {
    .button-line-center {
      width: 40% !important;
    }
  }
}