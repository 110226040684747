@import '@assets/reuse-mixin.scss';

.store-locator {
  &__content {
    &__list {
      @include media-breakpoint-up(xl) {
        padding: 24px 0 0;
      }

      &__search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
      }

      &__store {
        overflow-y: auto;
        max-height: 733px; // Fixed follow design
        position: relative;
        margin-right: -10px;
        padding-right: 10px;
        padding-bottom: 10px;

        @include media-breakpoint-up(lg) {
          margin-right: -20px;
          padding-right: 20px;
          padding-bottom: 30px;
          max-height: calc((#{$container-lg} / 12 * 8) - (24px + 56px + 55px) - #{$grid-gutter-width});
        }

        @include media-breakpoint-up(xl) {
          max-height: calc((#{$container-xl} / 12 * 8) - (24px + 56px + 24px + 55px) - #{$grid-gutter-width}); //INFO: 24px: padding-top, 56px + 24px: height of search input + margin-bottom, 55px: height of tag list
        }

        @include media-breakpoint-up(xxl) {
          max-height: calc((#{$container-xxl} / 12 * 8) - (24px + 56px + 24px + 55px) - #{$grid-gutter-width});
        }

        @include scrollbar-custom($secondary, 3px);
      }
    }
  }

  &__search {
    &__input {
      position: relative;
      flex-grow: 1;
      height: 48px;

      @include media-breakpoint-up(lg) {
        height: 56px;
      }

      &__item {
        width: 100%;
        height: 100%;
        border: 1px solid $gray;
        outline: none;

        &:focus {
          border-color: $gray;
          box-shadow: none;
        }
      }

      &__icon {
        position: absolute;
        left: 0;
        top: 7px;
        padding: 8px 15px;

        &.icon-search {
          left: auto;
          right: 10px;
        }
      }
    }

    &__btn {
      display: flex;

      &__item {
        width: 48px;
        height: 48px;
        border: 1px solid $gray;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
          height: 56px;
          width: 56px;
        }
      }
    }
  }

  .store-item {
    padding: 16px;
    font-size: $font-size-base;
    margin-bottom: 16px;
    transition: 0.3s;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s;

    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
    }

    @include media-breakpoint-up(xl) {
      padding: 16px 24px;
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h5-font-size;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid $gray-dark;
    }

    &__info {
      margin-bottom: 28px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 4px;
      }

      &__type {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $gray;

        &__name {
          display: block;
          margin: 0 0 0 12px;
          font-weight: $font-weight-bold;
          font-size: $font-size-large;
          text-transform: uppercase;
        }

        &__icon {
          width: 32px;
          height: 26px;
          background-position: 0 -27px;

          &--roadshow {
            height: 32px;
            display: inline-block;
            background: url('../../../../../assets/images/icons/location-icon.svg') center no-repeat;
            background-size: contain;
          }
        }
      }

      &__name {
        color: $gray-darker;
        font-size: $h4-font-size;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
        }
      }

      &__time {
        font-size: $font-size-large;
        font-weight: $font-weight-normal;
        margin-bottom: 18px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 14px;
        }
      }

      &__address,
      &__phone {
        color: $gray;
      }

      a {
        &:hover {
          color: $gray-darker;
        } 
      }

      &__address {
        position: relative;
        margin-bottom: 5px;

        &__text {
          display: inline-block;
          width: 90%;
        }

        &__icon {
          color: $black;
          position: absolute;
          top: 0;
          right: 0;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__btn {
      display: flex;
      justify-content: space-between;

      &__item {
        margin-right: 10px;
        padding: 10px;
        box-shadow: none;

        @include media-breakpoint-up(lg) {
          margin-right: 16px;
        }

        &:last-child {
          margin-right: 0;
        }

        &--visit-link {
          padding: 10px 0;
          //color: $secondary;
          color: $link-color;
          text-align: left;
        }

        &--book {
          min-width: 160px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include button-outline-variant($gray-darker, $gray-darker, $primary, $primary);

          &:hover,
          &:active {
            color: $white;
          }
        }
      }
    }

    &__load-more {
      text-align: center;
      padding: 10px 0;

      &__text {
        color: $os-blue;
        text-decoration: underline;
      }
    }
  }

  .store-item--activated--hovered {
    border: 1px solid $gray-dark;
  }

  .store-item--hovered {
    border: 1px solid $gray-dark;
  }

  ul.store-locator__content__list__tag {
    justify-content: center;
    margin-bottom: 24px;
    
    .wellness-filter-tag__list {
      &__item-wrap {
        width: 30%;
        max-width: 104px;
        margin-right: 8px;

        @include media-breakpoint-up(md) {
          max-width: 130px;
        }

        @include media-breakpoint-up(xl) {
          margin-right: 16px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &__item {
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: $gray-darker;
        }
      }
    }
  }
}