@import '@assets/reuse-mixin.scss';

.marquee {
    --marquee-width: 100vw;
    --marquee-height: 250px;
    --marquee-elements-displayed: 20;
    --marquee-element-width: 384px;
    --marquee-animation-duration: calc(var(--marquee-elements) * 6s);

    width: var(--marquee-width);
    height: var(--marquee-height);
    color: transparent;
    overflow: hidden;
    position: relative;
}

.marquee-content {
    margin: 0 auto;
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    animation: scrolling var(--marquee-animation-duration) linear infinite;

    &:hover {
        animation-play-state: paused;
        cursor: pointer;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: var(--marquee-element-width);
        max-height: 100%;
        white-space: nowrap;
        padding: 0 10px;

        img {
            height: 200px;
            box-shadow: $banner-item-box-shadow;
            // border-radius: 10px;
            transition: box-shadow .3s, transform .3s;

            &:hover {
                cursor: pointer;
                transform: translateY(-5px);
                box-shadow: $banner-item-box-shadow-hover;
            }
        }
    }
}