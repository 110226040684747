@import '@assets/reuse-mixin.scss';

.tierBenefit-anchor {
  &__title {
    padding-bottom: 24px;
  }

  &__list {
    @include media-breakpoint-up(lg) {
      padding-left: 48px !important;
    }

    &__item {
      font-size: $h4-font-size-mobile;
      color: $gray-1000;
      //text-transform: capitalize;
      padding-bottom: 12px;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
      
      &:last-child {
        padding-bottom: 0px;
      }

      // a {
      //   color: $gray-1000;
      // }
    }
  }
}