@import '@assets/reuse-mixin.scss';

.gift-unavailable-popup {
  margin: -24px auto;
  max-width: 560px;

  @include media-breakpoint-up(md) {
    margin: -32px auto 0;
  }

  &__group {
    margin-top: 36px;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 325px;
    }
  }

  &__desc {
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }

  &__btn {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }

    &__item {
      margin-top: 18px;

      &:first-child {
        margin-top: 0;
      }

      .btn {
        width: 200px;

        @include media-breakpoint-up(lg) {
          width: 240px;
        }
      }
    }
  }
}