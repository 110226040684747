@import '@assets/reuse-mixin.scss';

.promotion-detail-bundle-list {
  padding-top: 36px;

  @include media-breakpoint-up(lg) {
    padding-top: 48px;
  }

  &__title {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  .center-item {
    justify-content: center;
  }

  &__col {
    margin: 15px 0;

    @include media-breakpoint-up(xl) {
      margin: 37px 0;
    }
  }

  &__button {
    min-width: 164px;
    margin-top: 15px;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      min-width: 240px;
      margin-top: 10px;
    }
  }
}