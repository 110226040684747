@import '@assets/reuse-mixin.scss';

.slideup-banner {
  background-color: $white;
  width: 100%;
  position: fixed;
  z-index: 5;
  transition: .5s all linear;
  animation-name: slideUp;
  animation-duration: .5s;
  bottom: 0;

  &__button-close {
    width: 100%;
    position: relative;
    z-index: 10;

    .close-button {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: $gray;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 8px;
      padding: 6px;
      @include media-breakpoint-up(sm) {
        right: 16px;
        top: 16px;
      }

      .icon-close {
        color: $white;
        font-size: 10px;
      }
    }
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  width: 100%;
    //  height: 100%;
    //  left: 0;
    //  top: 0;
    //  background-color: rgba(0, 0, 0, .1);
    //}

    .background-item {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    min-height: 180px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 5;
    @include media-breakpoint-up(lg) {
      min-height: 270px;
      padding: 50px 0;
    }

    &.slideup-banner__with-countdown {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 420px;
      padding: 20px 0;

      .slideup-banner {
        &__countdown {
          margin-bottom: 15px;
          @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
          }
        }

        &__content-container {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &__left {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          @include media-breakpoint-up(xxl) {
            max-width: calc(100% / 2);
          }

          &-title {
            margin-bottom: 15px;
            font-size: $h5-font-size;
            @include media-breakpoint-up(lg) {
              margin-bottom: 20px;
            }
          }

          &-content {
            font-size: $font-size-base;
            margin-bottom: 15px;
            @include media-breakpoint-up(lg) {
              margin-bottom: 20px;
            }
          }

          .countdown-timer {
            &__container {
              padding: 5px 10px;
              @include media-breakpoint-up(sm) {
                padding: 10px 20px;
              }
            }

            &__title {
              font-size: $font-size-base;
              margin-bottom: 10px;
            }

            &__detail {
              gap: 15px;
              @include media-breakpoint-up(sm) {
                gap: 20px;
              }

              &-item {
                .timer-box {
                  width: 55px;
                  height: 45px;
                  margin-bottom: 5px;
                  @include media-breakpoint-up(sm) {
                    width: 65px;
                    height: 55px;
                  }

                  & > p {
                    font-size: $h3-font-size;
                    @include media-breakpoint-up(sm) {
                      font-size: 4.2rem;
                    }
                  }
                }

                &:not(:last-child) {
                  .timer-box:after {
                    width: 15px;
                    @include media-breakpoint-up(sm) {
                      width: 20px;
                    }
                  }
                }
              }

              &-label {
                font-size: $font-size-smaller;
              }
            }
          }

          &.content-first {
            .slideup-banner__content-detail {
              order: 1;
            }

            .slideup-banner__countdown {
              order: 2;
            }
          }

          &.countdown-first {
            .slideup-banner__content-detail {
              order: 2;
            }

            .slideup-banner__countdown {
              order: 1;
            }
          }
        }

        &__right {
          margin-top: 0;
          margin-right: 0;

          .btn {
            font-size: $font-size-large;
          }

          &.cta-button {
            &__bottom {
              &-left {
                @include media-breakpoint-up(md) {
                  margin-left: 0;
                  align-self: flex-start;
                }
              }

              &-right {
                @include media-breakpoint-up(md) {
                  margin-left: 0;
                  align-self: flex-end;
                }
              }

              &-center {
                @include media-breakpoint-up(md) {
                  margin-left: 0;
                  align-self: center;
                }
              }
            }
          }
        }
      }

      &.cta-position__right {
        .slideup-banner {
          &__content-container {
            width: 100%;
            display: flex;
            position: relative;
            @include media-breakpoint-up(md) {
              flex-direction: row;
            }
            @include media-breakpoint-up(xxl) {
              max-width: unset;
            }
          }

          &__left {
            @include media-breakpoint-up(xxl) {
              max-width: calc(100% / 3);
            }
          }

          &__right {
            @include media-breakpoint-up(md) {
              margin-left: 20px;
            }
            @include media-breakpoint-up(lg) {
              margin-left: 40px;
            }
            @include media-breakpoint-up(xxl) {
              position: absolute;
              right: 0;
            }

            &.cta-button {
              &__middle-right {
                @include media-breakpoint-up(md) {
                  align-self: center;
                }
                @include media-breakpoint-up(xxl) {
                  top: 0;
                  bottom: 0;
                }
              }

              &__top-right {
                @include media-breakpoint-up(md) {
                  align-self: flex-start;
                }
                @include media-breakpoint-up(xxl) {
                  top: 0;
                }
              }

              &__middle-bottom__right {
                @include media-breakpoint-up(md) {
                  align-self: flex-end;
                }
                @include media-breakpoint-up(xxl) {
                  bottom: 0;
                }
              }
            }
          }

          &__countdown {
            @include media-breakpoint-up(md) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &__content {
    &-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }
  }

  //&__left, &__right {
  //  width: 100%;
  //}

  &__left {
    color: $cp-gray-light;
    text-align: center;
    flex: 1;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }

    &-title {
      font-size: $h3-font-size-mobile;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }
    }

    &-content {
      font-weight: $font-weight-base;
      font-size: $h5-font-size-mobile;
      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      margin-left: 40px;
      margin-top: 0;
    }

    .btn {
      font-size: $h5-font-size-mobile;
      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }
  }

  &.closed-slideup {
    bottom: -100%;
  }
}

@keyframes slideUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}