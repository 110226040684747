@import '@assets/reuse-mixin.scss';

.customer-info {
  &__form {
    &__cus-name {
      margin: 8px 0 24px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }
    }
  }
}

.cp-form {
  &__wrap {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__left,
  &__right {
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &__left {
    @include media-breakpoint-up(md) {
      padding-right: 12px;
    }
  }

  &__right {
    @include media-breakpoint-up(md) {
      padding-left: 12px;
    }
  }

  &__bottom {
    width: 100%;
    text-align: center;
  }

  &__label {
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    color: $gray-darker;
    margin-bottom: 8px;
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;

    &__item {
      min-width: 128px;
      margin-right: 18px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &--center {
      justify-content: center;
    }
  }

  &__buttonSignature{
    @extend .cp-form__button;
    justify-content: start;
    margin-top: 5px;

    &__item{
      margin-right: 5px;
      text-align: center;
      font-size: 14px;
      padding: 5px 15px;
      font-size: 12px;
    }
  }

  &__view-profile {
    display: block;
    width: 100%;
    text-align: center;
    margin: 14px 0 32px;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 40px);

    &--item-w-50 {
      .cp-form__group__item {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    &__item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    .cp-form__group__item {
      .--rectangle > label,
      .cp-select__control.css-yk16xz-control {
        margin-bottom: -1px;
        margin-right: -1px;
      }
    }

    &__left {
      width: 80px;
      border: 1px solid $border-form;
      padding: 7px 12px;
      height: 100%;
      margin-right: -1px;
      max-height: 36px;

      &__text {
        color: $gray-darker;
      }
    }

    &__right {
      width: calc(100% - 80px);
      position: relative;

      .cp-icon {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
  }

  &__add-new {
    display: block;
    text-align: right;
    padding-right: 32px;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
  }

  &__icon-left {
    width: 40px;
  }

  &__radio-right {
    width: 32px;
    height: 24px;
    padding-left: 8px;
  }

  &__email,
  &__phone {
    width: calc(100% - 32px);
  }

  &__phone {
    display: flex;

    &__code {
      width: 80px;
    }

    &__number {
      width: calc(100% - 80px);
    }

    .cp-select__control.css-yk16xz-control {
      margin-right: -1px;
    }
  }

  &__social-icon {
    display: flex;

    &__item {
      min-width: 24px;
      height: 24px;
      padding: 5px 8px;
      border-radius: 17px;
      border: 1px solid $os-orange;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &__text {
        font-size: $font-size-smaller;
      }

      .cp-icon {
        width: 24px;
        height: 18px;
      }
    }
  }

  &__value-text {
    color: $gray-darker;
    &.phone-value {
      flex: 1;
    }

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }
  }

  &--existing-cus {
    .cp-form {
      &__social-icon__item {
        padding: 0;
        border: none;
        min-width: 0;
        margin-right: 12px;
      }
    }
  }

  .form-group {
    margin-bottom: 18px;

    &--mb-30 {
      margin-bottom: 30px;
    }
    &-custom__space{
      padding: 12px 0;
    }
  }

  .cp-input {
    input[type='text'],
    input[type='tel'] {
      width: 100%;
    }
  }

  .cp-select {
    width: 100%;

    &__customize {
      .cp-select__control {
        .cp-select__value-container {
          .cp-select__multi-value {
            background-color: unset;

            &:not(:first-child) {
              display: none;
            }

            .cp-select__multi-value__label {
              color: $gray-darker;
              font-size: $font-size-small;
              font-weight: $font-weight-normal;
            }

            .cp-select__multi-value__remove {
              display: none;
            }
          }
        }

        .cp-select__indicators {
          .cp-select__clear-indicator {
            visibility: hidden;
          }
        }
      }

      &-selected__item {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .item {
          background-color: $os-orange-lighter;
          padding: 12px 16px;
          display: flex;
          &-text {
            color: $black;
            font-size: $font-size-smaller;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
          }

          .item-remove__icon {
            margin-left: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .1s linear;
            cursor: pointer;

            &:hover {
              color: $black;
            }

            &.icon-close {
              &:before {
                font-size: $font-size-smaller;
              }
            }
          }
        }
      }
    }
  }

  .osim-checkbox-label {
    color: $cp-gray;
  }

  .form-group--checkboxes__label {
    color: $cp-gray;
    margin-bottom: 24px;
  }

  .phone-value,
  .mail-value {
    display: inline-block;
    margin-right: 20px;
  }

  .eye-icon:hover {
    cursor: pointer;
  }

  .d-flex {
    display: flex;
    align-items: center;

    &--space-between {
      justify-content: space-between;
    }
  }

  .w-60 {
    width: 60%;
  }

  .w-70 {
    width: 70%;
  }
}
