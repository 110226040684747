@import '@assets/reuse-mixin.scss';

.variant-buying {
  .pay-deposit {
    padding-bottom: 8px;
    
    @include media-breakpoint-up(lg) {
      padding-bottom: 16px
    }

    &__box {
      display: flex;
      align-items: center;
      padding-bottom: 16px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 22px;
      }
    }

    &__number {
      width: 18px;
      height: 18px;
      color: $os-blue;
      border: 1px solid $os-blue;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      position: relative;
      z-index: 2;
      background: $white;

      &--before::before {
        content: '';
        background: $os-blue;
        width: 1px;
        height: 40px;
        position: absolute;
        left: 8px;
        bottom: -40px;
        z-index: 1;
        
        @include media-breakpoint-up(lg) {
          left: 11px;
          height: 50px;
          bottom: -50px;
        }
      }
      
      @include media-breakpoint-up(lg) {
        width: 24px;
        height: 24px;
      }
    }

    &__content {
      &__info {
        font-size: $font-size-smaller;
        color: $gray-900;
        
        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
        }

        &--fontRegular {
          font-weight: $font-weight-normal;
        }
      }

      .promotion-detail-bundle__header__text__name {
        .countdowntimer--number {
          font-weight: $font-weight-bold;
          font-size: $font-size-smaller;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
          }
        }
      }
    }

    &__popup {
      margin-top: 2px;
      
      @include media-breakpoint-up(lg) {
        margin-top: 8px;
      }

      &__info {
        font-size: $font-size-smaller;
        color: $link-color;
        text-decoration: underline;
        cursor: pointer;
        
        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
        }
      }
    }
  }
}

.variant-buying-list {
  padding-bottom: 8px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  &__list {
    padding-bottom: 10px;
    border-bottom: 1px solid $gray;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }
  
  &__item-wrap {
    margin-bottom: 8px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gray-light;
    padding: 5px 16px 5px 12px;
    transition: all 0.3s;
    height: 100%;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      padding: 12px 10px;
    }

    @include media-breakpoint-up(xl) {
      padding: 12px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 33px 24px;
    }

    &:hover {
      border: 1px solid $gray-darker;
    }

    &.active {
      border: 1px solid $gray-darker;
    }

    &.disable {
      color: $gray-light;
      pointer-events: none;
    }

    &__group {
      width: calc(100% - 56px);
      padding-left: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-up(lg) {
        flex-direction: column;
        height: 100%;
        width: calc(100% - 80px);
        padding-left: 0;
        align-items: flex-end;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 10px;
      }

      @include media-breakpoint-up(xxl) {
        padding-left: 15px;
        width: calc(100% - 128px);
      }
    }

    &__image {
      width: 56px;
      height: 56px;

      @include media-breakpoint-up(lg) {
        width: 72px;
        height: 72px;
        margin-top: -20%;
      }

      @include media-breakpoint-up(xl) {
        width: 80px;
        height: 80px;
      }

      @include media-breakpoint-up(xxl) {
        width: 128px;
        height: 128px;
        margin-top: 0;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      max-width: 50%;
      word-break: break-word;

      @include line-clamp(4);

      @include media-breakpoint-up(lg) {
        // font-size: $font-size-large;
        // height: 36px;
        margin-bottom: 12px;
        width: 100%;
        max-width: none;
        text-align: right;
        line-height: 1.2;
      }

      @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
        // height: 43px;
        margin-bottom: 20px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
        min-height: 50px;
      }
    }

    &__price {
      text-align: right;

      &__selling-price {
        display: block;
        font-weight: bold;
        font-size: $font-size-base;

        @include media-breakpoint-up(xl) {
          font-size: $font-size-large;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h5-font-size;
        }
      }

      &__list-price {
        font-size: $font-size-smaller;
        color: $gray;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-small;
        }
      }
    }

    &__status {
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        padding-bottom: 18px;
        text-align: right;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }
    }
  }
}

.variant-buying-list-cp {
  .variant-buying-list__item-wrap {
    margin-bottom: 16px;
  }

  .variant-buying-list__item {
    padding: 8px 12px;

    &__image {
      width: 56px;
      height: 56px;
      margin: 0;
    }

    &__group {
      flex-direction: row;
      align-items: center;
      padding-left: 16px;
    }

    &__name {
      text-align: left;
      margin: 0;
      height: auto;
      font-size: $font-size-base;
      color: $gray-900;
      font-weight: $font-weight-normal;
    }

    &__price {
      &__selling-price {
        font-size: $font-size-base;
        color: $gray-900;
      } 

      &__list-price {
        font-size: $font-size-smaller;
      }
    }
  }
}