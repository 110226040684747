@import '@assets/reuse-mixin.scss';

.my-account-section {
  padding: 16px 0 32px;
  border-top: 1px solid $gray;
  color: $gray-darker;

  @include media-breakpoint-up(lg) {
    padding: 24px 24px 62px;
    margin-bottom: 40px;
    border: 1px solid $gray;
  }

  &:last-child {
    border-bottom: 1px solid $gray;
    margin-bottom: -1px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }

  &__heading {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 48px;
    }

    &__title {
      margin-bottom: 0;
      font-weight: $font-weight-normal;
      font-size: $h5-font-size;
      line-height: 1;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size + 0.4;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h3-font-size;
      }
    }

    &__label {
      font-size: $font-size-base;
      margin-top: 16px;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
        margin-top: 8px;
      }

      &--mobile {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__content {
    @include media-breakpoint-up(xl) {
      padding-left: 16px;
    }

    &__link-explore {
      font-size: $font-size-base;
      padding: 12px 0 6px;
      display: block;
      text-align: center;
    }

    &__btn-add {
      width: 200px;

      @include media-breakpoint-up(lg) {
        width: 240px;
      }
    }

    a.my-account-section__content__btn-add {
      color: $gray-darker;
    }

    &--empty {
      text-align: center;
    }
  }

  &__btn-link {
    cursor: pointer;
    //color: $os-blue;
    color: $link-color;
    display: inline-block;
    margin-left: 15px;
    border: none;
    background: transparent;
    font-weight: $font-weight-normal;

    // @include media-breakpoint-up(lg) {
    //   font-weight: $font-weight-light;
    // }

    &:hover {
      text-decoration: underline;
    }
    
    &:first-child {
      margin-left: 0;
    }

    &.disable {
      color: $gray;
      cursor: default;

      &:hover {
        text-decoration: none;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .my-account-section__btn-link {
    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }
  }

  &__back-link {
    text-transform: uppercase;
    //color: $os-blue;
    color: $link-color;
    margin-bottom: 24px;
    display: block;
    text-align: center;
    font-weight: bold;

    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-bottom: 40px;
    }
  }

  &--first-load {
    .my-account-section__btn-link,
    .my-account-section__btn-link {
      color: $gray;
      cursor: none;
    }
  }

  a {
    color: $link-color;
    font-weight: $font-weight-normal;

    &:hover {
      text-decoration: underline;
    }
  }
}

.my-account-widget {
  margin-bottom: 50px;
  
  &__heading {
    padding-bottom: 4px;
    border-bottom: 1px solid $gray;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 8px;
      margin-bottom: 32px;
    }

    &__title {
      font-weight: $font-weight-normal;
      margin-bottom: 0;
    }
  }

  &__content {
    color: $gray-darker;
  }
}

.orders-widget {
  &__content {
    &__line {
      margin-bottom: 4px;
      display: block;
      word-break: break-word;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
      }
    }

    &__id {
      max-width: 70%;
      font-weight: $font-weight-normal;
      font-size: $font-size-large;

      @include media-breakpoint-up(lg) {
        max-width: 75%;
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h4-font-size;
      }
    }

    &__view-detail {
      //color: $os-blue;
      color: $link-color;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
    }

    &__product {
      color: #272727;
    }

    &__order-date {
      font-size: $font-size-smaller;
      color: $gray;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
      }
    }

    &__price {
      font-size: $font-size-smaller;
      color: $black;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }

    &__status {
      display: flex;
      align-items: center;

      &__icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;

        @include media-breakpoint-up(lg) {
          width: 12px;
          height: 12px;
          margin-right: 12px;
        }
      }

      &__text {
        font-weight: $font-weight-normal;
      }

      .status {
        &--Arranged,
        &--Completed,
        &--Shipped {
          background-color: $os-green;
        }

        &--Released,
        &--Completed,
        &--FullyRefunded {
          background-color: #1B6006;
        }

        &--PartialRefunded {
          background-color: #f1c40f;
        }

        &--PendingBalancePayment,
        &--PendingPaymentBalance {
          background-color: #95a5a6;
        }

        &--BalancePaymentExpired {
          background-color: #E31937;
        }

        &--Processing {
          background-color: $os-blue;
        }

        &--WaitingForAvailability {
          background-color: #D49AD1;
        }

        &--WaitingForPayment {
          background-color: $os-brown;
        }

        &--Cancelled {
          background-color: #FF0000;
        }

        &--NoPayment {
          background-color: #e0dac9;
        }

        &--WaitingForFullPayment {
          background-color: #cce3f2;
        }

        &--DeliveryArranged {
          background-color: $os-green;
        }

        &--OnHold {
          background-color: #F91C1C;
        }

        &--Pending {
          background-color: $gray-dark;
        }

        &--Problem {
          background-color: #F37021;
        }
      }
    }

    &__refund {
      &__text {
        display: inline-block;
        color: $black;
        margin-right: 6px;
      }
    }
  }
}