@import '@assets/reuse-mixin.scss';

.cp-table {
  width: 100%;
  font-size: $font-size-smaller;
  color: $black;

  thead {
    tr {
      border-bottom: 1px solid $gray-lighter;
    }
  } 

  tbody {
    tr {
      border-bottom: 1px solid $gray-lighter;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  th {
    color: $cp-gray-light;
    font-weight: $font-weight-normal;
  }

  th, td {
    padding: 16px 9px;

    &:first-child {
      padding-left: 24px;
    }
  }
}

.customer-listing {
  background-color: $cp-background;

  &__table {
    background-color: $white;
    padding: 8px 0 34px 0;
    width: 100%;
    max-height: 700px;
    overflow: auto;

    @include  scrollbar-custom();
    &::-webkit-scrollbar:vertical {
      height: auto;
      padding: 0 3px;
      background: $white;
    }

    &::-webkit-scrollbar {
      height: 2px;
    }

    .cp-table {
      min-width: 700px;
    }
  }
}

// Search No Result
.search-no-result {
  background-color: $cp-background;
  padding-bottom: 18px;

  &__content {
    background-color: $white;
    min-height: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    &__image {
      max-width: 80px;
      margin-bottom: 24px;
    }

    &__label {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      margin-bottom: 24px;
    }

    &__desc {
      color: $cp-gray;
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }

    &__btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__item {
        margin-bottom: 18px;

        &.btn {
          min-width: 168px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}