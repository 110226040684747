@import '@assets/reuse-mixin.scss';

.purchase-on-spot-completed {
  padding: 40px 0px;

  &__body {
    max-width: 480px;
    margin: 0px auto;
  }

  &__icon {
    text-align: center;
    padding-bottom: 18px;

    img {
      width: 48px;
      height: 48px;
    }
  }

  &__content {
    padding-bottom: 16px;
    
    &__title {
      text-align: center;
      color: $success;
      font-size: $h4-font-size-mobile;
      font-weight: $font-weight-normal;
      padding-bottom: 18px;
      margin: 0;
    }

    &__subtitle {
      text-align: center;
      color: $cp-gray;
      font-size: $font-size-base;
      padding-bottom: 32px;
      margin: 0;
    }

    &__lable {
      color: $cp-gray;
      font-size: $font-size-small;
      margin: 0;
      padding-bottom: 16px;
    }

    &__lable-bold {
      color: $gray-900;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      margin: 0;
      padding-bottom: 16px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
    }
  }

  &__btn {
    text-align: center;
    padding-bottom: 18px;

    a {
      font-size: $font-size-small;
    }
  }

  &__link {
    text-align: center;

    a {
      font-size: $font-size-small;
    }
  }
}