@import "@assets/reuse-mixin.scss";

$color-border-item: #d8d8d8;

.your-product-hub {
  padding: 40px 0 0;
  font-weight: 400;

  // INFO: set margin top to 0 for comp ProductAccessories
  & + .product-accessories {
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    color: $gray;
    font-size: $font-size-small;
    text-transform: uppercase;
    margin: 0 0 24px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.3rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
      margin: 0 0 32px;
    }
  }

  &__item {
    border-top: 1px solid $color-border-item;
    padding: 16px 9px 24px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      padding: 32px 40px 40px;
      width: calc(50% - 20px);
      margin: 0 40px 40px 0;
      border: 1px solid $color-border-item;

      &:first-child {
        width: 100%;
      }

      // &:last-child {
      //   width: 100%;
      //   margin-bottom: 0;
      //   margin-right: 0;
      // }

      &:nth-child(odd) {
        margin-right: 0;
      }
    }

    &__icon {
      margin-bottom: 20px;
      max-width: 72px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    &__title {
      font-weight: 400;
      margin-bottom: 4px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
      }
    }

    &__desc {
      text-align: center;
      max-width: 450px;
    }

    &__btn {
      margin-top: 18px;

      @include media-breakpoint-up(lg) {
        margin-top: 32px;
      }
    }
  }
}
