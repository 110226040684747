@import '@assets/reuse-mixin.scss';

.special-deal {
  // padding: 12px 0;
  padding: 20px 0;

  @include media-breakpoint-up(lg) {
    padding: 24px 0;
  }

  &__title {
    text-align: center;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &.frequently-bought-together {
    background-color: $os-orange-lightest;

    // @include media-breakpoint-up(lg) {
    //   padding: 36px 0;
    // }

    // .special-deal__list {
    //   margin-left: -$grid-gutter-width/2;
    //   margin-right: -$grid-gutter-width/2;

    //   @include media-breakpoint-up(md) {
    //     margin-left: 0;
    //     margin-right: 0;
    //   }
    // }
  }

  &__list__item {
    &:last-child {
      .product-deals__list__item__content {
        .question-tooltip-icon .item-tooltip__label {
          right: 100%;
          left: unset;
          margin-right: 4px;
          top: 40%;
          transform: translateY(-40%);

          &:after {
            transform: translateY(-50%) rotate(45deg);
            right: -4px;
            left: unset;
          }
        }
      }
    }
  }
}