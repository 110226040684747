@import '@assets/reuse-mixin.scss';

.cart-seller {
  // margin-bottom: 24px;
  margin-bottom: 18px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 38px;
  }

  &__title {
    // margin-bottom: 15px;
    margin-bottom: 8px;
    display: block;
    // font-weight: $font-weight-bold;
    font-weight: $font-weight-normal;
    font-size: $font-size-large;
    color: $gray-darker;
    line-height: 1;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      margin-bottom: 24px;
      font-weight: $font-weight-normal;
    }
  }

  &__description {
    text-transform: uppercase;
    font-weight: $font-weight-light;
    // font-size: $font-size-base;
    margin: 0;
    line-height: 1;
    color: $gray-darker;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }
}