@import '@assets/reuse-mixin.scss';

.os-section-heading {
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
  }

  &--text-light {
    color: $white;

    .os-section-heading {
      &__title {
        color: $white;
      }
    }
  }
}
