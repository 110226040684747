@import '@assets/reuse-mixin.scss';

.free-gift-item {
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cart-item {
      &-information {
        &__body {
          &__remove-btn {
            color: $link-color;
            line-height: 16px;
            top: calc(-16px - 9px); // INFO: 16px: text line-height; 9px: spacing of .cart-item-information__body__remove-btn with .cart-item-information__body__total-amount
            @include media-breakpoint-up(md) {
              line-height: 20px;
              top: calc(-20px - 9px); // INFO: 20px: text line-height; 9px: spacing of .cart-item-information__body__remove-btn with .cart-item-information__body__total-amount
            }
          }

          &__total-amount {
            top: 0;
          }
        }
      }

      &-total-price {
        border-top: 1px solid #acacac;
      }
    }
  }
}