@import'@assets/reuse-mixin.scss';

.cart-promotion-code-item {
  position: relative;
  padding: 12px 16px 12px 24px;
  font-size: $font-size-smaller;
  border: 1px solid $gray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;

  @include media-breakpoint-up(md) {
    font-size: $font-size-small;
  }

  @include media-breakpoint-up(lg) {
    padding: 16px 18px 16px 24px;
  }

  &__infor-icon {
    position: absolute;
    top: 15px;
    right: 18px;
    width: 16px;
    // height: auto;

    @include media-breakpoint-up(md) {
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__name {
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
    letter-spacing: 1.2px;
    margin-bottom: 4px;
    line-height: 2.4rem;
    padding-right: 40px;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      font-size: $h4-font-size;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 8px;
    }
  }

  &__description {
    margin-bottom: 10px;
    letter-spacing: 1.12px;

    @include line-clamp(2);
  }

  &__footer {
    align-items: flex-end;

    &__date {
      letter-spacing: 0.96px;
    }

    &__button {
      letter-spacing: 1.68px;
      color: $primary;
      font-weight: $font-weight-bold;
      background: none;
      border: none;
      outline: none;
      font-size: $font-size-small;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }
  }

  .left-light {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    width: 7px;
    background-color: $primary;
  }
}