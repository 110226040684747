@import "@assets/reuse-mixin.scss";

.expert-popup {
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }

  &__info {
    margin-bottom: 18px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 27px;
    }

    &__group {
      display: flex;
      margin-bottom: 10px;
    }

    &__image {
      width: 50%;

      @include media-breakpoint-up(lg) {
        width: 240px;
      }
    }

    &__text {
      width: 50%;
      padding-left: 10px;

      @include media-breakpoint-up(lg) {
        width: calc(100% - 240px);
        padding-left: 28px;
      }
    }

    &__name {
      // padding: 0 4px;
      margin-bottom: 8px;
    }

    &__designation {
      margin-bottom: 16px;
    }

    &__story {
      li {
        margin-bottom: 5px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 10px;
        }
      }

      &--mobile { 
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }

  &__content {
    font-size: $font-size-base;
    height: 28vh;
    overflow-y: auto;
    padding: 0 10px 0 5px;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }

    @include scrollbar-custom();
  }
}