@import '@assets/reuse-mixin';

.cart-mini {
  padding-top: 15px;
  text-transform: none;
  font-size: $font-size-small;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    padding-top: 24px;
  }

  &__group {
    height: 65%;

    @include media-breakpoint-up(md) {
      height: 73%;
    }
  }

  &__main {
    height: 100%;
  }

  &__header {
    // border-bottom: 1px solid $gray-300;
    padding: 0 20px 24px;
    text-align: center;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      padding: 0 32px 32px;
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h5-font-size;
    }

    a {
      color: $gray-dark;
    }

    p {
      word-break: break-word;
    }
  }

  ul.cart-mini__list-item {
    margin: 0 10px 10px 20px;
    padding-right: 10px;
    // max-height: 600px;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    @include scrollbar-custom();

    @include media-breakpoint-up(lg) {
      margin: 0 16px 10px 32px;
      padding-right: 16px;
    }

    .overlay-bottom {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 24px;
      background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }
  }

  &__item {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__footer {
    background-color: $os-orange-lighter;
    padding: 24px 20px;
    width: 100%;
    text-align: center;
    z-index: $Z_INDEX_NORMAL;

    @include media-breakpoint-up(lg) {
      padding: 40px 32px;
    }

    .icon {
      display: inline-block;
      font-size: 12px;
      font-weight: 700;
    }

    & > .cart-milestone > .cart-milestone__message {
      text-align: left;
    }
  }

  &__button {
    padding-bottom: 24px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      padding-bottom: 16px;
    }

    .btn {
      min-width: 200px;
      white-space: inherit;

      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }
}
