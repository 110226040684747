@import '@assets/reuse-mixin.scss';

.orders-detail {
  &__general-info {
    margin-bottom: 5px;

    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__id {
      margin-bottom: 4px;
      word-break: break-word;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
        font-weight: $font-weight-light;
      }
    }

    &__package-quantity {
      font-size: $font-size-large;
      margin-bottom: 4px;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
        margin-bottom: 8px;
      }
    }

    &__status {
      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
    }

    &__refund {
      margin-bottom: 4px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
      }
      
      &__text {
        display: inline-block;
        margin-right: 6px;
      }
    }
  }

  &__summary {
    &__wrap {
      @include media-breakpoint-up(xxl) {
        padding: 0 130px;
        margin: 0 auto;
      }
    }
  }

  .bg-Sand.two-column11 {
    margin-bottom: 24px;
  }
}