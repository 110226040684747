@import '@assets/reuse-mixin.scss';

.cp-paging {
  display: flex;
  justify-content: center;
  padding: 20px 0 !important;
  background: $cp-background;

  &__item {
    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    cursor: pointer;
    overflow: hidden;
    font-size: $font-size-smaller;
    color: $gray;

    &.disabled {
      color: $gray-light;
      cursor: inherit;
    }

    &.active {
      background: none;
      font-size: $font-size-large;
      color: $gray-darker;
      font-weight: $font-weight-bold;
    }

    [class^="icon-"] {
      font-size: $font-size-base;
    }

    &__value {
      padding: 4px;
      font-size: $font-size-smaller;
    }
  }
}