@import '@assets/reuse-mixin.scss';

.promotion-detail-bundle-item {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $gray-darker;

  &__thumb {
    max-width: 360px;
    margin: 0 auto 18px;
    
    @include media-breakpoint-up(xl) {
      margin: 0 auto 24px;
    }
    

    &__picture {
      display: block;
      padding-bottom: 100%;
      position: relative;

      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .list-tags {
    left: -20px;

    @include media-breakpoint-up(md) {
      left: 0;
    }
  }

  &__info {
    padding: 0 4px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  &__name {
    margin-bottom: 12px;
    font-size: $h4-font-size;
    
    @include media-breakpoint-up(md) {
      min-height: 62px;
      
      @include line-clamp(2);
    }
    
    @include media-breakpoint-up(xl) {
      min-height: 70px;
      margin-bottom: 16px;
      font-size: $h4-font-size + 0.4;
    }
    
    @include media-breakpoint-up(xxl) {
      min-height: 84px;
      font-size: $h3-font-size;
    }

    a {
      color: inherit;

      &:hover {
        color: $link-color;
        text-decoration: none;
      }
    }
  }

  &__price-group {
    @include media-breakpoint-up(md) {
      min-height: 43px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 50px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 55px;
    }
  }

  &__selling-price {
    font-size: $font-size-large;
    // letter-spacing: 0.9px;
    // line-height: 2.4rem;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size;
      // letter-spacing: 1.2px;
      // line-height: 2.7rem;
    }

    span {
      color: $os-green;
    }

    &.with-question-tooltip {
      display: flex;
    }
  }

  &__list-price {
    font-size: $font-size-smaller;
    // letter-spacing:0.6px;
    // line-height: 1.8rem;
    margin-bottom: 5px;
    color: $gray;
    text-decoration: line-through;

    &.usual-text {
      text-decoration: unset;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-small;
    }

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
      // letter-spacing: 0.8px;
      // line-height: 1.9rem;
      margin-bottom: 12px;
    }
  }

  &__description {
    margin: 8px 0 0;
    font-size: $font-size-base;
    @include line-clamp(2);

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      margin: 16px 0 0;
      font-size: $h5-font-size;
    }
  }

  &__link {
    margin: 16px 0 0 4px;

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }

    @include media-breakpoint-up(xl) {
      margin: 32px 0 0;
    }
  }
}