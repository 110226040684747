@import '@assets/reuse-mixin.scss';
@import '../CPForm.scss';

.cp-input {
  input[type='text'],
  input[type='tel'] {
    &:disabled {
      background-color: $gray-lighter;
    }
  }

  label {
    display: none;
  }

  &.type-select {
    display: block;
    text-align: left;
  
    > input[type='checkbox'],
    > input[type='radio'] {
      cursor: pointer;
      position: absolute;
      opacity: 0;
    }
  
    > label {
      display: inline-block;
      padding-left: 35px;
      margin: 0;
      position: relative;
      cursor: pointer;
      color: $gray-darker;
      font-size: $font-size-small;

      > span {
        display: inline-block;
        margin-top: 2px;
      }
  
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        border: 1px solid $gray-darker;
        border-radius: 2px;
        margin-right: 8px;
      }
  
      &::after {
        content: '';
        position: absolute;
        display: none;
        left: 8px;
        top: 4px;
        width: 8px;
        height: 12px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  
    input:checked ~ label {
      &::before {
        background-color: $gray-darker;
      }
    }
  
    input:checked ~ label:after {
      display: block;
    }

    &.--rectangle {
      > label {
        @extend .input-nomal;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        line-height: 1;
        letter-spacing: 0.28px;

        &::after,
        &::before {
          display: none;
        }

        > span {
          margin-top: 0;
          text-align: center;
        }
      }

      input:checked ~ label {
        background-color: $gray-lighter;
      }

      input:checked ~ label:after {
        display: none;
      }

      input:disabled ~ label {
        cursor: auto;
        color: $gray;
        border-color: $gray-lighter;
        background-color: $gray-lighter;
      }
    }

    &__checkbox {
      > label {
        &::before {
          border-radius: 50%;
        }

        &::after {
          width: 12px;
          height: 12px;
          left: 6px;
          top: 6px;
          border: none;
          border-radius: 50%;
        }
      }

      input:checked ~ label {
        &::before {
          background-color: transparent;
        }

        &::after {
          background-color: $gray-darker;
        }
      }
    }
  }
}