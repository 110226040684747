@import'../../../../assets/reuse-mixin.scss';

//component-join-us
.careers-join-us {
  // padding: 25px 0;

  // @include media-breakpoint-up(md) {
  //   padding: 40px 0;
  // }

  &__wrap {
    text-align: center;

    @include media-breakpoint-up(xl) {
      max-width: 1000px;
      margin: 0 auto;
    }

    .careers-join-us__heading {
      margin-top: 0;
      padding: 0;
    }
  }

  &__description {
    font-size: $font-size-base;
    color: $gray-900;
    padding: 0 0 25px 0;

    @include media-breakpoint-up(md) {
      font-size: $h6-font-size;
      padding: 0 0 32px 0;
    }
  }

  &__image {
    margin: 0 -20px;

    img {
      width: 100%;
      height: auto !important;
    }

    @include media-breakpoint-up(md) {
      margin: 0 auto;
    }
  }
}

.join-us {
  .join-us__heading {
    margin-top: 0;
    padding: 0;
  }

  &__title {
    font-size: $font-size-base;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      font-size: $h5-font-size;
      margin-bottom: 24px;
    }
  }

  &__content {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 80%;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  ul,
  li {
    list-style-type: disc;
    line-height: 26px;
    color: $secondary;
  }

  ul {
    padding-left: 18px;
  }

  li {
    margin-bottom: 5px;

    @include media-breakpoint-up(md) {
      margin-bottom: 12px;
    }
  }
}

// careers-join-tab
.careers-join-tab {
  &__heading {
    padding: 25px 0 10px;
    text-transform: capitalize;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 40px 0 22px;
    }
  }

  .join-tab {
    &__tabs {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 80%;
        max-width: 1000px;
        margin: 0 auto;
      }

      &__title {
        font-size: $font-size-base;
        color: $gray-600;
        padding: 24px 0 16px;

        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
          padding: 32px 0 24px;
        }
      }

      &__list {
        color: $secondary;

      }

      &__item {
        margin-bottom: 20px;
        text-transform: uppercase;
      }
    }
  }

  .nav-item {
    width: calc(100% / 3);
  }
}

//title distance
.title-distance {
  padding: 0 0 15px;
  text-transform: capitalize;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 0 0 20px;
  }
}