@import '../../../assets/reuse-mixin.scss';

.contact-form {
  margin-bottom: 50px;

  &__heading {
    text-align: center;
    margin-bottom: 20px;
  }

  &__content {
    width: 100%;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      max-width: 650px;
      margin: 0 auto 50px;
    }

    &__btn {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      &__item {
        width: 175px;
      }
    }
  }

  &__support {
    text-align: center;

    &__heading {
      margin-bottom: 15px;
    }

    &__desc {
      margin-bottom: 30px;
    }
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-check {
    margin-bottom: 10px;
  }

  .form-check-label {
    margin-left: 10px;
  }
}