@import'@assets/reuse-mixin.scss';

.cart-item-free-gift {
  padding: 8px 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  color: $gray-darker;
  font-weight: $font-weight-normal;
  margin-top: -1px;

  @include media-breakpoint-up(lg) {
    padding: 16px 0;
  }

  &.pseudo-border {
    &:last-child {
      border-bottom: 0;
    }
  }

  &__header {
    display: flex;
    margin-bottom: 4px;
    align-items: baseline;

    @include media-breakpoint-up(md) {
      margin-bottom: 8px;
    }

    &__icon {
      width: 30px;
      display: flex;
      align-items: center;
    }

    &__contents {
      width: calc(100% - 30px);

      // p {
      //   font-size: $font-size-small;
      //   color: $gray-darker;

      //   @include media-breakpoint-up(lg) {
      //     font-size: $font-size-base;
      //   }
      // }

      span.small-text {
        font-size: $font-size-base;

        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  &__body {
    padding: 0 30px;
    font-size: $font-size-large;
    font-weight: $font-weight-light;
    line-height: 2.8rem;

    @include media-breakpoint-up(md) {
      font-size: $h5-font-size;
      padding: 0 90px 0 30px;
    }

    p {
      margin: 0;
    }

    // p {
      // max-width: 265px;

      // @include media-breakpoint-up(md) {
        // max-width: 370px;
      // }
    // }

    // &__group {
    //   display: flex;
    //   margin-bottom: 24px;

    //   &:last-child {
    //     margin-bottom: 0;
    //   }
    // }
    
    // &__item-image {
    //   width: 65px;
    // }

    // &__item-contents {
    //   width: calc(100% - 65px);
    //   padding-left: 10px;

    //   h5 {
    //     margin-bottom: 10px;
    //     font-size: $font-size-base;
    //     max-width: 200px;
    //   }
    // }

    // &__item-information {
    //   span {
    //     padding: 0 15px;
    //     border-right:1px solid $gray-dark;

    //     &:first-child {
    //       padding-left: 0;
    //     }

    //     &:last-child {
    //       padding-right: 0;
    //       border-right: none;
    //     }
    //   }
    // }
  }
}