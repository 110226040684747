@import "@assets/reuse-mixin.scss";

.calendar-picker {
  font-family: $font-family-base;
  border: none;
  border-radius: 0;
  font-size: $font-size-small;
  width: 100%;
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
  }

  .react-datepicker {
    &__header {
      border: none;
      background-color: transparent;
    }

    &__month {
      margin: 0;
    }

    &__current-month {
      font-size: $h4-font-size-mobile;
      font-weight: $font-weight-light;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }

    &__navigation {
      top: 16px;
      height: 25px;
      width: 10px;

      span {
        &::before {
          border-width: 1px 1px 0 0;
          height: 10px;
          width: 10px;
          border-color: $gray-900;
        }
      }

      &:focus {
        outline: none;
        border: none;
      }
    }

    &__week {
      padding: 0;
    }

    &__day {
      width: calc(100% / 7 - 6px);
      height: 40px;
      margin: 0 6px 6px 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $gray-900;
      font-size: $font-size-small;

      &:last-child {
        margin-right: 0;
      }

      @include media-breakpoint-up(lg) {
        width: calc(100% / 7 - 16px);
        margin: 0 16px 16px 0;
        font-size: $font-size-base;
      }

      &:hover {
        background-color: transparent;
        border-radius: 0;
        border: 1px solid $gray-900;
        color: $gray-900;
      }

      &-name {
        width: calc(100% / 7 - 6px);
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }

        @include media-breakpoint-up(lg) {
          width: calc(100% / 7 - 16px);
          height: 50px;
          margin-right: 16px;
        }
      }

      &--selected {
        background-color: transparent;
        border-radius: 0;
        border: 1px solid #717171;
        color: $gray-900;
        font-weight: $font-weight-normal !important;
      }

      &--disabled {
        color: $cp-border-color;
        border-color: transparent;

        &:hover {
          color: $gray-500;
          border-color: transparent;
        }
      }

      &--today {
        font-weight: $font-weight-light;
      }

      &--keyboard-selected {
        background-color: transparent;
      }
    }

    &__month-container {
      float: unset;
    }
  }
}