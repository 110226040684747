@import '../../../../assets/reuse-mixin.scss';

.product-hub-list {
  padding: 44px 0;

  @include media-breakpoint-up(xl) {
    padding: 60px 0;
  }
}

.product-hub-row {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    margin: 0 -16px;
  }

  @include media-breakpoint-up(xl) {
    margin: 0 -45px;
  }
}

.product-hub-col {
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    width: 50%;
    padding: 0 16px;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    padding: 0 45px;
    margin-bottom: 90px;
  }
}

.container {
  &--custome {
    @include media-breakpoint-up(xl) {
      max-width: 1410px;
    }
  }
}