@import "@assets/reuse-mixin.scss";

.download-osim {
  &-widget {
    border: 1px solid $gray-light;
    border-top: 0;
    display: flex;
    flex-direction: column;
    border-left: 0;
    border-right: 0;
    padding: 24px;

    @include media-breakpoint-up(md) {
      border: 1px solid $gray-light;
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(xl) {
      padding: 24px 32px 40px;
      border: 1px solid $gray-light;
    }
  }

  &-header {
    &__text {
      color: $mine-shaft;
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
      margin-bottom: 8px;
      line-height: 1.8rem;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
        line-height: 2.2rem;
      }
    }

    &__description {
      color: $gray-darker;
      font-size: $h5-font-size-mobile;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }
  }

  &-extend {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 7px;
    padding-top: 24px;

    @include media-breakpoint-up(xl) {
      padding-top: 32px;
      padding-bottom: 25px;
    }

    &__item {
      margin-right: 8px;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 24px;
      }

      &__image {
        max-width: 200px;
        margin-bottom: 24px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 40px;
        }
      }

      &__link {
        width: 100%;
      }
    }
  }

}