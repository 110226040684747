@import "@assets/reuse-mixin.scss";

.wellness-filter-tag {
  padding-bottom: 10px;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: baseline;
  }

  &__text {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      padding-bottom: 15px;
      white-space: nowrap;
    }

    @include media-breakpoint-up(md) {
      padding: 0 15px 0 0;
    }
  }

  &__list {
    display: flex;
    padding-bottom: 10px;
    overflow: hidden;
    overflow-x: auto;
    margin-left: 0;

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
      overflow: auto;
      margin-left: 30px;
    }

    &__item {
      white-space: nowrap;
      display: inline-block;
      padding: 3px 14px;
      border: 1px solid $os-orange;
      border-radius: 40px;
      margin-right: 15px;
      font-size: $font-size-smaller;
      letter-spacing: 0.6px;
      cursor: pointer;
      margin-bottom: 0;
      transition: 0.3s;
      text-align: center;
      color: $gray-darker;

      @include media-breakpoint-up(md) {
        margin-bottom: 15px;
        font-size: $font-size-small;
        letter-spacing: 0.7px;
        padding: 5px 30px;
      }

      &.selected {
        background-color: $os-orange;
      }

      &:hover {
        background-color: $os-orange;
      }
    }
  }
}