@import '@assets/reuse-mixin.scss';

.coupon-code {
  padding-top: 0;
  // padding-bottom: 15px;

  @include media-breakpoint-up(md) {
    padding-top: 10px;
  }

  &__container {
    // display: flex;
    // flex-wrap: wrap;
  }

  &__title {
    margin-bottom: 15px;
    font-size: $h5-font-size;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 50%;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
    }
  }

  &__list-cart-tags {
    display: flex;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    ul.coupon-code__list-cart-tags__group {
      margin: 0 -9px;
    }

    &__group {
      display: flex;
      flex-wrap: wrap;
      
      @include media-breakpoint-up(lg) {
        // width: 50%;
        padding-left: 20px;
      }

      &__row {
        padding: 0 9px;
        margin-bottom: 10px;
      }

      &__item {
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 116px;
        background-color: $os-orange-lighter;
        font-size: $font-size-smaller;
        letter-spacing: 0.6px;
        font-weight: $font-weight-bold;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          font-size: $font-size-small;
          letter-spacing: 0px;
        }

        @include media-breakpoint-up(lg) {
          font-size: $font-size-smaller;
          letter-spacing: 0.6px;
        }

        @include media-breakpoint-up(xl) {
          font-size: $font-size-small;
          letter-spacing: 0;
        }

        span {
          &:hover {
            color: $black;
          }
        }

        .x-icon {
          position: relative;
          width: 18px;
          height: 18px;
          margin-left: 8px;

          &:hover {
            span,
            &::after {
              background-color: $black
            }
          }

          span {
            width: 12px;
            height: 2px;
            background-color: $gray;
            transform: rotate(45deg);
          }

          &::after {
            content: '';
            position: absolute;
            background-color: $gray;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 2px;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }
  }
}