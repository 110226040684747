@import '@assets/reuse-mixin.scss';

.accordion-buying-option {
    &__header {
      position: relative;
      min-height: 36px;
      padding-top: 10px;

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
      }
  
      // .btn-change {
      //   display: none;
  
      //   &.active {
      //     display: block;
      //   }
      // }

      .error-msg, 
      .btn-change {
        display: none;
        transition: all .35s;
        right: 0;
        top: 50%;
  
        &.active {
          display: block;
        }
      }

      .btn-change {
        color: $link-color;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        cursor: pointer;
        position: absolute;
        transform: translateY(-50%);
  
        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }

        &:hover {
          font-weight: $font-weight-bold;
        }
      }

      .error-msg {
        color: $primary;
  
        @include media-breakpoint-up(md) {
          position: absolute;
          transform: translateY(-50%);
        }
      }
    }
  }
  