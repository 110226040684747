@import'../../../../../../assets/reuse-mixin.scss';

.list-item-swiper {
  position: relative;

  img {
    width: 100%;
  }

  &__slider {
    padding-top: 5px;
    cursor: pointer;
    font-size: $font-size-smaller;
    height: auto;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      font-size: $font-size-small;
    }
    @include media-breakpoint-up(lg) {
      font-size: $font-size-smaller;
    }
    @include media-breakpoint-up(xl) {
      font-size: $font-size-small;
    }

    &:last-child {
      margin-right: 10px;
    }

    &:hover {
      .list-item-swiper__slider__image {
        border-color: $gray-darker;
      }
    }

    &__image {
      border: 2px solid $white;
      position: relative;
      padding-bottom: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.selected {
      position: relative;

      .list-item-swiper__slider__image {
        border-color: $gray-darker;
      }

      .gift-name,
      .gift-price {
        font-weight: $font-weight-bolder;
      }
    } 

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      display: none;
    }

    .check-mark {
      width: 18px;
      height: 18px;
      position: absolute;
      right: -3px;
      bottom: -6px;
    }

    .gift-name {
      margin: 4px 0 8px 0;
      line-height: 1.6rem;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;

      @include media-breakpoint-up(lg) {
        margin: 10px 0 10px 0;
      }
    }

    .gift-price {
      font-weight: $font-weight-normal;
      margin-top: auto;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    color: $gray-darker;
    top: 35%;
    width: 12px;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    @include media-breakpoint-up(xl) {
      top: 30%;
    }

    @include media-breakpoint-up(xxl) {
      top: 35%;
    }
  }

  .swiper-button-prev:after, 
  .swiper-button-next:after {
    font-size: 2rem;

    @include media-breakpoint-up(xl) {
      font-size: 2.4rem;
    }
  }

  .swiper-button-prev {
    left: -24px;

    @include media-breakpoint-up(xl) {
      left: -30px;
    }
  }

  .swiper-button-next {
    right: -24px;

    @include media-breakpoint-up(xl) {
      right: -30px;
    }
  }

  .swiper-button-lock {
    display: none;
  }
}