@import '../../../assets/reuse-mixin.scss';

.strip-ads {
  // position: fixed;
  // top: 55px; //INFO: height of header mobile
  // left: 0;
  // z-index: $STRIP_ADS_STICK_ZINDEX;
  background: $gray-darker;
  color: $white;
  width: 100%;
  padding: 0;
  overflow: hidden;

  // @include media-breakpoint-up(md) {
  //   top: 72px; //INFO: height of header desktop
  // }

  &__wrap {
    position: relative;
  }

  &__close-btn {
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
    color: $gray;
    z-index: $Z_INDEX_NORMAL;

    @include media-breakpoint-up(md) {
      top: 16px;
      right: 36px;
    }

    &__icon {
      font-size: 0.8em;
    }
  }

  &__content {
    background: $gray-darker;
    text-align: center;
    padding: 0 20px;
    max-height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
  
    @include media-breakpoint-up(lg) {
      max-height: 54px;
    }

    &.text-dark {
      color: $gray-darker;

      a {
        color: $gray-darker;
      }
    }

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        color: $link-hover-color;
      }
    }
  }

  & ~ .header {
    .header__menu-nav,
    .overlay-black {
      top: $HEADER_MOBILE_HEIGHT + 54 - 1;

      @include media-breakpoint-up(md) {
        top: $HEADER_DESKTOP_HEIGHT + 54 - 1;
      }
    }

    &.stick {
      .header__menu-nav,
      .overlay-black {
        top: $HEADER_MOBILE_HEIGHT - 1;
  
        @include media-breakpoint-up(md) {
          top: $HEADER_DESKTOP_HEIGHT - 1;
        }
      }
    }
  }

  // &--cart {
  //   // max-height: 54px;
  //   .strip-ads {
  //     &__content {
  //       padding: 0 5px;
  //       max-height: 64px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
      
  //       @include media-breakpoint-up(xl) {
  //         max-height: 54px;
  //       }
  //     }
  //   }
  // }
}