@import "@assets/reuse-mixin.scss";

.contact-us-cta {
  &__box {
    padding: 20px;
    border-top: 0.5px solid $gray-light;
    border-bottom: 0.5px solid $gray-light;
    @include media-breakpoint-up(md) {
      padding: 40px 80px;
      border: 0.5px solid $gray-light;
      margin-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 0;
      padding-left: 0;
    }

    & > .title {
      color: $gray-darker;
      text-align: center;
      margin-bottom: 0;
    }

    & > .description-title {
      margin-top: 8px;
      text-align: center;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        margin-top: 18px;
      }
    }
  }

  &__detail {
    margin: 26px auto 32px auto;
    max-width: 1260px;
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 20px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-up(xl) {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .icon {
      max-width: 36px;
      width: 100%;
      flex-shrink: 0;
      @include media-breakpoint-up(xl) {
        max-width: 64px;
      }

      &-wrapper {
        width: 100%;
        padding-bottom: 100%;
        position: relative;

        & > img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: contain;
        }
      }
    }

    .content {
      margin-left: 16px;

      @include media-breakpoint-up(xl) {
        margin-left: 24px;
      }

      div > p {
        &:first-child {
          color: $gray-darker;
          font-weight: $font-weight-normal;
          font-size: $h4-font-size-mobile;
          margin-bottom: 4px;
          @include media-breakpoint-up(xl) {
            font-size: $h4-font-size;
            margin-bottom: 8px;
          }
        }

        &:last-child {
          margin-bottom: 0;
          line-height: 2.4rem;
          font-size: $font-size-small;
          @include media-breakpoint-up(xl) {
            font-size: $font-size-base;
            line-height: 2.8rem;
          }
        }
      }
    }
  }

  &__cta {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .btn {
      text-align: center;
    }
  }
}