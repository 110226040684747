@import '@assets/reuse-mixin.scss';

.add-on-deal {
  &-list {
    &__item {
      flex-direction: column;
      color: inherit;

      &:hover {
        color: inherit;
      }

      &-active {
        &.variant-buying-list__item {
          border-color: $gray-darker;
          border-bottom-width: 0;
        }
      }

      &.variant-buying-list__item {
        padding: 12px 10px;
        @include media-breakpoint-up(xl) {
          padding: 12px;
        }
      }

      &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &__name {
        width: 100%;
        font-weight: $font-weight-normal;
        font-size: $font-size-base;
        text-align: left;
        @include media-breakpoint-up(xl) {
          font-size: $h6-font-size;
          margin-bottom: 20px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h5-font-size;
        }
      }

      &__detail {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__image {
        &.variant-buying-list__item__image {
          @include media-breakpoint-up(lg) {
            margin-top: unset;
          }
          @include media-breakpoint-up(xxl) {
            margin-top: unset;
          }
        }
      }

      &__variant {
        height: fit-content !important;
        @include media-breakpoint-up(lg) {
          margin-bottom: 12px;
        }
        @include media-breakpoint-up(xl) {
          margin-bottom: 20px;
        }
      }

      &__button {
        &-custom {
          width: 100%;
        }
      }

      &__group {
        @include media-breakpoint-up(xxl) {
          padding-left: 20px !important;
        }

        &.variant-buying-list__item__group {
          justify-content: center;
        }

        .add-on-deal-list__item {
          &__variant {
            width: 100%;
            text-align: center;
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: 1em;

            @include media-breakpoint-up(lg) {
              margin-bottom: 12px;
              max-width: none;
            }

            @include media-breakpoint-up(xl) {
              font-size: $h6-font-size;
              margin-bottom: 20px;
            }

            @include media-breakpoint-up(xxl) {
              font-size: $h5-font-size;
            }
          }

          &__price {
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
}
