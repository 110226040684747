@import '@assets/reuse-mixin.scss';

.popup-z-index-hight {
  z-index: 3;
}

.osim-popup {

  &--no-padding {

    .modal-content {
      padding: 0 !important;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
    }

    .osim-popup {
      &__header {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
      }

      &__close-btn {
        &:hover {
          color: $white;
        }
      }
    }
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    height: 100%;

    @include media-breakpoint-up(lg) {
      min-height: 100%;
      height: auto;
      align-items: center;
      padding: 40px 0;
    }
  }

  &.product-image-zoom {
    .modal-content {
      background: transparent;
    }

    &--carousel {
      &.osim-popup {
        .modal-dialog {
          align-items: center;
          display: block;
        }

        .osim-popup__header {
          position: relative;
          margin-bottom: 12px;

          @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
          }
        }

        .osim-popup__close-btn {
          top: 0;
          right: 0;
        }

        .modal-content {
          margin-top: 0;
          padding-bottom: 50px;
          margin: 0 auto;

          @include media-breakpoint-up(lg) {
            width: 80%;
            margin-top: 32px;
            padding-bottom: 70px;
          }

          @include media-breakpoint-up(xl) {
            width: 90%;
            max-width: 1600px;
          }
        }
      }

      .custom-pagination {
        // margin: 24px auto 0;
        position: absolute;
        left: 50%;
        bottom: 15px;
        transform: translateX(-50%);

        &__prev,
        &__next {
          display: none;
        }

        .custom-pagination-bullets {
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: flex-end;

          &__icon {
            background-color: transparent;
            opacity: 1;
            width: 5px;
            height: 5px;
            transition: all 0.3s;
            border-radius: 50%;
            outline: none;
            border: 1px solid $white;
            cursor: pointer;
            margin: 0 5px;
            transform: none;

            @include media-breakpoint-up(md) {
              width: 8px;
              height: 8px;
              margin: 0 8px;
            }

            &.is-active {
              height: 16px;
              border-radius: 30px;
              background-color: $white;
              transform: none;

              @include media-breakpoint-up(md) {
                height: 24px;
              }

              @include media-breakpoint-up(xxl) {
                height: 32px;
              }
            }
          }
        }
      }

      // Customize swiper for Zoom slides
      .swiper-container {
        position: static;

        // @include media-breakpoint-up(lg) {
        //   position: static;
        // }

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
          color: $gray;

          @include media-breakpoint-up(lg) {
            display: block;
          }
        }

        .swiper-button-prev {
          left: -32px;

          @include media-breakpoint-up(xxl) {
            left: -48px;
          }
        }

        .swiper-button-next {
          right: -32px;

          @include media-breakpoint-up(xxl) {
            right: -48px;
          }
        }
      }
    }
  }

  &.warning-popup {
    .modal-dialog {
      align-items: center;
    }

    .modal-content {
      max-width: 368px;
      margin-top: inherit;
    }

    .osim-popup__header {
      margin-bottom: 0;
    }
  }

  .modal-content {
    border: none;
    padding: 24px 16px 48px;
    background: $white;
    min-height: 200px;
    margin-top: auto;
    width: 100%;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 24px 32px;
    }

    @include media-breakpoint-up(lg) {
      width: 60%;
      height: fit-content;
      margin-top: initial;
    }

    @include media-breakpoint-up(xl) {
      width: 50%;
      max-width: 740px;
    }
  }

  &__header {
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 24px;
    min-height: 20px;
    text-align: center;
    color: $gray;
    // font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 2.8px;
    padding: 0 25px;
    letter-spacing: 2.52px;

    // .icon-close {
    //   display: inline-block;
    //   margin-left: auto;
    //   cursor: pointer;
    // }

    @include media-breakpoint-up(md) {
      letter-spacing: 2.88px;
      padding: 0 40px;
      margin-bottom: 32px;
    }

    &__title {
      font-size: inherit;
      margin: 0;
    }
  }

  &__description {
    font-size: $font-size-base;
    max-width: 95%;
    margin: 0 auto 24px;
    text-align: center;
    color: $gray-darker;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
      max-width: 90%;
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      max-width: 560px;
      font-size: $h5-font-size;
    }
  }

  .btn-remove-wishlist {
    min-width: 140px;
    height: 36px;
    margin-top: 20px;
  }
}

// style video popup product detail
.video-popup {
  position: relative;

  .modal-dialog {
    align-items: center;
  }

  .modal-content {
    max-width: 1200px;
    width: 100%;
    padding: 0;
    background: none;
    margin-top: initial;
  }

  .video-banner {
    &__control {
      max-width: 100%;
      top: initial;
      // bottom: -12px;
      height: 36px;
      padding: 0;
      align-items: initial;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .osim-popup__close-btn {
    top: 0;
    right: 0;
  }

  .timeline-video {
    height: auto;
    display: flex;
    width: 100%;

    &__line {
      height: 3px;
      width: 100%;
      // background: $white !important;
    }

    &__line-active {
      // height: 10px;
      // width: 10px;
      // border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4098ff;
      outline: none;
    }

    &__button-group {
      display: flex;
      justify-content: space-between;
      padding: 4px 15px 0;
    }
  }
}

//Trade in Info Popup
.tradein-popup {
  .modal-content {
    @include media-breakpoint-up(lg) {
      //width: fit-content;
      max-width: 90%;
    }
  }

  .tradein-content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;

    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    &__image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 10px;

      & > .image-uploaded {
        width: calc(100% / 3 - 8px);

        .image-uploaded {
          &__content {
            //width: 150px;
            width: 100%;
            outline: 1px solid $gray;
            overflow: hidden;
            height: 0;
            padding-bottom: 100%;
            position: relative;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      &__text {
        margin-top: 24px;
        text-align: center;
      }
    }
  }
}

// Offer Widget Popup
.offer-widget-popup {
  .modal-content {
    @include media-breakpoint-up(lg) {
      width: auto;
      max-width: 800px;
    }
  }
}

// Spin Win Popup
.spin-win-popup {
  .modal-content {
    padding: 0;
  }

  .osim-popup__close-btn {
    z-index: 2;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .osim-popup__header {
    margin: 0;
    min-height: 0;
  }
}

// Review Media Popup
.review-media-popup {
  .osim-popup__header {
    @include media-breakpoint-up(lg) {
      min-height: 0;
      margin-bottom: 18px;
    }

    &__title {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .modal-content {
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      padding-bottom: 48px;
    }
  }
}
