@import '@assets/reuse-mixin.scss';

.create-bundle {
  padding-top: 24px;
  color: $gray-darker;

  @include media-breakpoint-up(lg) {
    padding-top: 72px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 30px;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  &__items {
    background-color: $os-orange-lighter;
    padding: 32px 0;

    &__title {
      margin-bottom: 32px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 48px;
      }
    }

    &__slider {
      &__image,
      &__border {
        padding-bottom: 100%;
        position: relative;
        
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__border {
        border: 1px dashed $gray;

        span {
          position: absolute;
          top: 30px;
          left: 40px;
          bottom: 30px;
          right: 40px;
          font-size: $font-size-small;
          letter-spacing: 0.45px;
          line-height: 2.2rem;
          text-align: center;
          color: $gray-darker;

          @include media-breakpoint-up(lg) {
            font-size: $h5-font-size;
            letter-spacing: 1.6px;
            line-height: 2.8rem;
          }
        }
      }

      &__name {
        margin-top: 8px;
        font-weight: $font-weight-normal;
        font-size: $font-size-base;
  
        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }
    
        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }
      }
    }

    &__button {
      margin-top: 20px;

      @include media-breakpoint-up(lg) {
        margin-top: 30px;
      }

      > a {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }
  }

  &.skeleton__item {
    .promotion-swiper {
      &__container {
        display: flex;
        
        .promotion-swiper__slider {
          margin-right: 16px;

          width: calc(100%/3);
          
          @include media-breakpoint-up(md) {
            width: calc(100%/4);
          }
        }
      }
    }
  }
}
