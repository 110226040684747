@import '@assets/reuse-mixin.scss';

.review-star-rating {
  display: flex;

  .rating-star-icon {
    margin-right: 8px;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      margin-right: 12px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.disable-rating {
    pointer-events: none;
  }
}
