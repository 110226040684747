@import'../../assets/reuse-mixin.scss';

.error-component {
  background: rgba($danger, 0.1);
  border: 1px solid rgba($danger, 0.3);
  padding: 16px 24px;
  font-size: $font-size-large;
  color: $danger;
  text-align: center;
  font-weight: $font-weight-normal;
}