@import '@assets/reuse-mixin.scss';

.slider-banners {
  @include pagination-bullets-custom(24px, 48px);

  &--bullets-white {
    &.slider-banners {
      .swiper-pagination-bullet {
        border-color: $white;
      }
  
      .swiper-pagination-bullet-active {
        background-color: $white;
      }
    }
  }
}
