@import "../../assets/reuse-mixin.scss";

.video {
  position: relative;

  &__background {
    background-color: $black;
    padding-bottom: 560/376 * 100%;

    @include media-breakpoint-up(md) {
      padding-bottom: 1080/1920 * 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--video {
      padding-bottom: 560/376 * 100%;
  
      @include media-breakpoint-up(md) {
        padding-bottom: 1080/1920 * 100%;
      }

      &:before {
        content: '';
        display: block;
        background: $black;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 550px;
    text-align: center;
    padding: 0 25px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      max-width: 750px;
    }

    &__title {
      font-size: $h4-font-size;
      margin-bottom: 5px;
      color: $white;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h2-font-size;
      }
    }

    &__text {
      font-size: $font-size-large;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }
    
    &__btn-play {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      transform: all 0.5s;
      cursor: pointer;
      margin-top: 20px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid $white;
        border-radius: 50%;
        opacity: 1;
        transition: all 0.3s;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $os-brown;
        transform: scale(0);
        transition: all 0.3s;
      }
      
      &:hover {
        &::before {
          transform: scale(1.2);
          opacity: 0;
        }

        &::after {
          transform: scale(1);
        }

        .btn-play-icon {
          .play-icon {
            background-position: 0 0;
          }
        }
      }

      .btn-play-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .play-icon {
          margin-left: 4px;
          width: 18px;
          height: 18px;
          background: url('../../assets/images/icons/play-icon.png') 0 -18px no-repeat;
          background-size: cover;
        }
      }

      &--dark {
        &::before {
          border: 1px solid $gray-darker;
        }

        .btn-play-icon {
          .play-icon {
            background-position: 0 0;
          }
        }
      }
    }
  }

  &.not-full-width {
    margin: 50px auto;

    .video__background,
    .video__background--video {
      position: relative;
    }
  }

  &.skeleton__item {
    .loading__default {
      @include centerer(true, true)
    }
  }
}