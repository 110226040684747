@import '@assets/reuse-mixin.scss';

.store-overview {
  color: $gray-darker;
  margin-bottom: 35px;

  @include media-breakpoint-up(lg) {
    margin-top: 16px;
  }

  &__back-link {
    display: flex;
    align-items: center;
    //color: $os-blue;
    color: $link-color;
    margin-bottom: 20px;
    font-size: $font-size-small;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__image {
      margin-bottom: 36px;

      @include media-breakpoint-up(xl) {
        order: 1;
        flex: 1 1;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 940px;
      }

      &__item {
        width: 100%;
        height: auto;
      }
    }

    &__info {
      @include media-breakpoint-up(xl) {
        order: 0;
        flex: 1 1;
      }

      &__wrap {
        @include media-breakpoint-up(xl) {
          padding-left: calc(((100vw - #{$container-xl}) / 2) - #{$grid-gutter-width/4});
        }

        @include media-breakpoint-up(xxl) {
          padding-left: calc(((100vw - #{$container-xxl}) / 2) - #{$grid-gutter-width/4});
          padding-right: 15px;
        }
      }

      &__contact {
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
          order: 1;
        }

        @include media-breakpoint-up(lg) {
          width: 320px;
        }
      }

      &__actions {
        text-align: center;
        // max-width: 320px;
        margin-bottom: 36px;

        @include media-breakpoint-up(md) {
          order: 3;
          text-align: left;
          margin-bottom: 0;
          margin-top: 40px;
        }
      }

      &__opening-hours {
        @include media-breakpoint-up(md) {
          order: 2;
          margin-bottom: 40px;
        }

        @include media-breakpoint-up(xxl) {
          padding-left: 30px;
        }
      }
    }
  }

  .contact,
  .opening-hours {
    &__title {
      color: $gray-darker;
      font-size: $h5-font-size;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h3-font-size;
      }
    }
  }

  .contact-info-group {
    margin-bottom: 18px;
  }

  .contact {
    &__title {
      margin-bottom: 9px;

      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }

    &__item {
      display: block;
      margin-bottom: 8px;
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__phone {
      color: inherit;

      &:hover {
        color: $link-color;
      }
    }
  }

  .actions {
    &__book-btn {
      font-size: $font-size-base;
      margin-bottom: 18px;
      min-width: 200px;

      @include media-breakpoint-up(lg) {
        min-width: 320px;
      }
    }

    &__wrap-link {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
      }

      span {
        cursor: pointer;
      }
    }

    &__link {
      font-size: $font-size-large;
      //color: $os-blue;
      color: $link-color;
      display: block;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .opening-hours {
    &__title {
      margin-bottom: 16px;
    }

    &__item {
      font-size: $font-size-base;
      color: $gray;
      margin-bottom: 8px;
      display: flex;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--active {
        color: $gray-darker;
      }

      &__day {
        font-weight: normal;
        width: 25%;

        @include media-breakpoint-up(xl) {
          width: 30%;
        }
      }

      &__time {
        width: 75%;

        @include media-breakpoint-up(xl) {
          width: 70%;
        }
      }
    }
  }

  &.skeleton__item {
    .store-overview__content {
      &__image {
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        
        .image-wrap {
          padding-bottom: 100%;
      
          @include media-breakpoint-up(md) {
            padding-bottom: 800/1920 * 100%;
          }


          .loading__default {
            @include centerer(true, true)
          }
        }
      }
    }
  }
}