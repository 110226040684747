@import '@assets/reuse-mixin.scss';

.product-variant {
  color: $gray-darker;

  // @include media-breakpoint-up(lg) {
  //   margin-bottom: 36px;
  // }

  &__status {
    font-weight: $font-weight-normal;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
      // display: flex;
      padding-bottom: 45px;
      // justify-content: flex-start;
      width: 180px;
    }

    @include media-breakpoint-up(xl) {
      width: 240px;
    }

    @include media-breakpoint-up(xxl) {
      // width: 320px;
      width: 380px;
    }

    &__icon {
      width: 20px;
      height: 20px;
      background-position: 0 -80px;

      & + span {
          padding-left: 8px;
          display: block;
          text-align: left;
          width: calc(100% - 20px);
          font-weight: $font-weight-bold;

        @include media-breakpoint-up(lg) {
          padding-left: 22px;
          width: 160px;
        }

        @include media-breakpoint-up(xl) {
          width: 220px;
        }

        @include media-breakpoint-up(xxl) {
          // width: 300px;
          width: 360px;
        }
      }
    }
  }

  &__wrap {
    position: relative;
    color: $gray-darker;
    overflow: hidden;

    &__background {
      padding-bottom: 420/375 * 100%; //INFO: Follow new Design 
      // padding-bottom: 666/375 * 100%;
      display: block;
      position: relative;
      
      @include media-breakpoint-up(md) {
        background-color: $gray-darker;
        padding-bottom: 400/768 * 100%;
      }
  
      @include media-breakpoint-up(lg) {
        padding-bottom: 640/1920 * 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;

        @include media-breakpoint-up(md) {
          opacity: 1;
        }
      }

      &.skeleton__item {
        .loading__default {
          @include centerer(true, true)
        }
      }
    }

    &__item {
      width: 100%;
      position: relative;

      @include media-breakpoint-up(md) {
        width: 43%;
      }

      @include media-breakpoint-up(lg) {
        width: 250px;
      }

      @include media-breakpoint-up(xl) {
        width: 360px;
      }

      @include media-breakpoint-up(xxl) {
        width: 460px;
      }

      &__img {
        position: relative;
        display: block;
        padding-top: 100%;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .swiper-container {
        padding-bottom: 65px;

        // @include media-breakpoint-up(md) {
        //   padding-bottom: 24px;
        // }

        @include media-breakpoint-up(xl) {
          padding-top: 24px;
        }

        @include media-breakpoint-up(xxl) {
          padding-top: 32px;
        }

        .custom-pagination {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          padding: 0;
          // transform: translateY(120px);

          // @include media-breakpoint-up(md) {
          //   transform: translateY(24px);
          // }

          // @include media-breakpoint-up(xl) {
          //   transform: translateY(0);
          // }

          // @media (min-width: 1920px) {
          //   transform: translateY(32px);
          // }
        }
      }

      &--indicator-white {
        .custom-pagination {
          @include media-breakpoint-up(md) {
            color: rgba($white, 0.8);
          }

          .custom-pagination-bullets {
            &__icon {
              @include media-breakpoint-up(md) {
                background-color: rgba($white, 0.8);
                border-color: $white;
              }
  
              &.is-active {
                @include media-breakpoint-up(md) {
                  background-color: $white;
                }
              }
            }
          }
        }
      }
    }

    .heart {
      z-index: 2;
      position: relative;
      display: block;
      top: 0;
      left: 0;
      // background-position: 0 -112px;
      background-position: 0 0;

      @include media-breakpoint-up(md) {
        background-position: 0 0;
      }

      &:hover {
        background-position: 0 -22px;

        @include media-breakpoint-up(xxl) {
          background-position: 0 -30px;
        }
      }

      &.active {
        background-position: 0 -44px;
    
        @include media-breakpoint-up(xxl) {
          background-position: 0 -60px;
        }
      }
    }
    
    .social-media__icon {
      // background-position: 0 -135px;
      background-position: 0 -67px;

      @include media-breakpoint-up(md) {
        background-position: 0 -67px;
      }

      @include media-breakpoint-up(xxl) {
        background-position: 0 -90px;
      }
  
      &:hover {
        background-position: 0 -90px;

        @include media-breakpoint-up(xxl) {
          background-position: 0 -120px;
        }
      }
    }

    // &--button-dark {
    //   .heart {
    //     background-position: 0 -112px;

    //     @include media-breakpoint-up(xxl) {
    //       background-position: 0 -150px;
    //     }

    //     &:hover {
    //       background-position: 0 -22px;
      
    //       @include media-breakpoint-up(xxl) {
    //         background-position: 0 -30px;
    //       }
    //     }
      
    //     &.active {
    //       background-position: 0 -44px;
      
    //       @include media-breakpoint-up(xxl) {
    //         background-position: 0 -60px;
    //       }
    //     }
    //   }

    //   .social-media__icon {
    //     background-position: 0 -135px;

    //     @include media-breakpoint-up(xxl) {
    //       background-position: 0 -180px;
    //     }

    //     &:hover {
    //       background-position: 0 -90px;
    
    //       @include media-breakpoint-up(xxl) {
    //         background-position: 0 -120px;
    //       }
    //     }
    //   }
    // }

    .icons-component {
      // position: absolute;
      // top: 102px;
      // right: 24px;
      width: 24px;
      height: 24px;
      z-index: 2;
    
      @include media-breakpoint-up(xxl) {
        width: 32px;
        height: 32px;
      }
    
      &:hover {
        .icons-component__img--nomal {
          display: none;
          opacity: 0;
        }
    
        .icons-component__img--active {
          display: block;
          opacity: 1;
        }
      }
    
      &__link {
        display: block;
      }
    
      &__img {
        width: 100%;
        height: auto;
        transition: opacity 0.5s;

        &--nomal {
          opacity: 1;
        }
    
        &--active {
          display: none;
          opacity: 0;
        }
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    // bottom: 24px;
    right: 0;

    @include media-breakpoint-up(md) {
      bottom: 24px;
    }

    @include media-breakpoint-up(lg) {
      top: 50%;
      transform: translateY(-50%);
      bottom: inherit;
    }

    .container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      @include media-breakpoint-up(md) {
        justify-content: center;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 24px;
    right: 24px;
    color: $gray;
    z-index: $Z_INDEX_NORMAL;

    @include media-breakpoint-up(xxl) {
      right: 36px;
    }

    &__item {
      .heart {
        top: 0;
        right: 0;
      }
    }

    &__zoom {
      background: url('../../../../assets/images/icons/icon-zoom-dark.png') 0 0 no-repeat;
      background-size: cover ;
      width: 24px;
      height: 24px;
      position: absolute;
      top: auto;
      right: 24px;
      bottom: 12px;
      cursor: pointer;
      z-index: 2;
      
      @include media-breakpoint-up(md) {
        background: url('../../../../assets/images/icons/icon-zoom.png') 0 0 no-repeat;
        top: 0;
        right: 0;
        bottom: auto;
        background-size: cover ;
      }

      @include media-breakpoint-up(xl) {
        top: 33px;
      }

      @include media-breakpoint-up(xxl) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__info {
    &--mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        padding-left: 32px;
        width: calc((100% - 250px)/2);
      }

      @include media-breakpoint-up(xl) {
        padding-left: 45px;
        width: calc((100% - 360px)/2);
      }

      @include media-breakpoint-up(xxl) {
        padding-left: 55px;
        width: calc((100% - 460px)/2);
      }
      
      &.text-light {
        .product-variant-info {
          @include media-breakpoint-up(lg) {
            color: $white;
          }
  
          &__content {
            &__name,
            &__installment {
              color: $white;
            }
  
            &__list-price {
              color: #C2C2C2;
            }
          }
        }
      }
    }
  }

  .product-variant-menu {
    position: relative;
    padding-top: 24px;
    text-align: center;
    // margin-bottom: 35px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }

    &__wrap {
      @include media-breakpoint-up(lg) {
        display: flex;
        padding: 0 15px;
        justify-content: center;
        align-items: center;
      }
    }

    &__label {
      display: inline-block;
      font-size: $h4-font-size;
      font-weight: $font-weight-normal;
      letter-spacing: 1.2px;
      margin-bottom: 12px;

      @include media-breakpoint-up(lg) {
        // display: flex;
        // flex-direction: row-reverse;
        // align-items: flex-end;
        width: 180px;
        padding-bottom: 45px;
        margin-bottom: 0;
        font-size: $h5-font-size;
        letter-spacing: 1.6px;
        text-align: right;
      }

      @include media-breakpoint-up(xl) {
        width: 240px;
        font-size: $h4-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h3-font-size;
        // width: 320px;
        width: 380px;
        // padding-bottom: 72px;
      }
    }

    div.product-variant-menu__list-item {
      margin: 0 -10px;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
      }

      @include media-breakpoint-up(lg) {
        min-width: 400px;
        max-width: calc(100% - 360px);
        padding: 0 12px;
        margin-top: -22px;
      }

      @include media-breakpoint-up(xl) {
        width: auto;
        max-width: 806px;
        margin-top: 0;
      }

      @media (min-width: 1366px) {
        margin-top: -28px;
      }

      @media (min-width: 1681px) {
        margin-top: -40px;
      }

      .swiper-container {
        padding-bottom: 80px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 72px;
        }
      }

      // .swiper-wrapper {
      //   justify-content: center;
      // }

      &--shimmer {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-bottom: 40px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 66px;
        }
      }
    }
  }

  .product-variant-item {
    // overflow: hidden;
    cursor: pointer;
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    position: relative;
    display: flex;
    margin: 0;

    @include media-breakpoint-up(md) {
      min-width: 100px;
      max-width: 100px;
      min-height: 100px;
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      min-width: 140px;
      max-width: 140px;
      min-height: 140px;
    }

    @include media-breakpoint-up(xxl) {
      min-width: 150px;
      max-width: 150px;
      min-height: 150px;
    }

    &__img {
      width: 100%;
      overflow: hidden;
      position: relative;
      transition: 0.3s all;
      width: 80px;
      margin: auto auto 0;

      @include media-breakpoint-up(md) {
        width: 80px;
      }

      &::before {
        content: '';
        padding-top: 100%;
        display: block;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__name {
      font-size: $font-size-smaller;
      opacity: 0;
      transition: 0.3s all;
      min-height: 36px;
      display: block;
      position: absolute;
      width: 100%;
      bottom: 0;
      transform: translateY(100%);

      @include media-breakpoint-up(md) {
        font-size: $font-size-small;
      }

      // @include media-breakpoint-up(lg) {
      //   font-size: $h5-font-size;
      // }

      // @include media-breakpoint-up(xl) {
      //   font-size: $h4-font-size;
      // }

      .info-stock-message {
        display: block;
        min-width: 100%;
      }
    }

    &:hover,
    &.is-active {
      .product-variant-item__img {
        width: 100px;
        transform: scale(1.1);
  
        @include media-breakpoint-up(md) {
          width: 100px;
          transform: scale(1);
        }
  
        @include media-breakpoint-up(lg) {
          width: 140px;
        }
  
        @include media-breakpoint-up(xxl) {
          width: 150px;
        }
      }

      .product-variant-item__name {
        opacity: 1;
      }
    }

    &.skeleton__item {
      .product-variant-item__name {
        opacity: 0;
      }
    }
  }

  // group social
  .group-social {
    position: fixed;
    right: 0;
    top: 180px;
    background: rgba(0, 0, 0, 0.5);
    padding: 12px 8px;
    border-radius: 16px 0 0 16px;
    border: 1px solid rgba($white, 0.2);
    border-right: 0;
    z-index: 3;    
  
    @include media-breakpoint-up(xxl) {
      top: 250px;
      padding: 20px 10px;
      border-radius: 20px 0 0 20px;
    }

    .item-tooltip {
      position: relative;
    }

    > div + div {
      margin-top: 10px;

      &.heart {
        margin-top: 12px;
    
        @include media-breakpoint-up(xxl) {
          margin-top: 14px;
        }
      }
    }
  }
}