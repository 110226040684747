@import '@assets/reuse-mixin.scss';

.newsletter-popup {
  &__content {
    .modal-content {
      @include media-breakpoint-down(sm) {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
      @include media-breakpoint-up(sm) {
        width: 100% !important;
        max-width: 740px !important;
      }

      .main-banner__background {
        &.no-ratio {
          height: 0;

          & > img {
            position: absolute;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .osim-popup__close-btn {
      top: 16px;
      right: 16px;
    }

    .main-banner {
      &__background {
        @include media-breakpoint-down(sm) {
          min-height: 429px;
          //padding-bottom: calc(429 / 375 * 100%) !important;
        }
        @include media-breakpoint-up(sm) {
          padding-bottom: calc(494 / 740 * 100%) !important;
        }
      }
    }
  }

  &__container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, .6);
    @include media-breakpoint-down(sm) {
      padding: 0 24px;
    }
  }

  &__wrapper {
    max-width: 560px;
    width: 100%;
    color: $gray-darker;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: $h2-font-size-mobile;
    line-height: 2.6rem;
    font-weight: $font-weight-normal;
    margin-bottom: 0;
    @include media-breakpoint-up(sm) {
      font-size: $h3-font-size;
      line-height: 4rem;
    }
  }

  &__description {
    font-weight: $font-weight-base;
    font-size: $h5-font-size-mobile;
    margin: 14px 0 20px;
    @include media-breakpoint-up(sm) {
      margin: 24px 0 32px;
      font-size: $h5-font-size;
      line-height: 3.2rem;
    }
  }

  &__form {
    width: 100%;

    & > .form-group {
      &:not(.email-input) {
        margin-bottom: 0 !important;
      }

      &.email-input {
        width: 100%;
        margin: 0 auto 26px;
        @include media-breakpoint-up(sm) {
          max-width: 80%;
          margin: 0 auto 29px;
        }

        .subscribe-success {
          margin-top: 0;
          margin-bottom: 8px;
        }
      }

      & > .btn {
        margin-bottom: 18px;
      }

      & > a {
        color: $link-color;
        cursor: pointer;

        &:hover {
          color: $link-color;
          text-decoration: underline;
        }
      }
    }
  }
}