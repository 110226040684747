@import '@assets/reuse-mixin.scss';

.baidu-map {
  width: 100%;
  height: 500px;
  position: relative;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 0 #ccc;
  border-radius: 3px;

  @include media-breakpoint-up(md) {
    height: 900px;
  }

  img {
    max-height: none;
  }

  .BMap_center,
  .BMap_bubble_content {
    font-size: 16px;
    text-align: center;
  }

  .BMap_shadow {
    display: none;
  }

  .BMapLabel {
    border: 1px solid #ccc !important;
    animation: baidu-map-label-show 0.2s;

    .info-window {
      padding: 10px;
      text-align: center;
      position: absolute;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 #333;
      transform: translateX(-50%) translateY(-135%);

      &::after {
        content: "";
        width: 15px;
        height: 15px;
        position: absolute;
        background: #fff;
        transform: rotate(45deg);
        left: 50%;
      }
    }

  }

  .geolocation {
    width: 33px;
    height: 32px;
    position: absolute;
    background: rgb(243, 241, 236);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    left: 11px;
    padding: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px 0 #ccc;
  }

  @keyframes baidu-map-label-show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}