@import '@assets/reuse-mixin.scss';

.spin-winner {
  padding-top: 32px;

  &__box {
    padding: 18px 16px 32px;
    border: 1px solid $gray-darker;
    max-width: 416px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      padding: 24px 24px 32px;
    }

    &__title {
      line-height: 1.12;
    }

    &__info {
      margin-top: 24px;

      @include media-breakpoint-up(lg) {
        margin-top: 32px;
      }
    }
  }

  &__btn-share {
    margin-top: 32px;

    @include media-breakpoint-up(lg) {
      margin-top: 48px;
    }

    &__icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url('../../../assets/images/icons/icon-share.png') no-repeat;
      background-size: cover;
      margin-right: 20px;
    }

    .btn {
      width: 280px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        width: 320px;
      }
    }
  }

  .winner-info-item {
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.48px;
    color: $gray-darker;
    margin-bottom: 18px;
    font-size: $font-size-smaller;
    
    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
      font-size: $font-size-base;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__left {
      width: 60%;
      text-align: left;
    }

    &__right {
      width: 40%;
      text-align: right;
    }

    &__name {
      font-weight: $font-weight-bold;
    }
  }
}