@import '@assets/reuse-mixin.scss';

.cancel-appointment {
  padding: 125px 0px 160px;
  
  @include media-breakpoint-up(lg) {
    padding: 205px 0px 156px;
  }

  &__content {
    margin: 0px auto;
    text-align: center;

    &__title {
      font-size: $h4-font-size-mobile;
      margin: 0;
      padding-bottom: 8px;
      
      @include media-breakpoint-up(lg) {
        font-size: $h2-font-size;
        padding-bottom: 16px;
      }
    }

    &__time {
      padding-bottom: 16px;
      color: $gray-900;
  
      @include media-breakpoint-up(md) {
        padding-bottom: 32px;
      }
      
      span {
        padding-right: 16px;
        font-size: $h4-font-size-mobile;
        font-weight: $font-weight-normal;
        line-height: 1;
        
        @include media-breakpoint-up(md) {
          font-size: $h4-font-size;
          padding-right: 24px;
        }
  
        &:last-child {
          padding-right: 0;
        }

        & + span {
          border-left: 1px solid $gray-900;
          padding-left: 16px;
          
          @include media-breakpoint-up(md) {
          padding-left: 24px;
        }
        }
      }
    }
  
    &__excerpts {
      font-size: $font-size-smaller;
      margin: 0;
      padding-bottom: 16px;
      color: $gray-900;
      
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
        padding-bottom: 32px;
      }
    }

    &__link-appointment {
      margin-bottom: 32px;
      
      @include media-breakpoint-up(lg) {
        margin-bottom: 64px;
      }
    }

    &__info {
      font-size: $h6-font-size-mobile;
      margin: 0;
      padding-bottom: 8px;
      color: $gray-900;
      
      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
        padding-bottom: 16px;
      }
    }
  }
}