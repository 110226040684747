@import '../../../../assets/reuse-mixin';

.header-menu {
  .quick-navigation {
    list-style: none;
    margin: 10px 0 0 0;
    border-top: 1px solid $gray;
  
    &__item {
      margin-bottom: 32px;
  
      &:first-child {
        margin-top: 24px;
      }
  
      &__icon {
        padding-right: 15px;
      }
    }
  }
}

.quick-navigation {
  &__mini-cart {
    position: relative;
    height: 100%;
    display: none;
    align-items: center;

    @include media-breakpoint-up(xl) {
      display: flex;
      padding: 0 10px;
    }

    &__icon {
      cursor: pointer;
    }

    &__contents {
      position: absolute;
      top: 100%;
      right: 0;
      width: 375px;

      @include media-breakpoint-up(md) {
        background-color: $white;
        box-shadow: 0px 4px 10px #00000040;
      }
    }
  }
}