@import '@assets/reuse-mixin.scss';

.social-login-widget {
  &__item {
    display: flex;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &__img {
      width: 40px;

      @include media-breakpoint-up(lg) {
        width: 48px;
      }
    }

    &__text {
      width: calc(100% - 40px);
      padding-left: 24px;

      @include media-breakpoint-up(lg) {
        width: calc(100% - 48px);
        padding-left: 32px;
      }
    }

    &__name {
      &__social {
        margin-bottom: 4px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 8px;
        }
      }
    }

    &__status {
      color: $os-blue;

      &--disable {
        color: $gray;
      }
    }
  }
}