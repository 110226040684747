@import '@assets/reuse-mixin.scss';

.timeline-panel {
  margin-bottom: 48px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 72px;
  }

  &__content {
    padding-left: $grid-gutter-width;

    @include media-breakpoint-up(md) {
      padding-left: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(lg) {
      padding-left: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
      padding-top: 24px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(xxl) {
      padding-left: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
    }
  }

  &__list {
    display: flex;
  }

  &__item {
    position: relative;
    // width: 20%;

    &:last-child {
      .timeline-panel__item__line::after {
        left: -50%;
        width: 0;
      }
    }

    &__bottom {
      opacity: 0;
    }

    &:nth-child(even) {
      .timeline-panel__item__top {
        opacity: 0;
      }

      .timeline-panel__item__bottom {
        opacity: 1;
      }
    }


    &__image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: (100/180)*100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: (135/240)*100%;
      }
    }

    &__des {
      height: 78px;
      overflow: hidden;
      margin-top: 8px;
      font-size: $font-size-mobile;
      text-align: center;
      @include line-clamp(4); 

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    &__year {
      margin-top: 80px;

      @include media-breakpoint-up(md) {
        margin-top: 90px;
      }
    }

    &__line {
      position: relative;
      margin: 15px auto 20px;
      text-align: center;

      @include media-breakpoint-up(md) {
        margin: 40px 0;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $secondary;
        top: 16px;
        left: 50%;
        z-index: -1;
      }

      .timeline-panel-icon {
        width: 32px;
        height: 32px;
        background-color: white;
        border: 1px solid $secondary;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &__item {
          width: 12px;
          height: 12px;
          background-color: $secondary;
          border-radius: 50%;
          position: absolute;
        }
      }
    }
  }
}