@import '@assets/reuse-mixin.scss';

.input-range {
  height: 24px;
  width : 24px;
  border-radius: 50%;
  border: 1px solid $gray-900;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  &.input-range--index {
    z-index: 2 !important;
  }

  &:focus {
    outline: none;
  }

  &__curent {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    font-size: $font-size-smaller;
    top: 30px;
    
    @include media-breakpoint-up(lg) {
      top: 35px;
      font-size: $font-size-small;
    }

    &.is-hidden {
      color: transparent;
    }

    &.change-position {
      top: -25px;

      @include media-breakpoint-up(lg) {
        top: -30px;
      }
    }
  }

  &__line {
    height: 3px;
    width: 100%;
    border-radius: 4px;
    align-self: center;
  }
}

.filter-popup {
  @include media-breakpoint-up(lg) {
    padding: 0 30px;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 60px;
  }

  &__title {
    text-transform: uppercase;
    color: $gray;
    text-align: center;
    margin: -25px 0 24px;

    @include media-breakpoint-up(lg) {
      margin: -25px 0 32px;
    }
  }

  &__content {
    // max-height: 60vh;
    // overflow-x: hidden;
    // overflow-y: auto;

    // @include media-breakpoint-up(lg) {
    //   max-height: none;
    // }
  }

  &__section {
    margin-bottom: 36px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }

    &--features {
      margin-bottom: 12px;

      // @include media-breakpoint-up(xl) {
      //   margin-bottom: 30px;
      // }
    }

    &__heading {
      text-align: center;
      font-size: $h4-font-size;
      margin-bottom: 18px;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
        margin-bottom: 24px;
      }
    }

    &__dropdown {
      width: 240px;
      margin: 0 auto;
      cursor: pointer;
      position: relative;
      height: 36px;

      @include media-breakpoint-up(lg) {
        width: 320px;
        height: 40px;
      }

      // &:hover {
      //   .filter-popup__section__dropdown__list {
      //     display: block;
      //   }
      // }

      &__selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 25px;
        border: 1px solid $gray-darker;
      }

      &__list {
        display: none;
        box-shadow: 0px 3px 6px #00000029;
        padding: 10px 0 6px;
        position: absolute;
        top: 36px;
        left: 0;
        width: 100%;
        z-index: $MENU_NAV;
        background-color: $white;

        &.is-open {
          display: block
        }

        @include media-breakpoint-up(lg) {
          top: 40px;
        }
      }

      &__item {
        padding: 10px 25px;
        margin-bottom: 4px;
        transition: all 0.3s;

        &:hover {
          background-color: #E0DAC9;
        }

        &.is-selected {
          background-color: #E0DAC9;
        }
      }
    }

    &__price-range {
      text-align: center;
      padding: 14px 0 34px;
      width: 80%;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        padding: 12px 0 38px;
      }
    }

    ul.filter-popup__section__color-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -10px -18px;

      @include media-breakpoint-up(lg) {
        padding: 0 20px;
      }
    }

    &__color-item {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #D8D8D8;
      margin: 0 9px 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @include media-breakpoint-up(xl) {
        margin: 0 11px 18px;
      }

      &.is-select {
        border: 1px solid $gray-900;
      }

      &.active {
        border: 1px solid $gray-darker;
      }

      &__block {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .color {
        &--default {
          background-color: $gray;
        }

        &--white {
          border: 1px solid #F6F4EF;
        }

        &--black {
          background-color: $black;
        }

        &--green {
          background-color: #52B4A0;
        }

        &--red {
          background-color: #F92145;
        }

        &--blue {
          background-color: #57B6F3;
        }

        &--orange {
          background-color: $primary;
        }

        &--yellow {
          background-color: #F3BB21;
        }

        &--purple {
          background-color: #8B67E1;
        }

        &--brown {
          background-color: #823806;
        }

        &--gray,
        &--grey {
          background-color: $gray-400;
        }

        &--cherry {
          background-color: #d50000;
        }

        &--ruby-red {
          background-color: #e0115f;
        }

        &--tan {
          background-color: #d2b48c;
        }

        &--copper {
          background-color: #B87333;
        }

        &--joyful-gray {
          background-color: $gray-light;
        }

        &--merry-red {
          background-color: #CF0023;
        }

        &--purple-white {
          background-color: #dc86fa;
        }

        &--pink {
          background-color: #ffc0cb;
        }

        &--orange-white {
          background-color: #ffcd94;
        }

        &--mandarin {
          background-color: #f37a48;
        }

        &--maroon {
          background-color: #800000;
        }

        &--pearl {
          background-color: #eae0c8;
        }
        
        &--pearl-white {
          background-color: #eae0c8;
        }

        &--peach {
          background-color: #f6a192;
        }

        &--cat {
          background-color: #f6d33c;
        }

        &--gold {
          background-color: #ffd700;
        }

        &--latte {
          background-color: #dbc4ab;
        }

        &--star {
          background-color: #ffd27d;
        }
      }
    }

    &__checkbox-list {
      &__group {
        @include media-breakpoint-up(lg) {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    &__checkbox-item {
      margin-bottom: 24px;
      padding-right: 20px;

      @include media-breakpoint-up(lg) {
        width: 50%;
        // margin-bottom: 32px;
      }

      .osim-checkbox {
        &-label {
          padding-top: 3px;
          font-weight: $font-weight-normal;

          @include media-breakpoint-up(lg) {
            padding-top: 0;
            font-size: $font-size-large;
          }
        }
      }
    }
  }

  &__btn-wrap {
    text-align: center;
    padding: 24px 0;

    &__item {
      margin-bottom: 26px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &__apply {
        width: 200px;
        text-transform: uppercase;
      }
    }
  }
}