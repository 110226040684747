@import '@assets/reuse-mixin.scss';

.tradein-buying {
  // padding: 16px 0;
  // border-bottom: 1px solid $gray;

  &-content {
    .btn {
      margin-top: 6px;
    }
  }

  &-popup {
    padding: 10px 0 30px;

    @include media-breakpoint-up(md) {
      padding: 10px 20px 30px;
    }

    &__heading {
      text-align: center;
      margin-bottom: 24px;

      &__title {
        font-size: $h4-font-size;
        margin-bottom: 4px;

        @include media-breakpoint-up(lg) {
          font-size: $h3-font-size;
        }
      }

      &__desc {
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
          padding: 0 15px;
        }
      }
    }

    &__step {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 24px;

      &__item {
        position: relative;
        flex: 1;

        &__line {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $gray;
            top: 50%;
            left: -50%;
          }
        }

        &__text {
          display: block;
          margin-top: 12px;
        }

        &:first-child {
          .tradein-buying-popup__step__item__line {
            &:after {
              content: none;
            }

            &:before {
              content: none;
            }
          }
        }
      }
    }

    &__learn-more {
      margin-bottom: 32px;
    }

    &__list {
      margin-bottom: 24px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 55px;
      }
    }

    &__step1-item {
      display: flex;
      margin-bottom: 18px;
      width: fit-content;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__info {
        width: 100%;

        &__name,
        &__downprice {
          font-weight: $font-weight-normal;
        }

        &__desc {
          font-size: $font-size-small;
          margin-bottom: 0;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
          }
        }
      }
    }

    &__checkbox {
      margin-top: 24px;
      display: flex;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        margin-top: 32px;
      }

      .osim-checkbox {
        width: fit-content;
      }
    }

    &__btn {
      text-align: center;

      &__link {
        display: block;
        //color: $os-blue;
        color: $link-color;
        text-decoration: underline;
        margin-top: 16px;
        cursor: pointer;
      }

      .btn {
        width: 280px;

        @include media-breakpoint-up(lg) {
          width: 320px;
        }
      }
    }
  }

  .label-info-modal {
    display: inline-block;
    margin-top: 6px;
    color: $link-color;
    cursor: pointer;
    text-decoration: underline;
  }

  .collapse {
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }
}

.tradein-step-section {
  margin-bottom: 32px;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__number {
      &.tradein-step-number,
      .tradein-step-number__value {
        width: 28px;
        height: 28px;

        @include media-breakpoint-up(lg) {
          width: 40px;
          height: 40px;
        }
      }
    }

    &__label {
      margin: 0 0 0 8px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(lg) {
        margin: 0 0 0 12px;
      }
    }
  }

  &__content {
    padding: 0 30px 0 40px;
    width: 100%;
    @include media-breakpoint-up(md) {
      padding: 0 60px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 70px;
    }
  }

  &.step-upload-image {
    .tradein-step-section {
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(md) {
          padding: 0 60px;
        }
      }
    }
  }

  &.step-provide-info {
    .tradein-step-section {
      &__content {
        @include media-breakpoint-down(md) {
          padding: 0 20px;
        }
      }
    }
  }

  .text-bold {
    font-weight: $font-weight-bold;
  }

  .image-selection {
    text-align: center;
    position: relative;
    width: 100%;

    &__input {
      position: relative;
      width: calc(calc(100% / 3) - 8px);
    }

    &__image-default,
    &__image-upload-failed {
      margin: 0 auto;
    }

    &__image-upload-failed {
      &.image-uploaded__content {
        border: 1px solid $primary;
      }
    }

    &__desc {
      margin-top: 12px;
      display: block;
    }

    &__wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 10px;
    }

    &__image-default {
      &__icon {
        object-fit: contain;
        width: 24px;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-up(lg) {
          width: 34px;
        }
      }

      &__remain-number {
        color: $gray-darker;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
  }

  .image-uploaded {
    width: calc(calc(100% / 3) - 8px);
    position: relative;

    &__content {
      //width: 150px;
      width: 100%;
      outline: 1px solid $gray;
      overflow: hidden;
      height: 0;
      padding-bottom: 100%;
      position: relative;

      //@include media-breakpoint-up(md) {
      //  width: 200px;
      //}

      img {
        position: absolute;
      }

      &:not(:has(.image-selection__image-default__icon)) {
        img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__action {
      position: relative;
      cursor: pointer;
      color: $link-color;
      text-align: center;
      margin-top: 12px;
      display: inline-flex;
    }

    .image-selection__image-default {
      opacity: 0;
    }

    &__remove {
      position: absolute;
      border: none;
      background-color: transparent;
      outline: none;
      top: 6px;
      right: 6px;
      color: $gray-darker;
      font-size: $font-size-smaller;
      padding: 0;
    }
  }

  .uploading-image {
    height: 100%;
    width: calc(calc(100% / 3) - 8px);
    outline: 1px solid $gray;

    .image-uploaded__content > .loading {
      position: absolute;
    }
  }
}

.tradein-step-number {
  position: relative;
  width: 38px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  background-color: $white;
  z-index: 1;

  @include media-breakpoint-up(lg) {
    width: 60px;
    height: 52px;
  }

  &__value {
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $gray;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    color: $gray-darker;

    @include media-breakpoint-up(lg) {
      width: 52px;
      height: 52px;
      font-size: $h4-font-size;
    }
  }
}
