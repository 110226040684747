.free-gift {
  &__lable {
    font-weight: bold;
    padding: 10px 0;
  }

  &__list {
    &__item {
      margin-right: 10px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &__image {
        width: 100%;

        .free-gift-image {
          width: 100%;
        }
      }

      &__content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 10px;
      }
    }
  }

  &__content {
    &__learn-more {
      position: absolute;
      bottom: 15px;
      left: 10px;
    }
  }

  //INFO: Customize swiper 
  .swiper-container {
    margin: 0;
    padding-bottom: 25px;
  }

  .swiper-pagination-progressbar {
    top: auto;
    bottom: 0;
    height: 3px;
  }
}