@import "@assets/reuse-mixin";

.header {
  transition: 0.5s;
  height: $HEADER_MOBILE_HEIGHT;
  z-index: $HEADER_STICK_ZINDEX;

  @include media-breakpoint-up(md) {
    height: $HEADER_DESKTOP_HEIGHT;
  }

  &.stick {
    .header__container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: $HEADER_STICK_ZINDEX;
    }
  }

  &.non-sticky-detail-product {
    .header__container {
      position: static;
    }

    .header__right-contents__mini-cart__contents {
      display: none;

      &.show-mini-cart {
        display: block;
      }
    }
  }

  &__container {
    background: $white;
    padding: 0 15px;
    border-bottom: 1px solid #ddd;
    height: $HEADER_MOBILE_HEIGHT;
    transition: 0.5s all;

    @include media-breakpoint-up(md) {
      height: $HEADER_DESKTOP_HEIGHT;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }

    &::before {
      background-image: linear-gradient(to top, rgba(255, 252, 251, 0.1), rgba(0, 0, 0, 0.5));
      content: "";
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.5s all;
    }
  }

  &--dark-mode {
    .header {
      &__container {
        background-color: transparent;
        // background-image: linear-gradient(to top, rgba(255, 252, 251, 0.1), rgba(0, 0, 0, 0.5));
        border-bottom: none;
        position: relative;

        &::before {
          display: block;
          opacity: 1;
        }

        // .show-mini-cart {
        //   background-position: 0 -140px;

        //   @include media-breakpoint-up(lg) {
        //     background-position: 0 -167px;
        //   }
        // }
      }

      &__right-contents {
        &__search {
          background: url("../../../assets/images/icons/icon-search-white--3.svg") 0 0 no-repeat;
          // background-position: 0 -60px;

          // @include media-breakpoint-up(lg) {
          //   background-position: 0 -70px;
          // }
        }

        &__user-icon {
          background: url("../../../assets/images/icons/icon-user-white--2.svg") 0 0 no-repeat;
          // background-position: 0 -24px;
        }

        &__mini-cart {
          position: relative;
          background: url("../../../assets/images/icons/cart-white.svg") 0 0 no-repeat;
          // background-position: 0 -100px;

          // @include media-breakpoint-up(lg) {
          //   background-position: 0 -120px;
          // }

          &__quantity {
            border: 0.5px solid $white;
          }

          &.show-mini-cart {
            background: url("../../../assets/images/icons/icon-close-white.svg") 0 0 no-repeat;
            background-size: cover;
          }
        }
      }
    }

    .main-logo-wrap {
      .main-logo {
        &__image {
          &--default {
            display: none;
          }

          &--dark-mode {
            display: block;
          }
        }
      }
    }

    .hamburger,
    .hamburger.active {
      .hamburger-icon {
        span {
          background: $white;
        }
      }
    }

    .header__col--right .account-quick-access-menu .account-quick-access__name {
      color: $white;
    }
  }

  &__row {
    display: flex;
    height: $HEADER_MOBILE_HEIGHT;
    padding: 15px 0;
    align-items: center;
    position: relative;

    @include media-breakpoint-up(md) {
      height: $HEADER_DESKTOP_HEIGHT;
    }
  }

  &__col {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    padding: 0 10px;

    @include media-breakpoint-up(lg) {
      padding: 0 15px;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;

      .account-quick-access-menu {
        padding: 0 10px;
        max-height: 35px;
        cursor: pointer;
        display: none;

        @include media-breakpoint-up(xl) {
          display: flex;
          padding: 0 55px;
          max-width: 300px;
        }

        .account-quick-access {
          vertical-align: middle;
          width: auto;
          min-width: 24px;

          &__name {
            margin: auto 0 auto 10px;
            color: $gray-darker;
            font-size: $h5-font-size;
            letter-spacing: 1px;
            line-height: 1;
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  &__right-contents {
    margin: 0 -10px;
    display: flex;

    &__search {
      background: url("../../../assets/images/icons/icon-search--3.svg") 0 0 no-repeat;
      // background-position: 0 -40px;
      margin-right: 30px;

      @include media-breakpoint-up(xl) {
        // background-position: 0 -47px;
        margin-right: 0;
      }
    }

    &__user-icon {
      background: url("../../../assets/images/icons/icon-user--2.svg") 0 0 no-repeat;
    }

    &__mini-cart {
      position: relative;
      background: url("../../../assets/images/icons/cart.svg") 0 0 no-repeat;
      // background-position: 0 -80px;

      @include media-breakpoint-up(lg) {
        // background-position: 0 -95px;
      }

      &__quantity {
        position: absolute;
        bottom: -10px;
        left: -10px;
        width: 20px;
        height: 20px;
        background-color: $primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          width: 24px;
          height: 24px;
          bottom: -12px;
          left: -12px;
        }

        .product-quantity {
          font-size: $font-size-smaller;
          color: $white;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-small;
          }
        }
      }

      &__contents {
        position: fixed;
        top: $HEADER_MOBILE_HEIGHT - 1;
        right: -100%;
        visibility: hidden;
        width: 100%;
        height: calc(100% - (#{$HEADER_MOBILE_HEIGHT} - 1px));
        z-index: $MENU_NAV;
        background-color: $white;
        transition: 0.5s;
        overflow-y: auto;

        // @include media-breakpoint-up(sm) {
        //   min-height: calc(90vh - (#{$HEADER_DESKTOP_HEIGHT} - 1px));
        // }

        @include media-breakpoint-up(md) {
          width: 60%;
          background-color: $white;
          max-width: 550px;
          top: $HEADER_DESKTOP_HEIGHT - 1;
          height: calc(100vh - (#{$HEADER_DESKTOP_HEIGHT} - 1px));
          // min-height: auto;
        }

        &.show-mini-cart {
          right: 0;
          visibility: visible;
        }
      }

      &.show-mini-cart {
        // background-position: 0 -120px;
        background: url("../../../assets/images/icons/icon-close.svg") 0 0 no-repeat;
        animation: 0.5s ease 0s normal forwards 1 icon-display;

        // @include media-breakpoint-up(lg) {
        //   background-position: 0 -142px;
        // }

        .header__right-contents__mini-cart__quantity {
          display: none;
        }
      }
    }
  }

  &__menu-nav {
    position: fixed;
    top: $HEADER_MOBILE_HEIGHT - 1;
    left: -100%;
    width: 100%;
    height: calc(100% - (#{$HEADER_MOBILE_HEIGHT} - 1px) - env(safe-area-inset-bottom));
    // max-height: calc(100% - (#{$HEADER_MOBILE_HEIGHT} - 1px));
    background-color: $white;
    z-index: $MENU_NAV;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    // box-shadow: 0 2px 4px #00000040;
    visibility: hidden;
    transition: 0.5s;
    display: flex;

    @include media-breakpoint-up(md) {
      top: $HEADER_DESKTOP_HEIGHT - 1;
      max-height: calc(100% - (#{$HEADER_DESKTOP_HEIGHT} - 1px));
      overflow-y: auto;
    }

    @include media-breakpoint-up(lg) {
      height: auto;
      min-height: 560px;
    }

    @include media-breakpoint-up(xxl) {
      min-height: 660px;
    }

    &.show-menu-nav {
      left: 0;
      visibility: visible;
    }

    &__contents {
      padding: 0 34px;
      width: 100%;
      display: flex;

      @include media-breakpoint-up(md) {
        padding: 0 0 0 45px;
      }

      @include media-breakpoint-up(lg) {
        max-width: $SUB_NAV_WIDTH;
      }

      &__list-nav {
        width: 100%;
        height: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        // fix for mobile
        // padding-bottom: calc(20px + env(safe-area-inset-bottom));

        @include media-breakpoint-up(md) {
          padding: 20px 0;
        }

        @include media-breakpoint-up(xxl) {
          padding: 48px 0;
        }
      }

      &--alt-site {
        .arrow-icon-next {
          &::before,
          &::after {
            [lang='zh-HK'] & {
              opacity: 0;
            }
          }
        }

        // .first-nav-item__title {
        //   [lang='zh-CN'] & {
        //     font-weight: $font-weight-light;
        //   }
        // }

        .header__menu-nav__contents__list-nav {
          .first-nav-item__title,
          .second-nav-list a {
            &:hover {
              font-weight: $font-weight-normal;

              // [lang='zh-CN'] & {
              //   font-weight: $font-weight-light;
              // }
            }
          }

          .second-nav-list a {
            font-size: $h5-font-size;
            font-weight: $font-weight-normal;

            @include media-breakpoint-up(md) {
              font-size: $h4-font-size;
            }

            @include media-breakpoint-up(lg) {
              font-size: $h5-font-size;
            }

            @include media-breakpoint-up(xxl) {
              font-size: $h4-font-size;
            }
          }

          .first-nav-item__title {
            &.arrow-icon-next {
              &:hover {
                font-weight: $font-weight-bold;
              }
            }
          }
        }

        .category-label {
          .first-nav-item__title {
            &:hover {
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }

    &__banner {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
        width: $SUB_NAV_CONTENT_WIDTH;
        padding: 40px 25px;
        justify-content: center;
        align-items: center;
      }

      @include media-breakpoint-up(xxl) {
        padding: 40px calc((#{$SUB_NAV_CONTENT_WIDTH} - 1170px) / 2);
      }

      &__images {
        @include media-breakpoint-up(lg) {
          padding: 0 15px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .first-nav-list {
    padding-bottom: 80px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 27px;
    }
  }

  .first-nav-item {
    padding: 16px 0;

    @include media-breakpoint-up(lg) {
      padding: 10px 0;
    }

    @include media-breakpoint-up(xxl) {
      padding: 16px 0;
    }

    .arrow-icon-next {
      &:hover {
        &::before,
        &::after {
          background: $gray-darker;
          width: 11px;
          height: 3px;
        }
      }
    }

    &.show-sub-menu-nav {
      .header__sub-menu-nav {
        left: 0;
        visibility: visible;

        @include media-breakpoint-up(xl) {
          left: $SUB_NAV_WIDTH;
        }

        &__inner {
          left: 0;
          visibility: visible;
        }
      }

      .arrow-icon-next {
        @include media-breakpoint-up(lg) {
          font-weight: $font-weight-bold;

          &::before,
          &::after {
            background: $gray-darker;
            width: 11px;
            height: 3px;
          }
        }
      }
    }

    > a:hover {
      text-decoration: none;
    }

    &__title {
      margin-bottom: 0;
      color: $gray-darker;
      font-size: $h5-font-size;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: $font-weight-normal;
      transition: all 0.5s;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h4-font-size;
      }

      &:hover {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__sub-menu-nav {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    transition: 0.5s;

    @include media-breakpoint-up(xl) {
      width: $SUB_NAV_CONTENT_WIDTH;
      left: $SUB_NAV_WIDTH;
    }

    &__inner {
      position: relative;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      overflow-x: hidden;
      overflow-y: auto;
      visibility: hidden;
      transition: 0.5s;
      background-color: $white;
      z-index: $SUB_MENU_NAV;
      padding: 25px;

      @include media-breakpoint-up(lg) {
        left: -100%;
        padding: 0 25px;
      }

      @include media-breakpoint-up(xxl) {
        padding: 25px;
      }
    }

    .first-nav-item__title {
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }

    ul.header__sub-menu-nav__contents {
      padding: 20px 0 0 0;

      @include media-breakpoint-up(lg) {
        padding: 5px 0 0 0;
        flex-direction: column;
        height: 100%;
        align-content: flex-start;
      }
    }

    &__item {
      padding: 0 15px 26px;
      width: 50%;
      height: 100%;

      @include media-breakpoint-up(md) {
        width: 33.3%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 20%;
        max-height: calc(100% / 2);
        padding: 0 30px 26px;
      }

      @include media-breakpoint-up(xxl) {
        padding: 0 15px 40px;
      }

      a:hover {
        text-decoration: none;

        img {
          transform: scale(1.2);
        }

        h3 {
          font-weight: $font-weight-bold;
        }
      }

      &__image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 8px;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
        }

        @include media-breakpoint-up(xxl) {
          max-width: 210px;
          margin-left: auto;
          margin-right: auto;
        }

        &::before {
          content: "";
          display: block;
          padding-top: 100%;
        }

        img {
          transition: all 0.5s;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__title {
        font-size: $font-size-small;
        text-align: center;
        padding: 0 10px;
        margin-bottom: 0;
        color: $black;
        transition: all 0.5s;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base;
        }
      }
    }

    .arrow-icon-prev {
      padding-left: 30px;

      &::before,
      &::after {
        right: auto;
        left: 1px;
      }
    }

    &--extra-links {
      ul.header__sub-menu-nav__contents {
        max-width: 600px;
        padding-top: 32px;
        
        @include media-breakpoint-up(lg) {
          padding: 40px 0 0 20px;
        }
      }

      .header__sub-menu-nav {
        &__item {
          max-width: 100%;
          width: 100%;
          height: auto;

          &__title {
            text-align: left;
            font-size: $font-size-base;

            @include media-breakpoint-up(lg) {
              font-size: $h5-font-size;
            }
          }
        }
      }
    }
  }

  .second-nav-list {
    width: 100%;
    margin-bottom: auto;

    .second-nav-link {
      display: block;
      color: $black;
      padding: 16px 0;
      transition: all 0.3s;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        padding: 10px 0;
      }

      @include media-breakpoint-up(xxl) {
        padding: 16px 0;
      }

      &:hover {
        text-decoration: none;
        font-weight: $font-weight-bold;
      }
    }
  }

  .quick-nav-list {
    display: flex;
    margin: 0 -10px;
    width: auto;

    a {
      font-size: $font-size-small;
      color: $black;
      padding: 15px 0 8px 0;
      transition: all 0.3s;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
        // padding: 15px 0 ;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $font-size-base;
      }

      &:hover {
        text-decoration: none;
        font-weight: $font-weight-bold;
      }
    }
  }

  .quick-nav-item {
    width: calc(100% / 3);
    padding: 0 10px;

    img,
    span {
      display: block;
    }

    &__link {
      text-align: center;
    }

    &__image {
      height: 35px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }

  &__search {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    background-color: $white;
    z-index: $SUB_MENU_NAV;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-out;

    &.show-search {
      visibility: visible;
      opacity: 1;

      .header__search__suggestions__item {
        padding: 10px 0;
        opacity: 1;

        @include media-breakpoint-up(md) {
          padding: 10px 30px;
        }
      }

      .header__search__input__wrapper {
        padding-left: 0;
        opacity: 1;
      }
    }

    &__container {
      padding: 0 15px;
      position: relative;

      @include media-breakpoint-up(md) {
        margin: 0 auto;
        max-width: 768px;
        padding: 0 30px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 1000px;
      }
    }

    &__input {
      height: $HEADER_MOBILE_HEIGHT;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        height: $HEADER_DESKTOP_HEIGHT;
      }

      &__wrapper {
        width: calc(100% - 25px);
        padding-left: 150px;
        opacity: 0;
        transition: padding 0.22s ease-out, opacity 0.22s ease-out;
        transition-delay: 0.5s, 0.55s;

        @include media-breakpoint-up(md) {
          padding-left: 150px;
        }
      }

      img {
        width: 25px;
        height: auto;
        cursor: pointer;
      }

      input {
        border: none;
        padding: 5px 20px;
        width: calc(100% - 50px);

        &:focus {
          outline: none;
        }
      }

      .close-icon {
        width: 25px;
        height: 25px;
        position: relative;
        cursor: pointer;

        span {
          display: block;
          background-color: $black;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;

          &:first-child {
            transform: translateY(-50%) rotate(45deg);
          }

          &:last-child {
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }

    &__suggestions {
      padding: 15px;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: auto;
      background-color: $white;

      @include media-breakpoint-up(md) {
        padding: 15px 0;
      }

      &__item {
        padding: 10px 0 10px 150px;
        color: $gray-dark;
        transition: padding 0.22s ease-out, opacity 0.22s ease-out;
        opacity: 0;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          padding: 10px 30px 10px 200px;
        }

        &:hover {
          color: $gray-darker;
          background-color: $os-brown-lighter;
        }
      }
    }
  }

  .search-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000040;
    z-index: $MENU_NAV;
  }

  .header-icon-bg {
    width: 20px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    // background: url('../../../assets/images/icons/icon-header.png') 0 0 no-repeat;
    background-size: cover;
    // transition: 0.3s;

    @include media-breakpoint-up(lg) {
      width: 24px;
      height: 24px;
    }
  }

  & > .overlay-black {
    top: unset;
  }
}

@keyframes icon-display {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}