@import '@assets/reuse-mixin.scss';

.center-banner {
    max-width: 1320px;
    margin: 30px auto;

    &__container {
        width: 100%;
        padding: 0 30px;

        @include media-breakpoint-up(md) {
            padding: 0 20px;
        }
    }

    &__item {
        position: relative;
        width: 100%;
        height: 0;
        margin-bottom: 20px;
        padding-bottom: calc(400 / 580 * 100%);
        // border-radius: 10px;
        // transition: all .3s;

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
            padding-bottom: calc(400 / 1260 * 100%);
        }

        // &:hover {
        //     box-shadow: rgba(0, 0, 0, 0.15) 5px 5px 20px 0px, rgba(0, 0, 0, 0.1) 2px 6px 6px 0px;
        //     transform: translateY(-2px);
        // }

        a {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            // border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // border-radius: 10px;
                // transform: scale(1.02);
                // transition: all .3s;

                // &:hover {
                //     transform: scale(1);
                // }
            }
        }
    }
}