@import '@assets/reuse-mixin.scss';

.automatically-added-free-gift {
  &__body {
    padding: 24px 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(md) {
      padding: 32px 0;
    }

    .popup {
      &-title {
        & > .title {
          text-transform: uppercase;
          text-align: center;
          color: $gray;
          font-weight: $font-weight-bold;
          margin-bottom: 0;
          font-size: $h6-font-size-mobile;
          line-height: 2.6rem;
          @include media-breakpoint-up(md) {
            font-size: $font-size-base;
          }
        }
      }

      &-description {
        height: calc(100% - 26px); // INFO: 26px is title line-height
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .description-body {
          max-width: 560px;
          width: 100%;
          margin: auto;

          .content {
            text-align: center;
            color: $gray-darker;
            font-size: $h5-font-size-mobile;
            line-height: 2.4rem;
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
              line-height: 3.2rem;
              font-size: $h5-font-size;
            }

            & > * {
              margin-bottom: 0;
            }
          }

          .free-gift {
            margin-top: 24px;
            @include media-breakpoint-up(md) {
              margin-top: 32px;
            }

            .gift-name {
              @extend .content;

              &:not(:last-child) {
                margin-bottom: 8px;
                @include media-breakpoint-up(md) {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }

        .description-cta {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}