@import '../../assets/reuse-mixin.scss';

.core-typo {
  padding: 40px 0;
}

// Typo
.docs-section {
  margin-bottom: 20px;

  .name-title {
    color: $gray-darker;
    display: inline-block;
    font-size: $h1-font-size;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .sub-title {
    font-size: $h4-font-size;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray;
    margin-bottom: 20px;
  }
}

.example-type {
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    margin: 20px 0;
  }
}

// Swatch Color
.swatch-item {
  padding: 20px;
  color: $white;
  margin-bottom: 20px;
}

.color-primary {
  background: $primary;
}

.color-secondary {
  background: $secondary;
}

.color-warning {
  background: $warning;
}

.color-danger {
  background: $danger;
}

.color-info {
  background: $info;
}

.color-success {
  background: $success;
}

.color-gray-darker {
  background: $gray-darker;
}

.color-gray-dark {
  background: $gray-dark;
}

.color-gray {
  background: $gray;
}

.color-gray-light {
  background: $gray-light;
  color: $black;
}

.color-gray-lighter {
  background: $gray-lighter;
  color: $black;
}

.color-gray-lightest {
  background: $gray-lightest;
  color: $black;
}

.color-os-blue {
  background: $os-blue;
}

.color-os-green {
  background: $os-green;
}

.color-os-orange-darker {
  background: $os-orange-darker;
}

.color-os-orange-dark {
  background: $os-orange-dark;
}

.color-os-orange {
  background: $os-orange;
}

.color-os-orange-lighter {
  background: $os-orange-lighter;
}

.color-os-orange-lightest {
  background: $os-orange-lightest;
}

.color-os-brown {
  background: $os-brown;
}

.color-os-brown-lighter {
  background: $os-brown-lighter;
}

.color-os-brown-lightest {
  background: $os-brown-lightest;
}


// Navigation
.example-navigation {
  .tab-content {
    display: none;
  }
}

// Button
.example-button {
  margin-bottom: 20px;

  > a {
    margin: 0 20px 10px 0;
  }
}

// Input
.example-inputs {
  .form-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
  }

  input {
    width: 100%;
  }
}

.text-dark {
  color: $black;
}