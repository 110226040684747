@import '@assets/reuse-mixin.scss';

.thankyou-payment-comp {
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;

  @include media-breakpoint-up(md) {
    border: none;
  }

  &__wrap {
    max-width: 740px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      border: 1px solid $gray-lighter;
    }

    @include media-breakpoint-up(lg) {
      padding: 32px 90px;
    }
  }

  // register-last-step
  .my-account-header__heading {
    padding-top: 24px;
  }

  .register-last-step {
    // max-width: 740px;
    margin-top: 0;
  }
}

.create-account {
  margin-top: 24px;
  padding: 36px 0 24px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  &__heading {
    margin-bottom: 43px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__title {
      margin-bottom: 16px;
      padding: 0 12%;

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }
    }

    &__desc {
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }

      &__list {
        list-style-type: disc;
      }

      &__item {
        list-style: inside;
      }
    }
  }

  &__form {
    .form-group {
      margin-bottom: 18px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }
    }

    .osim-checkbox {
      margin-bottom: 0;
    }

    &__btn {
      padding-top: 6px;

      @include media-breakpoint-up(lg) {
        padding-top: 8px;
      }

      &__item {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }
  }
}