@import "@assets/reuse-mixin.scss";
@import "./FormikStep/FormStep";

.be-schedule-appointment {
  padding-top: 16px;
  padding-bottom: 16px;

  @include media-breakpoint-up(md) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  &__progress-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      margin-bottom: 48px;
    }

    &__item {
      max-width: 40px;
      width: 100%;
      padding: 10px;
      border: 1px solid $gray-dark;
      border-radius: 100%;
      pointer-events: none;
      position: relative;
      @include media-breakpoint-up(md) {
        padding: 12px;
        max-width: 52px;
      }

      &:not(:last-child) {
        &:after {
          content: '';
          width: 32px;
          display: block;
          height: 1px;
          border-bottom: 1px solid $gray-darker;
          position: absolute;
          right: calc(-100% + 1px);
          top: 50%;
          transform: translateY(-50%);
          @include media-breakpoint-up(md) {
            width: 42px;
          }
        }
      }

      &:not(:first-child) {
        margin-left: 40px;
        @include media-breakpoint-up(md) {
          margin-left: 54px;
        }
      }

      &.active {
        background-color: $primary;
        border-color: $primary;

        .be-schedule-appointment__progress-bar__item-wrapper > .image-icon {
          -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
        }

        &:not(:last-child) {
          &:after {
            border-color: $primary;
          }
        }
      }

      &.current-step {
        border-color: $primary;
      }

      &__wrapper {
        width: 100%;
        padding-bottom: 100%;
        position: relative;

        & > .image-icon {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }
  
  &__disable-form {
    pointer-events: none;

    & > .select-store__date__title {
      color: $gray;
    }

    .calendar-picker .react-datepicker__navigation span::before {
      border-color: $gray;
    }

    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name, .calendar-picker .react-datepicker__day, .select-store__content__list__title {
      color: $gray;
    }
  }

  &__title {
    font-size: $h2-font-size-mobile;
    text-align: center;
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      font-size: $h2-font-size;
      margin-bottom: 48px;
    }
  }

  &__submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    @include media-breakpoint-up(md) {
      margin-top: 48px;
    }

    & > .btn {
      &:disabled, &.btn-disable {
        color: $white;
        background-color: $gray;
      }
    }
  }
}