@import '@assets/reuse-mixin.scss';

.data-table {
  width: 100%;

  &__head {
    border-bottom: 1px solid $gray-lighter;

    &>th {
      @extend .data-table__data;
    }
  }

  &__row {
    border-bottom: 1px solid $gray-lighter;
    font-size: $font-size-smaller;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
    }
  }

  &__data {
    padding: 12px 12px 12px 0;

    @include media-breakpoint-up(xl) {
      padding: 16px 16px 16px 0;
    }

    &__link {
      color: $black;
      max-width: 120px;

      @include line-clamp(2)
    }
  }

  &__hidden {
    display: none;
  }
}