html {
  // INFO: set font-size for html to 62.5% mean that <html> element will have font-size qual to 62.5% * {browser font setting default = 16px ( bootstrap also assum 16px ) } = 10px
  // From now, 1rem will equal to 10 px. This will be easier to scale font-size by rem if one rem is 10px instead of 16px
  font-size: 62.5%;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

// TODO: global styling here
// Override Default Style
body {
  letter-spacing: 0.1rem;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  [lang='zh-CN'] &,
  [lang='zh-TW'] &,
  [lang='zh-HK'] & {
    font-family: $font-family-base--chinese;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  [lang='zh-CN'] &,
  [lang='zh-TW'] &,
  [lang='zh-HK'] & {
    font-family: $font-family-base--chinese;
  }
}

img {
  max-width: 100%;
  height: auto;
}

ul[class],
ol[class] {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

// p {
//   margin: 0;
// }

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

// font size on mobile
@include media-breakpoint-down(md) {
  body {
    font-size: $font-size-mobile;
  }

  h1 {
    font-size: $h1-font-size-mobile;
  }

  h2 {
    font-size: $h2-font-size-mobile;
  }

  h3 {
    font-size: $h3-font-size-mobile;
  }

  h4 {
    font-size: $h4-font-size-mobile;
  }

  h5 {
    font-size: $h5-font-size-mobile;
  }

  h6 {
    font-size: $h6-font-size-mobile;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

// .main-container {
//   padding-top: 56px;

//   @include media-breakpoint-up(md) {
//     padding-top: 73px;
//   }
// }

.experience-title {
  color: $gray-darker;
  font-size: $h4-font-size;

  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size;
  }

  @include media-breakpoint-up(xl) {
    font-size: $h2-font-size;
  }
}

// page title
.page-title {
  color: $page-title;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  letter-spacing: 2.88px;
  margin-bottom: 24px;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-base;
  }
}

.section-title {
  @extend .experience-title;
  line-height: 1.33;
  letter-spacing: 1.2px;
  margin-bottom: 25px;

  @include media-breakpoint-up(xl) {
    line-height: 1.15;
    letter-spacing: 2px;
    margin-bottom: 40px;
  }
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.flex-between {
  display: flex;
  justify-content: center;
  align-items: center;

  &--space-between {
    justify-content: space-between;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-line-through {
  text-decoration: line-through;
}

.smaller-text {
  font-size: $font-size-smaller;

  @include media-breakpoint-up(md) {
    font-size: $font-size-small;
  }

  // @include media-breakpoint-up(lg) {
  //   font-size: $font-size-smaller;
  // }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-base;
  }

  &--light {
    color: $gray-dark;
  }

  &--lighter {
    color: $gray;
  }

  &--bold {
    color: $gray-darker;
    font-weight: $font-weight-normal;
  }
}

.normal-text {
  font-size: $font-size-base;

  // @include media-breakpoint-up(md) {
  //   font-size: $h5-font-size;
  // }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-large;
  }

  @include media-breakpoint-up(xxl) {
    font-size: $h5-font-size;
  }
}

.large-text {
  font-size: $font-size-large;

  // @include media-breakpoint-up(md) {
  //   font-size: $h4-font-size;
  // }

  // @include media-breakpoint-up(lg) {
  //   font-size: $font-size-large;
  // }

  @include media-breakpoint-up(xl) {
    font-size: $h4-font-size;
  }
}

.link-text {
  font-size: $font-size-small;
  //color: $secondary;
  color: $link-color;

  @include media-breakpoint-up(md) {
    font-size: $font-size-large;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-small;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-large;
  }

  &--gray-darker {
    color: $gray-darker;
  }
}

.text-highlight {
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  color: $os-green;
  text-align: center;

  @include media-breakpoint-up(md) {
    font-size: $h5-font-size;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
  }

  @include media-breakpoint-up(xl) {
    font-size: $h5-font-size;
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      text-align: left;
      margin-top: -28px;
    }
  }

  &--mobile {
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.smaller-container {
  @include media-breakpoint-up(xl) {
    max-width: 1040px;
    margin: 0 auto;
  }
}

.tablet-container {
  @include media-breakpoint-up(md) {
    max-width: 100%;
    padding: 0 24px;
    margin: 0 auto;
  }

  @include media-breakpoint-up(xl) {
    max-width: $container-xl;
  }

  @include media-breakpoint-up(xxl) {
    max-width: $container-xxl;
  }
}

.d-mobi {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.d-desktop {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.arrow-icon-next {
  @include arrow-icon('next');
}

.arrow-icon-prev {
  @include arrow-icon('prev');
}

.stop-scrolling {
  overflow: hidden;
  position: relative;
  height: 100%;

  @include media-breakpoint-up(lg) {
    padding-right: 17px;

    .header__row {
      padding-right: 17px;
    }
  }
}

.overlay-black {
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: $PRODUCT_DETAIL_HEADER_ZINDEX;
  background-color: rgba($black, 0.6);

  @include media-breakpoint-up(md) {
    top: 72px;
  }

  // &__header {
  //   height: 55px;

  //   @include media-breakpoint-up(md) {
  //     height: 72px;
  //   }
  // }

  // &__body {
  //   height: calc(100% - 55px);
  //   background-color: #00000040;

  //   @include media-breakpoint-up(md) {
  //     height: calc(100% - 72px);
  //   }
  // }
}

.overlay-transparent {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 70px);
  z-index: $POPUP_ZINDEX - 1;
}

.list-tags {
  position: absolute;
  top: 24px;
  left: 0;
  z-index: 1;
  width: 32%;
  max-width: 130px;

  .osim-label {
    // padding: 5px;
    margin-bottom: 8px;
    // display: block;
    // min-width: auto;

    // @include media-breakpoint-up(xl) {
    //   padding: 9px 5px;
    // }

    @include media-breakpoint-up(xxl) {
      max-width: 130px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.heart {
  background: url('../../assets/images/icons/os-icon--new.png') 0 0 no-repeat;
  background-size: cover;
  position: absolute;
  top: 24px;
  right: 24px;
  display: inline-block;
  cursor: pointer;
  z-index: $Z_INDEX_NORMAL;
  width: 24px;
  height: 22px;
  &.loading-wish-list {
    cursor: default;
  }
  @include media-breakpoint-up(xl) {
    top: 32px;
    right: 32px;
  }

  @include media-breakpoint-up(xxl) {
    width: 32px;
    height: 30px;
  }

  &:hover {
    background-position: 0 -22px;

    @include media-breakpoint-up(xxl) {
      background-position: 0 -30px;
    }
  }

  &.active {
    background-position: 0 -44px;

    @include media-breakpoint-up(xxl) {
      background-position: 0 -60px;
    }
  }

  // &__icon {
  //   display: inline-block;
  //   position: relative;
  //   width: 18px;
  //   height: 18px;
  //   margin: 0 auto;
  //   // background-color: $os-brown-lightest;
  //   transform: rotate(45deg);
  //   border-right: 2px solid $gray;
  //   border-bottom: 2px solid $gray;
  //   border-bottom-right-radius: 4px;

  //   span {
  //     position: absolute;
  //     bottom: -2px;
  //     right: -2px;
  //     width: 17px;
  //     height: 15px;
  //     z-index: $Z_INDEX_NORMAL + 1;
  //     background-color: $os-brown-lightest;
  //     border-right: 2px solid $gray;
  //     border-bottom: 2px solid $gray;
  //     border-bottom-right-radius: 4px;
  //   }

  //   &:before,
  //   &:after {
  //     position: absolute;
  //     content: "";
  //     border-radius: 50%;
  //     background-color: $os-brown-lightest;
  //     border: 2px solid $gray;
  //     z-index: $Z_INDEX_NORMAL;
  //   }

  //   &:before {
  //     bottom: -2px;
  //     left: -8px;
  //     width: 19px;
  //     height: 17px;
  //   }

  //   &:after {
  //     top: -8px;
  //     right: -2px;
  //     width: 17px;
  //     height: 19px;
  //   }
  // }
}

// swiper button disable
.swiper-container {
  .swiper-button-next,
  .swiper-button-prev {
    &:active,
    &:focus {
      outline: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}

.checkout-checkbox {
  display: flex;
  font-size: $font-size-small;
  margin-bottom: 10px;
  position: relative;
  align-items: center;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }

  &__input {
    width: 24px;
    height: 24px;
    opacity: 0;

    &:checked ~ .checkout-checkbox__label {
      font-weight: $font-weight-bold;
    }

    &:checked ~ .checkout-checkbox__label::after {
      background: url('../images/checkbox-selected.png') no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  &__label {
    width: calc(100% - 24px);
    padding-left: 10px;
    padding-top: 2px;
    cursor: pointer;
    margin-bottom: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border: 1px solid $gray-dark;
      border-radius: 3px;
      background: $white;
    }
  }
}

.no-padding-right-mobile {
  padding-right: 0;

  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }
}

.no-padding-mobile {
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }
}

.os-section-heading {
  padding: 24px 0;

  @include media-breakpoint-up(lg) {
    padding: 32px 0;
  }

  &__title {
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
    }
  }

  &__desc {
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 10%;
    }
  }

  &__btn {
    margin-top: 24px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-top: 32px;
    }

    .btn {
      width: 200px;

      @include media-breakpoint-up(lg) {
        width: 240px;
      }
    }
  }
}

// Confirm Remove Popup
.confirm-remove-message {
  &__text {
    font-size: $font-size-base;
    text-align: center;
    padding: 60px 15px 100px;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
      padding: 85px 15% 150px;
    }
  }

  &__btn {
    min-width: 200px;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      min-width: 240px;
      margin-bottom: 15px;
    }
  }
}

// Product Info Icons
.product-info-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../../assets/images/icons/pro-info-icons.png') 0 0 no-repeat;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    width: 18px;
    height: 18px;
  }

  &--warranty {
    background-position: 0 0.5px;
    height: 17px;

    @include media-breakpoint-up(lg) {
      background-position: 0 1.5px;
      height: 20px;
    }
  }

  &--trade-in {
    background-position: 0 -16px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -18px;
    }
  }

  &--free-gift {
    background-position: 0 -32px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -36px;
    }
  }

  &--gifting {
    background-position: 0 -48px;
    height: 14px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -54px;
      height: 16px;
    }
  }

  &--delivery {
    background-position: 0 -64px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -72px;
    }
  }

  &--rental {
    background-position: 0 -80px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -90px;
    }
  }

  // icon with images
  &--img {
    background: none;
    height: auto;
    display: flex;
  }
}

// Fulfillment Icons
.fulfillment-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url('../../assets/images/icons/fulfillment-icons.png') 0 0 no-repeat;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    width: 20px;
    height: 20px;
  }

  &--ship-item {
    @include media-breakpoint-up(lg) {
      width: 24px;
    }
  }

  &--pickup-store {
    background-position: 0 -19px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -21px;
    }
  }

  &--sf-express {
    background: url('../../assets/images/icons/icon-sf-express-new.png') 0 0 no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    background-position: -4px;

    @include media-breakpoint-up(lg) {
      width: 30px;
      height: 30px;
    }
  }
  &--delivery-sf-express {
    background-position: 0 -37px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -40px;
    }
  }
}

// Package Icons
.package-icon {
  width: 30px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/images/icons/package-icons.png') 0 0 no-repeat;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    width: 45px;
    height: 36px;
  }

  &--pickup {
    background-position: 0 -24px;
    height: 26px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -39px;
      height: 36px;
    }
  }

  &--sf-express {
    background: url('../../assets/images/icons/icon-sf-express-new.png') 0 0 no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;

    @include media-breakpoint-up(lg) {
      width: 40px;
      height: 40px;
    }
  }

  &--delivery-sf-express {
    background-position: 0 -37px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -40px;
    }
  }

  &--billing {
    background-position: 0 -79px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -118px;
    }
  }

  &--payment-method {
    background-position: 0 -103px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -155px;
    }
  }

  &--deposit {
    background: url('../../assets/images/icons/icon-deposit-test.png') 0 0 no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;

    @include media-breakpoint-up(lg) {
      width: 45px;
      height: 45px;
    }
  }
}

//Store Locator Icons
.store-locator-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url('../../assets/images/icons/store-icons.png') 0 0 no-repeat;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    width: 24px;
    height: 24px;
  }

  &--share {
    background-position: 0 -20px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -25px;
    }
  }

  &--filter {
    background-position: 0 -40px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -48px;
    }
  }

  &--direction {
    width: 24px;
    height: 24px;
  }
}

// Info icon
.info-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url('../../assets/images/icons/icon-info-blue.png') 0 0 no-repeat;
  background-size: cover;

  &--small {
    width: 16px;
    height: 16px;

    @include media-breakpoint-up(md) {
      width: 20px;
      height: 20px;
    }
  }

  &--black {
    background: url('../../assets/images/CP-images/info-icon-black.PNG') 0 0 no-repeat;
    background-size: cover;
  }
}

// Recommend icon
.recommend-icon {
  padding: 8px;
  display: inline-block;
  background: url('../../assets/images/success-icon.png') 0 0 no-repeat;
  background-size: cover;

  @include media-breakpoint-up(md) {
    padding: 12px;
  }

  &--fail {
    background: url('../../assets/images/icons/fail-icon-black.png') 0 0 no-repeat;
    background-size: cover;
  }
}

// Quote Icon
.quote-icon {
  width: 22px;
  height: 17px;
  display: inline-block;
  background: url('../../assets/images/icons/quote-icon.png') 0 0 no-repeat;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    width: 38px;
    height: 30px;
  }
}

// Spin Wheel Icons
.spin-wheel-icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: cover;

  &--help {
    background: url('../../assets/images/icons/help-icon.png') 0 0 no-repeat;
  }

  &--prize {
    background: url('../../assets/images/icons/gift-icon.png') 0 0 no-repeat;
  }
}

// Rating Star ICon
.rating-star-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url('../../assets/images/icons/star-empty-icon.png') 0 0 no-repeat;
  background-size: contain;

  @include media-breakpoint-up(md) {
    width: 24px;
    height: 24px;
  }

  &--filled {
    background: url('../../assets/images/icons/star-filled-icon.png') 0 0 no-repeat;
    background-size: contain;
  }

  &--half {
    background: url('../../assets/images/icons/star-half-icon.png') 0 0 no-repeat;
    background-size: contain;
  }
}

//Check mark icon
.check-mark {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url('../../assets/images/icons/check-mark.png') 0 0 no-repeat;
  background-size: contain;

  @include media-breakpoint-up(md) {
    width: 24px;
    height: 24px;
  }
}

.toastify-custom {
  .Toastify {
    &__toast {
      min-height: auto;

      &-body {
        font-size: $font-size-smaller;
      }

      &--error {
        background: $danger;
      }
    }

    &__close {
      &-button {
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.link-text-underline {
  display: block;
  font-size: $font-size-smaller;
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-small;
  }

  &--lg {
    font-size: $font-size-small;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }
}

.os-btn-link {
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  color: $link-color;
}

.asterisk {
  color: $primary;
}

.installment-info {
  &__text {
    display: block;
    font-size: $font-size-small;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }
}

.installment-img {
  max-height: 15px;
  width: auto;
  margin-bottom: 6px;
  margin-right: 8px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 4px;
  }
}

// .not-full-width (max-width: 1400px) follow OSIM_Request Measurement
.not-full-width {
  @include media-breakpoint-down(sm) {
    padding: 0;
    max-width: 100%;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1440px;
  }
}

//client portal
.cp-icon {
  // width: 33px;
  // height: 25px;
  display: inline-block;
  // background-image: url('../images/CP-images/email-icon.PNG');
  // background-repeat: no-repeat;
  // background-size: contain;

  &-email {
    background-image: url('../images/CP-images/email-icon.PNG');
    background-position: 0 0;
  }

  &--eye {
    background-image: url('../images/CP-images/icon-eye.svg');
    width: 18px;
    height: 16px;
  }

  &--filter {
    background-image: url('../images/CP-images/filter-black.png');
    width: 24px;
    height: 24px;
  }
  
  &--search {
    background-image: url('../images/icons/icon-search--3.svg');
    width: 24px;
    height: 24px;
  }

  &--ring {
    background-image: url('../images/CP-images/ring-icon.png');
    width: 32px;
    height: 24px;
  }

  &--camera {
    background-image: url('../images/CP-images/icon-camera.png');
    width: 24px;
    height: 24px;
  }
  
  &--wechat {
    background-image: url('../images/CP-images/icon-wechat.svg');
    width: 24px;
    height: 22px;
  }

  &--whatsapp {
    background-image: url('../images/CP-images/icon-whatsapp.svg');
    width: 20px;
    height: 20px;
  }
  
  &--mail {
    background-image: url('../images/CP-images/icon-mail.svg');
    background-position: center; 
    width: 24px;
    height: 24px;
  }
}
//Color
.text-blue {
  color: $secondary;
}
.text-orange {
  color: $primary;
}
.text-green {
  color: $success;
}

.link-nomal {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.disable-event{
  cursor: default;
  pointer-events: none;
  opacity: .5;
}

.label-active{
  background-color: $gray-200;
  pointer-events: none;
  cursor: default;
}

.disable-event-noneOpacity{
  cursor: default;
  pointer-events: none;
}


.input-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}
