@import '@assets/reuse-mixin.scss';

.additional-fullfilment {
  &__content {
    padding: 24px 0;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
      padding: 32px 0;
    }
  }
}
