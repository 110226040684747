@import '../../../../assets/reuse-mixin.scss';

.product-manual {
  padding-bottom: 56px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 72px;
  }

  &__image {
    max-width: 35px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      max-width: 72px;
    }
  }

  &__link {
    display: block;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: 1.57;
    letter-spacing: 0.7px;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      line-height: 1.75;
      letter-spacing: 0.8px;
      margin-top: 30px;
    }

    &.link-text {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .section-title {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 27px;
    }
  }
}