@import'@assets/reuse-mixin.scss';

.review-listing {
  @include media-breakpoint-up(lg) {
    padding-top: 13px;
  }

  &__item {
    margin-bottom: 36px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 42px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn {
    margin-top: 36px;

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }

    &--mobile {
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(xl) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 240px;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }

      &--large {
        width: 280px;

        @include media-breakpoint-up(lg) {
          width: 320px;
        }
      }

      i {
        margin-right: 12px;
      }

      i.icon-chevron-right-big {
        margin: 0 0 0 12px;
      }
    }
  }

  &--full {
    .review-listing__btn {
      margin-top: 40px;

      @include media-breakpoint-up(lg) {
        margin-top: 56px;
      }
    }
  }

  //ReviewsSummaryShimmer
  &__item {
    .product-review-content {
      &__star-rating {
        .text-line-30 {
          width: 30% !important;
        }
      }

      &__review-title {
        .text-line-20 {
          width: 28% !important;
        }
      }

      &__review-info {
        .text-line-20 {
          width: 60% !important;
        }
      }

      &__review-recommend {
        .text-line-20 {
          width: 70% !important;
        }
      }
    }
  }
}