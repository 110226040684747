@import '@assets/reuse-mixin.scss';

.my-reward__box {
  padding: 24px 16px;
  border-top: 0.5px solid $gray-light;
  border-bottom: 0.5px solid $gray-light;
  @include media-breakpoint-up(md) {
    padding: 24px 32px;
    border: 0.5px solid $gray-light;
  }

  &:not(:last-child) {
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  &-title {
    margin-bottom: 0;
    font-weight: $font-weight-normal;
  }

  &-wrapper {
    margin-top: 24px;
    @include media-breakpoint-up(md) {
      margin-top: 32px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    &:not(:last-child) {
      border-bottom: 0.5px solid $gray-light;
      margin-bottom: 24px;
      padding-bottom: 24px;
      @include media-breakpoint-up(md) {
        padding-bottom: 32px;
      }
    }

    &__description {
      display: flex;
      flex: 1;

      &-icon {
        max-width: 24px;
        width: 100%;
        @include media-breakpoint-up(md) {
          max-width: 36px;
        }

        .icon-wrapper {
          width: 100%;
          padding-bottom: 100%;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: contain;
          }
        }
      }

      &-detail {
        margin-left: 12px;
        @include media-breakpoint-up(md) {
          margin-left: 14px;
        }

        & > .title {
          font-weight: $font-weight-normal;
          margin-bottom: 0;
        }

        & > .content {
          color: $gray-1000;
          margin-top: 14px;
          margin-bottom: 0;
        }
      }
    }

    &__cta {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }
}