@import '@assets/reuse-mixin';

.my-address {
  padding-top: 8px;

  @include media-breakpoint-up(md) {
    padding-top: 26px;
  }

  &__list {
    max-height: 55vh;
    overflow-y: scroll;

    @include media-breakpoint-up(md) {
      max-height: 65vh;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    margin-bottom: 18px;
    // cursor: pointer;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }
  }

  &__btn {
    padding: 22px 0 5px;

    @include media-breakpoint-up(lg) {
      padding: 24px 0 16px;
    }

    .btn {
      min-width: 200px;

      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }

  .item-default {
    position: absolute;
    top: 16px;
    right: 24px;
    font-size: $font-size-smaller;
    color: $os-green;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-small;
    }
  }
}

.select-address {
  color: $gray-darker;
  padding: 16px 24px;
  border: 1px solid $gray-light;
  height: 100%;
  position: relative;
  cursor: pointer;

  &.selected {
    border: 1px solid $gray-darker;
    background-color: rgba($os-orange-lighter, 0.3);
  }

  &.active {
    border: 1px solid $gray-darker;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: inherit;
  }

  &__type {
    margin-bottom: 4px;
    font-size: $font-size-small;
    word-break: break-word;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(lg) {
      margin-bottom: 8px;
      font-size: $font-size-large;
    }
  }

  &__full-name,
  &__info-line,
  .my-addresses__section__content__info {
    font-size: $font-size-smaller;
    line-height: 1.8rem;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-small;
      line-height: 2rem;
    }
  }

  p {
    margin: 0;
  }
}