@import'@assets/reuse-mixin.scss';

.product-review-content {
  color: $gray-darker;

  &__review-title {
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
    margin-top: 12px;
    margin-bottom: 12px;
    line-height: 1.1;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size + 0.4;
    }
  }

  &__info {
    font-size: $font-size-base;
    line-height: 1;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }

    &__author {
      display: inline-block;
      padding-right: 6px;

      @include media-breakpoint-up(lg) {
        padding-right: 10px;
      }
    }

    &__date {
      display: inline-block;
      padding-left: 6px;
      border-left: 1px solid $gray-darker;
      line-height: 0.8;

      @include media-breakpoint-up(lg) {
        padding-left: 10px;
      }
    }
  }

  &__review-detail {
    margin-top: 18px;
    position: relative;

    &__quote-icon {
      position: absolute;
      top: 0;
      left: -32px;
      z-index: -1;

      @include media-breakpoint-up(lg) {
        left: -42px;
      }

      @include media-breakpoint-up(xxl) {
        left: -60px;
      }
    }

    p {
      font-size: $font-size-base;
      
      // @include line-clamp(3);
      
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }
  }

  &__media {
    margin-top: 24px;
  }

  &__review-recommend {
    display: flex;
    align-items: center;
    margin: 18px 0 12px 0;

    @include media-breakpoint-up(lg) {
      margin: 24px 0 16px 0;
    }

    &__text {
      display: inline-block;
      margin-left: 8px;
    }
  }
}