@import '@assets/reuse-mixin.scss';

.appointments-listing {
  padding-bottom: 40px;
  background-color: $cp-background;

  &__wrap {
    overflow: hidden;
    background-color: $white;
  }

  .appointments-listing__filter-list {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    padding-top: 24px;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      background-color: $gray-lighter;
    }

    .nav-item {
      width: unset;
      border-width: 1px;
      margin-left: 12px;

      &:first-child {
        margin-left: 32px;
      }
    }
  }

  &__filter-item {
    text-transform: uppercase;
    color: $gray;
    padding: 12px;
    white-space: nowrap;
    transition: all 0.3s ease;
    z-index: 1;
  }

  &__content {
    padding: 12px 12px 32px;
    overflow-x: auto;

    @include media-breakpoint-up(md) {
      padding: 12px 32px 32px;
    }

    &-status {
      color: $secondary;

      &__lost {
        color: $red;
      }

      &__trial {
        color: $os-green;
      }
    }
  }
}