@import '../../assets/reuse-mixin.scss';

.video-fullwidth {
  position: relative;

  // video 16:9
  .video-wrap {
    background: $black;
    position: relative;
    transition: 0.5s all;
    padding-top: 56.25%;

    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.is-sticky {
    height: 100vh * 1.5;

    @include media-breakpoint-up(xl) {
      height: 100vh * 2;
    }

    .video-fullwidth__item {
      position: sticky;
      top: $HEADER_MOBILE_HEIGHT;
      z-index: 2;

      @include media-breakpoint-up(md) {
        top: $HEADER_DESKTOP_HEIGHT;
      }
      
      .video-wrap {
        padding-top: calc(100vh - #{$HEADER_MOBILE_HEIGHT});

        @include media-breakpoint-up(xl) {
          padding-top: 56.25%;
        }
      }
    }

    .decor--top,
    .decor--bottom {
      height: 0;
    }

    .decor--left,
    .decor--right {
      width: 0;
    }
  }

  &__item {
    .decor {
      &--top,
      &--bottom,
      &--left,
      &--right {
        background: $white;
        position: absolute;
        display: block;
        z-index: 1;
        transition: 0.5s;
      }

      &--top,
      &--bottom {
        height: 25px;
        width: 100%;

        @include media-breakpoint-up(lg) {
          height: 55px;
        }
      }

      &--top {
        top: 0;
      }

      &--bottom {
        bottom: 0;
      }

      &--left,
      &--right {
        height: 100%;
        width: $grid-gutter-width/2;

        @include media-breakpoint-up(md) {
          width: calc(50% - (#{$container-md})/2);
        }

        @include media-breakpoint-up(lg) {
          width: calc(50% - (#{$container-lg})/2);
        }

        @include media-breakpoint-up(xl) {
          width: calc(50% - (#{$container-xl})/2);
        }

        @include media-breakpoint-up(xxl) {
          width: calc(50% - (#{$container-xxl})/2);
        }
      }

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }
  }

  &.skeleton__item {
    border-bottom: 1px solid $gray-200;
    
    .image-wrap {
      padding-bottom: 666/375 * 100%;
  
      @include media-breakpoint-up(md) {
        padding-bottom: 1080/1920 * 100%;
      }

      .loading__default {
        @include centerer(true, true)
      }
    }
  }
}