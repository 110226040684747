@import '@assets/reuse-mixin';

.countdowntimer--number {
  color: $os-green;
  text-transform: uppercase;
}

.countdown__label {
  color: $gray-darker;
}

.countdown__time {
  color: $os-green;
  // text-transform: uppercase;
}