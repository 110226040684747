@import '../../../assets/reuse-mixin.scss';

.store-locator {
  color: $gray-darker;
  padding-bottom: 12px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 40px;
  }

  &__heading {
    text-align: center;
    padding: 24px 0;

    @include media-breakpoint-up(lg) {
      padding: 40px 0 70px;
    }

    &__title {
      margin-bottom: 24px;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
      }
    }

    &__desc {
      margin-bottom: 0;
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }

      &__link {
        //color: $os-blue;
        color: $link-color;
        text-decoration: underline;
      }
    }
  }

  &__content {
    background: $white;
  }

  &__tab-content {
    padding-top: 32px;
  }

  //INFO: Customize Tabs
  .nav-tabs {
    display: flex;
    justify-content: space-between;
    margin: 0 -#{$grid-gutter-width/2};
    // background: $gray-lightest;

    @include media-breakpoint-up(md) {
      margin: 0 calc(-1 * (((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2}));
    }

    @include media-breakpoint-up(lg) {
      margin: 0 calc(-1 * (((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2}));
    }

    .nav-item {
      flex: 1 1;
      text-align: center;
      font-size: $font-size-smaller;
      padding-bottom: 10px;
      color: $gray;
      border-bottom: 2px solid $gray;
      transition: all 0.3s ease;

      &:hover {
        text-decoration: none;
      }

      &.active {
        color: $gray-darker;
        border-bottom: 2px solid $gray-darker;
      }
    }
  }

  .tab-content {
    .fade:not(.show) {
      display: none;
    }
  }

  // .react-google-maps-api {
  //   width: 100%;
  //   padding-bottom: 100%;
  // }

  .map-wraper {
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;

    .react-google-maps-api {
      width: 100%;
      height: 100%;
      position: absolute;
    } 
  }

  // modal Select Area
  &__modal {
    padding-top: 10px;
    max-width: 480px;
    margin: 0 auto;

    &__item {
      margin-bottom: 18px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .delivery-zone-instruction {
      font-size: $h5-font-size;
      text-align: center;
      color: $gray-darker;
      padding-top: 20px;
    }

    .select-address {
      text-align: center;
      padding: 20px 0;

      .store-locator__modal__item {
        max-width: 320px;
        margin: 32px auto;
      }
    }

    .estimate-delivery {
      color: $gray-darker;
      margin: 20px 0;
      display: flex;
      justify-content: space-around;
      font-size: $h4-font-size;
      font-weight: $font-weight-normal;

      span {
        min-width: 100px;
      }
    }

    .button-container {
      text-align: center;
      padding: 57px 0 10px;

      @include media-breakpoint-up(lg) {
        padding: 122px 0 10px;
      }

      .btn {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }

      .btn-clear-filter {
        display: block;
        color: $os-blue;
        margin-top: 18px;
        cursor: pointer;
      }
    }

    &--has-scroll {
      &.store-locator__modal {
        .user-addresses {
          max-height: 50vh;
          overflow-y: auto;
          margin-right: -10px;
          padding: 10px 10px 5px 0;

          @include scrollbar-custom();

          @include media-breakpoint-up(md) {
            max-height: 55vh;
            max-width: calc(480px + 40px * 2);
            margin-right: -40px;
            padding-right: 40px;
          }

          @include media-breakpoint-up(xxl) {
            max-width: calc(480px + 96px * 2);
            margin-right: -96px;
            padding-right: 96px;
          }
        }
      }
    }

    //Add New Address
    .user-addresses {
      padding-top: 8px;
      max-width: 480px;
      margin: 0 auto;

      .form-group {
        margin-bottom: 18px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 32px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &--select {
          &~.form-group--select {
            @include media-breakpoint-up(lg) {
              margin-top: -8px;
            }
          }
        }
      }

      .auto-address {
        color: $gray-darker;
        font-weight: $font-weight-normal;
      }
    }

    &#add-addresses {
      .button-container {
        padding-top: 24px;

        @include media-breakpoint-up(lg) {
          padding-top: 32px;
        }
      }
    }
  }

  &.skeleton__item {
    .page-title {
      .text-line-center {
        @include skeleton($lines: 1, $line-height: 18px, $line-gap: 0);
      }
    }

    .store-locator__heading__desc {
      .text-line-center {
        @include skeleton($lines: 1, $line-height: 24px, $line-gap: 0);
      }
    }

    .store-locator__content {
      &--desktop {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }

        .store-locator__content__list__search {
          .text-line {
            @include skeleton($lines: 1, $line-height: 56px, $line-gap: 0);
          }
        }

        .store-locator__content__list__store {
          .image-wrap {
            padding-bottom: 60%;
          }
        }
      }

      &--mobile {
        display: block;
        
        @include media-breakpoint-up(lg) {
          display: none;
        }

        .store-locator__tab-content {
          .image-wrap {
            padding-bottom: 40%;
          }
        }
      } 
    }
  }
}