@import '@assets/reuse-mixin.scss';

.tier-benefit {

  &__title {
    padding-bottom: 16px;
  }

  &__info {
    padding-bottom: 25px;
    margin-bottom: 0;
    font-size: $h4-font-size-mobile;
    color: $gray-1000;
    
    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      padding-bottom: 40px;
    }
  }

  &__list {
    padding-left: 13px !important;
    
    @include media-breakpoint-up(lg) {
      padding-left: 0 !important;
    }
    
    &__item {
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      &:last-child {
        padding-bottom: 0px
      }
      
      &__image {
        width: 20px;
        height: 20px;
        margin-right: 12px;
        
        @include media-breakpoint-up(lg) {
          width: 36px;
          height: 36px;
        }
      }

      &__text {
        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
          color: $gray-900;
        }
      }
    }
  }

  &__button {
    text-align: center;
    margin-top: 25px;
    
    @include media-breakpoint-up(lg) {
      margin-top: 50px;
    }
  }
}