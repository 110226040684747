@import '../../../assets/reuse-mixin.scss';

.footer__copyright {
  font-size: $font-size-small;
  padding-bottom: 56px;
  text-align: center;
  color: $gray-darker;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
  }

  @include media-breakpoint-up(xxl) {
    font-size: $font-size-large;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 72px;
  }

  a {
    //color: $os-blue;
    color: $link-color;
    text-decoration: underline;
  }
}

.page-not-found {
  background: url('../../../assets/images/page-not-found-bg.png') no-repeat center;
  background-size: contain;
  min-height: 400px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(xl) {
    min-height: 500px;
  }

  &__text {
    text-align: center;
  }
}