@import '@assets/reuse-mixin.scss';

.featured-products {
  padding-top: 56px;
  margin-bottom: 35px;
  color: $gray-darker;

  @include media-breakpoint-up(lg) {
    padding-top: 96px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 64px;
    }

    &__title {
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
      }
    }

    &__link {
      font-size: $font-size-base;
    }
  }

  .featured-product-item {
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;

    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
    }

    &__wrap {
      background-color: $os-brown-lightest;

      @include media-breakpoint-up(lg) {
        background-color: $white;
  
        &:hover {
          background-color: $os-brown-lightest;
        }
      }
    }
  }

  &__list {
    .skeleton__item {
      .image-wrap {
        padding-bottom: 100%;
      }
      .text-line {
        @include skeleton($lines: 3, $line-height: 30px, $line-gap: 15px);
      }
    }

    [class*="col-"] {
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }
  }
}