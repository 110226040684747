@import '../../../assets/reuse-mixin.scss';

.forgot-password {
  padding: 50px 0;

  &__heading {
    text-align: center;
    margin-bottom: 50px;
  }

  &__form {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 420px;
      margin: 0 auto;
    }

    &__title {
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__btn {
      margin: 40px 0 15px;
    }
  }
}