@import'@assets/reuse-mixin.scss';

.my-product-review {
    border-top: 1px solid $gray-500;

    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
        border: 1px solid $gray-500;
    }

    &__container {
        padding-top: 16px;

        @include media-breakpoint-up(lg) {
            padding: 24px 24px 0;
        }

        .my-account-section__group {
            .my-account-section__btn-link {
                @include media-breakpoint-up(lg) {
                    font-size: 1.8rem;
                }
            }
        }
    }

    &__title {
        font-size: 2rem;
        line-height: 0.9;
        letter-spacing: 0.48px;
        text-align: left;
        color: $gray-1000;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(lg) {
            font-size: 2.4rem;
            line-height: 0.687;
            letter-spacing: 0.77px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 2.8rem;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 3.2rem;
        }
    }

    &__content {
        // padding: 16px 0 24px 32px;
        padding: 16px 0 24px 0;

        @include media-breakpoint-up(lg) {
            // padding: 24px 0 26px 68px;
            padding: 24px 0 26px 0;
        }

        .product-review-content__review-detail {
            padding-right: 18px;

            @include media-breakpoint-up(xxl) {
                padding-right: 100px;
            }

            .quote-icon {
                @include media-breakpoint-up(lg) {
                    width: 38px;
                    height: 30px;
                    left: -65px;
                }
            }
        }
    }

    &__content-no-review {
        font-size: 1.6rem;
        line-height: 1.375;
        letter-spacing: 0.38px;
        color: $gray-1000;
        padding: 16px 0 24px;
        margin: 0;

        @include media-breakpoint-up(lg) {
            font-size: 2rem;
            line-height: 1.6;
            letter-spacing: 1.6px;
            color: $gray-900;
        }

        @include media-breakpoint-up(xxl) {
            padding: 8px 0 48px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        padding-bottom: 36px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 48px;

        }
    }
}