@import '@assets/reuse-mixin.scss';


.event-registration {
    &__form {
        text-align: center;
        margin: 33px auto 32px;

        .form-title {
            font-size: 2.8rem;
            line-height: 1.3;
            letter-spacing: 1.4px;
            color: $brown;

            @include media-breakpoint-up(lg) {
                font-size: 5rem;
                line-height: .92;
                letter-spacing: 2.5px;
                color: $mine-shaft;
            }
        }

        .btn-back {
            display: block;
            margin-top: 18px;
            font-size: 1.4rem;
            line-height: 2;
            letter-spacing: 2.24px;
            //color: $secondary !important;
            color: $link-color !important;
            font-weight: $font-weight-bold;

            @include media-breakpoint-up(lg) {
                margin-top: 24px;
                font-size: 1.6rem;
                line-height: 1.75;
                letter-spacing: 2.56px;
            }
        }
    }
}