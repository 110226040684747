@import '../../../../assets/reuse-mixin';

.hamburger {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  // @include media-breakpoint-up(xl) {
  //   display: none;
  // }

  &-icon {
    display: inline-block;
    width: 22px;
    height: 16px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      width: 30px;
      height: 24px;
    }

    span {
      display: block;
      position: absolute;
      height: 1.5px;
      width: 50%;
      background: $black;
      opacity: 1;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-child(even) {
        left: 50%;
        border-radius: 0 3px 3px 0;
      }

      &:nth-child(odd) {
        left: 0px;
        border-radius: 3px 0 0 3px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        top: 0px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        top: 8px;

        @include media-breakpoint-up(lg) {
          top: 10px;
        }
      }

      &:nth-child(5),
      &:nth-child(6) {
        top: 16px;

        @include media-breakpoint-up(lg) {
          top: 20px;
        }
      }
    }
  }

  &.active {
    background-color: transparent;

    .hamburger-icon {
      span {
        background: $black;

        &:nth-child(1) {
          left: 1px;
          top: 8px;

          @include media-breakpoint-up(lg) {
            left: 3px;
            top: 7px;
          }
        }

        &:nth-child(2) {
          left: calc(50% - 3px);
          top: 8px;

          @include media-breakpoint-up(lg) {
            top: 7px;
          }
        }

        &:nth-child(3) {
          left: -50%;
          opacity: 0;
        }

        &:nth-child(4) {
          left: 100%;
          opacity: 0;
        }

        &:nth-child(5) {
          left: 0;
          top: 16px;

          @include media-breakpoint-up(lg) {
            left: 2px;
            top: 17px;
          }
        }

        &:nth-child(6) {
          left: calc(50% - 2px);
          top: 16px;

          @include media-breakpoint-up(lg) {
            left: calc(50% - 2px);
            top: 17px;
          }
        }

        &:nth-child(1),
        &:nth-child(6) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &:nth-child(2),
        &:nth-child(5) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }
}