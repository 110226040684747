@import '../../../assets/reuse-mixin.scss';

.pagination {
  &.hidden {
    display: none;
  }

  .pagination__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-content: center;
    justify-content: center;

    &__item {
      cursor: pointer;
      position: relative;
      text-align: center;
      text-decoration: none;
      border-radius: 50%;
      margin: 5px;
      color: $gray-500;
      width: 38px;
      height: 38px;
      line-height: 38px;
      font-size: $font-size-smaller;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(sm) {
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-size: $font-size-base;
      }

      &:hover {
        color: $gray-darker;
      }

      &.active {
        color: $gray-900;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(sm) {
          font-size: $h4-font-size;
        }
      }

      &.disable {
        opacity: 0;
        pointer-events: none;
      }

      [class^='icon-'] {
        font-size: $font-size-smaller;
        // color: $gray-500;

        @include media-breakpoint-up(sm) {
          font-size: $h5-font-size;
        }

        // &:hover {
        //   color: $gray-darker;
        // } 
      }
    }

    // .chevron {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
  }
}