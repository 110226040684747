@import '@assets/reuse-mixin.scss';

.product-thank-you-page {
    padding-top: 24px;

    @include media-breakpoint-up(lg) {
        padding-top: 36px;
    }

    &__top {
        text-align: center;
        padding-bottom: 24px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 40px;
        }

        &__icon--success,
        &__title,
        &__notification {
            margin: 0 auto;
        }

        &__icon--success {
            width: 49px;
            height: 49px;

            @include media-breakpoint-up(lg) {
                width: 69px;
                height: 69px;
            }

            img {
                width: 18.9px;
                height: 13.2px;
                margin-left: 2px;
                margin-bottom: 5px;

                @include media-breakpoint-up(lg) {
                    width: 25.6px;
                    height: 17.8px;
                }
            }
        }

        &__title,
        &__notification {
            font-weight: $font-weight-normal;
        }

        &__title {
            width: 270px;
            padding: 12px 0 8px 0;
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: 1.44px;
            color: $success;

            @include media-breakpoint-up(lg) {
                padding: 24px 0 16px 0;
                width: 335px;
                font-size: 3.2rem;
                line-height: 1.25;
                letter-spacing: 1.6px;
            }
        }

        &__notification {
            width: 270px;
            font-size: 1.8rem;
            line-height: 1.333;
            letter-spacing: 1px;
            color: $gray-900;

            @include media-breakpoint-up(md) {
                width: 400px;
            }

            @include media-breakpoint-up(lg) {
                width: 508px;
                font-size: 2.4rem;
                letter-spacing: 1.2px;
            }
        }
    }

    &__bottom {
        text-align: center;
        padding: 25px 0 36px 0;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;

        @include media-breakpoint-up(lg) {
            padding: 40px 0 48px 0;
            width: 1000px;
            margin: 0 auto;
            border: 1px solid $gray-200;
        }

        &__content,
        &__link-view {
            margin: 0 auto;
            font-size: 1.6rem;
            line-height: 1.375;
            letter-spacing: .8px;

            a:hover {
                text-decoration: underline;
            }

            @include media-breakpoint-up(lg) {
                font-size: 2rem;
                line-height: 1.3;
                letter-spacing: 1px;
            }
        }

        &__content {
            width: 340px;
            color: $gray-900;

            @include media-breakpoint-up(md) {
                width: 550px;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 24px;
                width: 826px;
            }

            &--center {
                text-align: center;
                padding-bottom: 18px;
                line-height: 1.25;

                p {
                    margin: 0;
                }
            }

            &--left {
                text-align: left;
                line-height: 1.375;
                padding-bottom: 0;

                p {
                    margin: 0;

                    &:last-of-type {
                        padding-bottom: 1.8rem;

                        @include media-breakpoint-up(lg) {
                            padding-bottom: 2.8rem;
                        }
                    }
                }

                ul {
                    padding-left: 0;
                    list-style: none;
                    line-height: 1.375;

                    li::before {
                        content: "";
                        width: 5px;
                        height: 5px;
                        background-color: $black;
                        border-radius: 50%;
                        margin: 10px 10px 0 0;
                        float: left;

                        @include media-breakpoint-up(lg) {
                            margin-top: 11px;
                        }
                    }
                }

                .ddict_btn {
                    display: none;
                }
            }
        }

        &__link-view {
            width: 330px;
            line-height: 1.375;
            padding-bottom: 24px;
            font-weight: $font-weight-normal;

            a, span {
                //color: $secondary !important;
                color: $link-color !important;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 36px;
                width: 415px;
            }
        }

        &__button {
            a {
                color: unset;
            }

            font-size: 1.4rem;
            line-height: 1.857;
            letter-spacing: 2.24px;
            padding: 10px 25px;

            @include media-breakpoint-up(lg) {
                font-size: 1.6rem;
                line-height: 1.625;
                letter-spacing: 2.56px;
                padding: 10px 34px;
            }
        }
    }
}

.guest-warranty {
    padding-bottom: 8px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 12px;
    }
}

.guest-no-warranty {
    padding-bottom: 18px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 24px;
    }
}