@import '../../../../assets/reuse-mixin.scss';

.product-hub-info {
  position: relative;

  &__image {
    display: block;
    padding-bottom: calc(229/343 * 100%);
    position: relative;
    opacity: 0.5;

    @include media-breakpoint-up(xl) {
      padding-bottom: calc(400/640 * 100%);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
  }

  &__contents {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 24px 16px 24px;
    color: $gray-darker;

    @include media-breakpoint-up(xl) {
      width: 60%;
      padding: 48px;
    }
  }

  &__title {
    display: inline-block;
    font-size: $h5-font-size;
    line-height: 1.3;
    letter-spacing: 1px;
    margin-bottom: 8px;
    font-weight: normal;

    @include media-breakpoint-up(xl) {
      font-size: $h2-font-size;
      line-height: 1.15;
      letter-spacing: 2px;
      margin-bottom: 12px;
    }
  }

  &__description {
    line-height: 1.29;
    letter-spacing: 1.12px;
    font-size: $font-size-small;
    margin-bottom: 6px;
    max-width: 231px;
    @include line-clamp(2);

    @include media-breakpoint-up(xl) {
      line-height: 1.4;
      letter-spacing: 1.6px;
      font-size: $h5-font-size;
      margin-bottom: 10px;
      max-width: 331px;
    }
  }

  &__list-links {
    &.row {
      margin: 0 -10px
    }

    .col-6 {
      padding: 0 10px;
    }
  }

  &__link {
    //color: $secondary;
    color: $link-color;
    font-size: $font-size-small;
    display: inline-block;
    line-height: 1.57;
    letter-spacing: 1.12px;
    font-weight: $font-weight-normal;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
      line-height: 1.75;
      letter-spacing: 0.8px;
    }
  }

  &__group-link {
    li:nth-child(n+6) {
      display: none;
    }
  }
}

.image-mobi {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.image-desktop {
  display: none;

  @include media-breakpoint-up(xl) {
    display: block;
  }
}