@import '@assets/reuse-mixin.scss';

.my-addresses {
  padding-top: 12px;

  &__section {
    @include media-breakpoint-up(lg) {
      padding-bottom: 24px;
    }

    &__heading {
      &__title {
        margin: 0;
        font-weight: $font-weight-normal;
        font-size: $font-size-base;

        @include media-breakpoint-up(md) {
          font-size: $font-size-large;
        }

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h4-font-size;
          font-weight: $font-weight-light;
        }
      }
    }

    &__content {
      margin-bottom: 36px;
      color: $gray-darker;

      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }

      &__name {
        display: flex;
        align-items: baseline;
        max-width: 68%;

        @include media-breakpoint-up(md) {
          max-width: 80%;
        }

        @include media-breakpoint-up(lg) {
          max-width: 75%;
        }

        @include media-breakpoint-up(xxl) {
          max-width: 80%;
        }
      }

      &__address-name {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        margin-bottom: 4px;
        line-height: 1;
        word-break: break-word;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
          margin-bottom: 8px;
        }
      }
    }

    &__btn-add {
      text-align: center;
      margin: 16px 0 24px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  &__status {
    font-size: $font-size-smaller;
    margin-left: 18px;
    display: inline-block;
    color: $os-green;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-small;
      margin-left: 20px;
    }
  }

  .addresses-info {
    &__line {
      display: block;
      font-size: $font-size-base;
      line-height: 1.375;
      margin-bottom: 0;
      // margin-bottom: 4px;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        line-height: 1.6;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
        // margin-bottom: 8px;
      }
    }
  }
}

// .customization-options {
//   padding: 0 20px;

//   .customization-dropdown {
//     &__control {
//       &.css-yk16xz-control {
//         border-radius: 0;
//         border: 1px solid $gray-400;
//         height: calc(2.4rem + 3.2rem);
//       }

//       &.css-1pahdxg-control {
//         border-radius: 0;
//         border-color: $gray-400;
//         box-shadow: none;
//         height: calc(2.4rem + 3.2rem);
//       }

//       &.css-1n7v3ny-option {}
//     }

//     &__menu {
//       &.css-26l3qy-menu {
//         background-color: $white;
//         box-shadow: none;
//         border-radius: 0;
//         border-top: none;
//         margin: 0;
//         box-shadow: 0px 3px 6px #00000029;

//       }
//     }

//     &__single-value {
//       color: $gray-500;
//       font-size: $input-font-size-lg;
//     }

//     &__option {
//       height: calc(2.4rem + 3.2rem);
//       display: flex;
//       align-items: center;
//       font-size: $input-font-size-lg;

//       &--is-focused {
//         background-color: $os-brown-lighter;

//         &:active {
//           background-color: $os-brown-lighter;
//         }
//       }

//       &--is-selected {
//         background-color: $os-brown-lighter;
//         color: black;
//         font-weight: $font-weight-normal;
//       }
//     }
//   }

// }