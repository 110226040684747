@import '@assets/reuse-mixin.scss';

.my-appointment {
  text-align: center;

  &__back {
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    padding-bottom: 24px;
    letter-spacing: 2.24px;
    //color: $secondary !important;
    color: $link-color !important;
    max-width: 1520px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 40px;
      text-align: left;
      margin: 0 auto;
    }
  }

  &__banner {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    padding-bottom: 24px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 40px;
    }

    &__wrapper {
      position: relative;
      width: 100%;
      padding-bottom: calc(778 / 1520 * 100%);

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__body {
    .title {
      margin: 0;
      font-size: 2.8rem;
      line-height: 1.3;
      letter-spacing: 1.4px;
      color: $gray-900;
      padding-bottom: 24px;

      @include media-breakpoint-up(lg) {
        font-size: 50px;
        line-height: 1.16;
        letter-spacing: 2.5px;
        padding-bottom: 32px;
      }
    }

    .schedule {
      font-size: 1.4rem;
      line-height: 1.4;
      letter-spacing: .7px;
      padding-bottom: 16px;
      color: $gray-900;

      @include media-breakpoint-up(lg) {
        font-size: 2.4rem;
        line-height: 1.4;
        padding-bottom: 24px;
      }

      &__time {
        padding: 0 12px;
        margin: 0 12px;
        border-left: 1px solid $gray-500;
        border-right: 1px solid $gray-500;

        @include media-breakpoint-up(lg) {
          padding: 0 24px;
          margin: 0 24px;
        }
      }
    }

    .location {
      padding-bottom: 32px;
      font-size: 1.4rem;
      line-height: 1.3;
      letter-spacing: .7px;
      max-width: 572px;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        padding-bottom: 48px;
        font-size: 2.4rem !important;
        letter-spacing: 1.2px;
      }

      &__name {
        margin: 0;
        padding-bottom: 8px;
        color: $gray-900;

        @include media-breakpoint-up(lg) {
          padding-bottom: 16px;
        }
      }

      &__address {
        margin: 0;
        color: $gray-600;
      }
    }

    .message {
      font-size: $h5-font-size-mobile;
      color: $primary;;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }

    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__change-schedule {
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 2.24px;

        @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
          line-height: 1.625;
          letter-spacing: 2.56px;
        }
      }

      &__cancel-appointment {
        border: none;
        outline: none;
        margin-top: 18px;
        font-size: 1.4rem;
        font-weight: $font-weight-bold;
        line-height: 2;
        letter-spacing: 2.24px;
        color: $secondary !important;
        background-color: transparent;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          margin-top: 24px;
          font-size: 1.6rem;
          line-height: 1.75;
          letter-spacing: 2.56px;
        }
      }
    }

    .contact {
      padding-top: 56px;
      padding-bottom: 32px;

      @include media-breakpoint-up(lg) {
        padding-top: 72px;
        padding-bottom: 48px;
      }

      &__title {
        margin: 0;
        font-size: 1.8rem;
        font-weight: $font-weight-normal;
        line-height: 2;
        letter-spacing: .9px;

        @include media-breakpoint-up(lg) {
          font-size: 2.4rem;
          line-height: 1.2;
          letter-spacing: 1.2px;
        }
      }

      &__details {
        padding-top: 24px;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: .8px;
        color: $gray-900;

        @include media-breakpoint-up(lg) {
          padding-top: 32px;
          font-size: 2.4rem;
          line-height: 1.3;
          letter-spacing: 1.2px;
        }

        p {
          margin: 0;
        }
      }
    }

    .question-answer {
      &__title {
        margin: 0;
        padding-bottom: 24px;
        font-size: 1.8rem;
        font-weight: $font-weight-normal;
        line-height: 1.3;
        letter-spacing: .9px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 32px;
          font-size: 2.4rem;
          line-height: .875;
          letter-spacing: 1.2px;
        }
      }

      &__details {
        padding-bottom: 24px;
        text-align: left;
        margin: 0 auto;
        max-width: 460px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 32px;
        }

        &:last-child {
          padding-bottom: 0;
        }

        .question {
          padding-bottom: 12px;
          font-size: 1.4rem;
          font-weight: $font-weight-normal;
          line-height: 1.2;
          letter-spacing: .7px;
          color: $gray-900;
          margin: 0;

          @include media-breakpoint-up(lg) {
            padding-bottom: 8px;
            font-size: 1.6rem;
            line-height: 1.3;
            letter-spacing: 1.28px;
          }
        }

        .answer {
          margin: 0;
          font-size: 1.4rem;
          line-height: 1.7;
          letter-spacing: 1.12px;
          color: $gray-900;

          @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
            line-height: 1.25;
            letter-spacing: 1.28px;
          }
        }
      }
    }
  }

  &__learn-more {
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    padding-top: 32px;
    line-height: 2;
    letter-spacing: 2.24px;
    //color: $secondary !important;
    color: $link-color !important;

    @include media-breakpoint-up(lg) {
      padding-top: 48px;
      font-size: 1.6rem;
      line-height: 1.75;
      letter-spacing: 2.56px;
    }
  }

  &__modal {
    &.popup-banner {
      &__content {
        .modal {
          &-content {
            @include media-breakpoint-down(sm) {
              width: 100%;
              position: absolute;
              bottom: 0;
            }
            @include media-breakpoint-up(md) {
              width: 100% !important;
              max-width: 740px !important;
              min-height: 494px;
            }

            .osim-popup__header {
              position: relative;
              margin-top: 24px;
              @include media-breakpoint-up(md) {
                margin-top: 32px;
              }

              .osim-popup__close-btn {
                top: 0;
                right: 24px;
                @include media-breakpoint-up(md) {
                  right: 32px;
                }
              }
            }
          }

          &-wrapper {
            @include media-breakpoint-down(sm) {
              min-height: 375px;
            }
            padding-bottom: calc(494 / 740 * 100%);
            background-color: $white;

            .confirm {
              &-container {
                position: absolute;
                width: 100%;
                left: 0;
                height: calc(100% - 44px);
                @include media-breakpoint-up(md) {
                  height: calc(100% - 54px);
                }
              }

              &-wrapper {
                max-width: 560px;
                width: 100%;
                margin: 0 auto;
                padding: 0 16px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }

              &-question {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &-cta {
                flex-shrink: 0;
                padding-bottom: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @include media-breakpoint-up(md) {
                  padding-bottom: 40px;
                }

                .btn-link {
                  margin-top: 18px;
                }
              }
            }
          }
        }

      }
    }
  }
}