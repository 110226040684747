@import "../../../../assets/reuse-mixin.scss";

.bg-Sand {
  background-color: $os-orange-lighter;

  &.two-column11 {
    position: relative;
    margin-bottom: 138px;

    .col-md-6 {
      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }

    @include media-breakpoint-up(md) {
      .col-md-6 {
        &:first-child {
          order: 1;
        }

        &:last-child {
          order: 2;
        }
      }
    }

    .cart-summary {
      &__button {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
      }
    }
  }
}

.cart-summary {
  font-size: $font-size-large;
  color: $gray-darker;
  font-weight: $font-weight-normal;
  letter-spacing: 0.9px;
  padding: 24px 0 0;

  @include media-breakpoint-up(md) {
    padding: 24px 0;
    position: relative;
  }

  @include media-breakpoint-up(lg) {
    padding: 32px 0;
  }

  @include media-breakpoint-up(xl) {
    font-size: $h4-font-size;
    letter-spacing: 1.2px;
  }

  &__total-amount {
    padding-bottom: 10px;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    &__last-list {
      border-top: 1px solid $gray-dark;
      padding-top: 20px;
    }

    &__total-price {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
      letter-spacing: 1.2px;
      // padding-top: 24px;

      @include media-breakpoint-up(xl) {
        font-size: $h2-font-size;
        letter-spacing: 1.75px;
      }
    }

    .group-contents {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        margin-top: 5px;
      }

      &:first-child {
        margin-top: 0;
      }

      &--align-baseline {
        &.group-contents {
          align-items: baseline;
        }
      }

      .discount-applied,
      .select-area {
        font-size: $font-size-smaller;
        color: $link-color;
        margin: auto auto auto 5px;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          font-size: $font-size-small;
        }

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .select-area {
        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }
      }

      span.discount-applied {
        display: inline-block;
        margin-left: 5px;

        @include media-breakpoint-up(lg) {
          margin-left: 7px;
        }
      }

      // &--has-sub {
      //   margin-bottom: 5px;
      // }

      .sub-text {
        font-size: $font-size-smaller;
        color: $secondary;
        margin-top: 5px;

        @include media-breakpoint-up(md) {
          font-size: $font-size-large;
        }
      }

      .contents-left {
        max-width: 250px;

        @include media-breakpoint-up(md) {
          max-width: 610px;
        }

        &__description,
        a {
          font-size: $font-size-smaller;
          letter-spacing: 1.6px;

          @include media-breakpoint-up(md) {
            font-size: $font-size-small;
          }

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
          }

          @include media-breakpoint-up(xl) {
            font-size: $h5-font-size;
          }
        }

        &__description {
          display: inline;
          padding-right: 10px;
          color: $gray-dark;
          font-weight: $font-weight-light;
          margin-bottom: 0;

          &__text {
            margin-right: 6px;
          }
        }

        &__warning-message {
          padding-right: 10px;
          color: $primary;
          font-weight: $font-weight-light;
          font-size: $font-size-smaller;
          margin: 4px 0 0;

          @include media-breakpoint-up(md) {
            font-size: $font-size-small;
          }

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
          }
        }
      }

      .text-line-through {
        color: $gray-dark;
      }
    }

    .group-contents-cp {
      padding-top: 24px;

      &__wrapper {
        padding-top: 12px;
      }

      &__date {
        margin-bottom: 24px;
        width: 100%;
      }

      &__btn {
        margin-bottom: 18px;

        &__text {
          width: 100%;
        }

        &:has(.error-invalid) .error-invalid {
          margin-top: 12px;
          display: block;
          font-size: $font-size-large;
        }
      }

      &__link {
        text-align: center;
      }
    }
  }

  &__free-shipping {
    @include media-breakpoint-up(lg) {
      order: 1;
      display: flex;
      align-items: center;
    }

    &__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__contents {
      padding: 15px 54px 15px 15px;
      display: flex;
      align-items: center;
      border: 1px solid $primary;
      position: relative;
      max-width: 654px;

      @include media-breakpoint-up(md) {
        padding: 25px 58px 25px 30px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding: 15px 54px 15px 15px;
      }

      @include media-breakpoint-up(xl) {
        padding: 25px 58px 25px 30px;
      }

      &__icon {
        width: 40px;
      }

      &__text {
        width: calc(100% - 40px);
        padding-left: 20px;
        color: $gray-dark;
        font-weight: $font-weight-light;
        letter-spacing: 0.8px;

        @include media-breakpoint-up(md) {
          letter-spacing: 1px;
        }

        @include media-breakpoint-up(lg) {
          letter-spacing: 0.8px;
        }

        @include media-breakpoint-up(xl) {
          letter-spacing: 1px;
        }

        b {
          font-weight: $font-weight-bold;
          color: $gray-darker;
        }
      }

      &__infor-icon {
        position: absolute;
        top: 17px;
        right: 15px;
        width: 20px;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          top: 25px;
          right: 25px;
        }

        @include media-breakpoint-up(lg) {
          top: 17px;
          right: 15px;
        }

        @include media-breakpoint-up(xl) {
          top: 29px;
          right: 25px;
        }
      }
    }
  }

  &__button {
    background-color: $white;
    padding: 24px 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
      padding: 30px 0;
    }

    &__container {
      display: flex;
      text-align: center;
      justify-content: center;
    }

    &__inner {
      flex-direction: column;

      .btn {
        min-width: 200px;
        margin: 0;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }

    .btn-link {
      margin-top: 16px;
    }
  }

  label {
    margin-bottom: 0;
  }
}
