@import '@assets/reuse-mixin.scss';

.popup-layout {
  &__content {
    &.popup-banner {
      &__content {
        .modal-content {
          @include media-breakpoint-down(sm) {
            width: 100%;
            position: absolute;
            bottom: 0;
          }
          @include media-breakpoint-up(md) {
            width: 100% !important;
            max-width: 740px !important;
          }

          .osim-popup__close-btn {
            display: none;
          }
        }

        .main-banner {
          &__background {
            @include media-breakpoint-down(sm) {
              min-height: 375px;
            }
            padding-bottom: calc(494 / 740 * 100%);
            background-color: $white;
          }
        }
      }
    }
  }
}