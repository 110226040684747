@import '@assets/reuse-mixin.scss';

.our-wellness-team {
  padding-top: 24px;

  @include media-breakpoint-up(lg) {
    padding-top: 40px;
  }

  &__heading {
    margin-bottom: 18px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    &__title {
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }

    &__desc {
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }
  }

  &__content {
    @include media-breakpoint-down(md) {
      .row {
        margin-right: -10px;
        margin-left: -10px;

        > [class*="col-"] {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }

    &__item {
      margin-bottom: 24px;
      cursor: pointer;

      &__img {
        display: block;
        position: relative;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 32px;
        }
  
        &::before {
          content: '';
          display: block;
          padding-top: 100%;
        }
  
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__name {
        min-height: 46px;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
          min-height: 62px;
        }

        @include media-breakpoint-up(xxl) {
          margin-bottom: 16px;
          min-height: auto;
        }
      }

      &__designation {
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }
      }
    }
  }
}