@import '@assets/reuse-mixin.scss';


.award {
  &__wrap {
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 1300px;
      margin: 0 auto;
    }
  }

  .award-des {
    text-align: center;
    margin-bottom: 25px;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      padding: 0 150px;
      margin-bottom: 70px;
      font-size: $h5-font-size;
    }
  }

  .item-center {
    display: flex;
    align-items: center;
  }

  .our-awards {
    &__highlight {
      &__name {
        margin: 0 0 0 9px;
        font-size: $font-size-base;
  
        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
          margin: 0 0 0 33px;
        }
      }
  
      &__title {
        margin-bottom: 8px;
      }
  
      &__desc {
        font-size: $font-size-base;
        height: auto;
        overflow-y: auto;
        margin-bottom: 40px;
  
        @include media-breakpoint-up(lg) {
          height: auto;
          font-size: $font-size-large;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }
  
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__group {
        display: flex;
        align-items: center;
        margin: 24px 0;

        @include media-breakpoint-up(md) {
          margin: 0 0 40px;
        }
      }
    }
  }
}