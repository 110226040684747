@import'@assets/reuse-mixin.scss';

.review-media-popup {
  &__content {
    padding: 0 28px;
    color: $gray-darker;

    &__info-desktop {
      display: none;
  
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__review-title {
      font-size: $h4-font-size + 0.4;
      margin: 8px 0 0;
    }

    &__info {
      margin: 18px 0 0;
    }

    &__media-slide {
      max-width: 586px;
      margin: 32px auto 0;
      
      &__preview {
        width: 100%;
        position: relative;
      }

      &__thumbnail {
        display: none;

        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
        }
      }
    }

    .media-slide-item {
      width: 100%;
      height: 0;
      padding-bottom: 297/287 * 100%;
      position: relative;
      background-color: #d4d4d4;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        padding-bottom: 586/586 * 100%;
      }
      
      img, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .media-thumnail-item {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      cursor: pointer;

      @include media-breakpoint-up(xl) {
        width: 60px;
        height: 60px;
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;
      }

      img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.active {
        border: 2px solid $gray;
        width: 54px;
        height: 54px;

        @include media-breakpoint-up(xl) {
          width: 64px;
          height: 64px;
        }
      }
    }
  }
  
  //Customize Swiper
  .swiper-container,
  .swiper-wrapper {
    height: 100%;
    position: static; //INFO: set position for swiper button
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 44px;
    color: $gray;
    transition: 0.5s;

    &:hover {
      color: $gray-darker;
    }

    &::after {
      font-size: 16px;
      font-weight: $font-weight-bold;

      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }
    }
  }

  .swiper-button-next {
    right: -42px;

    @include media-breakpoint-up(lg) {
      right: -60px;
    }
  }

  .swiper-button-prev {
    left: -42px;

    @include media-breakpoint-up(lg) {
      left: -60px;
    }
  }
}