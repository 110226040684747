@import'@assets/reuse-mixin.scss';

.cart-promotion-code-item {
  // position: relative;
  // padding: 15px 18px 15px 24px;
  // font-size: $font-size-smaller;
  // border: 1px solid $border-color;
  // cursor: pointer;

  // &:focus,
  // &:active,
  // &:hover,
  // &.selected {
  //   border-color: $gray-dark;
  //   border-width: 2px;
  // }

  &.disable {
    border-color: $gray-light;
    color: $gray-light;
    cursor: default;
    pointer-events: none;

    .cart-promotion-code-item__name {
      color: $gray-light;
    }
  }

  &.applied {
    background-color: rgba($os-orange, 0.8);
    border-color: rgba($os-orange, 0.8);
  }

  @include media-breakpoint-up(md) {
    font-size: $font-size-small;
  }

  // &__infor-icon {
  //   position: absolute;
  //   top: 15px;
  //   right: 18px;
  //   width: 16px;
  //   height: auto;

  //   @include media-breakpoint-up(md) {
  //     width: 20px;
  //   }

  //   &:hover {
  //     cursor: pointer;
  //   }
  // }

  // &__name {
    // font-size: $h5-font-size;
    // font-weight: $font-weight-normal;
    // letter-spacing: 1.2px;
    // margin-bottom: 5px;

    // @include media-breakpoint-up(md) {
    //   font-size: $h4-font-size;
    // }
  // }

  // &__description {
  //   margin-bottom: 5px;
  //   letter-spacing: 1.12px;
  // }

  // &__footer {
  //   align-items: flex-end;

  //   &__date {
  //     letter-spacing: 0.96px;
  //   }

  //   &__button {
  //     letter-spacing: 1.68px;
  //     color: $primary;
  //     font-weight: $font-weight-bold;
  //     background: none;
  //     border: none;
  //     outline: none;
  //     font-size: $font-size-small;

  //     @include media-breakpoint-up(md) {
  //       font-size: $font-size-base;
  //     }
  //   }
  // }

  // .left-light {
  //   position: absolute;
  //   top: -1px;
  //   left: -1px;
  //   bottom: -1px;
  //   width: 7px;
  //   background-color: $primary;
  // }
}