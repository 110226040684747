@import '@assets/reuse-mixin.scss';

.appointment-update {
  background: $cp-background;
  padding-bottom: 40px;

  &__wrap {
    background: $white;
    padding: 24px 24px 40px;
  }

  &__content {
    margin-bottom: 20px;
  }

  .appointments-infor {
    &__group {
      &__left {
        @include media-breakpoint-up(md) {
          width: 335px;
        }
      }

      &__right {
        @include media-breakpoint-up(md) {
          width: calc(100% - 335px);
          padding-left: 64px;
        }
      }
      
      &--bottom {
        @include media-breakpoint-up(md) {
          display: flex;

          .appointments-infor {
            &__item {
              margin-bottom: 32px;
  
              &:not(.d-flex) {
                .appointments-infor__item__label {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }

      &--border-bottom {
        margin-bottom: 16px;
      }
    }
  }

  .checkbox-list {
    &__item {
      margin-bottom: 14px;
    }

    .osim-checkbox-label {
      color: $cp-gray;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }
  }

  .radio-list {
    &--flex {
      display: flex;
      flex-wrap: wrap;

      .radio-list {
        &__item {
          width: 20%;
          margin-right: 32px;
        }
      }
    }

    .osim-radio {
      margin: 0 15px 10px 0;
      display: inline-flex;
      align-items: center;
    }

    .osim-radio-input {
      margin: 0;
    }

    .osim-radio-title {
      display: inline-block;
      padding-left: 12px;
      color: $cp-gray;
      margin-bottom: 0;
    }
  }

  .max-w-320 {
    max-width: 320px;
  }

  .customization-dropdown__control {
    min-width: 240px;
  }

  textarea {
    height: 120px;
  }
}
