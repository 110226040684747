@import "@assets/reuse-mixin.scss";

.address-component {
  color: $gray-darker;

  &__row {
    padding-top: 18px;

    @include media-breakpoint-up(lg) {
      padding-top: 30px;
    }
  }

  &__line1 {
    display: flex;
    justify-content: space-between;

    .auto-address {
      padding-bottom: 6px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        padding-bottom: 8px;
      }
    }

    &__text {
      font-size: $font-size-small;
      color: $gray-darker;
      font-weight: $font-weight-light;
      max-width: 170px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-large;
        max-width: 350px;
      }
    }

    &__price {
      font-weight: $font-weight-normal;
      font-size: $font-size-base;

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
        font-weight: $font-weight-bold;
      }
    }
  }

  &__line2 {
    // max-width: 280px;
    // margin-bottom: 10px;

    &__info {
      max-width: 280px;
    }

    strong,
    span {
      display: block;
    }

    strong {
      font-weight: $font-weight-normal;
      margin-bottom: 4px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        margin-bottom: 8px;
      }

      span {
        display: inline;
        color: $os-green;
        font-weight: $font-weight-normal;
        margin-left: 5px;
      }
    }

    span {
      font-weight: $font-weight-light;
      font-size: $font-size-smaller;

      @include media-breakpoint-up(md) {
        font-size: $font-size-small;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }
  }

  &__no-address {
    font-size: $font-size-small;
    // padding-top: 10px;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      font-size: $font-size-large;
      // padding-top: 20px;
    }
  }

  ul.address-component__list,
  &__list {
    margin-top: 8px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    & + .address-component__button-add-new {
      margin-top: 24px;
    }

    &__item {
      height: 48px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 24px;
      border: 1px solid $gray-light;
      font-weight: $font-weight-light;
      cursor: pointer;
      transition: all 0.3s;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
        height: 56px;
        // padding-right: 50px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      > span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.selected,
      &:hover {
        border-color: $gray-darker;
      }

      &.selected {
        font-weight: $font-weight-normal;
      }

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: inherit;
      }
    }

    .item-default {
      font-size: $font-size-smaller;
      color: $os-green;
      white-space: nowrap;
      padding-left: 6px;
      display: inline-block;
    }
  }

  &__button-see-more {
    font-size: $font-size-base;
    color: $os-blue;
    cursor: pointer;
    margin-top: 8px;

    @include media-breakpoint-up(lg) {
      margin-top: 18px;
    }

    &:hover {
      text-decoration: underline;
    }

    & + .address-component__button-add-new {
      margin-top: 24px;
    }
  }

  &__button-add-new {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    // margin-top: 24px;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }

    .my-addresses__section__btn-add {
      text-align: left;
    }
  }

  .area-selected {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    @include media-breakpoint-up(lg) {
      max-width: 80%;
    }

    &__content {
      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
  
      @include media-breakpoint-up(xl) {
        font-size: $font-size-large;
      }
    }

    &__price {
      font-size: $font-size-base;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
  
      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
        font-weight: $font-weight-bold;
      }
    }
  }

  .warning-message {
    padding-right: 10px;
    color: $primary;
    font-weight: $font-weight-light;
    font-size: $font-size-smaller;
    letter-spacing: 1.6px;

    @include media-breakpoint-up(md) {
      font-size: $font-size-small;
      margin-bottom: 4px;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
    }
  }

  &__priority-delivery {
    max-width: 350px;
    width: 100%;
    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }

    & > .title {
      color: $os-green;
      font-size: $h6-font-size-mobile;
      line-height: 2.2rem;
      margin-bottom: 4px;
      @include media-breakpoint-up(md) {
        font-size: $h6-font-size;
        margin-bottom: 8px;
      }
    }

    & > .description {
      color: $gray-darker;
      font-size: $font-size-smaller;
      line-height: 1.8rem;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: $font-size-small;
        line-height: 2.6rem;
      }
    }

    & > .checkout-checkbox {
      margin-top: 16px;
      @include media-breakpoint-up(md) {
        margin-top: 14px;
      }
    }

    .priority-delivery {
      &__checkbox {
        & > .checkout-checkbox__label {
          width: fit-content;
          padding-top: 0;
        }

        & > .info-icon {
          margin-left: 16px;
          cursor: pointer;
          @include media-breakpoint-up(md) {
            margin-left: 12px;
          }
        }
      }
    }
  }
}
