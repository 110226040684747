@import "@assets/reuse-mixin";

.voucher {
  min-height: 109px;
  border: 1px solid $gray-light;
  border-left: 0;
  position: relative;
  margin-bottom: 12px;
  padding: 21px 16px 21px 23px ;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    width: 4px;
    background-color: $primary;
  }

  &__name {
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
    letter-spacing: 1.2px;
    margin-bottom: 4px;
    line-height: 2.4rem;
    word-break: break-word;
    padding-right: 28px;

    @include media-breakpoint-up(md) {
      font-size: $h4-font-size;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 8px;
    }
  }

  &__description {
    letter-spacing: 0.13px;
    font-size: $font-size-small;
    margin-bottom: 10px;
    letter-spacing: 1.12px;

    @include line-clamp(2);
  }

  &__info {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__code {
    display: inline-block;
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
    color: $black;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      font-size: $h4-font-size;
    }
  }

  &__hint-text {
    display: inline-block;
    margin-bottom: 0;
    padding-right: 5px;
    font-size: $font-size-base;

    @include media-breakpoint-up(md) {
      font-size: $font-size-large;
    }
  }

  &__footer {
    font-size: $font-size-small;
  }
}

.voucher-detail {
  @include media-breakpoint-up(md) {
    padding: 0 64px;
  }
  
  &__title {
    font-weight: $font-weight-normal;
    font-size: $h5-font-size;
    color: $gray-darker;
    text-align: center;
    margin-bottom: 2.4rem;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      line-height: 1.125;
      letter-spacing: 1.2px;
    }
  }

  &__text {
    font-size: $font-size-base;
    line-height: 1.25;
    font-weight: $font-weight-light;
    letter-spacing: 0.8px;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__bold {
      font-weight: $font-weight-bold;
    }
  }

  &__code {
    font-weight: $font-weight-bold;
    color: $black;
    margin-bottom: 15px;
    font-size: $font-size-base;
    display: inline-block;
    padding-left: 5px;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }

  &__hint-text {
    font-size: $font-size-small;
    margin-bottom: 0;
    display: inline-block;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }
}