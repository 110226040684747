@import '../../assets/reuse-mixin.scss';

.signup-form {
  h1,
  .form-title,
  .sing-up-new-letter__title {
    margin-bottom: 12px;
    color: $gray-darker;
    font-size: $h4-font-size;

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h2-font-size;
    }
  }

  h2,
  .form-description,
  .sign-up-new-letter__des {
    margin-bottom: 24px;
    color: $gray-darker;
    font-size: $font-size-base;

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  .form-message-success {
    color: $success;
    margin-top: 24px;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-large;
      margin-top: 32px;
    }
  }

  &__heading {
    text-align: center;
    margin-bottom: 24px;

    &__title {
      text-transform: uppercase;
      color: $primary;
    }

    &__desc {
      color: $black;
    }
  }

  &__form {
    &.hide {
      form * {
        display: none;
      }
      .form-message-success, .form-message-error{
        display:  block !important;
      }
    }
    &__input {
      &__item {
        outline: none;
        box-sizing: border-box;
        transition: 0.3s;

        &::placeholder {
          color: $primary;
        }
      }

      &__icon {
        position: absolute;
        left: 0;
        top: 7px;
        padding: 8px 15px;
        transition: 0.3s;
      }
    }

    &__btn {
      &.btn {
        margin-top: 20px;
      }
    }

    &--wrap-btn-submit {
      text-align: center;
    }

    .form__field--captcha {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      input {
        visibility: hidden;
      }
    }

    // subscribe
    &.subscribe {
      padding: 30px 0;
      text-align: center;
      max-width: 880px;
      margin: 0 auto;

      .form-group {
        width: 200px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        z-index: 1;
        margin: 0;

        @include media-breakpoint-up(sm) {
          width: 216px;
        }

        @include media-breakpoint-up(md) {
          width: 385px;
        }

        .form-control {
          margin: 0;
          border-color: $gray-darker;
        }
      }

      .signup-form__form--wrap-btn-submit {
        display: inline-block;
        width: 128px;
        margin-left: -1px;

        @include media-breakpoint-up(md) {
          width: 180px;
        }

        .btn {
          margin: 0;
          width: 100%;
          padding: calc(#{$btn-padding-y-lg} - 0.4rem) calc(#{$btn-padding-x-lg} - 0.4rem);
          height: calc(#{$input-height-lg} - 0.8rem);

          @include media-breakpoint-up(md) {
            padding: $btn-padding-y-lg $input-padding-x-lg;
            height: $input-height-lg;
          }
        }
      }
    }

    // contact us
    &.contact-us {
      max-width: 480px;
      margin: 0 auto;
      text-align: center;
      padding: 10px 0 24px;

      @include media-breakpoint-up(lg) {
        padding: 20px 0 32px;
      }

      .form-title {
        color: $gray-darker;
        font-size: $h4-font-size;
        text-align: center;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
          font-size: $h3-font-size;
          margin-bottom: 24px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h2-font-size;
        }
      }

      .osim-checkbox {
        margin-bottom: 24px;
      }
    }

    &.group-check-box {
      text-align: left;
      
      .osim-checkbox {
        display: inline-block;
        margin-right: 32px;
      }
    }

    // franchise enquiry form
    &.franchise-enquiry {
      padding-top: 50px;
      max-width: 480px;
      margin: 0 auto;

      @include media-breakpoint-up(xxl) {
        padding-top: 100px;
      }
    }

    &.contact-us,
    &.franchise-enquiry {
      .form-group {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 32px;
        }
      }

      .signup-form__form--wrap-btn-submit {
        .btn {
          margin-top: 24px;
          min-width: 200px;

          @include button-variant($primary, $primary, $primary, $primary);

          @include media-breakpoint-up(lg) {
            margin-top: 32px;
            min-width: 240px;
          }
        }
      }
    }

    &.review-experience {
      max-width: 720px;
      margin: 0 auto;
      text-align: center;
      padding: 10px 0 24px;

      .review-experience-title {
        color: $gray-darker;
        font-size: $h4-font-size;
        text-align: center;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
          font-size: $h3-font-size;
          margin-bottom: 24px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h2-font-size;
        }
      }
    }
  }
}
