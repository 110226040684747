@import '@assets/reuse-mixin.scss';

.spinwin-popup {
  &__banner {
    position: relative;

    &__bg {
      position: relative;
      background-color: $black;
      overflow: hidden;
      height: 0;
      padding-bottom: 340/375 * 100%;

      @include media-breakpoint-up(md) {
        padding-bottom: 454/740 * 100%;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
      }

      img,
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
      text-align: center;
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(md) {
        padding: 32px 90px;
      }

      &__title,
      &__prize {
        color: $white;
      }

      &__title {
        margin-bottom: 0;
        line-height: 1.1;
      }

      &__img {
        width: 160px;
        height: 160px;
        margin: 24px auto 0;

        @include media-breakpoint-up(lg) {
          margin: 32px auto 0;
        }
      }

      &__prize {
        margin: 18px 0 0;

        @include media-breakpoint-up(lg) {
          margin: 24px 0 0;
        }
      }
    }
  }

  &__group {
    padding: 24px 20px;

    @include media-breakpoint-up(md) {
      padding: 32px 90px;
    }
  }

  &__prize-desc {
    font-size: $font-size-base;
    max-height: 300px;
    overflow-y: auto;

    @include scrollbar-custom();

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }

  &__btn {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }

    &__item {
      margin-top: 18px;

      &:first-child {
        margin-top: 0;
      }

      .btn {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }
  }
}