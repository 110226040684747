@import '../../../../assets/reuse-mixin.scss';

.store-feature {
  color: $gray-darker;
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }

  &__heading {
    text-align: center;
    font-size: $h3-font-size;
    color: $gray-darker;
    margin-bottom: 15px;
    
    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size;
    }
    
    @include media-breakpoint-up(xl) {
      font-size: $h1-font-size;
      margin-bottom: 45px;
    }
  }

  &__main {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    padding-bottom: 563/375 * 100%;

    @include media-breakpoint-up(md) {
      padding-bottom: 800/768 * 100%;
    }
  
    @include media-breakpoint-up(lg) {
      padding-bottom: 375/1024 * 100%;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 560/1920 * 100%;
    }

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    &__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  
      .container {
        height: 100%;
      }

      &__wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: flex-end;
        padding-bottom: 32px;

        @include media-breakpoint-up(md) {
          padding-bottom: 100px;
        }
  
        @include media-breakpoint-up(lg) {
          width: 50%;
          justify-content: center;
          padding-bottom: 0;
          text-align: left;
          margin-left: auto;
          padding-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
          padding: 0 20px 0 80px;
        }

        @include media-breakpoint-up(xxl) {
          padding: 0 120px 0 80px;
        }
      }

      &__title {
        margin-bottom: 12px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 18px;
        }
      }

      &__desc {
        font-size: $font-size-base;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
          -webkit-line-clamp: 2;
          margin-bottom: 36px;
        }
      }

      &__btn {
        .btn-tem {
          font-size: $font-size-base;
          padding: 11px 25px;
          width: 163px;

          @include media-breakpoint-up(lg) {
            width: 200px;
          }
        }
      }
    }
  }
}