@import "@assets/reuse-mixin";

.info-header {
  padding: 32px 0;
  @include media-breakpoint-up(md) {
    padding: 32px 24px;
  }

  &__title {
    color:  $gray-darker;
    font-size: $h4-font-size;
    font-weight: $font-weight-normal;
    line-height: 1.17;
    letter-spacing: 1.2px;
    margin-bottom: 8px;
  }

  &__description {
    color: #7d7d7d;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    line-height: 1.06;
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
}