@import '../../../../assets/reuse-mixin.scss';

.explore-product {
  color: $gray-darker;
  padding: 35px 0;
  margin-bottom: 50px;

  @include media-breakpoint-up(lg) {
    padding: 42px 0;
  }

  &__heading {
    text-align: center;
    margin-bottom: 40px;
    
    @include media-breakpoint-up(xl) {
      margin-bottom: 45px;
    }
  }

  &__slide {
    padding-left: 33px;

    @include media-breakpoint-up(md) {
      padding: 0 calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/4});
    }

    @include media-breakpoint-up(lg) {
      padding: 0 calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/4});
    }

    @include media-breakpoint-up(xl) {
      padding: 0 calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/4});
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/4});
    }

    .swiper-container {
      @include media-breakpoint-up(lg) {
        padding: 0 50px;
      }
    }

    &__item {
      text-align: center;

      &__image {
        max-width: 210px;
        max-height: 210px;
        margin: 0 auto 25px;

        .explore-product-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__text {
        text-transform: uppercase;
        font-weight: $font-weight-normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }
  }
}

.swiper-button {
  &--small {
    border: none;
    background-color: transparent;
    display: none;
    
    &::after {
      content: '';
      width: 12px;
      height: 12px;
      border-left: 1px solid $gray-500;
      border-top: 1px solid $gray-500;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    &.swiper-button-next {
      right: 0;
  
      &::after {
        transform: rotate(135deg);
      }
    }
  
    &.swiper-button-prev {
      left: 0;
  
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}