@import '@assets/reuse-mixin.scss';

.registered-or-changed {
    position: relative;
    text-align: center;
    height: 500px;
    // padding-bottom: calc(500/375 * 100%);

    @include media-breakpoint-up(lg) {
        height: 658px;
    }

    &__opacity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: .7;
        z-index: 1;
    }

    &__background {
        &>img {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: absolute;
        width: 100%;
        top: 25%;
        left: 50%;
        transform: translate(-50%);
        z-index: 2;

        @include media-breakpoint-up(lg) {
            top: 20%;
        }

        &-head {
            .head__icon {
                width: 24px;
                margin: 0 auto;

                @include media-breakpoint-up(lg) {
                    width: 48px;
                }
            }

            .head__title {
                padding-top: 4px;
                font-size: 2.4rem;
                line-height: .7;
                letter-spacing: 1.2px;
                color: $success;
                margin: 0;

                @include media-breakpoint-up(lg) {
                    padding-top: 9px;
                    font-size: 4rem;
                    line-height: .7;
                    letter-spacing: 2px;
                }

            }
        }

        &-details {
            padding-top: 8px;
            color: $white;

            @include media-breakpoint-up(lg) {
                padding-top: 16px;
            }

            p {
                margin: 0;
            }

            .details {
                &__code {
                    font-size: 1.2rem;
                    line-height: 1.4;
                    letter-spacing: .6px;
                    padding-bottom: 4px;

                    @include media-breakpoint-up(lg) {
                        font-size: 2rem;
                        line-height: 1.5;
                        letter-spacing: 1px;
                        padding-bottom: 12px;
                    }
                }

                &__title {
                    font-size: 1.6rem;
                    line-height: 1.125;
                    letter-spacing: .8px;
                    font-weight: $font-weight-normal;
                    padding-bottom: 12px;

                    @include media-breakpoint-up(lg) {
                        font-size: 2.4rem;
                        line-height: 1.3;
                        letter-spacing: 1.2px;
                    }
                }

                &__schedule {
                    font-size: 1.4rem;
                    line-height: 1.43;
                    letter-spacing: .7px;
                    padding-bottom: 8px;

                    @include media-breakpoint-up(lg) {
                        font-size: 2.4rem;
                        line-height: 1.3;
                        padding-bottom: 12px;
                        letter-spacing: 1.2px;
                    }

                    &__time {
                        padding: 0 12px;
                        margin: 0 12px;
                        border-left: 1px solid;
                        border-right: 1px solid;

                        @include media-breakpoint-up(lg) {
                            padding: 0 24px;
                            margin: 0 24px;
                        }
                    }
                }

                &__name {
                    padding-bottom: 5px;
                    font-size: 1.4rem;
                    line-height: 1.3;
                    letter-spacing: .7px;

                    @include media-breakpoint-up(lg) {
                        padding-bottom: 8px;
                        font-size: 2.4rem;
                        line-height: 1.3;
                        letter-spacing: 1.2px;
                    }
                }

                &__location {
                    padding-bottom: 8px;
                    font-size: 1.4rem;
                    line-height: 1.3;
                    letter-spacing: .7px;
                    font-weight: $font-weight-lighter;

                    @include media-breakpoint-up(lg) {
                        padding-bottom: 24px;
                        font-size: 2.4rem;
                        line-height: 1.3;
                        letter-spacing: 1.2px;
                    }
                }

                &__notification {
                    padding-bottom: 8px;
                    font-size: 1.2rem;
                    line-height: 1.4;
                    letter-spacing: .6px;

                    @include media-breakpoint-up(lg) {
                        padding-bottom: 12px;
                        font-size: 2rem;
                        line-height: 1.5;
                        letter-spacing: 1px;
                    }
                }

                &__see-you-soon {
                    font-size: 1.6rem;
                    line-height: 1.25;
                    letter-spacing: .8px;

                    @include media-breakpoint-up(lg) {
                        font-size: 2.4rem;
                        line-height: 1.5;
                        letter-spacing: 1.2px;
                    }
                }
            }
        }

        &-button {
            padding-top: 18px;
            font-size: 1.4rem;
            font-weight: $font-weight-normal;
            line-height: 1.2;
            letter-spacing: .7px;
            color: $os-blue !important;

            @include media-breakpoint-up(lg) {
                padding-top: 32px;
                font-size: 1.8rem;
                line-height: 1;
                letter-spacing: .9px;
            }
        }
    }
}