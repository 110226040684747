@import '@assets/reuse-mixin.scss';

.cp-demo {
    height: $CP_DEMO_STATUS;

    &__status {
        position: fixed;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: $black;
        z-index: $HEADER_STICK_ZINDEX - 1 ;

        &__content {
            margin-bottom: 0;
            line-height: 32px;
            font-size: 14px;
            letter-spacing: 0.7px;
            color: $white;

            .customer-name {
                font-weight: $font-weight-bold;
            }
        }
    }
}