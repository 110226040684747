@import '@assets/reuse-mixin.scss';

.password-hint {
  font-weight: $font-weight-normal;
  margin-top: 1rem;

  &__title {
    color: $gray-darker;
    margin-bottom: 1rem;
  }

  &__list {
    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: $font-size-small;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    &-icon {
      display: block;
      width: 1.75rem;
      margin-right: 0.5rem;

      .icon-img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      .icon-active {
        display: none;
      }
    }

    &.active {
      color: $success;

      .icon-inactive {
        display: none;
      }

      .icon-active {
        display: block
      }
    }
  }
}