@import '@assets/reuse-mixin.scss';

// group social
.group-social {
  position: fixed;
  right: 0;
  top: 180px;
  background: rgba(0, 0, 0, 0.5);
  padding: 12px 8px;
  border-radius: 16px 0 0 16px;
  border: 1px solid rgba($white, 0.2);
  border-right: 0;
  z-index: 3;

  @include media-breakpoint-up(xxl) {
    top: 250px;
    padding: 20px 10px;
    border-radius: 20px 0 0 20px;
  }

  .item-tooltip {
    position: relative;
  }

  > div + div {
    margin-top: 10px;

    &.heart {
      margin-top: 12px;

      @include media-breakpoint-up(xxl) {
        margin-top: 14px;
      }
    }
  }

  .heart {
    z-index: 2;
    position: relative;
    display: block;
    top: 0;
    left: 0;
    // background-position: 0 -112px;
    background-position: 0 0;

    @include media-breakpoint-up(md) {
      background-position: 0 0;
    }

    &:hover {
      background-position: 0 -22px;

      @include media-breakpoint-up(xxl) {
        background-position: 0 -30px;
      }
    }

    &.active {
      background-position: 0 -44px;

      @include media-breakpoint-up(xxl) {
        background-position: 0 -60px;
      }
    }
  }

  .social-media__icon {
    // background-position: 0 -135px;
    background-position: 0 -67px;

    @include media-breakpoint-up(md) {
      background-position: 0 -67px;
    }

    @include media-breakpoint-up(xxl) {
      background-position: 0 -90px;
    }

    &:hover {
      background-position: 0 -90px;

      @include media-breakpoint-up(xxl) {
        background-position: 0 -120px;
      }
    }
  }

  .icons-component {
    width: 24px;
    height: 24px;
    z-index: 2;

    @include media-breakpoint-up(xxl) {
      width: 32px;
      height: 32px;
    }

    &:hover {
      .icons-component__img--nomal {
        display: none;
        opacity: 0;
      }

      .icons-component__img--active {
        display: block;
        opacity: 1;
      }
    }

    &__link {
      display: block;
    }

    &__img {
      width: 100%;
      height: auto;
      transition: opacity 0.5s;

      &--nomal {
        opacity: 1;
      }

      &--active {
        display: none;
        opacity: 0;
      }
    }
  }
}

// AR View Modal
.ar-view-modal {
  &__btn {
    all: unset;

    &:focus {
      outline: none;
    }
  }

  &__wrapper {
    padding-right: 0;

    .modal-content {
      padding: 0;
      min-height: 86%;
      @include media-breakpoint-up(lg) {
        width: 80%;
        max-width: 1560px;
        height: 86dvh;
      }

      .osim-popup__header {
        .osim-popup__close-btn {
          z-index: 1;
        }
      }
    }
  }

  &__iframe {
    border: 0;
  }

  &__loading, &__iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}