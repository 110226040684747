@import '@assets/reuse-mixin.scss';

.categories-navigation {
  background-color: $cp-background;

  &__title {
    padding: 30px 0 30px 24px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    h2 {
      font-size: $h4-font-size;
      letter-spacing: 1.2px;
      color: $gray-darker;
      line-height: 1.167;
      font-weight: $font-weight-normal;
      margin: 0;
    }
  }

  &__icon {
    padding-left: 24px;

    .arrow-icon-down {
      width: 9px;
      height: 15px;

      &::before, 
      &::after {
        transform-origin: 10px center;
        width: 10px;
        height: 2px;
      }

      &.show-drop-down {
        transform: rotate(-90deg);
      }
    }
  }

  &__listing {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: $Z_INDEX_NORMAL;
    background: $white;
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
    max-height: 83vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid $gray-lighter;

    @include scrollbar-custom();

    &.show-drop-down {
      opacity: 1;
      visibility: visible;
    }
  }
}