@import'@assets/reuse-mixin.scss';

.simple-card {
  background-color: #FBF7F9;
  padding: 36px 0 24px;
  color: $gray-darker;

  @include media-breakpoint-up(lg) {
    padding: 75px 0;
  }

  &__background {
    display: none;
  }

  &__heading {
    max-width: 70%;
    text-align: center;
    margin: 0 auto 10px;
    color: $gray-darker;

    // @include media-breakpoint-up(lg) {
    //   margin-bottom: 48px;
    // }
  }

  &__description {
    font-size: $h5-font-size;
    text-align: center;
    margin: 0 auto;
    white-space: break-spaces;

    @include media-breakpoint-up(lg) {
      max-width: 70%;
      font-size: $h4-font-size;
    }
  }

  &__list-wrap {
    display: flex;
    flex-wrap: wrap;

    &--4item-per-row {
      .simple-card__item-wrap {
        &:nth-child(4),
        &:nth-child(8),
        &:nth-child(12) {
          @include media-breakpoint-up(lg) {
            margin-right: 1px;
          }
        }
      }
    }

    &--4item-from-2ndrow {
      .simple-card__item-wrap {
        &:nth-child(9),
        &:nth-child(13) {
          @include media-breakpoint-up(lg) {
            margin-right: 1px;
          }
        }
      }
    }
  }

  &__item-wrap {
    width: 100%;

    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 20%;
      max-width: 30%;
    }
  }

  &__item {
    text-align: center;
    margin-bottom: 36px;
    padding: 0 10px;
    margin-top: 24px;
    // letter-spacing: 5px;
    // line-height: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
      margin-top: 48px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 20px;
    }

    &__image {
      width: 96px;
      // height: 96px;
      margin: 0 auto 18px;

      @include media-breakpoint-up(lg) {
        width: 154px;
        // height: 154px;
        margin: 0 auto 24px;
      }

      @include media-breakpoint-up(xxl) {
        margin: 0 auto 32px;
      }

      img {
        width: 100%;
        // height: 100%;
        // object-fit: cover;
        // border-radius: 50%;
      }
    }

    &__title {
      // margin-bottom: 8px;
      margin-bottom: 16px;
      font-size: $h5-font-size;
      color: $gray-darker;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }

    &__description {
      font-size: $font-size-base;
    }
  }

  &--has-background {
    position: relative;
    padding: 0;

    .simple-card {
      &__background {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        height: 0;
        padding-bottom: (1690/640)*100%; //INFO: Follow image ratio customer's current website

        @include media-breakpoint-up(md) {
          padding-bottom: (900/1920)*100%; //INFO: Follow image ratio customer's current website
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &__content {
        position: absolute;
        left: 0;
        width: 100%;
        height: 75%;
        top: 50%;
        transform: translateY(-50%);
        justify-content: center;

        @include media-breakpoint-up(md) {
          height: auto;
        }

        .container {
          height: 100%;
          overflow: hidden;
        }
      }

      &__list {
        height: 90%;
        overflow: hidden;
        margin-top: 0;

        @include media-breakpoint-up(md) {
          height: auto;
        }
      }

      &__item {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }
  }

  &--position-bottom {
    .simple-card {
      &__content {
        top: auto;
        bottom: 50px;
        transform: translateY(0);
        justify-content: flex-end;

        @include media-breakpoint-up(xl) {
          bottom: 80px;
        }
      }
    }
  }

  &--text-light {
    color: $white;

    .simple-card {
      &__heading {
        color: $white;
      }

      &__item {
        &__title {
          color: $white;
        }
      }
    }
  }

  &--full-height {
    .simple-card {
      &__background {
        height: 100%;
        width: 100%;
        padding-bottom: 0;
        position: absolute;
        overflow: hidden;
  
        img,
        video {
          position: static;
          min-width: 100%;
          height: 100%;
        }
      }

      &__content {
        height: auto;
        position: static;
        transform: translateY(0);
        top: 0;
        padding: 40px 0;

        @include media-breakpoint-up(md) {
          padding: 60px 0;
        }
      }

      &__list {
        height: auto;
      }
    }
  }

  //INFO: Customize swiper
  .swiper-container {
    height: 100%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: $gray;
    outline: none;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
    
    &:hover {
      color: $gray-darker;
    }

    &::after {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  &.skeleton__item {
    .simple-card__heading {
      opacity: 0;
    }

    .simple-card__list {
      opacity: 0;
    }
  }
}