@import '@assets/reuse-mixin.scss';

.single-featured-product {
  padding: 24px 0;

  @include media-breakpoint-up(lg) {
    padding: 32px 0;
  }

  &__container {
    @include media-breakpoint-up(lg) {
      max-width: 840px;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $gray-lighter;
    padding: 50px 35px;

    @include media-breakpoint-up(lg) {
      padding: 50px 40px;
      max-width: 820px;
      border: 1px solid $gray;
    }
  }

  &__col {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__image {
    padding-bottom: 42px;

    @include media-breakpoint-up(md) {
      padding: 0 40px 0 0;
    }

    img {
      width: 100%;
      max-width: 240px;

      @include media-breakpoint-up(lg) {
        max-width: 315px;
      }
    }
  }

  &__contents {
    @include media-breakpoint-up(md) {
      padding-left: 20px;
    }

    &__title {
      font-size: $h4-font-size;
      letter-spacing: 1.2px;
      font-weight: $font-weight-normal;
      text-align: center;
      margin-bottom: 6px;
  
      @include media-breakpoint-up(md) {
        text-align: left;
      }
  
      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
        letter-spacing: 1.6px;
        padding-right: 20px;
        margin-bottom: 12px;
      }

      a {
        color: inherit;

        &:hover {
          color: $link-hover-color;
        }
      }
    }

    &__rated {
      flex-wrap: wrap;
      margin-bottom: 4px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
      }

      &__star {
        display: flex;
        align-items: center;

        &__item {
          margin-right: 9px;

          @include media-breakpoint-up(lg) {
            margin-right: 17px;
          }

          img {
            width: 18px;
            @include media-breakpoint-up(lg) {
              width: 32px;
            }
          }
        }
      }

      &__number {
        font-size: $font-size-small;
        letter-spacing: 0.7px;
        color: $gray-darker;
        padding-left: 4px;

        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
          letter-spacing: 1.2px;
          padding-left: 10px;
        }

        .rated-number {
          font-size: $h4-font-size;
          letter-spacing: 1.2px;

          @include media-breakpoint-up(lg) {
            font-size: $h2-font-size;
            letter-spacing: 2px;
          }
        }
      }
    }

    &__review {
      color: $gray;
      font-size: $font-size-small;
      letter-spacing: 0.7px;
      text-align: center;
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        letter-spacing: 0.9px;
        margin-bottom: 32px;
      }
    }

    &__button {
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      a {
        font-size: $font-size-small;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
          letter-spacing: 2.56px;
        }
      }
    }
  }
}