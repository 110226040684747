@import '../../../assets/reuse-mixin.scss';

.wishlist-landing {
  padding: 0 0 24px;

  @include media-breakpoint-up(lg) {
    padding: 40px 0 36px;
  }

  &__header {
    padding-bottom: 30px;

    &__title {
      font-size: $font-size-small;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }

    &__btn {
      text-transform: uppercase;
      font-weight: bold;

      &__share {
        width: 280px;
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
      }

      &__back {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__content {
    // border-top: 1px solid $gray;
    // border-bottom: 1px solid $gray;

    // @include media-breakpoint-up(lg) {
    //   border: none;
    // }
  }

  ul.wishlist-landing__content__list {
    max-width: 980px;
    margin: 0 auto 36px;
  }
}