@import '@assets/reuse-mixin.scss';

.information-form {
    max-width: 343px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        max-width: 480px;
    }

    h2 {
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 1.28px;
        color: $gray-900;
        padding: 36px 0 24px 0;

        @include media-breakpoint-up(lg) {
            font-size: 2rem;
            line-height: 1.4;
            letter-spacing: 1.6px;
            padding: 48px 0 40px 0;
        }
    }

    .form-group {
        .error-validate {
            text-align: left;
        }
    }

    &__text {
        margin-bottom: 12px;
        text-align: left;
    }
}