@import '@assets/reuse-mixin.scss';

.product-interest {
  &__accordion {
    &__item {
      position: relative;
      padding: 22px 24px 8px;
      border-bottom: 1px solid $gray-lighter;

      &:nth-child(even) {
        background-color: $os-brown-lightest;
      }

      &--create {
        &.product-interest__accordion__item {
          border: 1px solid $gray-lighter;
          padding: 10px 24px;

          .accordion-header {
            &__title {
              color: $gray-darker;
              font-size: $font-size-small;
            }
          }

          .accordion-arrow {
            display: none;
          }

          .product-interest__form {
            padding: 14px 0 12px;
          }
        }
      }

      &.collapsed {
        .accordion-header, .accordion-header__container {
          display: none;
        }

        .accordion-content {
          height: auto;
          visibility: visible;
          display: block;
        }

        .accordion-arrow {
          transform: rotate(180deg);
          display: block;
          margin-top: 12px;
        }
      }
    }

    .accordion-header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &.custom-second__title{
        .accordion-header__label {
          right: calc(24px + 16px + 12px); //24px space from Capture badge to border-right, 16px icon width, 12px space between badge and icon
        }
      }

      &__title {
        color: $link-color;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base;
        }
      }

      &__label {
        display: inline-block;
        padding: 3px 22px;
        font-weight: $font-weight-normal;
        background-color: $gray-lighter;
        border-radius: 12.5px;
        position: absolute;
        top: 20px;
        right: 24px;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base;
        }
      }

      &-remove__icon {
        cursor: pointer;

        img {
          width: 16px;
        }
      }
    }

    .accordion-content {
      margin: 0 -4px;
      padding: 0;
      height: 0;
      display: none;
      visibility: hidden;
      transition: all 0.5s;
    }

    .accordion-arrow {
      text-align: center;
      cursor: pointer;
      line-height: 0;
      margin-top: -8px;

      i {
        font-size: 2.4rem;
      }
    }
  }

  &__form {
    .cp-form {
      &__group {
        width: 100%;

        &--item-mt-18 {
          margin-top: 18px;
        }
      }

      &__value-text {
        font-size: $font-size-small;
      }

      &__label {
        &-required {
          color: $red;

          span {
            color: $red;
          }
        }
      }
    }

    .osim-checkbox-label {
      font-size: $font-size-small;
      padding-left: 32px;
    }
  }
}

.error-mess {
  color: $primary;
  font-size: $font-size-smaller;
  margin-top: 6px;
  display: inline-block;
}
