@import "../SupportCentre";

.two-column11 > .container > .row {
  & > .col-md-6:has(.simple-cta) {
    & > .container {
      padding: 0
    }
  }
}

.simple-cta {
  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    & > .support-centre-box {
      border-bottom-width: 0;
      @include media-breakpoint-up(md) {
        border-bottom-width: 1px;
      }
    }
  }

  & > .support-centre-box {
    height: 100%;
  }

  &__container {
    max-width: 740px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;

    &-icon {
      width: 100%;
      margin: auto;
      max-width: 56px;
      @include media-breakpoint-up(md) {
        max-width: 72px;
      }

      &__wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 100%;

        & > img {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          object-fit: contain;
        }
      }
    }

    &-body {
      margin-top: 20px;
      @include media-breakpoint-up(md) {
        margin-top: 32px;
      }
    }

    &-footer {
      text-align: center;
      margin-top: 18px;
      @include media-breakpoint-up(md) {
        margin-top: 32px;
      }

      & > .btn {
        margin: auto;
        display: block;
        width: 200px;

        @include media-breakpoint-up(lg) {
          width: 240px;
        }

        &:not(:first-child) {
          margin-top: 12px;
        }
      }
    }
  }
}