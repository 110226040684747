@import '@assets/reuse-mixin.scss';

// Search result
.search-result {
  &__heading {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__input {
      position: relative;

      input {
        padding: 20px 40px 20px 24px;
        color: $gray-darker;
        width: 100%;
      }

      .icon-close {
        display: block;
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__tabs {
    margin-bottom: 12px;

    .nav-tabs {
      display: flex;
      border-bottom: 2px solid $gray-lighter;

      .nav-item {
        max-width: 340px;
        margin-bottom: -2px;
      }
    }
  }

  &__notice {
    display: flex;
    padding: 8px 0 24px;
    // border-top: 1px solid $gray-lighter;

    @include media-breakpoint-up(lg) {
      padding: 12px 0 24px;
    }

    &__text {
      color: $gray-dark;
      font-size: $font-size-small;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
    }

    .btn {
      margin-left: auto;
    }
  }

  &__content {
    &__notice {
      color: $gray-darker;
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }

    .article {
      margin-bottom: 32px;
      
      @include media-breakpoint-up(lg) {
        max-width: 980px;
        margin: 0 auto;
      }

      &__content {
        &__description {
          @include line-clamp(2);
        }

        &__price,
        &__action {
          display: none;
      
          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }
    }

    .policy-result__list {
      @include media-breakpoint-up(lg) {
        max-width: 980px;
        margin: 0 auto;
      }
    }
  }
}