@import '@assets/reuse-mixin.scss';

.login-register-modal {
  text-align: center;
  
  &__text {
    margin: 84px 0;
    color: $gray-900;
    
    @include media-breakpoint-up(xl) {
      margin: 110px 0;
    }
  }
}