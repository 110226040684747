@import '@assets/reuse-mixin.scss';

.my-account-header {
  &__nav {
    position: fixed;
    left: 0;
    top: $HEADER_MOBILE_HEIGHT;
    width: 100%;
    z-index: $PRODUCT_DETAIL_HEADER_ZINDEX;
    background-color: $white;
    border-bottom: 1px solid $gray-lighter;

    @include media-breakpoint-up(md) {
      top: $HEADER_DESKTOP_HEIGHT;
    }

    &__wrap {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      padding: 16px 0;

      @include media-breakpoint-up(md) {
        justify-content: center;
      }

      @include media-breakpoint-up(lg) {
        padding: 22px 0 15px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      width: auto;
      margin-right: 36px;

      @include media-breakpoint-up(md) {
        margin-right: 80px;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 160px;
      }

      &__link {
        text-transform: uppercase;
        color: $gray-darker;
        white-space: nowrap;
        transition: all 0.25s;
      }

      &:hover,
      &.active {
        .my-account-header__nav__item__link {
          font-weight: bold;
        }
      }
    }
  }

  &__heading {
    padding: 24px 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding: 40px 0 32px;
    }

    &__title {
      color: $gray;
      font-size: $font-size-small;
      text-transform: uppercase;
      margin: 0;
      font-weight: $font-weight-bold;
      letter-spacing: 0.3rem;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }

    &__button {
      margin-top: 4px;
      
      @include media-breakpoint-up(lg) {
        margin-top: 8px;
      }
    }

    & + .your-product-hub {
      padding-top: 0;
    }
  }
}