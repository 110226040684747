@import '@assets/reuse-mixin.scss';

.submit-review-process-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  &__container {
    width: max-content;
  }

  &__icon {
    margin-bottom: 3.5rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: calc(12px + 2.8rem);
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .product-registration-step__icon__product,
    .product-registration-step__icon__login {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .icon-product-img,
      .icon-login-img {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

    .focus {
      .icon-product-img {
        filter: brightness(0) invert(1);
      }
    }

    .line-focus {
      border-bottom: 1px solid $primary;
    }
  }

  &__text {
    padding-right: 0;

    &__product,
    &__login {
      font-weight: $font-weight-normal;
      margin-right: 0;
      position: absolute;
      top: 100%;
      width: max-content;
      @include media-breakpoint-up(lg) {
        margin-top: 12px;
      }
    }
  }

  &__description {
    @include media-breakpoint-up(lg) {
      padding-top: 32px;
      padding-bottom: 0;
    }
  }
}
