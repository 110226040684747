@import '../../components/SlicingPage/About/BrandStoryDetail/Affiliates/Affiliates.scss';
@import '../../components/SlicingPage/About/BrandStoryDetail/Awards/Awards.scss';
@import '../../components/SlicingPage/About/BrandStoryDetail/BrandOsim/BrandOsim.scss';
@import '../../components/SlicingPage/About/BrandStoryDetail/GlobalNetworkDetail/GlobalNetworkDetail.scss';
@import '../../components/SlicingPage/About/Careers/Careers.scss';

.rich-text-content {
  color: $gray-darker;
  padding: 32px 0;
    
  @include media-breakpoint-up(xl) {
    padding: 48px 0;
  }

  &--1000 .container {
    max-width: 1040px;
  }

  & + .rich-text-content {
    padding-top: 0;
  }

  h1,
  h2 {
    margin: 25px 0 8px;

    @include media-breakpoint-up(md) {
      margin: 40px 0 25px;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    margin: 24px 0 5px;

    @include media-breakpoint-up(md) {
      margin: 32px 0 16px;
    }
  }

  ul,
  ol,
  p {
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  p {
    margin-bottom: 10px;
  }

  &--left, 
  &--right {
    width: auto;
    padding: $grid-gutter-width/2;

    @include media-breakpoint-up(lg) {
      padding: 30px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 120px 60px 40px 60px;
    }

    h2 {
      padding-bottom: 10px;
    }

    p {
      margin-top: 16px;

      @include media-breakpoint-up(xl) {
        margin-top: 24px;
      }
    }
  }

  &--left {
    @include media-breakpoint-up(md) {
      padding-left: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(lg) {
      padding-left: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(xl) {
      padding-left: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(xxl) {
      padding-left: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
    }
  }

  &--right {
    @include media-breakpoint-up(md) {
      padding-right: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(lg) {
      padding-right: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(xl) {
      padding-right: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
    }

    @include media-breakpoint-up(xxl) {
      padding-right: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
    }
  }

  .rich-text {
    &__font-20 {
      p {
        font-size: $font-size-base;
  
        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }
    
        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }
      }
    }
  }

  // Band Story
  h4.our-awards__highlight__name {
    margin: 0 0 0 9px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 0 33px;
    }
  }
  
  .text-red {
    color: #FF0000;
  }

  &.add-to-cart-success {
    padding-bottom: 0;

    &.is-cp-cart {
      padding-bottom: 32px;

      @include media-breakpoint-up(xl) {
        padding-bottom: 48px;
      }

      & > .container {
        .add-to-cart__body__success-text {
          margin-bottom: 0;
        }
      }
    }

    & > .container {
      padding-bottom: 0;

      .add-to-cart__body {
        padding-top: 0;
        padding-bottom: 0;
        @include media-breakpoint-up(md) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.rich-text-left--desktop {
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

.rich-text-font-80--desktop {
  @include media-breakpoint-up(lg) {
    font-size: 0.8em;
  }
}