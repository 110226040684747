@import '@assets/reuse-mixin.scss';

$color-title: #49484e;
$color-silver: #aaabaf;
$color-bronze: #db6300;
$color-gold: #f8ac0b;
$color-platinum: #9da4be;
$color-diamond: #96d0d9;
$border-card: #dcdcdc;

.loyalty-table {
  padding: 32px 16px;

  @include media-breakpoint-up(lg) {
    padding: 72px;
  }

  &__card-deck {
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      text-align: center;
    }

    &.card-deck {
      @include media-breakpoint-up(lg) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
      }

      .card {
        margin: 0;
        color: $gray-900;
        padding: 36px 0 24px;
        border-bottom: 1px solid $border-card;

        &:nth-child(2) {
          padding-top: 0;
        }

        @include media-breakpoint-up(lg) {
          -ms-flex: 1 0 0%;
          flex: 1 0 0%;
          padding-top: 0;
          margin-bottom: 0;
          border-bottom: none;
        }

        &:first-child {
          @include media-breakpoint-up(lg) {
            .card-body__list__item {
              border-left: 1px solid $gray-500;
            }
          }
        }

        &--bronze {
          color: $color-bronze;
        }

        &--silver {
          color: $color-silver;
        }

        &--platinum {
          color: $color-platinum;
        }

        &--gold {
          color: $color-gold;
        }

        &--diamond {
          color: $color-diamond;
        }

        &--desktop {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        &-header {
          background-color: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-up(lg) {
            min-height: 72px;
          }

          &__image {
            max-width: 40px;
          }

          &__title {
            display: inline-block;
            margin-left: 8px;
            font-size: $h4-font-size-mobile;
            font-weight: $font-weight-bold;
            color: $color-title;
            margin-bottom: 0;

            @include media-breakpoint-up(xl) {
              font-size: $h4-font-size;
            }
          }
        }

        &-body {
          &__title {
            text-align: center;
            color: $gray-900;
            margin-top: 8px;
            font-weight: $font-weight-normal;

            @include media-breakpoint-up(lg) {
              display: none;
            }
          }

          &__list {
            &__item {
              padding: 0 8px;
              display: none;

              @include media-breakpoint-up(lg) {
                display: block;
                min-height: 72px;
                border-right: 1px solid $gray-500;
                border-bottom: 1px solid $gray-500;
                display: flex;
                align-items: center;
                justify-content: center;

                &:first-child {
                  border-top: 1px solid $gray-500;

                  span {
                    font-weight: $font-weight-bold;
                  }
                }

                span {
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  font-weight: $font-weight-normal;
                }
              }

              &--mobile {
                display: flex;
                margin-top: 12px;
                color: $gray-900;
                align-items: center;

                @include media-breakpoint-up(lg) {
                  display: none;
                }

                &:nth-child(n + 3) {
                  + .card-body__list__item--mobile {
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                    margin-top: 0;
                    transition: all ease .5s;
                  }

                  &.active {
                    opacity: 1;
                    visibility: visible;
                    height: auto;
                    margin-top: 12px;
                  }
                }

                span {
                  &:first-child {
                    margin-left: 38px;
                    display: block;
                  }
                }
              }

              &__icon {
                max-width: 30px;
                max-height: 30px;
                margin-right: 8px;
              }

              .icon-check {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 3px solid;
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%) rotate(-45deg);
                  width: 12px;
                  height: 9px;
                  border-left: 3px solid;
                  border-bottom: 3px solid;
                }
              }

              &.no-bold {
                span {
                  font-weight: $font-weight-normal;
                }
              }
            }

            &__button {
              text-align: center;
              margin-top: 24px;

              @include media-breakpoint-up(lg) {
                display: none;
              }

              &.hidden {
                display: none;
              }

              button {
                display: inline-flex;
                align-items: center;
              }

              .icon-arrow-down {
                transform: translateY(-50%) rotate(-45deg);
                width: 9px;
                height: 9px;
                border-bottom: 1px solid;
                border-left: 1px solid;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}