@import '@assets/reuse-mixin.scss';

.my-product-widget {
  &__list {
    &__wrap {
      .warranty-product {
        &:first-child {
          .warranty-product__wrap {
            border: none;
          }
        }

        &__wrap {
          padding: 0;
        }

        &__view-btn {
          display: none;
        }

        &__group {
          margin-bottom: 24px;

          @include media-breakpoint-up(lg) {
            display: unset;
          }

          @include media-breakpoint-up(xl) {
            margin-bottom: 32px;
          }

          @include media-breakpoint-up(xxl) {
            display: flex;
            align-items: baseline;
            margin-bottom: 32px;
          }
        }

        &__order-date {
          margin-top: 4px;

          @include media-breakpoint-up(lg) {
            padding-left: 0;
            margin-left: 0;
            border-left: none;
            line-height: 1;
          }

          @include media-breakpoint-up(xxl) {
            margin-top: 0;
            padding-left: 16px;
            margin-left: 16px;
            border-left: 1px solid $gray-1000;
            line-height: 1;
          }
        }
      }
    }
  }

  &__button {
    &__register {
      margin-top: 24px;
    }
  }

  .btn {
    font-weight: $font-weight-bold;

    &:hover {
      text-decoration: unset;
    }
  }

  .btn-primary {
    color: $btn-primary-color;
  }

  //Loading
  .text-line-small {
    width: 70px !important;
  }

  .text-line-20 {
    width: 50% !important;
  }

  .button-line {
    width: 70% !important;
    margin: 0 auto;
  }
}