@import "@assets/reuse-mixin.scss";

.registration-error-popup {
  &__body {
    .popup-email-already {
      &__action {
        padding: 0;

        &__btn {
          &--login {
            @include media-breakpoint-up(md) {
              margin: 0 auto 18px;
            }
          }
        }
      }
    }
  }
}