@import '@assets/reuse-mixin.scss';

$MIN_HEIGHT_FILTER: 48px;

.cp-filter-popup-orders {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 400px;
  z-index: 2;
  background-color: $white;
  border: 1px solid $gray;
  padding: 20px;
  display: none;

  @include media-breakpoint-up(md) {
    left: unset;
    right: 0;
    max-width: none;
    width: 483px;
    padding: 24px;
  }

  &.show {
    display: block;
  }

  &__item {
    display: flex;
    margin-bottom: 18px;

    &__label {
      color: $gray-darker;
      font-weight: $font-weight-normal;
      width: 30%;
      padding-right: 24px;
      display: flex;
      align-items: center;
    }

    &__value {
      width: 70%;

      .cp-select .cp-select__control {
        height: auto;
        min-height: $MIN_HEIGHT_FILTER;
      }
    }

    &.filter-date {
      .cp-filter-popup-orders__item__value {
        .react-date-picker, .react-daterange-picker {
          width: 100%;
          min-height: $MIN_HEIGHT_FILTER;

          &__clear-button {
            display: none;
          }

          &__inputGroup {
            padding-left: 16px;

            &__month {
              max-width: 30px;
              padding: 0 5px;
              text-align: center;
              appearance: none;
              cursor: pointer;
            }

            &__divider {
              display: none;
            }

            &__year {
              padding: 0;
            }
          }

          &__button {
            padding-right: 16px;

            &:focus {
              outline: none;
            }
          }

          &__calendar {
            z-index: 2;
          }
        }
      }
    }

    &.filter-range {
      .cp-filter-popup-orders__item__value {
        margin: 10px 0 24px;

        @include media-breakpoint-up(md) {
          margin: 12px 0 30px;
        }
      }

      .input-range__line {
        width: calc(100% - 24px);
        margin-left: 12px;
      }

      .input-range__curent {
        top: 25px;

        &.is-hidden {
          color: transparent;
          visibility: hidden;
        }

        &.change-position {
          top: -25px;
        }
      }

      &__disable {
        cursor: auto;
        pointer-events: none;
        opacity: 0.2;
      }
    }
  }

  &__item-category {
    width: 70%;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;

    &__value {
      display: flex;
      background: $os-orange-lighter;
      color: $black;
      font-size: $font-size-smaller;
      align-items: center;
      font-weight: $font-weight-normal;
      padding: 8px 12px;
      margin: 4px;

      &:first-child {
        margin-left: 0;
      }
    }

    &__btn {
      border: none;
      background: $os-orange-lighter;
      color: $gray-500;
      padding: 0;
      margin-left: 8px;
      font-size: 12px;

      &:focus {
        outline: none;
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 20px;
  }
}