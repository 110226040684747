@import "@assets/reuse-mixin.scss";

.search-filter-bar {
  &__filter-popup {
    .store-locator__modal {
      .button-container {
        padding-top: 24px;
        @include media-breakpoint-up(lg) {
          padding-top: 32px;
        }
      }
    }
  }
}