@import '@assets/reuse-mixin.scss';

.product-detail-header {
  // left: 0;
  // top: $HEADER_MOBILE_HEIGHT;
  width: 100%;
  z-index: $PRODUCT_DETAIL_HEADER_ZINDEX;
  background-color: $white;
  color: $gray-darker;
  border-bottom: 1px solid $gray-lighter;
  position: relative;

  & + .sub-navigation .sub-navigation__wrap {
    position: static;
  }

  &.product-detail-sticky {
    position: sticky;
    top: 0;

    & + .sub-navigation {
      position: sticky;
      z-index: $PRODUCT_DETAIL_HEADER_ZINDEX - 1;
      top: 64px;
  
      @include media-breakpoint-up(md) {
        top: 57px;
      }
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    height: 64px;

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }

    @include media-breakpoint-up(lg) {
      height: 56px;
    }
  }

  &__drop-down {
    display: inline-block;
    position: relative;
    padding: 6px 20px 6px 0;
    width: calc(100% - 128px);

    @include media-breakpoint-up(md) {
      width: auto;
      max-width: calc(100% - 260px);
    }

    @include media-breakpoint-up(lg) {
      padding: 9px 50px 9px 0;
    }

    &__arrow {
      font-size: 1.2em;
      position: absolute;
      right: 10px;
      bottom: 9px;
      cursor: pointer;
      transition: all .35s;

      @include media-breakpoint-up(md) {
        font-size: 1.4em;
        right: -15px;
        bottom: 6px;
      }

      @include media-breakpoint-up(lg) {
        top: 5px;
        right: -40px;
        bottom: auto;
        font-size: 1.2em;
      }

      &.rotate {
        transform: rotate(180deg);
      }
    }

    &__menu {
      visibility: hidden;
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 65px;
      background-color: #fff;
      transition: all .35s;
      height: calc(100vh - #{$HEADER_MOBILE_HEIGHT} - 64px);
      overflow: auto;
      padding: 36px 0 24px;

      @include media-breakpoint-up(lg) {
        padding: 32px 0 48px;
        top: 57px;
        height: calc(100vh - #{$HEADER_DESKTOP_HEIGHT} - 56px);
        // max-height: 810px;
        // box-shadow: 0 10px 14px rgba($black, 0.1);
        // height: auto;
      }

      &.show {
        visibility: visible;
        opacity: 1;
      }

      .container {
        @include media-breakpoint-up(lg) {
          position: relative; //INFO: set position for swiper button
          height: 100%;
        }
      }

      &__list-product {
        height: 72vh;
        padding: 0 10px 5vh;
        overflow-y: scroll;
        overflow-x: hidden;

        @include media-breakpoint-up(lg) {
          padding: 0 10px 10vh;
          overflow: hidden;
          height: 100%;
        }

        &--mobile {
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
        
        &--desktop {
          display: none;
          
          @include media-breakpoint-up(lg) {
            padding: 0;
            display: block;
            max-width: 1402px;
            margin: auto;
            min-height: 600px;
            max-height: 630px;
            
            @include scrollbar-custom();
          }

          @include media-breakpoint-up(xxl) {
            max-height: 730px;
          }
        }
      }

      &__btn {
        text-align: center;
        padding-top: 24px;
        padding-bottom: 24px;
        // position: fixed;
        // bottom: 24px;
        // left: 0;
        // width: 100%;
        // background: #FFF;

        @include media-breakpoint-up(lg) {
          // bottom: 48px;
          padding-top: 40px;
          padding-bottom: 40px;
        }

        .btn {
          width: 200px;
          text-transform: uppercase;

          @include media-breakpoint-up(lg) {
            width: 240px;
          }
        }
      }

      .drop-down__menu__list {
        &__item {
          padding: 15px;
          border-bottom: 1px solid #ccc;

          &.active {
            color: red;
          }
        }
      }
    }
  }

  &__product-name {
    font-size: $font-size-large;
    width: fit-content;
    cursor: pointer;
    
    @include media-breakpoint-up(lg) {
      position: relative;
      font-size: $h4-font-size;
    }

    &:has(.product-detail-header__price__list-price.with-tooltip) {
      &__text {
        line-height: 1;
      }
    }

    &__text {
      font-weight: $font-weight-normal;
      @include line-clamp(1);
    }
  }

  &__group {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }

  &.--cp-product-detail {
    .product-detail-header {
      &__product-name {
        @include media-breakpoint-up(md) {
          position: relative;
          font-size: $h4-font-size;
        }
      }

      &__drop-down {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
        }

        &__arrow {
          @include media-breakpoint-up(md) {
            top: 5px;
            right: -40px;
            bottom: auto;
            font-size: 1.2em;
          }
        }
      }

      &__group {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
        }

        .product-detail-header {
          &__price {
            @include media-breakpoint-up(md) {
              margin-right: 20px;
            }

            &__selling-price {
              color: $cp-gray;
              @include media-breakpoint-up(md) {
                padding-right: 20px;
              }

              @include media-breakpoint-up(lg) {
                font-size: $font-size-base;
              }
            }
  
            &__list-price {
              @include media-breakpoint-up(md) {
                font-size: $font-size-base;
                letter-spacing: 0.9px;
                font-weight: $font-weight-normal;
                color: $gray-darker;
              }

              @include media-breakpoint-up(lg) {
                font-size: 3.0rem;
                margin: 0;
              }
            }
          }
        }
      }

      &__price {
        &--mobile {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
    
        &--desktop {
          display: none;
    
          @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            margin-right: 50px;
          }
        }
      } 
    }
  }

  &__price {

    &__deposit {
      font-size: $h5-font-size-mobile;
      color: $gray-500;
      font-size: $font-size-smaller;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }

    &__list-price {
      display: inline-block;
      margin-right: 10px;
      font-size: $font-size-base;
      line-height: 1;
      font-weight: $font-weight-normal;
      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
        margin: 0 2px 0 16px;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 3.0rem;
      }

      &.deposit-price {
        margin: 0 0 0 8px;
      }

      &.with-tooltip {
        display: flex;
      }
    }

    &__selling-price {
      margin-right: 10px;
      font-size: $font-size-smaller;
      color: $gray;
      line-height: 1;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
      @include media-breakpoint-up(xl) {
        margin: 0 0 0 16px;
      }
    }

    &--mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }

      &:has(.product-detail-header__price__list-price.with-tooltip) {
        display: flex;
        flex-direction: column-reverse;
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      .product-detail-header__price {
        &__list-price {
          .question-tooltip-icon__wrapper {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(lg) {
        // display: block;
        display: flex;
        margin-right: 3rem;
        &:has(.product-detail-header__price__list-price.with-tooltip) {
          flex-direction: column-reverse;

          .product-detail-header__price__list-price {
            margin-bottom: 2px;
            @include media-breakpoint-up(xl) {
              margin: 0;
            }
          }

          .product-detail-header__price__selling-price {
            @include media-breakpoint-up(xl) {
              margin: 4px 0 0;
            }
          }
        }
      }
      @include media-breakpoint-up(xxl){
        align-items: flex-start;
        margin-right: 5rem;
        flex-direction: row;
        .product-detail-header__price__selling-price {
          margin-left: 0;
        }
        .product-detail-header__price__deposit {
          align-self: flex-end;
        }
      }
    }
  }

  &__btn {
    width: 128px;
    height: 100%;

    @include media-breakpoint-up(md) {
      width: 240px;
    }

    .btn {
      width: 100%;
      height: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--pay-deposit-now {
      text-wrap: wrap;
    }
  }

  //Customize Swiper
  .swiper-container,
  .swiper-wrapper {
    height: 100%;
    position: static; //INFO: set position for swiper button
  }

  .swiper-wrapper {
    // padding: 0 48px;
    // flex-wrap: wrap;
  }

  .swiper-slide {
    max-height: 50%;
    margin-top: 0 !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 44px;
    font-size: 2.0rem;
    color: $gray;
    transition: 0.5s;
    
    &:hover {
      color: $gray-darker;
    }

    &::after {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }
  }

  .swiper-button-next {
    right: -15px;
  }

  .swiper-button-prev {
    left: -15px;
  }
}