@import '@assets/reuse-mixin.scss';

.productReviewForPDP {
    padding-top: 48px;
    margin-bottom: 56px;

    @include media-breakpoint-up(lg) {
        padding-top: 74px;
        margin-bottom: 72px;
    }

    &__content {
        max-width: 1333px;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        .reviews-summary {
            flex: 1;

            @include media-breakpoint-up(lg) {
                min-width: 348px;
                padding: 0;
                width: fit-content;
            }
        }

        .review-listing {
            //width: 68%;
            flex: 1;
            max-width: 985px;

            @include media-breakpoint-up(lg) {
                padding-left: 57px;
            }

            @include media-breakpoint-up(xxl) {
                padding-left: 77px;
            }

            &__list {
                padding-left: 33px;

                @include media-breakpoint-up(lg) {
                    padding-left: 60px;
                }
            }

            .product-review-content__review-detail {
                &__quote-icon {
                    @include media-breakpoint-up(lg) {
                        left: -60px;
                    }
                }
            }
        }
    }

    &__navigation {
        margin-bottom: 36px;

        @include media-breakpoint-up(xl) {
            margin-bottom: 40px;
        }

        &__item {
            text-align: center;

            @include media-breakpoint-up(md) {
                display: flex;
                justify-content: center;
                align-items: center;

            }

            img {
                text-align: center;
                width: 17%;
                max-width: 128px;
                opacity: 0.5;
            }

            p {
                font-size: $font-size-smaller;
                line-height: 1.3;
                letter-spacing: 0.6px;
                color: $gray-200;
                margin: 0;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 2rem;
                    line-height: 1.2;
                    letter-spacing: 1px;
                }
            }
        }

        .swiper-wrapper {
            align-items: center;

            .swiper-slide-active {
                .productReviewForPDP__navigation__item {
                    img {
                        display: initial;
                        width: 76%;
                        max-width: 128px;
                        opacity: 1;

                        @include media-breakpoint-up(md) {
                            max-width: 200px;
                        }
                    }

                    p {
                        font-size: $font-size-base;
                        line-height: 1.5;
                        letter-spacing: 0.8px;
                        color: $gray-900;

                        @include media-breakpoint-up(xl) {
                            font-size: 2.4rem;
                            line-height: 1;
                            letter-spacing: 1.2px;
                        }
                    }
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            transform: translateY(-50%);
            height: 100%;
            margin: 0;
            background-color: $white;
            color: $gray-900;

            &::after {
                font-size: 2.5rem;
            }
        }

        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
        }
    }

}