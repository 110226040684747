@import '@assets/reuse-mixin.scss';

.policy-result {
  padding-top: 24px;
  border-top: 1px solid $gray-lighter;

  @include media-breakpoint-up(lg) {
    border: none;
    padding-top: 0;
  }

  &__wrap {
    @include media-breakpoint-up(lg) {
      padding-top: 24px;
      border-top: 1px solid $gray-lighter;
    }
  }

  &__number {
    font-size: $font-size-smaller;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 72px;
    }
  }

  &__list {
    margin: 0 auto 36px;
    max-width: 1000px;

    @include media-breakpoint-up(lg) {
      margin: 0 auto 60px;
    }
  }

  &__item {
    margin-bottom: 32px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 72px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      font-size: $h4-font-size;
      margin-bottom: 8px;

      @include media-breakpoint-up(xxl) {
        font-size: $h2-font-size;
      }
    }

    &__question {
      margin-bottom: 8px;
      font-size: $font-size-large;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h4-font-size;
      }
    }

    &__answer {
      margin-bottom: 18px;
      font-size: $font-size-base;
      @include line-clamp(4);

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
        font-size: $h5-font-size;
        @include line-clamp(3);
      }
    }

    &.skeleton__item {
      .policy-result__item__title {
        max-width: 300px;

        .text-line {
          @include skeleton($lines: 1, $line-height: 35px, $line-gap: 5px);
        }
      }

      .policy-result__item__question {
        .text-line {
          @include skeleton($lines: 1, $line-height: 25px, $line-gap: 0px);
        }
      }

      .policy-result__item__answer {
        .text-line {
          @include skeleton($lines: 3, $line-height: 20px, $line-gap: 5px);
        }
      }
    }
  }
}