@import '@assets/reuse-mixin.scss';

.accordion-buying-option {
  &__header {
    position: relative;
    min-height: 36px;
    padding: 12px 0;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
      padding: 16px 0;
    }

    // .btn-change {
    //   display: none;

    //   &.active {
    //     display: block;
    //   }
    // }

    .error-msg,
    .btn-change {
      display: none;
      transition: all .35s;
      right: 0;
      // top: 50%;
      top: 25px;

      @include media-breakpoint-up(lg) {
        top: 30px;
      }

      @include media-breakpoint-up(xl) {
        top: 32px;
      }

      &.active {
        display: block;
      }
    }

    .btn-change {
      color: $link-color;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      cursor: pointer;
      position: absolute;
      // transform: translateY(-50%);

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      &:hover {
        font-weight: $font-weight-bold;
      }
    }

    .error-msg {
      color: $primary;

      @include media-breakpoint-up(md) {
        position: absolute;
        // transform: translateY(-50%);
      }
    }

    > div {
      width: 100%;
    }
  }

  .free-gift-selected {
    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
      margin-bottom: 4px;
    }
  }
}

.accordion-buying-option-cp {
  .accordion-buying-option__header {
    padding: 0px 0px 18px 0px;

    .bo-title-section {
      margin: 0;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
    }
  }
}