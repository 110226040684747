@import '@assets/reuse-mixin.scss';

.footer {
  background: $os-brown-lightest;

  .two-column {
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      padding: 0 calc(((100vw - #{$container-lg}) / 2));
    }

    @include media-breakpoint-up(xl) {
      padding: 0 calc(((100vw - #{$container-xl}) / 2));
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 calc(((100vw - #{$container-xxl}) / 2 - #{$grid-gutter-width} / 4));
    }
  }

  .group-lang-social {
    @include media-breakpoint-up(lg) {
      width: 20%;
      padding-top: 45px;
    }
  }
}