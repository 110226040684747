@import "@assets/reuse-mixin.scss";
@import "@components/AbandonedCartWidget/AbandonedCartWidget";

.personalize-offer-widget {
  @extend .abandoned-card-widget;

  &__heading {
    @extend .abandoned-card-widget__heading;
  }

  &__content {
    @extend .abandoned-card-widget__content;

    .content-wrapper {
      .product-name {
        margin-bottom: 0;
      }

      .product-price-offer {
        margin-top: 18px;
        @include media-breakpoint-up(md) {
          margin-top: 24px;
        }

        .personalize-offer-widget__content-description {
          &:not(:first-child) {
            margin-top: 8px;
            @include media-breakpoint-up(md) {
              margin-top: 16px;
            }
          }
        }
      }
    }

    &-description {
      @extend .abandoned-card-widget__content-description;
    }

    .view-cart-button {
      &.btn {
        max-width: fit-content;
        margin-top: 32px;
      }
    }

    &-expired-text {
      color: #f91c1c;
      margin-top: 32px;
      font-size: $font-size-base;
      line-height: 2.6rem;
      margin-bottom: 0;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
        line-height: 2.8rem;
        text-align: left;
      }
    }
  }

  .offer-from__text {
    margin-bottom: 16px;
    font-size: $font-size-base;
    line-height: 1.8rem;
    color: $gray-1000;
  }
}

