@import "@assets/reuse-mixin.scss";

.fake-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // -webkit-backdrop-filter: saturate(180%) blur(20px);
  // backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  align-items: center;
  transition: all 0.5s;
  // overflow-y: scroll;

  .installment {
    --show-copied: none;
    width: 50%;
    max-width: 740px;
    border: none;
    padding: 24px 20px 32px;
    background: #fff;
    min-height: 200px;
    margin-top: auto;
    width: 100%;
    position: relative;
    margin: 50px auto;

    &__wrap {
      margin-bottom: 32px;

      @include media-breakpoint-up(lg) {
        padding: 0 5%;
      }

      @include media-breakpoint-up(xxl) {
        padding: 0 70px;
      }
    }

    &__title {
      text-align: center;
      font-size: $font-size-small;
      color: $gray;
      text-transform: uppercase;
      margin-bottom: 32px;
    }

    a.osim-popup__close-btn {
      color: $gray-darker;
    }

    &__table {
      width: 100%;

      &__record {
        display: flex;
        border: 1px solid $gray;
        border-bottom: none;
        
        &:last-child {
          border-bottom: 1px solid $gray;
        }

        &__item {
          padding: 8px 4px;

          @include media-breakpoint-up(md) {
            padding: 8px;
          }

          @include media-breakpoint-up(lg) {
            padding: 12px;
          }
          
          &--name,
          &--interest-rate {
            text-align: center;
            border-right: 1px solid $gray;
          }

          &--name {
            width: 30%;
          }

          &--interest-rate {
            width: 30%;

            @include media-breakpoint-up(md) {
              width: 20%;
            }
          }

          &--bank-list {
            width: 40%;

            @include media-breakpoint-up(md) {
              width: 50%;
            }
          }

          span {
            word-break: break-word;
          }
        }
      }
    }

    &__form {
      max-width: 480px;
      margin: 0 auto 47px;

      &__label {
        margin-bottom: 8px;
      }

      //Customize form
      .form-group {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 32px;
        }
      }
    }

    &__footer {
      .btn {
        min-width: 200px;
  
        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
  
      .btn-clear-filter {
        display: block;
        color: $os-blue;
        margin-top: 18px;
        cursor: pointer;
      }
    }
  }
}