@import '@assets/reuse-mixin.scss';

.balance-payment {
  &__empty {
    padding: 32px 20px;
    text-align: center;
    color: $gray-900;
    font-size: $font-size-large;
    border-top: 1px solid $gray-500;
    border-bottom: 1px solid $gray-500;
    margin-bottom: 48px;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      margin-bottom: 72px;
    }
  }

  &__summary {
    background: $os-orange-lighter;

    &__column-reverse {
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: unset;
      }
    }
  }

}