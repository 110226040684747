@import '@assets/reuse-mixin.scss';

// Search result
.search-result {
  &__heading {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;

    &__input {
      position: relative;

      input {
        padding: 20px 40px 20px 24px;
        color: $gray-darker;
        width: 100%;
      }

      .icon-close {
        display: block;
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__notice {
    display: flex;
    padding: 8px 0 32px;

    @include media-breakpoint-up(lg) {
      padding: 24px 0;
    }

    &__text {
      color: $gray-dark;
      font-size: $font-size-small;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
    }

    .btn {
      margin-left: auto;
    }

    &.skeleton__item {
      display: flex;
      justify-content: space-between;
    }
  }

  &__content {
    &__notice {
      color: $gray-darker;
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }

    .article {
      margin-bottom: 32px;
      
      @include media-breakpoint-up(lg) {
        max-width: 980px;
        margin: 0 auto;
      }

      &__content {
        padding: 0 0 0 20px;

        @include media-breakpoint-up(xl) {
          padding: 0 0 20px 90px;
        }
        
        &__link {
          &.bundle-link {
            @include media-breakpoint-up(md) {
              display: none
            }
          }
        }

        &__action {
          a {
            margin-bottom: 20px;
            margin-right: 20px;
          }

          a:last-child {
            margin-bottom: 20px;
            margin-left: 0
          }
        }
      }
    }

    .article__content__description {
      @include line-clamp(2);
    }

    .policy-result__list {
      @include media-breakpoint-up(lg) {
        max-width: 980px;
        margin: 0 auto;
      }
    }

    .article__content__action {
      & > .btn {
        width: 100%;
        max-width: calc(50% - 10px);
      }
    }
  }

  .resource-search {
    max-width: 1000px;
    margin: 0 auto;
  }
}