@import "@assets/reuse-mixin.scss";

.applied-promotion {
  // padding: 10px;
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    padding: 0 30px;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 64px;
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 48px;
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      margin-bottom: 12px;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size;
    }
  }

  &__description {
    font-size: $font-size-base;
    color: $gray-darker;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
      margin-bottom: 12px;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  .btn-link {
    color: $link-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}