@import '@assets/reuse-mixin.scss';

.member-event-cancel {
  padding: 56px 16px;
  @include media-breakpoint-up(md) {
    padding: 72px 16px;
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-normal;
    font-size: $h2-font-size-mobile;
    line-height: 3.6rem;
    margin-bottom: 36px;
    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
      line-height: 4.6rem;
      margin-bottom: 48px;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn-link {
      margin-top: 18px;
    }
  }
}