@import '@assets/reuse-mixin.scss';

.promotion-listing-item {
  margin-bottom: 30px;
  color: $gray-darker;
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(md) {
    margin-bottom: 32px;
  }

  .row {
    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
    }

    [class^='col'] {
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }

  &__contents-wrapper {
    display: flex;
    align-items: center;
  }

  &__image {
    display: block;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      object-fit: cover;
    }
  }

  &__contents {
    padding: 18px 16px;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 16px 0;
    }

    &__title {
      margin-bottom: 16px;
      font-size: $h4-font-size;
      font-weight: $font-weight-normal;
      text-align: center;
      color: $gray-darker;
      // letter-spacing: 1.2px;
      // line-height: 2.8rem;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size + 0.2;
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h2-font-size;
        // letter-spacing: 2px;
        // line-height: 4.6rem;
      }

      a {
        color: inherit;

        &:hover {
          color: $link-color;
        }
      }
    }

    &__desription {
      margin-bottom:  18px;
      text-align: center;
      font-size: $font-size-base;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        margin-bottom:  24px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }
    }

    &__link {
      margin-bottom:  8px;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }

      a {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }

    &__date {
      font-size: $font-size-smaller;
      letter-spacing: 0.55px;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
        letter-spacing: 0.8px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
      }
    }

    &__countdown {
      font-size: $font-size-smaller;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(xl) {
        font-size: $font-size-small;
      }
    }
  }
}