@import '@assets/reuse-mixin.scss';

.item-description {
  color: $gray-darker;
  padding: 40px 0;

  @include media-breakpoint-up(md) {
    margin-bottom: 32px;
  }

  .row {
    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;
    }
  }

  &__contents-wrapper {
    display: flex;
    align-items: center;
  }

  &__contents {
    padding: 10px 0;

    @include media-breakpoint-up(lg) {
      max-width: 460px;
    }

    &__title {
      margin-bottom: 8px;
      font-size: $h1-font-size-mobile;
      color: $gray-darker;
      letter-spacing: 0.56px;

      @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
        letter-spacing: 2px;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
        font-size: $h4-font-size;
        letter-spacing: 0.56px;
        text-align: left;
      }
      @include media-breakpoint-up(xl) {
        font-size: $h2-font-size;
        letter-spacing: 2px;
      }
    }
  }
}

.note-contents {
  text-align: center;
  color: $gray-darker;
  padding: 40px 0 28px 0;
  background-color: $os-orange-lightest;

  @include media-breakpoint-up(lg) {
    padding: 64px 0 20px 0;
  }

  &__container {
    @include media-breakpoint-up(lg) {
      max-width: 760px;
    }
  }

  &__title {
    font-size: $h4-font-size;
    letter-spacing: 1.2px;
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size;
      letter-spacing: 2px;
    }
  }

  &__description {
    font-size: $font-size-base;
    letter-spacing: 1.28px;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      letter-spacing: 1.6px;
      margin-bottom: 30px;
    }
  }

  &__button {
    a {
      min-width: 220px;
      font-size: $font-size-small;
      letter-spacing: 2.24px;

      @include media-breakpoint-up(lg) {
        min-width: 240px;
        font-size: $font-size-base;
        letter-spacing: 2.56px;
      }
    }
  }
}
