// Color system

$white:    #fff;
$gray-100: #f5f5f7;
$gray-200: #e6e6e6;
$gray-300: #d8d8d8;
$gray-400: #ccc;
$gray-500: #acacac;
$gray-600: #707070;
$gray-700: #666;
$gray-800: #333;
$gray-900: #2a2a2a;
$gray-1000: #272727;
$black:    #000;
$mine-shaft: #272727;
$gallery-gray: #f0f0f0;
$red: #FF0000;
$brown: #514244;
$gray-c2: #c2c2c2;


// Grays
// -------------------------

$gray-darker:            $gray-900;
$gray-dark:              $gray-600;
$gray:                   $gray-500;
$gray-light:             $gray-300;
$gray-lighter:           $gray-200;
$gray-lightest:          $gray-100; // Add gray lightest

$page-title:       #989898;
// Client Portal
$cp-background:    #f6f6f6;
$cp-gray-light:    #8e8e8e;
$cp-gray:          #7d7d7d;
$border-form:      #979797;
$cp-border-color:  #d4d4d4;

// Brand colors
// -------------------------

$primary:             #f37021;
$secondary:           #0072bc;
$success:             #00ae4d;
// $warning:         #ffdb00;
// $info:            #0abceb;
$danger:              #d9534f;
$os-orange-darker:    #df6116;
$os-orange-dark:      #f37021;
$os-orange:           #fee6d3;
$os-orange-lighter:   #feeee4;
$os-orange-lightest:  #fef2e9;
$os-brown:            #e0dac9;
$os-brown-lighter:    #f6f4ef;
$os-brown-lightest:   #fcfbfa;
$os-blue:             #0072bc;
$os-green:            #00ae4d;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "default":             $gray,
    "dark":                $gray-darker,
    "white":               $white,
    "primary":             $primary,
    "secondary":           $secondary,
    "os-orange-darker":    $os-orange-darker,
    "os-orange-dark":      $os-orange-dark,
    "os-orange":           $os-orange,
    "os-orange-lighter":   $os-orange-lighter,
    "os-orange-lightest":  $os-orange-lightest,
    "os-brown":            $os-brown,
    "os-brown-lighter":    $os-brown-lighter,
    "os-brown-lightest":   $os-brown-lightest,
    "os-blue":             $os-blue,
    "os-green":            $os-green,
  ),
  $theme-colors
);

// Body

$body-bg:               #fff;
$body-color:            $gray-dark;
$text-color:            $gray;
$border-color:          $gray;


// Links
// -------------------------
$link-color-v2:         #007ACC;
$link-color:            $link-color-v2;
$link-hover-color:      $link-color-v2;

// Typography
// -------------------------

$font-family-sans-serif:  'FSAlbert', sans-serif;
$font-family-serif:       'FSAlbert', sans-serif;
$font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-serif;

// Language Fonts Family
$font-family-base--chinese: 'HeiTASC', sans-serif;

$font-size-base:          1.6rem;
$font-size-large:         1.8rem;
$font-size-small:         1.4rem;
$font-size-smaller:       1.2rem;

$font-size-mobile:        1.4rem;

$font-weight-lighter:     100;
$font-weight-light:       300;
$font-weight-normal:      400;
$font-weight-bold:        600;
$font-weight-black:       700;
$font-weight-bolder:      900;

$font-weight-base:        $font-weight-light;

$h1-font-size:            5.0rem;
$h2-font-size:            4.0rem;
$h3-font-size:            3.2rem;
$h4-font-size:            2.4rem;
$h5-font-size:            2.0rem;
$h6-font-size:            $font-size-large;

// MOBILE
$h1-font-size-mobile:         2.8rem;
$h2-font-size-mobile:         2.4rem;
$h3-font-size-mobile:         2.0rem;
// TODO: update font-size h4 when we have complete design
$h4-font-size-mobile:         1.8rem;
$h5-font-size-mobile:         1.6rem;
$h6-font-size-mobile:         1.4rem;

$line-height-base:        1.375;
$line-height-computed:    floor($font-size-base * $line-height-base);

$headings-font-family:    $font-family-sans-serif;
$headings-font-weight:    $font-weight-light;
$headings-line-height:    1.33333;
$headings-color:          $gray-darker;

$border-radius:               .3rem;
$border-radius-lg:            .4rem;
$border-radius-sm:            .2rem;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.8rem;
$input-btn-padding-x:         1rem;
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
// $input-btn-line-height:       2;
$input-disabled-bg:           $gray-lighter;

$btn-box-shadow:              0 0 5px rgba($black, 0.2);
$banner-item-box-shadow:      rgba(0, 0, 0, 0.15) 5px 5px 20px, rgba(0, 0, 0, 0.1) 2px 6px 6px;
$banner-item-box-shadow-hover: rgba(0, 0, 0, 0.25) 7px 12px 17px, rgba(0, 0, 0, 0.2) 2px 4px 7px;

// Buttons
// -------------------------

// $btn-padding-y:               $input-btn-padding-y;
// $btn-padding-x:               $input-btn-padding-x;
$btn-padding-y:               6.5px;
$btn-padding-x:               13px;
$btn-padding-y-desktop:       7px;
$btn-padding-x-desktop:       32px;
$btn-font-family:             $input-btn-font-family;
$btn-font-size:               $font-size-small;
$btn-font-size-desktop:       $font-size-base;
// $btn-line-height:             2;
$btn-border-radius:           0;

$btn-padding-y-lg:            1.5rem;
$btn-padding-x-lg:            2.4rem;
$btn-font-size-lg:            $input-btn-font-size;
$btn-border-radius-lg:        0;

// button style
$btn-default-color:              $white;
$btn-default-bg:                 $text-color;
$btn-default-border:             $text-color;
$btn-default-color-hover:        $text-color;
$btn-default-bg-hover:           $white;
$btn-default-border-hover:       $white;
$btn-default-color-active:       $text-color;
$btn-default-bg-active:          rgba($btn-default-bg, 0.1);
$btn-default-border-active:      rgba($btn-default-bg, 0.1);

$btn-primary-color:              #fff;
$btn-primary-bg:                 $primary;
$btn-primary-border:             $primary;
$btn-primary-color-hover:        $primary;
$btn-primary-bg-hover:           $white;
$btn-primary-border-hover:       $white;
$btn-primary-color-active:       $primary;
$btn-primary-bg-active:          $os-orange-darker;
$btn-primary-border-active:      $os-orange-darker;

$btn-secondary-color:          #fff;
$btn-secondary-bg:               $secondary;
$btn-secondary-border:           $secondary;
$btn-secondary-color-hover:      $secondary;
$btn-secondary-bg-hover:         $white;
$btn-secondary-border-hover:     $white;
$btn-secondary-color-active:     $secondary;
$btn-secondary-bg-active:        rgba($btn-secondary-bg, 0.1);
$btn-secondary-border-active:    rgba($btn-secondary-bg, 0.1);


// Forms
// -------------------------

$input-padding-y:                $input-btn-padding-y;
$input-padding-x:                $input-btn-padding-x;
$input-bg:                       #fff;
$input-bg-disabled:              $gray-200;

$input-bg:                       #fff;
$input-bg-disabled:              $gray-200;

$input-color:                    $gray-darker;
$input-border:                   $border-color;
$input-border-radius:            0;
$input-border-focus:             darken($input-border, 15%);
$input-font-size:                $input-btn-font-size;

$input-focus-color:              $input-color;

$input-color-placeholder:        $gray;

$input-height:                   calc(#{$input-line-height * $input-font-size} + #{$input-padding-y * 2});
$input-group-addon-bg:           $gray-200;
$input-group-addon-border-color: $input-border;

$input-padding-y-lg:             1.6rem;
$input-padding-x-lg:             2.4rem;
$input-border-radius-lg:         0;
// $input-font-size-lg:             2rem;
$input-font-size-lg:             1.8rem;
$input-height-lg:                calc(#{$input-line-height * $input-font-size} + #{$input-padding-y-lg * 2});


// Paragraphs
//
// Style p element.
$paragraph-margin-bottom:   1.2rem;


// Grid system
// --------------------------------------------------

// Number of columns in the grid system
$grid-columns:              12;
// Padding, to be divided by two and applied to the left and right of all columns
$grid-gutter-width:         40px;


$grid-breakpoints: (
  xxs: 0,
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1680px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1100px,
  xxl: 1560px
);

// Container max width
// ------------------------------------------

// container iphone
$container-sm:      container-max-widths(sm);

// container ipad
$container-md:      container-max-widths(md);

// container laptop
$container-lg:      container-max-widths(lg);

// container xl desktop
$container-xl:      container-max-widths(xl);

// container xxl desktop
$container-xxl:      container-max-widths(xxl);


// CUSTOM
// INFO: z-index here
$HEADER_STICK_ZINDEX: 5;
$STRIP_ADS_STICK_ZINDEX: 2;
$POPUP_ZINDEX: 1000;
$PRODUCT_DETAIL_HEADER_ZINDEX: $HEADER_STICK_ZINDEX - 1;
$STEP_HEADER: -1;
$Z_INDEX_NORMAL: 1;
$MENU_NAV: 999;
$SUB_MENU_NAV: $MENU_NAV + 1;
// INFO: variables for pages here
// PAGE
//Header
$SUB_NAV_WIDTH: 390px;
$SUB_NAV_CONTENT_WIDTH: calc(100% - #{$SUB_NAV_WIDTH});

$HEADER_MOBILE_HEIGHT: 55px;
$HEADER_DESKTOP_HEIGHT: 72px;

//Client Portal
$CP_HEADER_HEIGHT: 70px;

$CP_DEMO_STATUS : 32px;