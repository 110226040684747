@import'@assets/reuse-mixin.scss';

.support-widget {
  &.my-account-section {
    border-bottom: none;
    padding-top: 32px;

    @include media-breakpoint-up(lg) {
      border: none;
      padding-top: 0;
    }
  }

  &__heading {
    font-weight: $font-weight-normal;
    font-size: $h5-font-size;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h3-font-size;
      margin-bottom: 24px;
    }
  }

  &__desc {
    font-size: $font-size-base;
    
    @include media-breakpoint-up(md) {
      padding-right: 10px;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  &__btn {
    margin-top: 32px;

    @include media-breakpoint-up(lg) {
      margin-top: 24px;
    }

    a.support-widget__btn__item {
      width: 280px;
      color: $primary;
      text-decoration: none;

      @include media-breakpoint-up(xl) {
        width: 320px;
      }
    }
  }
}