@import '../../../../assets/reuse-mixin.scss';

.carousel-detail-slide {
  &__image {
    display: block;
    position: relative;
    padding-bottom: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__contents {
    padding: 16px;

    @include media-breakpoint-up(lg) {
      padding: 24px 0 0;
    }
  }

  &__title {
    margin-bottom: 4px;
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    line-height: 1.33;
    letter-spacing: 0.9px;
    @include line-clamp(2);

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size;
      letter-spacing: 1.2px;
    }
  }

  &__text {
    margin: 0;
    color: $gray;
    font-size: $font-size-small;
    line-height: 1.43;
    letter-spacing: 0.7px;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
      line-height: 1.5;
      letter-spacing: 0.8px;
    }

    span {
      display: inline-block;
      
      & ~ span {
        margin-left: 8px;

        &::before {
          content: '|';
          margin-right: 8px;
        }
      }
    }
  }

  .video__contents__btn-play {
    margin: 0;
  }
}