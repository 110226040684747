@import '@assets/reuse-mixin.scss';

.member-event__detail {
  &-banner {
    max-width: 1520px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }

    &__wrapper {
      width: 100%;
      padding-bottom: calc(778 / 1520 * 100%);
      position: relative;

      &>img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &-body {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    &__location {
      margin-bottom: 56px;

      @include media-breakpoint-up(md) {
        margin-bottom: 72px;
      }

      .event {
        &-title {
          text-align: center;
          margin-top: 32px;
          margin-bottom: 0;

          @include media-breakpoint-up(md) {
            margin-top: 40px;
          }
        }

        &-location {
          text-align: center;
          margin-top: 56px;

          @include media-breakpoint-up(md) {
            margin-top: 72px;
          }

          .location {
            &__container {

              h5,
              p {
                color: $gray-900;
                font-size: 1.6rem;
                padding-bottom: 1.6rem;
                margin: 0;
              }

              h5 {
                font-weight: $font-weight-normal;
              }

              p:last-child {
                padding-bottom: 0;
              }
            }

            .location__swiper {
              &-container {
                position: relative;
              }

              .swiper-container {
                position: static;
                padding-bottom: 1px;
              }

              .swiper-container .swiper-button-prev,
              .swiper-container .swiper-button-next {
                color: $gray;
                display: none;

                @include media-breakpoint-up(lg) {
                  display: block;
                }

                &::after {
                  font-size: 25px;
                }
              }

              .swiper-container .swiper-button-next {
                left: 99%;
                right: unset;

                @include media-breakpoint-up(lg) {
                  margin-left: 12px;
                }

                @include media-breakpoint-up(xl) {
                  margin-left: 53px;
                }
              }

              .swiper-container .swiper-button-prev {
                right: 99%;
                left: unset;

                @include media-breakpoint-up(lg) {
                  margin-right: 12px;
                }

                @include media-breakpoint-up(xl) {
                  margin-right: 53px;
                }
              }

              .swiper-location {
                &__container {
                  border: 0.5px solid #d8d8d8;
                  text-align: left;
                  padding: 17px;
                  height: auto;

                  h5,
                  p {
                    color: $gray-900;
                    font-size: 1.6rem;
                    padding-bottom: 1.6rem;
                    margin: 0;
                  }

                  h5 {
                    font-weight: $font-weight-normal;
                  }

                  p:last-child {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__about {
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
      }

      .event-section__description {
        text-align: center;
        margin-bottom: 0;
        line-height: 1.6;
        color: $gray-900;
        font-size: 1.6rem;
        letter-spacing: 1.28px;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
          letter-spacing: 1.6px;
        }
      }
    }

    &__swiper {
      .event-banner__swiper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-container {
          max-width: 1024px;
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-container {
          position: static;
        }

        .swiper-container .swiper-button-prev,
        .swiper-container .swiper-button-next {
          color: $gray;
          display: none;

          @include media-breakpoint-up(lg) {
            display: block;
          }

          &::after {
            font-size: 25px;
          }
        }

        .swiper-container .swiper-button-next {
          left: 99%;
          right: unset;

          @include media-breakpoint-up(lg) {
            margin-left: 12px;
          }

          @include media-breakpoint-up(xl) {
            margin-left: 53px;
          }
        }

        .swiper-container .swiper-button-prev {
          right: 99%;
          left: unset;

          @include media-breakpoint-up(lg) {
            margin-right: 12px;
          }

          @include media-breakpoint-up(xl) {
            margin-right: 53px;
          }
        }

        .swiper-image {
          &__container {
            width: 100%;
            padding-bottom: calc(480 / 1024 * 100%);
            position: relative;

            &>img {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              object-fit: contain;
            }
          }
        }
      }
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 39px;

      @include media-breakpoint-up(md) {
        margin-top: 72px;
      }
    }

    &__registered {
      text-align: center;

      > p {
        text-align: center;
        margin-bottom: 0;
        line-height: 1.6;
        color: $gray-900;
        font-size: 1.6rem;
        letter-spacing: 1.28px;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
          letter-spacing: 1.6px;
        }
      }
    }
  }

  .event-section {
    &__title {
      color: $gray-darker;
      font-weight: $font-weight-normal;
      line-height: 4rem;
      margin-bottom: 24px;
      text-align: center;

      @include media-breakpoint-up(md) {
        margin-bottom: 32px;
      }
    }
  }

  .event-registration__start {
    text-align: center;
    color: $os-orange-dark;
    margin-bottom: 0;
    margin-top: 56px;

    @include media-breakpoint-up(md) {
      margin-top: 72px;
    }
  }
}