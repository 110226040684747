@import '@assets/reuse-mixin.scss';

.chancetowin-popup {
  
  &__list {
    padding: 0 50px;
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;

    @include scrollbar-custom();

    @include media-breakpoint-up(md) {
      padding: 0;
      padding-right: 16px;
      margin-right: -16px;
      max-height: 52vh;
    }

    .spin-coupon-item {
      margin-bottom: 24px;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 50%;

        &:nth-child(even) {
          padding-left: 12px;
        }

        &:nth-child(odd) {
          padding-right: 12px;
        }
      }
    }
  }
}