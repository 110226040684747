@import '@assets/reuse-mixin.scss';

.spin-win-banner {
  position: relative;
  color: $white;

  &__background {
    background-color: $black;
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 610/375 * 100%;

    @include media-breakpoint-up(md) {
      padding-bottom: 490/768 * 100%;
    }

    @include media-breakpoint-up(xxl) {
      padding-bottom: 800/1920 * 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
    }

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .container {
      height: 100%;
    }

    &__wrap {
      height: 100%;
      padding: 30px 4px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;

      @include media-breakpoint-up(md) {
        padding: 30px 0;
      }

      @include media-breakpoint-up(xl) {
        max-width: 570px;
      }
    }
  }
}