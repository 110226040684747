@import '../../../assets/reuse-mixin.scss';

.product-also-bought {
  color: $gray-darker;
  padding: 15px 0 40px;

  @include media-breakpoint-up(lg) {
    width: 50%;
    padding: 30px 0 70px;
    display: inline-block;
  }

  &__wrap {
    @include media-breakpoint-up(lg) {
      padding: 0 25px 0 calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/4});
    }

    @include media-breakpoint-up(xl) {
      padding: 0 25px 0 calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/4});
    }
    
    @include media-breakpoint-up(xxl) {
      padding: 0 30px 0 calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/4});
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 32px;
  }

  &__list {
    display: flex;

    &__item {
      margin-right: 10px;
      position: relative;

      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 50px;
      }

      @include media-breakpoint-up(xxl) {
        margin-right: 60px;
      }

      &:last-child {
        margin-right: 0;
      }

      &__image {
        width: 100%;
        margin-bottom: 24px;

        .product-also-bought-image {
          width: 100%;
        }
      }

      &__content {
        text-align: center;
      }
    }
  }
  &__info {
    &__name,
    &__selling-price {
      font-size: $font-size-large;

      @include media-breakpoint-up(xl) {
        font-size: $h3-font-size;
      }
    }

    &__name {
      color: $gray-darker;
      margin-bottom: 15px;
    }

    &__selling-price {
      margin-bottom: 5px;
    }

    &__fixed-price {
      text-decoration: line-through;
      color: $gray;
    }
  }

  //INFO: Customize swiper
  .swiper-container {
    margin: 0;
    padding-bottom: 25px;
  }

  .swiper-pagination-progressbar {
    top: auto;
    bottom: 0;
    height: 3px;
  }
}
