@import '@assets/reuse-mixin.scss';

.footer-navigation {
  padding: 24px 0 18px;
  color: $primary;

  @include media-breakpoint-up(lg) {
    padding: 45px 0;
    width: 80%;
  }

  &--mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0;
    margin-top: 18px;
    color: $gray-darker;

    &:first-child {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 0 25px;
    }

    &__text {
      text-transform: uppercase;
      font-weight: $font-weight-normal;
      font-size: $font-size-base;
      margin: 0;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
      }
    }

    &__icon {
      font-size: 1.6em;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__options {
    color: $gray-dark;

    &__item {
      display: block;
      padding: 9px 0;

      &:last-child {
        padding-bottom: 0;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $font-size-large;
      }
    }

    &__link {
      color: $gray-dark;

      &:hover {
        font-weight: $font-weight-normal;
        color: $gray-darker;
      }
    }
  }

  .collapse__icon--active {
    display: none;
  }

  .collapse-active {
    .collapse__icon--default {
      display: none;
    }

    .collapse__icon--active {
      display: block;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}
