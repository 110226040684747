@import "@assets/reuse-mixin";

.cp-product {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $gray-lighter;
  padding: 24px 0;

  &:last-child {
    border-bottom: 0;
  }

  &__left {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 146px;
    }
  }

  &__right {
    width: 100%;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(md) {
      width: calc(100% - 146px);
      padding: 14px 0 0 25px;
    }
  }

  &__image {
    padding-bottom: 100%;
    position: relative;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__name {
    color: $black;
    font-size: $font-size-large;
    line-height: 1.33;
    letter-spacing: 0.9px;

    > span {
      display: block;
    }
  }

  &__color {
    color: #7d7d7d;
    font-size: $font-size-small;
    line-height: 1.36;
    letter-spacing: 0.16px;
    margin: 16px 0 21px 0;

    & > span {
      letter-spacing: 0.7px;
      color: #313131;
    }
  }

  &__price {
    margin: 18px 0;
    color: $black;
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    line-height: 1.33;
    letter-spacing: 0.9px;

    .old-price {
      color: #7d7d7d;
      font-size: $font-size-base;
      line-height: 0.94;
      letter-spacing: 0.8px;
      margin-left: 16px;
      text-decoration: line-through;
    }
  }

  &__warranty {
    margin-bottom: 0;
    color: #7d7d7d;
    line-height: 1.06;
    letter-spacing: 0.8px;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
  }
}