@import '../../../assets/reuse-mixin.scss';

.product-specification {
  color: $gray-darker;
  padding: 24px 0;

  @include media-breakpoint-up(lg) {
    padding: 36px 0;
  }

  &__heading {
    text-align: center;
    margin-bottom: 12px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }

  &__image-list {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 45px;
    }

    &__item {
      text-align: center;
    }
  }

  &__info-list {
    margin: 10px 0 0;

    // @include media-breakpoint-up(lg) {
    //   margin-bottom: 60px;
    // }
  }

  &__collapse {
    border-bottom: 1px solid #ccc;

    &__title {
      padding: 10px 0;
      // font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        padding: 16px 0;
      }

      &__text {
        font-size: $font-size-base;
        // text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size;
        }
      }

      &__icon {
        font-size: 2.2rem;
        // padding: 3px;
        // border: 1px solid $gray;
        // border-radius: 50%;

        // @include media-breakpoint-up(lg) {
        //   border: none;
        // }

        &.icon--collapsed {
          display: none;
        }
      }
    }

    &__content {
      border-top: 0.2px solid #ccc;

      &__list {
        padding: 20px 0;

        @include media-breakpoint-up(lg) {
          padding: 40px 0;
        }
      }
      &__measurement {
        padding-top: 10px;
      }
    }

    &.collapse-active {
      .icon--nomal {
        display: none;
      }

      .icon--collapsed {
        display: block;
      }
    }
  }

  &__compare-btn {
    text-align: center;
    
    &__item {
      text-transform: uppercase;
    }
  }

  .info-content {
    &__item {
      margin-bottom: 15px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }

      &:nth-last-of-type(2) {
        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
      
      &__label {
        display: block;
        font-weight: bold;
        margin-bottom: 3px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 6px;
        }
      }

      &__value {
        // white-space: pre-line;
      }
    }
  }

  dl {
    margin-bottom: 0;
  }
}