@import '../../../assets/reuse-mixin.scss';

.category-landing {
  // padding-top: 30px;

  &__client-teling {
    background-color: $cp-background;
  }

  &--view {
    padding-top: 30px;

    @include media-breakpoint-up(md) {
      padding-top: 40px;
      display: flex;
      flex-wrap: wrap;
      // margin-left: -$grid-gutter-width/2;
      // margin-right: -$grid-gutter-width/2;
    }
    
    &__no-product {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    &.--cp-view {
      background-color: $white;
      padding-top: 0;

      .category-product {
        &__contents {
          border-bottom: 1px solid $gray-lighter;

          @include media-breakpoint-up(md) {
            margin: 0 32px;
          }
        }

        &__main {
          padding: 0 5px;

          @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0 0 0 24px;
          }

          @include media-breakpoint-up(lg) {
            margin: 0 0 0 56px;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        &:nth-child(odd) {
          .category-product__image {
            order: 0;
          }

          .category-product__main {
            padding: 0 5px;

            @include media-breakpoint-up(md) {
              padding: 0;
              margin: 0 0 0 20px;
            }
            @include media-breakpoint-up(lg) {
              margin: 0 0 0 56px;
            }
          }
        }

        &__list-tag,
        .heart {
          display: none;
        }

        .category-product__main {
          &__name,
          &__price {
            @include media-breakpoint-down(lg) {
              font-size: $font-size-large;
              letter-spacing: 0.9px;
              line-height: 1.39;
              font-weight: $font-weight-normal;
            }
          }

          &__name {
            @include media-breakpoint-down(lg) {
              margin-bottom: 20px;
            }
          }

          &__price {
            @include media-breakpoint-down(lg) {
              line-height: 1.06;
              color: $black;
              margin-bottom: 16px;
            }
          }

          &__old-price {
            @include media-breakpoint-down(lg) {
              font-size: $font-size-small;
              font-weight: $font-weight-normal;
              letter-spacing: 0.7px;
              line-height: 1.07;
              color: $cp-gray;
            }
          }

          &__sort-decs {
            @include media-breakpoint-down(lg) {
              font-size: $font-size-small;
              letter-spacing: 0.7px;
              line-height: 1.71;
              color: $cp-gray;
            }
          }

          // &__btn {
          //   display: none;
          // }
        }
      }

      .category-landing {
        &__product {
          padding-bottom: 20px;
          @include media-breakpoint-up(md) {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  &__product {
    margin-bottom: 20px;

    // @include media-breakpoint-up(md) {
    //   width: 50%;
    //   padding: 0 $grid-gutter-width/2;
    //   margin-bottom: 20px;
    // }

    // @include media-breakpoint-up(lg) {
    //   width: 33.3%;
    //   padding: 0 $grid-gutter-width/2;
    //   margin-bottom: 30px;
    //   background-color: $white;
    // }
  }

  &--list-view {
    .category-product {
      @include media-breakpoint-up(md) {
        width: 100%;
        margin-bottom: 0;

        &:nth-child(odd) {
          .category-product__status {
            right: 0;
            left: auto;
          }
          
          .category-product__image {
            order: 2;
          }

          .category-product__main {
            padding: 0 50px 0 0;
            margin: 0 auto 0 0;
          }
        }
      }

      &__wrap {
        max-width: 1340px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;

          &:hover {
            background-color: $white;
    
            .category-product__header__image__item {
              transform: scale(1);
            }
          }
        }
      }

      &__contents {
        @include media-breakpoint-up(md) {
          width: 100%;
          display: flex;
          align-items: center;
        }
      }

      &__image {
        position: relative;

        @include media-breakpoint-up(md) {
          width: 50%;
          max-width: 640px;
          margin-bottom: 0;
        }
      }

      &__main {
        @include media-breakpoint-up(md) {
          width: 50%;
          max-width: 550px;
          padding-left: 50px;
          margin-left: auto;
          text-align: left;
        }

        &__icon,
        &__btn {
          @include media-breakpoint-up(md) {
            opacity: 1;
            transform: translateY(0);
            transition: none;
          }
        }

        &__icon {
          @include media-breakpoint-up(md) {
            margin-left: 60px;
          }
        }

        &__btn {
          @include media-breakpoint-up(md) {
            padding-left: 0;
            // width: 75%;
          }
          
          @include media-breakpoint-up(lg) {
            flex-direction: row;
            margin-top: 32px;
          }

          &:has(.btn-book-experience) {
            @include media-breakpoint-up(lg) {
              flex-direction: column-reverse;
            }
            @include media-breakpoint-up(xl) {
              flex-direction: row;
              margin-top: 32px;
            }
          }

          .btn {
            @include media-breakpoint-up(lg) {
              margin: 0 20px 0 0;
    
              &:last-child {
                margin: 0;
              }
            }
          }
        }

        &__learn-more-btn {
          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }
    }

    .group-price-icon {
      @include media-breakpoint-up(md) {
        display: flex;
      }
    }
  }

  // osim view type
  .osim-view-type {
    display: flex;
    padding: 10px 0;
    display: none;

    &__button {
      background-color: #fff;
      border: none;
      width: 45px;
      outline: none;
      margin-right: 10px;
    }

    .activated {
      background-color: lightblue;
    }
  }

  //Product Action
  .product-action {
    margin-bottom: 30px;

    &__item {
      cursor: pointer;
    }
  }

  //Skeleton Style
  &--list-view {
    &.skeleton__item {
      .category-product__main {
        .text-line {
          margin-bottom: 15px;
        }
      }

      .category-product__image {
        .image-line {
          @include media-breakpoint-up(md) {
            @include skeleton($lines: 1, $line-height: 95%, $line-gap: 0);
            
            &:empty {
              width: 95%;
            }
          }

        }
      }
    }
  }

  .product-action {
    .image-wrap {
      width: 35px;
      height: 35px;
    }
  }
}
