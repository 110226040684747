@import '@assets/reuse-mixin.scss';

.appointment-booked {
  background: $gray-100;
  
  &__content {
    max-width: 556px;
    margin: 0px auto;
    text-align: center;
    padding: 112px 0px;

    @include media-breakpoint-up(md) {
      padding: 175px 0px 226px;
    }
    
    &__icon {
      width: 24px;
      height: 24px;
      margin-bottom: 6px;
      
      @include media-breakpoint-up(md) {
        width: 48px;
        height: 48px;
        margin-bottom: 9px;
      } 
    }

    &__title {
      color: $success;
      font-size: $h4-font-size-mobile;
      padding-bottom: 4px;
      margin: 0;
      
      @include media-breakpoint-up(md) {
        padding-bottom: 8px;
        font-size: $h2-font-size;
      }
    }
    
    &__code {
      color: $gray-900;
      font-size: $h6-font-size-mobile;
      display: block;
      padding-bottom: 24px;
      
      @include media-breakpoint-up(md) {
        padding-bottom: 40px;
        font-size: $h4-font-size;
      }
    }
    
    &__time {
      padding-bottom: 8px;
      @include media-breakpoint-up(md) {
        padding-bottom: 15px;
      }
      
      span {
        color: $gray-900;
        font-size: $h6-font-size-mobile;
        padding-right: 24px;
        
        @include media-breakpoint-up(md) {
          font-size: $h4-font-size;
        }

        &:last-child {
          padding-right: 0;
        }

        &:nth-child(n+2) {
          padding-left: 24px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            left: 0;
            background: $gray-900;
          }
        }
      }
    }

    &__address {
      padding-bottom: 9px;
      
      @include media-breakpoint-up(md) {
        padding-bottom: 25px;
      }

      &__title {
        margin: 0;
        padding-bottom: 4px;
        font-size: $h6-font-size-mobile;
        
        @include media-breakpoint-up(md) {
          padding-bottom: 8px;
          font-size: $h2-font-size-mobile;
        }
      }

      &__description {
        margin: 0;
        color: $gray-600;
        font-size: $h6-font-size-mobile;
        
        @include media-breakpoint-up(md) {
          font-size: $h4-font-size;
        }
      }
    }

    &__excerpts {
      padding-bottom: 8px;
      font-size: $font-size-smaller;
      color: $gray-600;
      margin: 0;
      
      @include media-breakpoint-up(md) {
        padding-bottom: 14px;
        font-size: $h5-font-size;
      }
    }

    &__text {
      padding-bottom: 16px;
      font-size: $h5-font-size-mobile;
      color: $gray-900;
      margin: 0;
      
      @include media-breakpoint-up(md) {
        padding-bottom: 32px;
        font-size: $h4-font-size;
      }
    }

    &__link-view {
      //color: $os-blue;
      color: $link-color;
      font-size: $h6-font-size-mobile;
      
      @include media-breakpoint-up(md) {
        font-size: $h4-font-size-mobile;
      }
    }
  }
}