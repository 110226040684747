@import '@assets/reuse-mixin.scss';

.warning-popup {
  .osim-popup__header {
    display: none;
  }

  &__content {
    background-color: $white;
    text-align: center;

    &__title {
      font-weight: $font-weight-normal;
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }
    }

    &__desc {
      color: $gray-darker;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }

    &__info {
      &__line {
        display: block;
      }
    }

    &__btn {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__item {
        margin-bottom: 18px;
        font-size: $font-size-small;
        width: fit-content;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}