@import '../../../../assets/reuse-mixin.scss';

.mobile-app {
  margin-bottom: 56px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 112px;
  }

  &__image {
    max-width: 80px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      max-width: 120px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 154px;
    }
  }

  &__link {
    display: block;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: 1.57;
    letter-spacing: 0.7px;
    margin: 20px 0 26px 0;

    @include media-breakpoint-up(md) {
      line-height: 1.75;
      letter-spacing: 0.8px;
      margin: 30px 0 35px 0;
    } 
  }

  .section-title {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 27px;
    }
  }
}

.list-app {
  max-width: 559px;
  margin: 0 auto 16px auto;

  .row {
    margin: 0 -8px;

    @include media-breakpoint-up(md) {
      margin: 0 -16px;
    }
  }

  .col-4 {
    padding: 0 8px;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      padding: 0 16px;
      margin-bottom: 32px;
    }

    a {
      display: block;
    }
  }

  img {
    display: block;
    width: 100%;
    max-width: 104px;

    @include media-breakpoint-up(md) {
      max-width: 165px;
    }
  }
}