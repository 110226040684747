@import '@assets/reuse-mixin.scss';

.product-item-view-all {
    display: flex;
    font-family: $font-family-serif;
    flex-wrap: wrap;
    border-bottom: 2px solid $gray-200;
    padding: 2rem 0;

    &:last-child{
        border: none;
    }

    @include media-breakpoint-up(sm) {
        display: block;
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }

    &__img {
        @include media-breakpoint-up(lg) {
            width: 40%;
            margin-left: 10%;
        }

        img {
            @include media-breakpoint-up(sm) {
                width: 100%;
                margin-bottom: 10px;
                overflow: hidden;
                display: block;
            }

            @include media-breakpoint-up(md) {
                width: 146px;
                height: 146px;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                width: 100%;
                height: 100%;
                margin-bottom: 10px;
                overflow: hidden;
                display: block;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        &-title,
        &-warranty {
            margin: 0;
            font-weight: $font-weight-normal;

            @include media-breakpoint-up(lg) {
                margin-left: 10%;
            }
        }

        &-color,
        &-warranty {
            color: $cp-gray;
        }

        &-title {
            font-size: $font-size-large;
            line-height: 2.4rem;
            letter-spacing: .9;
            font-weight: $font-weight-normal;
            color: $black;

            @include media-breakpoint-up(lg) {
                line-height: 4rem;
                font-size: 3.8rem;
            }
        }

        &-color {
            font-size: $font-size-small;
            line-height: 1.9rem;
            letter-spacing: .7;
            margin: 1.4rem 0;

            @include media-breakpoint-up(lg) {
                font-size: 2rem;
                margin: 2.4rem 0 2.4rem 10%;

            }

            span {
                padding-left: 5px;
                color: #313131;
                font-weight: $font-weight-normal;

                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                }
            }
        }

        &-price {
            font-size: $font-size-large;
            line-height: 2.4rem;
            letter-spacing: .9;
            margin: 1.4rem 0;
            color: $black;

            @include media-breakpoint-up(lg) {
                font-size: 2.4rem;
                margin: 3rem 0 3rem 10%;
            }

            s {
                padding-left: 5px;
                color: $cp-gray;
            }
        }

        &-warranty {
            font-size: $font-size-base;
            line-height: 17px;
            letter-spacing: .8;

            @include media-breakpoint-up(lg) {
                font-size: 2.4rem;
            }
        }

        &-addon {
            @extend .product-item-view-all__content-warranty
        }
    }
}