@import '@assets/reuse-mixin.scss';

.warranty-registration {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $gray-lighter;
    border-style: solid;
    border-width: 1px 0;
    padding-top: 40px;
    padding-bottom: 48px;
    @include media-breakpoint-up(md) {
      border-left-width: 1px;
      border-right-width: 1px;
    }
  }
}

.warranty-registration__form {
  max-width: 480px;
  width: 100%;

  &-header {
    &__text {
      font-size: $h5-font-size-mobile;
      color: $gray-darker;
      @include media-breakpoint-up(sm) {
        font-size: $h5-font-size;
      }
    }
  }

  &-select__item, &-input__item, &-date__item, &-submit__item {
    margin-top: 24px;
  }

  &-select__item {
    .customization-dropdown {
      .css-1wa3eu0-placeholder {
        color: $gray;
      }

      &__control {
        &.css-yk16xz-control {
          border: 1px solid $gray;
          height: 48px;
        }

        &--is-disabled {
          height: 48px;

          &.css-1fhf3k1-control {
            border: 1px solid $gray;
            border-radius: unset;
            background-color: $input-disabled-bg;
          }

          .css-g1d714-ValueContainer {
            .css-1wa3eu0-placeholder {
              color: $gray;
            }
          }

          .css-1hb7zxy-IndicatorsContainer {
            span.css-109onse-indicatorSeparator {
              width: 0;
            }
          }
        }

        &--menu-is-open, &--is-focused {
          height: 48px;
        }

      }

      &__value-container {
        padding-left: calc(2.4rem - 0.4rem);
      }
    }
  }

  &-input__item {
    &::placeholder {
      color: $gray;
      font-weight: $font-weight-normal;
    }

    .input-item {
      color: $gray-darker;
      padding: 0 24px;
      font-weight: $font-weight-normal;
      border: 1px solid $gray;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      height: 48px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.input-error-validate {
        border-color: $os-orange-dark;
      }
    }
  }

  &-link__item {
    margin-top: 16px;

    .link-text {
      font-weight: $font-weight-normal;
      color: $link-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-date__item {
    position: relative;

    .form-group__date__picker {
      &-container {
        margin-top: 12px;
        display: flex;
        gap: 8px;

        .customization-dropdown {
          flex: 1;

          .css-1wa3eu0-placeholder {
            color: $gray;
          }

          &__control {
            //height: calc(2.4rem + 1.6rem);
            height: 48px;

            &.css-yk16xz-control {
              border: 1px solid $gray;
            }
          }
        }
      }
    }

    .react-date-picker {
      width: 100%;
      height: 48px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }

      &__wrapper {
        border: 1px solid $gray;
        padding: 0 24px;
      }

      &__inputGroup {
        color: $gray-darker;
        font-weight: $font-weight-normal;
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;

        &__day, &__year {
          &::placeholder {
            color: $gray;
            font-weight: $font-weight-normal;
          }
        }

        &__month {
          max-width: 33px;
          padding: 0 5px;
          text-align: center;
          appearance: none;
          cursor: pointer;

          &[data-select="true"] {
            pointer-events: none;
            color: $gray;
            font-weight: $font-weight-normal;
          }
        }

        &__divider {
          display: none;
        }

        &__year {
          padding: 0;
        }
      }

      &__calendar-button {
        padding-right: 0;
        padding-left: 0;
      }

      &__clear-button {
        display: none;
      }
    }

    &.react-date-picker__active {
      .react-date-picker {
        &__inputGroup {
          &__month {
            &[data-select="true"] {
              color: $gray-darker;
              font-weight: $font-weight-normal;
              font-family: $font-family-sans-serif;
              font-size: $font-size-base;
            }
          }
        }
      }
    }
  }

  &-submit__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}