@import '@assets/reuse-mixin.scss';

.appointment-detail {
  // appointmant detail header
  &__header {
    position: relative;
    color: $gray-darker;

    &.text-white {
      color: $white;

      h1,
      h2 {
        color: inherit;
      }
    }

    &.text-align-left {
      @include media-breakpoint-up(md) {
        .content-wrapper {
          text-align: left !important;
          align-items: flex-start !important;
        }
      }
    }

    &.text-align-right {
      @include media-breakpoint-up(md) {
        .content-wrapper {
          text-align: right !important;
          align-items: flex-end !important;

          .btn-play-video {
            justify-content: flex-end;
          }

          .btn-link {
            margin: 12px 0 0 auto;

            @include media-breakpoint-up(xxl) {
              margin: 24px 0 0 auto;
            }
          }
        }
      }
    }

    &.text-align-center {
      @include media-breakpoint-up(md) {
        .content-wrapper {
          text-align: center !important;
          align-items: center !important;

          .btn-play-video {
            justify-content: center;
          }

          .btn-link {
            margin: 12px 0 0;

            @include media-breakpoint-up(xxl) {
              margin: 24px 0 0;
            }
          }
        }
      }
    }

    &.not-full-width {
      .content-wrapper {
        padding: 0 5px !important;

        @include media-breakpoint-up(md) {
          padding: 0 25px !important;
        }
      }
    }

    &__background {
      background-color: $black;
      position: relative;
      overflow: hidden;
      height: 0;
      padding-bottom: 666/375 * 100%;

      @include media-breakpoint-up(md) {
        padding-bottom: 800/1920 * 100%;
      }

      &--white {
        background: none;
      }

      img,
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        top: 50%;
        height: auto;
        transform: translate(0, -50%);

        @include media-breakpoint-up(md) {
          width: auto;
          height: 100%;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
        }

        & + .main-banner__background:after {
          content: "";
          background: $black;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &.no-ratio {
        background: none;
        height: auto;
        padding-bottom: 0;

        img,
        video {
          position: static;
          width: 100%;
          height: auto;
          transform: translate(0, 0)
        }
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &__container {
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(md) {
          align-items: center;
          padding-top: 50px;
          padding-bottom: 50px;
        }
      }

      &__wrap {
        max-width: 556px;
        margin: 0px auto;
        text-align: center;
        color: $white;

        &__icon {
          width: 24px;
          height: 24px;
          margin-bottom: 6px;

          @include media-breakpoint-up(lg) {
            width: 48px;
            height: 48px;
            margin-bottom: 8px;
          }
        }

        &__code {
          font-size: $h4-font-size-mobile;
          display: block;
          font-weight: $font-weight-normal;
          padding-bottom: 8px;

          @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
          }
        }

        &__campaign-uid {
          font-size: $h3-font-size-mobile;
          display: block;
          padding-bottom: 16px;
          font-weight: $font-weight-normal;

          @include media-breakpoint-up(md) {
            padding-bottom: 32px;
            font-size: calc(#{$h3-font-size} - 2px);
          }
        }

        &__time {
          padding-bottom: 16px;

          @include media-breakpoint-up(md) {
            padding-bottom: 32px;
          }

          span {
            padding-right: 16px;
            font-size: $h4-font-size-mobile;
            font-weight: $font-weight-normal;
            line-height: 1;

            @include media-breakpoint-up(md) {
              font-size: $h4-font-size;
              padding-right: 24px;
            }

            &:last-child {
              padding-right: 0;
            }

            & + span {
              border-left: 1px solid $white;
              padding-left: 16px;

              @include media-breakpoint-up(md) {
                padding-left: 24px;
              }
            }
          }
        }

        &__title {
          margin: 0;
          padding-bottom: 4px;
          font-size: $h4-font-size-mobile;
          font-weight: $font-weight-normal;
          color: $white;

          @include media-breakpoint-up(md) {
            padding-bottom: 8px;
            font-size: $h4-font-size;
          }
        }

        &__description {
          padding-bottom: 16px;

          @include media-breakpoint-up(md) {
            padding-bottom: 32px;
          }

          .item {
            margin: 0;
            font-size: calc(#{$h4-font-size-mobile} - 2px);
            font-weight: $font-weight-lighter;
            line-height: 1.1;

            @include media-breakpoint-up(md) {
              font-size: calc(#{$h4-font-size} - 2px);
            }
          }
        }

        &__btn-cancel {
          display: inline-block;
          margin-top: 20px;
        }

        &__cancel-modal {
          text-align: center;

          .form-cancel {
            padding-bottom: 24px;

            &__head {
              display: none;

              &.show {
                display: block;
              }

              &__ques {
                display: inline-block;
                margin-bottom: 48px;
              }

              &__btn-group {
                display: inline-flex;
                flex-direction: column;

                @include media-breakpoint-up(md) {
                  flex-direction: row;
                }

                &__button {
                  margin-bottom: 20px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  @include media-breakpoint-up(md) {
                    margin-right: 32px;
                    margin-bottom: 0;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }

            }

            &__list-reason {
              display: none;

              &.show {
                display: block;
              }

              &__title {
                text-align: center;
                display: inline-block;
                margin-bottom: 36px;
              }

              &__list {
                margin-bottom: 48px;

                &__item {
                  text-align: left;
                  display: block;

                  .form-control {
                    display: inline-block;
                    width: auto;
                    margin-left: 8px;
                  }

                  label {
                    vertical-align: middle;
                    cursor: pointer;

                    & + label {
                      margin-left: 12px;
                    }
                  }
                }
              }

              &__btn-group {
                display: inline-flex;
                flex-direction: column;

                @include media-breakpoint-up(md) {
                  flex-direction: row;
                }

                &__button {
                  margin-bottom: 20px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  @include media-breakpoint-up(md) {
                    margin-right: 32px;
                    margin-bottom: 0;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
            }

            &__error {
              color: $os-orange-dark;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }

  // Appointment detail body
  &__body {
    padding-top: 32px;

    @include media-breakpoint-up(md) {
      padding-top: 48px;
    }

    &__title {
      font-size: $h2-font-size-mobile;
      font-weight: $font-weight-normal;
      text-align: center;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
      }
    }

    &__item {
      padding-top: 32px;
      text-align: center;
      max-width: 754px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        padding-top: 64px;
      }

      &--contact-details {
        .appointment-detail__body__item__content__description {
          @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
          }
        }
      }

      &__title {
        font-size: $h4-font-size-mobile;
        color: $primary;
        padding-bottom: 16px;
        margin: 0;

        @include media-breakpoint-up(md) {
          font-size: $h4-font-size;
          padding-bottom: 20px;
        }
      }

      &__img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
        max-width: 249px;
        // max-width: 36px;
        // height: 36px;

        @include media-breakpoint-up(md) {
          // max-width: 69px;
          // height: 69px;
          margin-bottom: 40px;
          max-width: 371px;
        }

        &-wrapper {
          width: 100%;
          padding-bottom: 100%;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: contain;
          }
        }
      }

      &__content {
        &__title {
          font-size: $h5-font-size-mobile;
          color: $gray-darker;
          //font-weight: $font-weight-bold;
          //margin: 0 0 24px 0;
          font-weight: $font-weight-bold;
          padding-bottom: 16px;
          margin-bottom: 0;

          @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
            //margin: 0 0 32px 0;
            padding-bottom: 32px;
          }

          &.user-name {
            padding-bottom: 0;
          }
        }

        &__time {
          color: $gray-darker;
          font-size: $font-size-base;
          font-weight: $font-weight-bold;
          margin-bottom: 16px;

          @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
            margin-bottom: 32px;
          }

          span {
            display: inline-block;

            & + span {
              border-left: 1px solid $gray-dark;
              padding-left: 16px;
              margin-left: 16px;

              @include media-breakpoint-up(md) {
                padding-left: 24px;
                margin-left: 24px;
              }
            }
          }
        }

        &__name {
          margin: 0;
          font-size: $h5-font-size-mobile;
          font-weight: $font-weight-normal;

          @include media-breakpoint-up(lg) {
            font-size: $h4-font-size;
          }
        }

        &__description {
          font-size: $font-size-base;
          margin-bottom: 0;

          @include media-breakpoint-up(md) {
            font-size: $font-size-large;
          }
        }
      }

      &__box {
        padding-bottom: 16px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 40px;
        }

        &:last-child {
          padding-bottom: 0px;
        }
      }

      &__button {
        margin-top: 24px;
        color: $primary !important;

        @include media-breakpoint-up(lg) {
          margin-top: 32px;
        }

        &--m0 {
          margin: 0;
        }
      }
    }
  }
}
