@import '@assets/reuse-mixin.scss';

.appointments-widget {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    position: relative;

    &__time {
      padding-bottom: 4px;
      padding-right: 42.45px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 8px;
        padding-right: 50.58px;
      }

      span {
        font-size: $h6-font-size-mobile;
        color: $gray-1000;
        font-weight: $font-weight-normal;
        padding-right: 4px;
        border-right: 1px solid $gray-500;

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
          padding-right: 8px;
        }

        &:last-child {
          padding: 0;
          border-right: none;
        }

        &:nth-child(n+2) {
          padding-left: 4px;

          @include media-breakpoint-up(lg) {
            padding-left: 8px;
          }
        }
      }
    }

    &__title {
      font-weight: $font-weight-normal;
      // padding-bottom: 8px;
      //margin-bottom: 8px;
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        // padding-bottom: 16px;
      }
    }

    &__description {
      .item {
        margin: 0;
        color: $gray-900;
        font-size: $h6-font-size-mobile;
        //padding-bottom: 8px;
        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }

        //&:last-child {
        //  padding-bottom: 0px;
        //}
      }
    }

    &__btn-link {
      font-size: $h6-font-size-mobile;
      //color: $secondary;
      color: $link-color;
      font-weight: $font-weight-normal;
      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size-mobile;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}