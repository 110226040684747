@import '@assets/reuse-mixin.scss';

.popup-email-already {
  &__description {
    font-size: $font-size-base;
    padding: 75px 15px;
    color: $gray-darker;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      padding: 85px 12% 150px;
    }

    p {
      margin-bottom: 12px;
    }
  }

  &__action {
    text-align: center;
    padding: 24px 0;

    &__btn {
      display: block;
      margin: 0 auto 18px;

      @include media-breakpoint-up(lg) {
        margin: 0 auto 26px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--login {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }
  }
}