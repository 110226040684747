@import '@assets/reuse-mixin.scss';

.featured-tiles {
  background-color: $os-orange-lightest;
  color: $gray-darker;
  padding: 30px 0 20px;

  @include media-breakpoint-up(lg) {
    padding: 88px 0;
  }

  &__wrap {
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }

    @include media-breakpoint-up(xxl) {
      display: flex;
    }
  }

  &__heading {
    padding: 0 20px 24px 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 85%;
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 0 40px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 70px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      width: 33.3%;
    }

    &__title {
      margin-bottom: 12px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }

    &__desc {
      font-size: $font-size-base;
      margin-bottom: 24px;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        margin-bottom: 32px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }
    }

    &__btn-view-all {
      &__item {
        padding: 12px 54px;
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          padding: 12px 70px;
        }
      }
    }
  }

  &__list {
    @include media-breakpoint-up(xxl) {
      width: 75%;
    }
  }

  &__content {
    background: $white;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &:hover {
      .featured-tiles__content__image__item {
        transform: scale(1.2);
      }
    }

    &__image {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;

      &__link {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &__item {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s ease;
      }
    }

    &__text {
      padding: 16px 16px 24px;

      @include media-breakpoint-up(xl) {
        padding: 16px 16px 27px;
      }
    }

    &__title {
      color: $gray-darker;
      font-size: $font-size-large;
      height: 46px;
      margin-bottom: 42px;
      font-weight: $font-weight-normal;
      
      @include line-clamp(2);

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
        margin-bottom: 52px;
        height: 52px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size;
        height: 62px;
      }
    }

    &__info {
      &__sub-info {
        font-size: $font-size-base;
        color: $gray;
        display: inline;
      }

      p ~ p {
        margin-left: 5px;
        position: relative;

        &::before {
          content: '|';
          margin-right: 5px;
        }
      }
    }
  }

  &__link {
    color: $gray-darker;
  }

  .btn-view-all {
    min-width: 200px;

    @include media-breakpoint-up(lg) {
      min-width: 240px;
    }
  }

  .skeleton__item {
    &__container {
      display: flex;
    }

    .image-wrap {
      padding-bottom: 100%;
    }

    &.featured-tiles__list {
      display: flex;

      .text-line {
        @include skeleton($lines: 1, $line-height: 20px, $line-gap: 0);
      }
    }
  }
}
