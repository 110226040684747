@import '@assets/reuse-mixin.scss';

.help-me-decide {
  // heading
  &__heading {
    text-align: center;
    padding: 32px 0;

    @include media-breakpoint-up(lg) {
      padding: 48px 0;
    }

    &__title {
      color: $gray;
      font-size: $font-size-small;
      text-transform: uppercase;
      margin: 0 0 10px;
      font-weight: $font-weight-bold;
      letter-spacing: 0.3rem;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }

    &__description {
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }
  }

  // question
  &__question {
    border-bottom: 2px solid $gray-lighter;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 48px;
    }

    &__text {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }
  }

  // anser
  &__anser {
    display: flex;
    flex-wrap: wrap;
    max-width: 980px;
    margin: 0 auto 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 36px;
    }

    &--step2 {
      justify-content: center;
      padding-top: 20px;
    }

    &__item {
      width: calc(50% - 5px);
      display: flex;
      align-items: center;
      padding: 16px 24px;
      margin: 0 10px 10px 0;
      border: 1px solid $gray-light;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        width: calc(50% - 10px);
        min-height: 80px;
        margin: 0 20px 20px 0;
        font-size: $font-size-large;
      }

      &:nth-child(even) {
        margin-right: 0;
      }

      &--active {
        border: 1px solid $gray-darker;
        color: $gray-darker;
      }
    }
  }

  // summary
  &__summary {
    border-top: 2px solid $gray-lighter;
    padding: 48px 0 12px;

    &__item {
      max-width: 980px;
      margin: 0 auto 24px;

      // summary question 3
      &:last-child {
        .help-me-decide__summary__item__anser > * {
          display: block;

          &::after {
            content: ',';
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }

      &__question {
        font-size: $font-size-base;
      }

      &__anser {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        color: $gray-darker;

        > * {
          display: inline-block;
        }
      }
    }
  }

  // result
  &__result {
    border-top: 2px solid $gray-lighter;
    padding: 12px 0;
  }

  // button
  &__button {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0;
    border-top: 1px solid $gray-light;

    &__right {
      margin-left: auto;
    }

    &__left {
      margin-right: auto;
    }

    &--summary {
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: inherit;
      }

      .btn {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: auto;
        }
      }
    }
  }
}

// question 2
.question2-wrapper {
  position: relative;

  &--fullbody {
    .question2-wrapper__image__item {
      opacity: 0;

      &--full-body {
        opacity: 1;
      }
    }

    .question2-wrapper__item--active {
      .question2-wrapper__item__position {
        &::before {
          opacity: 0;
        }
      }
    }
  }

  &__image {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 200px;
      padding-top: 794px/200px*100%;
    }

    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &--full-body {
        opacity: 0;
      }
    }
  }

  &__item {
    position: absolute;
    left: 15px;
    top: 0;
    cursor: pointer;
    max-width: 100px;

    @include media-breakpoint-up(md) {
      left: -15px;
    }

    &--full-body {
      top: 0;
      left: 40%;
      
      .question2-wrapper__item__position {
        &::after {
          top: 0;
          left: 50%;
          width: 1px;
          height: 20px;
          border-bottom: none;
          border-left: 1px dashed $gray;
        }
      }

      .question2-wrapper__item__text {
        right: auto;
        text-align: center;
        transform: translate(-50%, 0);
        bottom: 100%;
      }
    }

    &--active {
      color: $secondary;
      font-weight: $font-weight-bold;

      .question2-wrapper__item__position {
        &::after {
          border-color: $secondary;
        }
      }
    }

    // left side
    &--upper-head {
      top: 4%;
    }

    &--lower-head {
      top: 12%;
      left: 20px;

      @include media-breakpoint-up(md) {
        left: -10px;
      }
    }

    &--neck {
      top: 15%;
      left: 27px;

      @include media-breakpoint-up(md) {
        left: -3px;
      }
    }

    &--shoulders,
    &--shoulder {
      top: 20%;
    }

    &--upper-back {
      top: 28%;
      left: 8px;

      @include media-breakpoint-up(md) {
        left: -22px;
      }
    }

    &--lower-back,
    &--lower-back---lumbar {
      top: 39%;
      left: 26px;

      @include media-breakpoint-up(md) {
        left: -4px;
      }
    }

    &--hip,
    &--hips {
      top: 46%;
      left: 42px;

      @include media-breakpoint-up(md) {
        left: 12px;
      }
    }
    
    &--butt {
      top: 50%;
    }

    &--thighs {
      top: 60%;
      left: 27px;

      @include media-breakpoint-up(md) {
        left: -3px;
      }
    }
    
    &--calves {
      top: 82%;
    }

    // right side
    &--hair,
    &--scalp,
    &--hair-scalp,
    &--hair---scalp,
    &--eyes,
    &--face,
    &--upper-arm,
    &--lower-arm,
    &--hand,
    &--waist,
    &--feet {
      .question2-wrapper__item__position {
        &::after {
          right: 0;
        }
      }

      .question2-wrapper__item__text {
        right: auto;
        left: 5px;
        text-align: left;
      }
    }

    &--hair-scalp,
    &--hair---scalp,
    &--hair,
    &--scalp {
      top: 2.5%;
      left: auto;
      right: 78px;

      @include media-breakpoint-up(md) {
        right: 48px;
      }
    }

    &--eyes {
      top: 8%;
      left: auto;
      right: 30px;

      @include media-breakpoint-up(md) {
        right: 0;
      }
    }

    &--face {
      top: 12%;
      left: auto;
      right: 44px;

      @include media-breakpoint-up(md) {
        right: 14px;
      }
    }

    &--upper-arm {
      top: 30%;
      left: auto;
      right: 50px;

      @include media-breakpoint-up(md) {
        right: 30px;
      }
    }

    &--waist {
      top: 39%;
      left: auto;
      right: 8px;

      @include media-breakpoint-up(md) {
        right: -22px;
      }
    }

    &--lower-arm {
      top: 43%;
      left: auto;
      right: 30px;

      @include media-breakpoint-up(md) {
        right: 0;
      }
    }

    &--hand {
      top: 52%;
      left: auto;
      right: -2px;

      @include media-breakpoint-up(md) {
        right: -32px;
      }
    }

    &--feet {
      top: 95%;
      left: auto;
      right: 0;

      @include media-breakpoint-up(md) {
        right: -30px;
      }
    }
    
    // Position item
    &__position {
      position: relative;

      // disable check icon
      // &::before {
      //   background: url('../../assets/images/ticked-icon.png') 0 0 no-repeat;
      //   background-size: cover;
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: 50%;
      //   left: 105px;
      //   width: 24px;
      //   height: 24px;
      //   transform: translateY(-50%);
      //   opacity: 0;
      //   z-index: 1;
      // }

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 40px;
        border-bottom: 1px dashed $gray;

        @include media-breakpoint-up(md) {
          width: 70px;
        }
      }
    }

    &__text {
      position: absolute;
      right: 5px;
      width: 126px;
      text-align: right;
      transform: translateY(-50%);

      @include media-breakpoint-up(md) {
        width: 160px;
      }
    }
  }
}