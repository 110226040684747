@import "@assets/reuse-mixin.scss";

.support-centre {
  &-box {
    border-color: $gray-light;
    border-style: solid;
    border-width: 1px 0;
    padding: 24px 0 26px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: 32px 40px 40px;
      border-width: 1px;
    }

    &__wrapper {
      max-width: 924px;
      width: 100%;
    }

    &.no-border-top-mobile {
      border-top-width: 0;
      @include media-breakpoint-up(md) {
        border-top-width: 1px;
      }
    }

    &.no-border-bottom-mobile {
      border-bottom-width: 0;
      @include media-breakpoint-up(md) {
        border-bottom-width: 1px;
      }
    }
  }

  &__title {
    text-align: center;
    font-size: $h2-font-size-mobile;
    font-weight: 500;
    line-height: 3rem;
    margin-bottom: 4px;
    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
      margin-bottom: 8px;
      line-height: 4rem;
    }
  }

  &__description {
    text-align: center;
    font-size: $h5-font-size-mobile;
    line-height: 2.2rem;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      font-size: $h5-font-size;
      line-height: 2.8rem;
    }
  }
}