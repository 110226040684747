@import '@assets/reuse-mixin.scss';

.three-column__banner {
  margin: 30px auto;

  &.container {
    padding: 0 30px;
    
    @include media-breakpoint-down(sm) {
      max-width: none;
    }

    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }
  }

  &__container {
    @include media-breakpoint-up(xl) {
      max-width: 1240px;
      margin: 0 auto;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -30px;

    @include media-breakpoint-up(md) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  &__box {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      padding-left: 10px;
      padding-right: 10px;
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% / 3);
    }

    &__wrapper {
      @include media-breakpoint-up(lg) {
        max-width: 420px;
      }
    }
  }

  &__item {
    display: block;
    position: relative;
    // border-radius: 12px;
    padding-bottom: calc(400 / 580 * 100%);
    // transition: all .2s linear;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      padding-bottom: calc(250 / 400 * 100%);
    }

    // &:hover {
    //   box-shadow: var(--box_shadow);
    //   transform: translateY(-3px);

    //   img {
    //     transform: scale(1.1);
    //   }
    // }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      // transition: all .2s linear;
    }
  }
}