@import "@assets/reuse-mixin.scss";

.cp-header-noti-icon {
  &.had-notification {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: $os-orange-dark;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}