@import '@assets/reuse-mixin.scss';

.store-header {
  margin-top: 24px;

  @include media-breakpoint-up(lg) {
    margin-top: 32px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 34px;
    }

    &__title {
      margin: 0;
    }

    &__desc {
      font-size: $font-size-base;
      margin-top: 8px;

      @include media-breakpoint-up(lg) {
        margin-top: 16px;
        font-size: $h5-font-size;
      }
    }
  }
}