@import "@assets/reuse-mixin.scss";

.featured-article {
  padding-top: 40px;
  padding-bottom: 10px;

  @include media-breakpoint-up(xl) {
    padding-top: 56px;
    padding-bottom: 18px;
  }

  &__title {
    margin-bottom: 18px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 24px;
    }
  }

  &__item {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }
  }

  &__button {
    margin-top: 12px;
    margin-bottom: 36px;

    @include media-breakpoint-up(xl) {
      margin-top: 24px;
      margin-bottom: 72px;
    }

    a {
      min-width: 200px;

      @include media-breakpoint-up(xl) {
        min-width: 240px;
      }
    }
  }
}
