@import '../../../assets/reuse-mixin';

.payment-detail {
  margin-bottom: 30px;
  padding: 18px 0;
  color: $gray-darker;

  &__item {
    margin-bottom: 18px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__label,
    &__value  {
      display: block;
    }

    &__label {
      font-size: $font-size-large;
      margin-bottom: 4px;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
        margin-bottom: 6px;
      }
    }

    &__value  {
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }

    &__title {
      margin-bottom: 4px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
      }
    }

    &__line {
      font-size: $font-size-base;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
        font-size: $font-size-large;
      }
    }

    &--summary {
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 18px;
      }
    }
  }

  &__total {
    padding-top: 8px;
    border-top: 1px solid $gray;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      padding-top: 18px;
    }
  }

  .text-regular {
    font-weight: $font-weight-normal;
  }
}