@import '@assets/reuse-mixin.scss';

//component richtext osim overview
.osim-overview {
  color: $gray-900;

  .osim-overview__wrap {
    max-width: 1040px;
    margin: 0 auto;
  }

  .osim-overview__des {
    margin: 0 0 30px;

    @include media-breakpoint-up(lg) {
      padding: 0 100px;
    }
  }

  .osim-overview__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 48px;

    &__title {
      font-size: $h2-font-size-mobile;
      margin: 24px 0 8px;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
        margin: 24px 0 16px;
      }
    }

    &__des {
      max-width: 200px;
      margin: 0 auto 8px auto;
    }
  }
}

//component richtext brand osim
.brand-osim-care {
  color: $gray-900;

  .brand-osim-care__wrap {
    text-align: center;
  }

  .brand-osim-care__heading {
    margin: 50px 0 18px;

    @include media-breakpoint-up(lg) {
      margin: 70px 0 18px;
    }
  }

  .brand-osim-care__des {
    font-size: $font-size-base;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      margin-bottom: 40px;
    }
  }

  .brand-osim-care__list {
    display: block;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      width: 100%;
      text-align: center;
      margin-bottom: 36px;

      @include media-breakpoint-up(md) {
        width: 80%;
        margin: 0 auto 36px;
      }

      @include media-breakpoint-up(lg) {
        width: 13%;
        text-align: left;
        margin: 0 0 36px;
      }
    }
  }
}