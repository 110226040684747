@import '@assets/reuse-mixin.scss';

.purchase-on-spot {
  padding: 40px 0px;

  &__content {
    max-width: 480px;
    margin: 0px auto;

    &__btn {
      width: 100%;
      font-size: $font-size-small;
    }
  }

  &__link-back {
    text-align: center;
     a {
      font-size: $font-size-small;
     }
  }
}