@import "@assets/reuse-mixin.scss";

.notification-popup {
  width: 100%;
  max-width: 500px;
  padding: 0;
  @include media-breakpoint-up(lg) {
    max-width: 600px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 25px;

    & > .title {
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
      margin-bottom: 0;
    }

    .read-all-button {
      background-color: transparent;
      color: $link-color;
      border: none;
      outline: none;
      font-size: $font-size-small;
    }
  }

  &__body {
    overflow-y: auto;
    max-height: 400px;

    &::-webkit-scrollbar {
      display: none;
    }

    .load-more-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 25px;

    &.notification-popup__item-read {
      background-color: $white;

      .notification-popup__item-title .noti-dot {
        visibility: hidden;
      }
    }

    &.notification-popup__item-unread {
      background-color: $os-orange-lightest;

      .notification-popup__item-title .noti-dot {
        display: block;
      }
    }

    &-title {
      display: flex;
      justify-content: start;
      align-items: center;
      flex: 1;

      .noti-dot {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: $primary;
        margin-right: 16px;
      }

      .title {
        &-icon {
          max-width: 40px;
          width: 100%;
          margin-right: 16px;

          &__wrapper {
            width: 100%;
            padding-bottom: 100%;
            position: relative;
          }

          &__image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: contain;
          }
        }

        &-text {
          .title {
            font-weight: $font-weight-normal;
            color: $gray-darker;
            font-size: $font-size-base;
          }

          .timestamp {
            font-size: $font-size-small;
            color: #5a5a5a;
          }
        }
      }
    }

    &-link {
      .view-detail-link {
        color: $link-color;
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
      }
    }
  }

  &__footer {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-mask {
    position: absolute;
  }
}