@import '@assets/reuse-mixin.scss';

.spin-coupon-info-popup{
    .cart-promotion-code-item {
        &__hint-text{
          font-size: 2rem;
          color: $gray-900;
        }

        &__code{
          font-size: 2.4rem;
          line-height: 0.6;
          font-weight: $font-weight-bold;
          word-wrap: anywhere;
          color: $black;
        }

        &__description{
          margin-top: 15px;
        }
      }
}