@import '@assets/reuse-mixin.scss';

.related-gift {
  position: relative;
  height: 0;
  padding-bottom: 329/375 * 100%;

  @include media-breakpoint-up(md) {
    padding-bottom: 380/768 * 100%;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 680/1920 * 100%;
  }

  @include media-breakpoint-up(xxl) {
    padding-bottom: 607/1920 * 100%;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 32px;

    @include media-breakpoint-up(lg) {
      // padding-top: 50px;
    }
  }

  &__title {
    text-align: center;
    max-width: 90%;
    margin: 0 auto 30px;
  }

  &__list {
    // Customize Swiper
    .swiper-container {
      @include media-breakpoint-up(xl) {
        padding: 0 32px;
        margin: 0 -32px
      }
    }
  
    .swiper-wrapper {
      position: static;
    }
  
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
      width: 44px;
      font-size: 2.0rem;
      color: $gray;
      transition: 0.5s;
      
      &:hover {
        color: $gray-darker;
      }
  
      &::after {
        font-size: $h4-font-size;
        font-weight: $font-weight-bold;
      }
  
      @include media-breakpoint-up(xl) {
        display: block;
      }
    }
  
    .swiper-button-next {
      right: -30px;
    }
  
    .swiper-button-prev {
      left: 0;
    }

    &__item {
      &__image {
        position: relative;
        margin-bottom: 4px;

        @include media-breakpoint-up(md) {
          margin-bottom: 12px;
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 24px;
        }

        &:before {
          content: '';
          display: block;
          padding-top: 100%;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 85%;
          object-fit: cover;
        }
      }

      &__name {
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}