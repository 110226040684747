@import '@assets/reuse-mixin.scss';

.balance-detail-modal {
  text-align: center;

  &__info {
    margin: 0;
    padding-bottom: 125px;
    font-size: $font-size-base;
    color: $gray-900;
    
    @include media-breakpoint-up(lg) {
      padding-bottom: 185px;
      font-size: $h5-font-size;
    }
  }

  &__button {
    padding-bottom: 18px;
    
    &:last-child {
      padding-bottom: 0px;
    }

    &__yes {
      min-width: 200px;
      
      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }
}