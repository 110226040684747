@import '@assets/reuse-mixin.scss';

.compareProductsForPDP {
  &__compare-btn {
    text-align: center;
    padding-bottom: 48px;
    padding-top: 48px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 74px;
      padding-top: 74px;
    }

    .btn {
      width: 200px;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        width: 240px;
      }
    }
  }
}