@import '@assets/reuse-mixin.scss';

.accordion {
  padding: 36px 0 0;

  @include media-breakpoint-up(lg) {
    padding: 40px 0 0;
  }

  &__heading {
    text-align: center;
    margin: 0;
    line-height: 1.2;
  }

  &__description {
    font-size: $font-size-base;
    margin-top: 12px;
    color: $gray-darker;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
      margin-top: 24px;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }

    // @include media-breakpoint-up(xxl) {
    //   padding: 0 10%;
    // }
  }

  .accordion-wrap {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
      width: 80%;
      max-width: 1260px;
      margin: 0 auto;
    }
  }

  &__info-list {
    margin: 24px -20px;
    padding: 24px;
    color: $gray-900;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    
    @include media-breakpoint-up(md) {
      border: none;
      margin: 32px 0 56px;
      padding: 0;
    }

    &__item {
      &:first-child {
        .accordion__collapse__title {
          margin-top: 0;
        }
      }
    }
  }

  &__wrap {
    p {
      margin-top: 8px;
      font-size: $font-size-small;

      @include media-breakpoint-up(md) {
        margin-top: 27px;
        font-size: $font-size-base;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
      }
    }
  }



  &__collapse {
    &__title {
      margin-top: 25px;
      // font-weight: bold;
      // display: flex;
      // align-items: flex-start;
      // justify-content: space-between;
      position: relative;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        margin-top: 50px;
      }

      &__text {
        font-size: $font-size-large;
        font-weight: 400;
        padding-right: 25px;
        display: inline-block;
        // text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size;
        }
      }

      &__icon {
        font-size: 2.2rem;
        // padding-top: 1px;
        transition: all 0.3s;
        position: absolute;
        right: 0;
        top: 1px;

        @include media-breakpoint-up(lg) {
          // padding-top: 4px;
          top: 4px;
        }

        @include media-breakpoint-up(xl) {
          // padding-top: 6px;
          top: 6px;
        }

        &.icon--collapsed {
          // display: none;
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__content {
      border-top: 0.2px solid #ccc;

      &__list {
        padding: 20px 0;

        @include media-breakpoint-up(lg) {
          padding: 40px 0;
        }
      }
    }

    

    &.collapse-active {
      .icon--nomal {
        display: none;
      }

      .icon--collapsed {
        display: block;
      }
    }
  }

  &__compare-btn {
    text-align: center;
    
    &__item {
      text-transform: uppercase;
    }
  }

  .info-content {
    &__item {
      margin-bottom: 15px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }

      &:last-child {
        margin-bottom: 0;
      }
      
      &__label {
        display: block;
        font-weight: bold;
        margin-bottom: 3px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 6px;
        }
      }
    }
  }

  &--wide {
    .accordion-wrap {
      @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: none;
      }
    }
  }

  &--has-border {
    .accordion {
      &__info-list {
        @include media-breakpoint-up(md) {
          border: 1px solid $gray-200;
          margin: 32px 0 56px;
          padding: 50px 70px;
        }
      }
    }
  }

  &--under-line {
    .accordion {
      &__collapse__title {
        padding-bottom: 5px;
        margin-top: 22px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 8px;
          margin-top: 24px;
        }
      }

      &__info-list {
        border-top: 0;
        border-bottom: 0;
        margin: 28px 0 56px 0;
        padding: 0;

        @include media-breakpoint-up(xl) {
          margin: 36px 0 72px 0;
        }

        &__item {
          border-bottom: 1px solid $gray-dark;
        }
      }

      &__wrap {
        p {
          margin: 24px 0 0 0;

          @include media-breakpoint-up(xl) {
            margin: 32px 0 0 0;
          }
        }
        
        .collapse {
          > p {
            margin: 24px 0;
   
            @include media-breakpoint-up(xl) {
               margin: 32px 0;
            }
          }

          .accordion__wrap__image,
          > .btn-link--readmore {
            margin: 0 0 24px 0;
            display: inline-block;
   
            @include media-breakpoint-up(xl) {
               margin: 0 0 32px 0;
            }
          }
        }
      }
    }
  }
}