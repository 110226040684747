@import '@assets/reuse-mixin.scss';

// Campaign Detail Listing
.campaign__listing {
  margin-bottom: 24px;

  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1000px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1340px;
    }
  }

  .campaign-item--horizontal {
    margin-bottom: 48px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .article__img {
    position: relative;
  }

  .article__content__link {
    margin-left: auto;

    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }

  .article__content__action {
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  .article__content__price--old {
    &.rental-deposit {
      text-decoration: none;
    }
  }

  &.--cp-view {
    background-color: $cp-background;
    margin-bottom: 0;

    .container {
      @include media-breakpoint-up(xl) {
        max-width: $container-xl;
      }
    
      @include media-breakpoint-up(xxl) {
        max-width: $container-xxl;
      }
    }

    .campaign__listing__group {
      background-color: $white;
    }

    .article {
      border-bottom: 1px solid $gray-lighter;
      margin-bottom: 0;
      padding: 20px;

      &:last-child {
        border-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        margin: 0 32px;
        padding: 20px 0;
      }

      &__image {
        @include media-breakpoint-up(md) {
          width: 50%;
        }
      }

      &__content {
        padding: 0;

        @include media-breakpoint-up(md) {
          width: 50%;
          padding-left: 20px;
        }

        @include media-breakpoint-up(lg) {
          padding-left: 56px;
        }



        &__title,
        &__price--main {
          @include media-breakpoint-down(lg) {
            font-size: $font-size-large;
            letter-spacing: 0.9px;
            line-height: 1.39;
            font-weight: $font-weight-normal;
          }
        }

        &__title {
          @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
          }
        }

        &__price--main {
          @include media-breakpoint-down(lg) {
            line-height: 1.06;
            color: $black;
            margin-bottom: 16px;
          }
        }

        &__price--old {
          @include media-breakpoint-down(lg) {
            font-size: $font-size-small;
            font-weight: $font-weight-normal;
            letter-spacing: 0.7px;
            line-height: 1.07;
            color: $cp-gray;
          }
        }

        &__description {
          @include media-breakpoint-down(lg) {
            font-size: $font-size-small;
            letter-spacing: 0.7px;
            line-height: 1.71;
            color: $cp-gray;
          }
        }

        //&__link,
        //&__action {
        //  display: none;
        //}
      }
    }
  }
}
