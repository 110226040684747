@import '@assets/reuse-mixin.scss';

.cp-connect-task {
  display: flex;
  justify-content: flex-end;

  & ~ .cp-search-bar {
    width: fit-content;
  }

  &__link {
    display: block;

    &.head-icon {
      margin-right: 0;
      width: 30px;
      height: 30px;
    }
  }
}