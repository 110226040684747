@import '@assets/reuse-mixin.scss';

.location-form {
  padding-bottom: 32px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 48px;
  }

  &__left,
  &__right {
    h2 {
      font-size: 1.8rem;
      line-height: .95;
      letter-spacing: .9px;
      color: $gray-900;
      padding: 24px 0 24px 0;
      margin: 0;
      text-align: center;

      @include media-breakpoint-up(lg) {
        font-size: 2.4rem;
        line-height: .83;
        letter-spacing: 1.92px;
        padding: 48px 0 24px 0;
        text-align: left;
      }
    }
  }

  &__left {
    &__list-store {
      text-align: left;
      max-height: 500px;
      overflow-y: auto;
      padding-right: 20px;

      @include media-breakpoint-up(lg) {
        max-height: 470px;
      }

      .store-item {
        cursor: pointer;
        margin-bottom: 10px;
        border: 1px solid $gray-500;
        padding: 16px 24px 24px;

        @include media-breakpoint-up(lg) {
          padding: 20px 32px 24px 48px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__name {
          font-size: 2rem;
          color: $gray-900;
          line-height: 1.4;
          letter-spacing: 1px;
          padding-bottom: 16px;

          p {
            margin: 0;
          }

          @include media-breakpoint-up(lg) {
            font-size: 2.4rem;
            line-height: 1.2;
            letter-spacing: 1.2px;
          }
        }

        &__address {
          display: flex;
          justify-content: space-between;
          gap: 28px;
          padding-bottom: 8px;

          @include media-breakpoint-up(lg) {
            padding-bottom: 16px;
            gap: 89px;
          }

          &__text {
            font-size: 1.4rem;
            line-height: 1.3;
            letter-spacing: .7px;

            @include media-breakpoint-up(lg) {
              font-size: 1.6rem;
              line-height: 1.5;
              letter-spacing: 1.28px;
            }
          }

          &__icon {
            .store-locator-icon {
              cursor: pointer;
              width: 16px;
              height: 20px;
              padding-left: 18px;

              @include media-breakpoint-up(lg) {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        &__code {
          padding-bottom: 8px;
          font-size: 1.4rem;
          line-height: 1.3;
          letter-spacing: .7px;

          @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: 1.28px;
            padding-bottom: 16px;
          }
        }

        &__phone {
          font-size: 1.4rem;
          line-height: 1.3;
          letter-spacing: .7px;

          @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: 1.28px;
          }
        }
      }

      .store-active {
        border: 1px solid $gray-900;
      }
    }
  }

  .store-item--activated--hovered {
    border: 1px solid $gray-900
  }

  .store-item--hovered {
    border: 1px solid $gray-900
  }

  &__right {
    .preferred-date {
      padding-bottom: 32px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 40px;
      }

      .form-group {
        margin: 0;
      }

      h2 {
        padding-top: 48px
      }

      .customization-dropdown__placeholder {
        font-size: 1.4rem;
        line-height: 2.5;
        letter-spacing: .7px;
        color: $gray-500 !important;

        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }
      }
    }

    .preferred-time-slot,
    .number-of-guest {
      padding-bottom: 32px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 40px;
      }

      h2 {
        padding-top: 0;
      }

      .radio-list {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(lg) {
          justify-content: start;
          gap: 16px;
        }

        .radio-item [type='radio'] {
          display: none;
        }

        .radio-item {
          label {
            min-width: 75px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            border: 1px solid $gray-500;
            cursor: pointer;
            font-size: 1.6rem;
            color: $gray-darker;
            padding: 10px 16px;
            line-height: 17px;
            @include media-breakpoint-up(lg) {
              padding: 12px 20px;
              font-size: 1.8rem;
              line-height: 21px;
            }
          }
        }
        .radio-item [type='radio']:disabled ~ label {
          opacity: .7;
          cursor: default;
        }

        .radio-item [type='radio']:checked ~ label {
          border-color: $gray-900;
          color: $gray-900;
        }

        .radio-item [type='radio']:checked ~ label:before {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        }
      }
    }

    .question-answer {
      padding-top: 32px;
      border-top: 1px solid $gray-300;

      @include media-breakpoint-up(lg) {
        padding-top: 40px;
      }

      h2 {
        padding-top: 0;
      }

      .qa-list {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        padding-bottom: 24px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 40px;
        }

        &:last-child {
          padding-bottom: 0;
        }

        h3 {
          width: 100%;
          display: block;
          text-align: left;
          font-size: 1.4rem;
          line-height: 1.2;
          letter-spacing: .7px;
          margin-bottom: 16px;

          @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
            line-height: 1.3;
            letter-spacing: 1.28px;
          }
        }

        .qa-item [type='radio'] {
          display: none;
        }

        .qa-item {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
          }

          label {
            position: relative;
            display: block;
            cursor: pointer;
            padding: 0px 30px;
            margin: 0;
            font-size: 1.4rem;
            line-height: 1.7;
            letter-spacing: 1.12px;
            color: $gray-700;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
              font-size: 1.6rem;
              line-height: 1.5;
              letter-spacing: 1.28px;
            }
          }
        }

        .qa-item label:after,
        .qa-item label:before {
          content: "";
          position: absolute;
          border-radius: 50%;
        }

        .qa-item label:before {
          background: $gray-900;
          height: 12px;
          width: 12px;
          left: 4px;
          top: calc(50% - 8px);
          opacity: 0;
          visibility: hidden;
        }

        .qa-item label:after {
          height: 20px;
          width: 20px;
          border: 1px solid $gray-900;
          left: 0;
          top: calc(50% - 12px);
        }

        // .qa-item [type='radio']:checked~label {
        //     color: $gray-800;
        // }

        .qa-item [type='radio']:checked ~ label:before {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        }

        // error massage
        .error-validate {
          width: 100%;
        }
      }
    }
  }


  // .form-group {
  //     .error-validate {
  //         text-align: left;
  //     }
  // }
  .customization-dropdown {
    &__option {
      &--is-disabled {
        color: $gray-300 !important;
        background-color: transparent !important;
        font-weight: $font-weight-base !important;
        opacity: .7;
        
        &:hover {
          background-color: transparent !important;
          color: $gray-300 !important;
          font-weight: $font-weight-base !important;
        }
      }
    }
  }
}
