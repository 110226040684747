@import '@assets/reuse-mixin.scss';

.modal-add-product {
  text-align: center;

  &__sub-title {
    color: $gray-900;
  }

  &__btn {
    margin-top: 48px;
  }
}