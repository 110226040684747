@import'../../../../../../assets/reuse-mixin.scss';

.cart-item-delivery {
  padding: 8px 0;
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  margin-top: -1px;

  @include media-breakpoint-up(lg) {
    padding: 16px 0;
  }
  
  &__header {
    display: flex;
    font-size: $font-size-large;
      
    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size;
    }

    &__icon {
      width: 30px;
      padding-right: 5px;
    }

    &__title {
      width: calc(100% - 30px);
      color: $gray-darker;
      font-weight: $font-weight-normal;
    }
  }

  &__option {
    padding-left: 30px;

    &__group {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 0;
    }

    &__text {
      padding-left: 10px;
    }

    label {
      margin: 0;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
    }

    .smaller-text {
      display: block;
      padding-left: 34px;
      font-size: $font-size-smaller;
      color: $gray-dark;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }

      &--inline {
        display: inline;
        padding-left: 5px;

        &:first-child {
          padding-left: 34px;
        }
      }

      &--underline {
        cursor: pointer;
        color: $secondary;
        text-decoration: underline;
      }

      &--sf-express {
        display: inline-block;
        padding-left: 34px;
      }
    }
  }

  .osim-radio {
    &-label {
      width: 24px;
      height: 24px;
      border: 1px solid $gray-darker;
  
      &::after {
        background-color: $gray-darker;
        width: 12px;
        height: 12px;
      }
    }

    input:checked ~ label {
      border-color: $gray-darker;
    }
    &.disable {
      .osim-radio-label {
        pointer-events: none;
        border-color: $gray-200;
        &::after {
          background-color: $gray-200;
        }
      }
    }
  }
}