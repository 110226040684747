@import '@assets/reuse-mixin.scss';

.custom-infomation-returning-customer {
    margin-top: 15px;
    &__action {
        display: flex;
        flex-direction: column;
    }
}

.returning-customer {
    .account-checking__form__button {
        @include media-breakpoint-up(md) {
            margin-top: 48px;
        }
    }
}