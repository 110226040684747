@import '@assets/reuse-mixin.scss';

.change-schedule-modal {
  position: relative;
  color: $gray-darker;

  &.text-white {
    color: $white;
  
    h1,
    h2 {
      color: inherit;
    }
  }

  &.text-align-left {
    @include media-breakpoint-up(lg) {
      .content-wrapper {
        text-align: left !important;
        align-items: flex-start !important;
      }
    }
  }

  &.text-align-right {
    @include media-breakpoint-up(lg) {
      .content-wrapper {
        text-align: right !important;
        align-items: flex-end !important;

        .btn-play-video {
          justify-content: flex-end;
        }

        .btn-link {
          margin: 12px 0 0 auto;
    
          @include media-breakpoint-up(xxl) {
            margin: 24px 0 0 auto;
          }
        }
      }
    }
  }

  &.text-align-center {
    @include media-breakpoint-up(lg) {
      .content-wrapper {
        text-align: center !important;
        align-items: center !important;

        .btn-play-video {
          justify-content: center;
        }

        .btn-link {
          margin: 12px 0 0;
    
          @include media-breakpoint-up(xxl) {
            margin: 24px 0 0;
          }
        }
      }
    }
  }
  
  &.not-full-width {
    .content-wrapper {
      padding: 0 5px !important;

      @include media-breakpoint-up(lg) {
        padding: 0 25px !important;
      }
    }
  }

  &__background {
    background-color: $black;
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: calc(666/375 * 100%);
    
    @include media-breakpoint-up(md) {
      padding-bottom: calc(1920/1920 * 100%);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($color: $black, $alpha: .3);
      z-index: 1;
    }

    &--white {
      background: none;
    }

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video {
      top: 50%;
      height: auto;
      transform: translate(0, -50%);

      @include media-breakpoint-up(lg) {
        width: auto;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }

      & + .main-banner__background:after {
        content: "";
        background: $black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.no-ratio {
      background: none;
      height: auto;
      padding-bottom: 0;

      img,
      video {
        position: static;
        width: 100%;
        height: auto;
        transform: translate(0,0)
      }
    }
  }
  
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    &__container {
      padding-top: 30px;
      padding-bottom: 30px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(lg) {
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
    
    &__wrap {
      max-width: 556px;
      margin: 0px auto;
      text-align: center;
      color: $white;

      &__icon {
        width: 24px;
        height: 24px;
        margin-bottom: 6px;

        @include media-breakpoint-up(lg) {
          width: 48px;
          height: 48px;
          margin-bottom: 8px;
        } 

        &--failed {
          width: 32px;
          height: 32px;
          margin-bottom: 32px;

          @include media-breakpoint-up(md) {
            width: 56px;
            height: 56px;
          } 
        }
      }
  
      &__title {
        color: $success;
        font-size: $h4-font-size-mobile;
        // padding-bottom: 4px;
        padding-bottom: 24px;
        margin: 0;
        
        @include media-breakpoint-up(md) {
          // padding-bottom: 8px;
          padding-bottom: 40px;
          font-size: $h2-font-size;
        }

        &--failed {
          color: $danger;
        }
      }

      &__uid {
        font-size: $h3-font-size-mobile;
        display: block;
        padding-bottom: 16px;
        font-weight: $font-weight-normal;
        
        @include media-breakpoint-up(md) {
          padding-bottom: 32px;
          font-size: calc(#{$h3-font-size} - 2px);
        }
      }
      
      // &__code {
      //   font-weight: $font-weight-normal;
      //   // color: $gray-900;
      //   font-size: $h6-font-size-mobile;
      //   display: block;
      //   padding-bottom: 24px;
        
      //   @include media-breakpoint-up(lg) {
      //     padding-bottom: 40px;
      //     font-size: $h4-font-size;
      //   }
      // }
      
      &__time {
        padding-bottom: 16px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 32px;
        }
        
        span {
          font-weight: $font-weight-normal;
          padding-right: 24px;
          font-size: $h6-font-size-mobile;
          
          @include media-breakpoint-up(lg) {
            font-size: $h4-font-size;
            padding-right: 48px;
          }
  
          &:last-child {
            padding-right: 0;
          }
        }
      }
  
      &__address {
        padding-bottom: 9px;
        
        @include media-breakpoint-up(lg) {
          padding-bottom: 24px;
        }
  
        &__title {
          font-weight: $font-weight-normal;
          margin: 0;
          padding-bottom: 4px;
          font-size: $h6-font-size-mobile;
          color: $white;
          
          @include media-breakpoint-up(lg) {
            padding-bottom: 8px;
            font-size: $h2-font-size-mobile;
          }
        }
  
        &__description {
          margin: 0;
          color: $gray-600;
          font-size: calc(#{$h4-font-size-mobile} - 2px);
          color: $white;
          line-height: 1.1;
          
          @include media-breakpoint-up(lg) {
            font-size: calc(#{$h4-font-size} - 2px);
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
  
      &__excerpts {
        padding-bottom: 8px;
        font-size: $font-size-smaller;
        margin: 0;
        
        @include media-breakpoint-up(md) {
          padding-bottom: 12px;
          font-size: $h5-font-size;
        }
      }
  
      &__text {
        font-weight: $font-weight-normal;
        font-size: $h5-font-size-mobile;
        margin: 0;
        
        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
        }
      }
      
      &__link-view {
        font-weight: $font-weight-normal;
        display: inline-block;
        margin-top: 16px;
        font-size: $h6-font-size-mobile;
        
        @include media-breakpoint-up(lg) {
          margin-top: 32px;
          font-size: $h4-font-size-mobile;
        }
      }
    }
  }
}