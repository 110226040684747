@import '../../../../assets/reuse-mixin.scss';

.feature-category {
  color: $gray-darker;
  background: $gray-lightest;
  padding: 35px 0;
  margin-bottom: 50px;

  @include media-breakpoint-up(lg) {
    padding: 42px 0;
  }

  &__heading {
    text-align: center;
    margin-bottom: 40px;
    
    @include media-breakpoint-up(xl) {
      margin-bottom: 45px;
    }
  }

  &__slide {
    padding-left: 33px;

    @include media-breakpoint-up(md) {
      padding: 0 calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/4});
    }

    @include media-breakpoint-up(lg) {
      padding: 0 calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/4});
    }

    @include media-breakpoint-up(xl) {
      padding: 0 calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/4});
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/4});
    }

    &__item {
      text-align: center;

      &__image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 25px;

        .feature-category-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__text {
        display: inline-block;
        font-size: $font-size-base;
        text-align: center;
      }
    }
  }
}