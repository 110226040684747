@import'@assets/reuse-mixin.scss';

.product-review-widget {
  &__content {
    &.my-account-section__content {
      margin-top: -8px;
      padding: 0 6px;
  
      @include media-breakpoint-up(lg) {
        margin-top: -22px;
        padding: 0 24px;
      }
  
      @include media-breakpoint-up(xl) {
        padding: 0 32px;
      }
  
      @include media-breakpoint-up(xxl) {
        padding: 0 68px;
      }
    }

    &__btn {
      margin-top: 24px;
    }
  }
}