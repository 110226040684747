@import "@assets/reuse-mixin.scss";
@import "@components/PersonalizedOffer/PersonalizedOfferWidget/PersonalizedOfferWidget.scss";

body {
  &:has(.personalized-offer-listing) {
    &:has([aria-label="personalized-offer-listing-busy-true"]) {
      overflow: hidden;
    }
  }
}

.personalized-offer-listing {
  &__wrapper {
    margin-top: 24px;
    @include media-breakpoint-up(md) {
      margin-top: 72px;
    }
  }

  &__item {
    padding-top: 16px;
    padding-bottom: 24px;
    border-color: $gray;
    border-style: solid;
    border-width: 1px 0 1px;

    &:not(:last-child) {
      border-bottom-width: 0;
    }

    @include media-breakpoint-up(md) {
      padding: 0;
      border-width: 0;
    }

    &:not(:last-child) {
      @include media-breakpoint-up(md) {
        margin-bottom: 90px;
      }
    }

    .personalize-offer-widget {
      &__content {
        .content-wrapper {
          .personalize-offer-widget__content-description {
            color: $gray-darker;
          }
        }
      }
    }

    & > .personalize-offer-widget__content-description.offer-from__text {
      margin-bottom: 16px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    & > .view-cart-button {

      margin: 32px auto 0;
      display: block;
      max-width: fit-content;
    }
  }

  &__lazy-loading {
    width: 100%;
    padding: 20px 0;
    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }

    & > .loading-mask {
      position: relative;
      width: 100%;
    }
  }
}
