@import "@assets/reuse-mixin.scss";

.payment-method {
  padding-top: 48px;

  &__heading {
    margin-bottom: 4px;

    .container {
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 25px;
      margin-right: 8px;

      @include media-breakpoint-up(lg) {
        width: 43px;
        margin-right: 16px;
      }
    }

    &__title {
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
      padding-left: 8px;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
        padding-left: 16px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size + 0.4;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h3-font-size;
      }
    }
  }

  &__content {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 24px 0 0;

    @include media-breakpoint-up(md) {
      border: none;
      padding: 0;
    }

    &__wrap {
      @include media-breakpoint-up(md) {
        border: 1px solid #e6e6e6;
        padding: 25px 30px;
      }

      @include media-breakpoint-up(lg) {
        padding: 30px;
      }

      @include media-breakpoint-up(xl) {
        padding: 40px 60px 32px;
      }
    }

    &__group {
      margin-bottom: 24px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      &__label {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        margin-bottom: 18px;

        @include media-breakpoint-up(md) {
          font-size: $font-size-large;
        }

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
          margin-bottom: 24px;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size;
          margin-bottom: 32px;
        }
      }

      &--pay-full {
        @include media-breakpoint-up(lg) {
          padding-right: 40px;
        }

        @include media-breakpoint-up(xxl) {
          padding-right: 100px;
        }
      }

      &--pay-installments {
        .payment-method__item {
          padding: 0;

          & + .payment-method__item {
            margin-top: 10px;
          }
        }

        .payment-method__content__group--installment-list {
          margin-top: 10px;

          @include media-breakpoint-up(lg) {
            margin-left: 20px;
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    margin-top: 17px;

    @include media-breakpoint-up(xl) {
      margin-top: 26px;
    }

    &:first-child {
      margin-top: 0;
    }

    &--have-card-power {
      position: relative;
      flex-wrap: wrap;

      .payment-method__item__image {
        // border-bottom: 1px solid #c5c4c4;

        &.none-border {
          border: none;
          padding-bottom: 0;
        }
      }

      .payment-method__item__title {
        width: 100%;
        padding-left: 34px;
        margin: 0;

        @include media-breakpoint-up(xl) {
          padding-left: 44px;
        }
      }
    }

    &__radio {
      margin-right: 10px;

      @include media-breakpoint-up(xl) {
        margin-right: 20px;
      }
    }

    &__image {
      padding-bottom: 10px;
      width: 60%;

      @include media-breakpoint-up(xl) {
        width: 65%;
      }

      @include media-breakpoint-up(xxl) {
        width: 75%;
      }

      img {
        display: inline-block;
        margin: 0 16px 8px 0;
        max-height: 30px;
        max-width: 100px;

        @include media-breakpoint-up(xs) {
          max-width: 150px;
        }

        @include media-breakpoint-up(xl) {
          max-width: inherit;
          max-height: 32px;
          margin: 0 33px 8px 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__card-text {
      position: absolute;
      right: 0;
      top: 10px;
      font-size: $font-size-smaller;
      background-color: $white;
      z-index: $Z_INDEX_NORMAL;
      padding-left: 15px;

      @include media-breakpoint-up(lg) {
        padding-left: 20px;
      }

      img {
        margin-left: 5px;
        max-width: 55px;
      }
    }

    &__card-text-line {
      position: absolute;
      right: 0;
      bottom: -5px;
      width: 100%;
      height: 1px;
      background: #c5c4c4;
    }

    &__title {
      display: block;
      margin-bottom: 8px;
      font-weight: $font-weight-normal;
      color: $gray-darker;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large; //INFO: Follow customer's comment
      }

      &--img {
        margin-bottom: 0;

        img {
          display: inline-block;
          margin: 0 16px 8px 0;
          max-width: 100px;
          max-height: 30px;
  
          @include media-breakpoint-up(xs) {
            max-width: 150px;
          }
  
          @include media-breakpoint-up(xl) {
            max-height: 32px;
            margin: 0 33px 8px 0;
          }
  
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__description {
      font-size: $font-size-smaller;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
      }

      .payment-link {
        text-decoration: underline;
      }
    }

    &__text__icon {
      width: 100%;

      .payment-icon {
          //width: 100%;
          max-height: 20px;
          //max-width: 54px;
          //object-fit: contain;

          &:not(:first-child) {
            margin-left: 12px;
          }
      }

      .payment-description {
        flex: 1;

        .payment-method__item__title span {
          margin-left: 16px;
        }
      }
    }
  }

  .warning-message {
    padding-right: 10px;
    color: red;
    font-weight: $font-weight-light;
    text-align: center;
    padding-top: 20px;

    font-size: $font-size-smaller;
    letter-spacing: 1.6px;

    @include media-breakpoint-up(md) {
      font-size: $font-size-small;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
    }
  }

  .term-link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.order-review-confirm {
  display: flex;
  flex-wrap: wrap;
  padding-top: 18px;
  padding-bottom: 18px;
  color: $gray-darker;

  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
  }

  &__container {
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &__heading {
    margin-bottom: 10px;
    padding-left: 0;
  }

  &__body {
    p {
      margin-bottom: 5px;
    }

    &__textarea {
      margin: 18px 0;
      padding: 10px 18px;
      width: 100%;
      height: 120px;
    }
  }
}

.hidden-form {
  visibility: hidden;
}

.bank-installment {
  // border-bottom: 1px solid #c5c4c4;
  padding-bottom: 10px;
  position: relative;

  &:last-child {
    padding-bottom: 0;
  }

  &__item {
    position: relative;
    margin-bottom: 34px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 44px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}