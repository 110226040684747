@import "@assets/reuse-mixin.scss";

.embedded-html {
  padding-top: 24px;
  padding-bottom: 24px;

  @include media-breakpoint-up(lg) {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  &__wrapper {
    width: 100%;
    transform: scale(1);

    .swiper-container {
      .swiper-button-next, .swiper-button-prev {
        color: $gray;
        position: fixed;
        width: fit-content;
        @include media-breakpoint-down(sm) {
          display: none;
        }

        &::after {
          font-size: 2rem;
          @include media-breakpoint-up(md) {
            font-size: 2.4rem;
          }
        }
      }

      .swiper-button-next {
        right: 0;
        @include media-breakpoint-up(md) {
          transform: translateX(24px);
        }
        @include media-breakpoint-up(lg) {
          transform: translateX(36px);
        }
      }

      .swiper-button-prev {
        left: 0;
        @include media-breakpoint-up(md) {
          transform: translateX(-24px);
        }
        @include media-breakpoint-up(lg) {
          transform: translateX(-36px);
        }
      }

      .swiper-pagination {
        position: relative;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &.align-center-swiper {
      .swiper-wrapper {
        justify-content: center;
      }
    }
  }

  &__item {
    width: 100%;
    // max-width: 450px;
    padding: 10px 0;

    &-wrapper {
      width: 100%;
      position: relative;
      padding-bottom: calc(600 / 400 * 100%);
      background: $gray-lightest;
      border-radius: 3px;

      & > .twitter-tweet {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      & > .instagram-media {
        box-shadow: unset !important;

        @include media-breakpoint-up(xl) {
          min-width: 250px !important;
        }
      }

      .twitter-tweet, .instagram-media {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
      }

      .tiktok-embed {
        margin: 0;
      }

      &__scroll {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        iframe {
          width: 100%;
        }

        &--tiktok {
          border: 1px solid $gray-light;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: start;
        }
      }

      .rsme-embed {
        position: absolute;
        left: 0;
        top: 0;
        background-color: $white;
        overflow: auto !important;

        &::-webkit-scrollbar {
          @include scrollbar-custom();
        }
      }
    }
  }
}
