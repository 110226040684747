@import '@assets/reuse-mixin.scss';

.resource-listing {
  &__title {
    text-align: center;
    text-transform: uppercase;
    color: $page-title;
    font-weight: $font-weight-bold;
    padding: 24px 0 16px 0;
    font-size: $font-size-small;
    letter-spacing: 2.52px;
  
    @include media-breakpoint-up(lg) {
      padding: 40px 0 24px 0;
      font-size: $font-size-base;
      letter-spacing: 2.88px;
    }
  }

  .results-item {
    border-top: 1px solid $gray-lighter;
    color: $gray-dark;
    line-height: 2.17;
    letter-spacing: 0.6px;
    font-size: $font-size-smaller;
    margin-bottom: 60px;
    padding-top: 8px;

    @include media-breakpoint-up(md) {
      line-height: 1.44;
      letter-spacing: 0.9px;
      font-size: $font-size-large;
      margin-bottom: 48px;
      padding-top: 25px;
    }
  }

  .searchbox--autocomplete--wrap {
    @include media-breakpoint-up(lg) {
      max-width: 1000px;
    }
  }

  .resource-search {
    max-width: 1000px;
    margin: 0 auto;
  }
}