@import '@assets/reuse-mixin.scss';

.add-to-sales-order-popup {
  text-align: center;
  
  &__message {
    font-size: $font-size-base;
    color: $gray-900;
    margin: 0;
    padding-bottom: 32px;
  }
  
  &__btn {
    font-size: $font-size-small;
    padding-bottom: 15px;
  }

  &__back {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    cursor: pointer;
  }
}