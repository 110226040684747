@import '@assets/reuse-mixin.scss';


.checkout-process-stepper {
  margin-top: 20px;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
  }

  &__title {
    text-align: center;
    font-size: $font-size-large;
    margin-bottom: 15px;
    color: $gray;
    font-weight: $font-weight-normal;
  }

  &__step {
    position: relative;
    flex: 1;

    &__line {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $gray;
        top: 16px;
        left: -50%;
        z-index: $STEP_HEADER;
      }
    }

    &__info {
      &__icon {
        margin: 12px 0 8px;

        img {
          height: 24px;
          width: auto;
        }
      }

      &__title {
        font-size: $font-size-small;
      }
    }

    .icon {
      background-color: white;
      padding: 10px;
      border: 1px solid lightgrey;
      border-radius: 100%;
    }

    .step-icon {
      width: 32px;
      height: 32px;
      background-color: white;
      border: 1px solid transparent;
      border-radius: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &__item {
        width: 12px;
        height: 12px;
        background-color: $gray;
        border-radius: 50%;
      }
    }

    &:first-child {
      .checkout-process-stepper__step__line {
        &:after {
          content: none;
        }

        &:before {
          content: none;
        }
      }
    }

    &.active,
    &.processing {
      .checkout-process-stepper__step__line {
        &:after {
          background-color: $os-blue;
        }
      }

      .checkout-process-stepper__step__info__title {
        color: $os-blue;
      }
    }

    &.active {
      .step-icon {
        background-color: $os-blue;
        border: 1px solid $os-blue;

        &__item {
          width: 9px;
          height: 13px;
          border: solid $white;
          border-width: 0 2.5px 2.5px 0;
          transform: rotate(45deg);
          border-radius: 0;
          background-color: transparent;
          margin-top: -5px;
        }
      }
    }

    &.processing {
      .step-icon {
        border: 1px solid $os-blue;

        &__item {
          background-color: $os-blue;
        }
      }
    }
  }
}