@import '../../../assets/reuse-mixin.scss';

.freegift-buying {
  // padding: 16px 0 32px;
  // padding-bottom: 32px;

  // @include media-breakpoint-up(lg) {
  //   // padding: 16px 0 22px;
  //   padding-bottom: 24px;
  // }

  &-list {
    &__item {
      margin-bottom: 15px;

      .item {
        &__variant {
          &:hover {
            cursor: pointer;
          }

          .item__variant__image {
            img {
              max-width: 100%;
            }
          }

          &.disable {
            opacity: .4;
            pointer-events: none;
          }
        }
      }
    }
  }

  .accordion-buying-option {
    border-bottom: 0;
  }

  //Custom Swiper 
  .swiper-container {
    width: auto;

    @include media-breakpoint-up(lg) {
      padding: 0 20px;
      margin: 0 -20px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 30px;
      margin: 0 -30px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 50px;
      margin: 0 -50px;
    }

    &:before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 2;
      background: $white;

      @include media-breakpoint-up(lg) {
        width: 20px;
      }

      @include media-breakpoint-up(xl) {
        width: 30px;
      }

      @include media-breakpoint-up(xxl) {
        width: 50px;
      }
    }

    &::after {
      left: auto;
      right: 0;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    width: 44px;
    font-size: 2.0rem;
    color: $gray;
    transition: 0.5s;
    outline: none;

    &:hover {
      color: $gray-darker;
    }

    &::after {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .swiper-button-next {
    right: -30px;
  }

  .swiper-button-prev {
    left: 2px;
  }
}