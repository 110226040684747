@import '@assets/reuse-mixin.scss';

.offer-widget {
  @include media-breakpoint-up(xl) {
    padding-bottom: 38px;
  }

  &__content {
    &.my-account-section__content {
      padding: 0;
    }

    &__list {
      &--mobile {
        @include media-breakpoint-up(xl) {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include media-breakpoint-up(xl) {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
    &__item {
      &.coupon-popup__item {
        padding: 0;
        margin-bottom: 0;
  
        @include media-breakpoint-up(xl) {
          margin-bottom: 24px;
        }
  
        &:nth-child(odd) {
          @include media-breakpoint-up(xl) {
            padding-right: 6px;
          }
  
          @include media-breakpoint-up(xxl) {
            padding-right: 15px;
          }
        }
  
        &:nth-child(even) {
          @include media-breakpoint-up(xl) {
            padding-left: 6px;
          }
  
          @include media-breakpoint-up(xxl) {
            padding-left: 15px;
          }
        }
      }
    }
  }

  .swiper-slide {
    height: unset;
  }
}

.offer-widget__content__item {
  .cart-promotion-code-item {
    height: 100%;

    &__group {
      padding-right: 40px;
      margin-bottom: 4px;
    }

    &__name {
      padding-right: 8px;
      margin: 0 0 15px 0;
      display: block;
    }

    &__code {
      display: inline-block;
      font-size: $h5-font-size;
      font-weight: $font-weight-bold;
      color: $black;
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }
    }

    &__hint-text {
      display: inline-block;
      margin-bottom: 0;
      padding-right: 5px;
      font-size: $font-size-base;

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }
    }
  }
}

.offer-widget-modal {
  &__content {
    max-height: 50vh;
    overflow-y: auto;
    margin-right: -10px;
    padding: 10px 10px 5px 0;

    @include scrollbar-custom();

    @include media-breakpoint-up(md) {
      max-height: 55vh;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__btn {
    text-align: center;
    padding-top: 24px;
  }

  .offer-widget {
    &__content {
      &__item {
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
          padding: 0 12px;
        }
      }
    }
  }

  .error-validate {
    color: $primary;
    margin: 8px 0 0;

    @include media-breakpoint-up(md) {
      padding: 0 12px;
    }
  }

  .cart-promotion-code-item  {
    border-width: 2px;
    &:focus,
    &:active,
    &:hover,
    &.selected {
      border-color: $gray-dark;
      border-left-color: unset;
    }

    &.selected {
      border-width: 2px;
      border-color: $black;
    }
  }
}