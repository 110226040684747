@import '@assets/reuse-mixin.scss';

.view-my-prizes {
  width: 100%;
  padding-top: 22px;

  &__loading {
    width: 10%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}