@import '@assets/reuse-mixin.scss';

.myprizes-popup {
  margin-bottom: -24px;

  &__list {
    padding: 0 50px;
    max-height: 55vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;

    @include scrollbar-custom();

    @include media-breakpoint-up(md) {
      padding: 0;
      padding-right: 16px;
      margin-right: -16px;
      max-height: 48vh;
    }

    .spin-coupon-item {
      margin-bottom: 24px;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 50%;

        &:nth-child(even) {
          padding-left: 12px;
          padding-right: 12px;
        }

        &:nth-child(odd) {
          padding-left: 12px;
          padding-right: 12px;
        }
      }

      .cart-promotion-code-item {
        border-width: 2px;
        &:focus,
        &:active,
        &:hover,
        &.selected {
          border-color: $gray-dark;
          border-left-color: unset ;
        }
    
        &.selected {
          border-width: 2px;
          border-color: $black;
        }

        &__hint-text {
          font-size: 2rem;
          color: $gray-900;
        }

        &__code {
          display: inline-block;
          font-size: 2.4rem;
          line-height: 1;
          font-weight: $font-weight-bold;
          word-wrap: anywhere;
          color: $black;
        }

        &__description {
          margin-top: 15px;
        }
      }
    }
  }

  &__btn {
    padding-top: 24px;

    @include media-breakpoint-up(md) {
      padding-bottom: 24px;
    }

    .btn {
      min-width: 200px;

      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }
}