@import '@assets/reuse-mixin.scss';

.why-choose-osim {
  padding: 48px 0;

  @include media-breakpoint-up(lg) {
    padding: 40px 0 72px;
  }

  &__title {
    margin-bottom: 36px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }

  &__content {
    &__item {
      text-align: center;

      &__icon {
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 32px;
        }

        img {
          max-width: 20px;
          
          @include media-breakpoint-up(md) {
            max-width: 55px;
          }
        }
      }

      &__text {
        color: $gray-900;
        display: flex;
        justify-content: center;
        font-size: 1.6rem;

        @include media-breakpoint-up(lg) {
          font-size: 2.4rem;
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          
          @include media-breakpoint-up(md) {
            min-height: 55px;
          }
        }
      }
    }
  }
}