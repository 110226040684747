@import '@assets/reuse-mixin.scss';

.resource-search-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 48px;
  }

  &__image-wrap {
    width: 120px;

    @include media-breakpoint-up(md) {
      width: 200px;
    }

    @include media-breakpoint-up(lg) {
      width: 300px;
    }
  }

  &__image {
    display: block;
    position: relative;
    padding-bottom: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video__contents__btn-play {
      margin: 0;
      width: 56px;
      height: 56px;

      @include media-breakpoint-up(lg) {
        width: 64px;
        height: 64px;
      }
    }
  }

  &__content {
    width: calc(100% - 120px);
    padding-left: 12px;
    color: $gray-darker;

    @include media-breakpoint-up(md) {
      width: calc(100% - 200px);
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% - 300px);
      padding-left: 90px;
    }

    &__title {
      font-size: $h4-font-size;
      color: $gray-darker;
      margin-bottom: 5px;
      line-height: 1.17;
      letter-spacing: 1.2px;

      @include media-breakpoint-up(lg) {
        font-size: $h2-font-size;
        font-weight: $font-weight-normal;
        line-height: 1.15;
        letter-spacing: 2px;
      }
    }

    &__description {
      font-size: $font-size-base;
      line-height: 1.5;
      letter-spacing: 0.8px;
      font-weight: $font-weight-normal;
      margin-bottom: 18px;
      @include line-clamp(2);

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
        line-height: 1.17;
        letter-spacing: 1.2px;
        margin-bottom: 24px;
      }
    }

    &__link {
      font-size: $font-size-small;
      letter-spacing: 2.24px;
      font-weight: $font-weight-bold;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
        letter-spacing: 2.56px;
      }
    }
  }
}