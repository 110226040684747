@import "@assets/reuse-mixin.scss";

.product-deals {
  padding: 24px 0;
    
  @include media-breakpoint-up(lg) {
    padding: 36px 0;
  }

  &__title {
    margin-bottom: 6px;
    text-align: center;

		@include media-breakpoint-up(xl) {
			margin-bottom: 25px;
		}
  }

  &__description {
    margin: 0 auto 30px;
    color: $black;
    text-align: center;
    font-size: $font-size-base;

    @include media-breakpoint-up(sm) {
      max-width: 80%;
		}

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      font-size: $font-size-large;
		}

    @include media-breakpoint-up(xl) {
      margin: 0 auto 32px;
      font-size: $h5-font-size;
		}
  }

  &__list {
    position: relative;
    // margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;

    @include media-breakpoint-up(md) {
      // margin-left: 0;
      margin-right: 0;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;

      &__image {
        display: block;
        padding-top: 100%;
        position: relative;

        .product-deals-image {
          width: 100%;
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }

      &__name {
        font-size: $font-size-large;
        margin-bottom: 10px;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size;
        }

        &-link {
          color: $gray-darker
        }
      }

      &__price {
        font-weight: bold;
        margin-bottom: 5px;
      }

      &__buy-btn {
        margin-top: 20px;
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(lg) {
          margin-top: 32px;
        }

        // .buy-btn__item {
        //   border-radius: 24px;
        //   display: inline-block;
        //   text-align: center;
        //   padding: 8px 30px;
        //   width: 100%;
        //   background-color: #1E7BE2;
        //   color: #fff;
        // }

        .btn {
          width: 240px;
          max-width: 100%;
        }
      }
    }
  }
  
  //INFO: Customize swiper
  .swiper-container {
    position: static;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    width: 44px;
    font-size: 20px;
    color: $gray;
    border-radius: 50%;
    transition: 0.5s;
    position: absolute;
    z-index: 2;
    
    &:hover {
      color: $gray-darker;
    }

    @include media-breakpoint-up(xl) {
      display: flex;
    }

    &::after {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }

    &.swiper-button-disabled {
      // opacity: 0;
      display: none;
    }
  }

  .swiper-button-next {
    right: -60px;
  }

  .swiper-button-prev {
    left: -60px;
  }
}