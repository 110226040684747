@import '@assets/reuse-mixin.scss';

.compare-product {
  .customization-dropdown {
    &__menu {
      &-list {
        .customization-dropdown__option {
          text-align: left;
        }
      }
    }
  }

  &__select-category {
    width: 280px;
    margin: 0 auto 48px;

    @include media-breakpoint-up(md) {
      margin: 0 auto 72px;
      width: 320px;
    }

    & > .form-group {
      .customization-dropdown {
        max-width: 320px;
        margin: 0 auto;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    justify-items: center;
    grid-column-gap: 18px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    & > .form-group {
      width: 100%;
      max-width: 320px;
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 104px;
      }

      .customization-dropdown__indicator-separator {
        background-color: unset;
      }

      .customization-dropdown__control--is-disabled {
        border-radius: unset;

        .customization-dropdown__placeholder {
          color: $gray-500;
        }

        .customization-dropdown__indicator {
          .css-6q0nyr-Svg {
            color: $gray-500;
          }
        }
      }

      .customization-dropdown__placeholder {
        white-space: nowrap;
      }
    }
  }

  &__item {
    width: 100%;
    max-width: 163px;

    @include media-breakpoint-up(sm) {
      max-width: 320px;
    }

    &-select-product {
      margin-bottom: 28px;
      position: relative;

      &.freeze-on-top {
        position: sticky;
        z-index: 4;
        background-color: $white;

        &:after {
          content: '';
          width: calc(calc(100vw / 3) + 18px);
          height: 100%;
          position: absolute;
          top: 0;
          z-index: -1;
          left: 0;
          background-color: $white;
        }

        .form-group {
          margin-bottom: 0;
        }
      }

      & > .form-group {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;

        .customization-dropdown__indicator-separator {
          background-color: unset;
        }

        .customization-dropdown__control--is-disabled {
          border-radius: unset;

          .customization-dropdown__placeholder {
            color: $gray-500;
          }

          .customization-dropdown__indicator {
            .css-6q0nyr-Svg {
              color: $gray-500;
            }
          }
        }

        .customization-dropdown__placeholder {
          white-space: nowrap;
        }
      }
    }


    &-header {
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        margin-bottom: 72px;
      }

      .product-preview {
        display: flex;
        justify-content: center;
        align-items: center;

        .compare-products-item {
          position: relative;
          width: 100%;
          max-width: 320px;

          &__img {
            width: 100%;
            overflow: hidden;
            padding-bottom: 100%;
            position: relative;
            display: block;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &__variant {
            max-width: 163px;
            min-height: 74px;
            margin: 0 auto;
            cursor: pointer;

            @include media-breakpoint-up(md) {
              min-height: 90px;
            }

            @include media-breakpoint-up(lg) {
              min-height: 119px;
              max-width: 210px;
            }

            @include media-breakpoint-up(xl) {
              max-width: 65%;
            }

            .swiper-slide {

              @include media-breakpoint-up(lg) {
                width: 70px !important;
              }

              @include media-breakpoint-up(xl) {
                max-width: 80px;
              }
            }
          }

          .variant__item {
            img {
              width: 50px;
              transition: all .3s;

              @include media-breakpoint-up(md) {
                width: 80px;
              }
            }

            p {
              opacity: 0;
              transition: all .3s;
              text-align: center;
              margin: 0;
              font-size: 1.2rem;

              @include media-breakpoint-up(lg) {
                font-size: 1.6rem;
              }

              @include media-breakpoint-up(xl) {
                text-align: left;
                padding-left: 15px;
              }
            }

            &:hover,
            &.is-active {
              img {
                transform: scale(1.27);
              }

              p {
                opacity: 1;
              }
            }
          }

          &__remove-btn {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;

            i {
              font-size: 1.2rem;
              color: $gray-700;

              &:hover {
                color: $gray-900;
              }

              @include media-breakpoint-up(lg) {
                font-size: 1.6rem;
              }
            }
          }

          &__info {
            text-align: center;
            color: $gray-900;
            font-weight: $font-weight-lighter;

            p {
              margin: 0;
              font-size: 1.6rem;
              font-weight: $font-weight-bold;
            }

            del {
              font-size: 1.2rem;
            }

            &-name {
              padding-bottom: 18px;
              font-weight: $font-weight-normal;
              color: $mine-shaft;
              text-align: center;
              @include line-clamp(2);

              @include media-breakpoint-up(lg) {
                font-size: 2.4rem;
              }
            }

            .info-selling-price {
              font-weight: $font-weight-normal;
              font-size: 1.6rem;

              @include media-breakpoint-up(lg) {
                font-size: 2.4rem;
              }

              &.product-card-price__member {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            .info-list-price {
              color: $gray-500;
              font-size: 1.2rem;

              @include media-breakpoint-up(lg) {
                font-size: 1.6rem;
              }
            }
          }

          &__btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.4rem;
            letter-spacing: 2.24px;
            height: 88px;

            @include media-breakpoint-up(lg) {
              font-size: 1.6rem;
              letter-spacing: 2.56px;
              height: 95px;
            }

            &:has(.learn-more-btn) {
              height: unset;
              @include media-breakpoint-up(lg) {
                height: unset;
              }

              .learn-more-btn {
                margin-top: 18px;
                margin-bottom: 16px;
              }
            }

            .button-compare,
            .show-notify-me {
              display: block;
              color: $white;
              width: 100%;
              margin-top: 18px;
              margin-bottom: 16px;
              @include media-breakpoint-up(md) {
                width: 160px;
              }
              @include media-breakpoint-up(lg) {
                width: 240px;
              }
            }

            .show-notify-me {
              width: 160px;
              margin-top: 18px;
              margin-bottom: 16px;

              @include media-breakpoint-up(lg) {
                width: 240px;
              }
            }

            a {
              //color: $secondary;
              color: $link-color;
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }

    &-body {
      .product-function {
        &__label {
          //width: 100%;
          //height: 0;
          //position: absolute;
          //left: 0;

          & > .divider {
            width: 100%;
            height: 0;
            border-top: 1px solid $gray-dark;
            position: absolute;
            left: 0;
          }

          & > .label {
            font-size: $font-size-large;
            font-weight: $font-weight-normal;
            color: $gray-900;
            line-height: 1.4;
            letter-spacing: .9px;
            visibility: hidden;
            margin-bottom: 4px;

            @include media-breakpoint-up(lg) {
              font-size: $h4-font-size;
              letter-spacing: 1.2px;
              margin-bottom: 8px;
            }

            &.visible {
              visibility: visible;
              //border-bottom: 1px solid $gray-dark;
            }
          }
        }

        &__detail {
          width: 100%;
          padding-top: 16px;
          padding-bottom: 24px;

          @include media-breakpoint-up(lg) {
            padding-top: 24px;
            padding-bottom: 32px;
          }

          // @include media-breakpoint-up(xl) {
          //   padding-left: 120px;
          // }

          &-container {
            max-width: 320px;
            margin: 0 auto;
          }

          &-item {
            font-size: $font-size-base;
            color: $gray-900;

            @include media-breakpoint-up(lg) {
              font-size: $h5-font-size;
            }

            & > .label {
              font-weight: $font-weight-normal;
            }

            & > .value {
              font-weight: $font-weight-light;
            }

            &.benefit {
              max-width: 240px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              &:not(:last-child) {
                margin-bottom: 40px;
                margin-top: 14px;
              }

              & > .label {
                width: 22.5px;
                margin-bottom: 18px;
                font-weight: $font-weight-normal;

                @include media-breakpoint-up(lg) {
                  width: 40px;
                }
              }

              & > .value {
                text-align: center;
                font-weight: $font-weight-light;
              }
            }
          }
        }
      }
    }
  }

  &__no-product {
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: 1.2px;
    text-align: center;
    margin-bottom: 48px;
    color: $gray-900;

    @include media-breakpoint-up(lg) {
      font-size: 4rem;
      line-height: 1.15;
      letter-spacing: 2px;
      margin-bottom: 72px;
    }
  }
}

.product-view-compare-product,
.recently-compare-product {
  background-color: rgba(254, 230, 211, 0.5);
  margin-top: 44px;

  @include media-breakpoint-up(lg) {
    margin-top: 60px;
  }
}

.recently-compare-product {
  .recently-viewed-products {
    padding-top: 32px;

    @include media-breakpoint-up(lg) {
      padding-top: 48px;
    }

    .recently-viewed-products__title {
      font-size: 2.4rem;
      line-height: 1.3;
      letter-spacing: 1.2px;
      color: $gray-900;

      @include media-breakpoint-up(lg) {
        font-size: 3.2rem;
        line-height: 1.45;
        letter-spacing: 1.6px;
      }
    }

    &__contents {
      min-width: 168px;

      &:hover {
        background-color: unset;

        .recently-viewed-products__name {
          a:hover {
            color: unset;
          }
        }
      }
    }
  }

}