@import '@assets/reuse-mixin.scss';

.select-store {
  text-align: left;
  padding: 24px 0;

  @include media-breakpoint-up(lg) {
    padding: 48px 0;
  }

  &__title {
    text-align: center;
    font-size: $h1-font-size-mobile;
    line-height: 1.5;
    letter-spacing: 1.28px;
    color: $gray-900;
    padding-bottom: 24px;

    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
      line-height: 1.4;
      letter-spacing: 1.6px;
      padding-bottom: 40px;
    }

  }

  &__content {

    &__list {
      margin-top: 24px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
      
      &__title {
        color: $primary;
        margin-bottom: 16px;
      }

      &__group {
        max-height: 508px;
        overflow-y: auto;
        padding-right: 4px;

        @include scrollbar-custom();

        &__item {
          cursor: pointer;
          border: 1px solid $gray-400;
    
          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            border-color: $gray-900;
          }
    
          &:has(> input:checked) {
            border-color: $gray-900;
          }

          &.hidden {
            display: none;
          }
    
          &__title {
            margin-bottom: 8px;
            font-weight: $font-weight-normal;
    
            @include media-breakpoint-up(lg) {
              margin-bottom: 16px;
            }
          }

          &__label {
            cursor: pointer;
            width: 100%;
            padding: 12px 24px;
          }
    
          p {
            margin-bottom: 6px;
          }
        }
      }
    }
  
    &__question {
      margin-top: 24px;
      
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      &__item {
        &__title {
          font-weight: $font-weight-normal;
        }

        &__answer {
          margin: 16px 0px 24px 16px;

          label {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__policy {
    padding-top: 16px;
    
    @include media-breakpoint-up(lg) {
      padding-top: 48px;
    }
    
    .row {
      justify-content: center;
    }

    &__option {
      margin-bottom: 12px;
    
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }

      &__list {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
      }
    }
  
    button {
      margin: 0 auto;
      display: block;
    }
  }
}