@import '@assets/reuse-mixin.scss';

.sale-orders-listing {
  background: $cp-background;

  &__tabs {
    padding: 24px 0 20px;

    .nav {
      background: $white;

      .nav-item {
        width: calc(100% / 3);
      }
    }

    &__content {
      padding: 24px 0px 0px;
    }
  }
}

.sale-orders-item {
  position: relative;
  padding: 24px 24px 32px;
  background-color: $white;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  &__box {
    padding-bottom: 8px;
    color: $gray-900;
  }

  &__info {
    font-size: $font-size-base;

    &--order-id {
      font-weight: $font-weight-normal;
    }

    &--title {
      display: block;
      padding-top: 8px;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
    }
  }

  &__status {
    position: absolute;
    right: 24px;
    bottom: 40px;
    color: $gray-900;
    padding: 4px 12px;
    border-radius: 25px;
    background: $gray-200;
    text-transform: capitalize;

    &--draft,
    &--0 {
      color: $gray-900;
      background: $gray-200;
    }

    &--active,
    &--1 {
      color: $secondary;
      background: #d7efff;
    }

    &--viewed,
    &--2 {
      color: $secondary;
      background: #d7efff;
    }

    &--completed,
    &--3 {
      color: $success;
      background: #e2edec;
    }

    &--expired,
    &--4 {
      color: #e31937;
      background: #facfd5;
    }
  }

  &__link {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;

    &:hover {
      text-decoration: underline;
    }
  }
}
