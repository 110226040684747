@import'@assets/reuse-mixin.scss';

.confirm-remove-product {
    text-align: center;
    padding-top: 20px;

    &__text {
        padding: 40px 0 100px 0;
        text-align: center;
        font-size: $font-size-base;
        letter-spacing: 1.28px;
        line-height: 2.4rem;
        color: $gray-darker;

        @include media-breakpoint-up(md) {
            padding: 60px 0 150px 0;
            font-size: $font-size-large;
            letter-spacing: 1.6px;
            line-height: 2.8rem;
            max-width: 560px;
            margin: 0 auto;
        }

        @include media-breakpoint-up(lg) {
            font-size: $h5-font-size;
            letter-spacing: 1.6px;
            line-height: 3.2rem;
        }

        >span {
            display: block;
        }
    }

    .btn-remove-product {
        display: block;
        min-width: 200px;
        margin: 0 auto;
        margin-bottom: 15px;
        letter-spacing: 2.24px;

        @include media-breakpoint-up(md) {
            min-width: 240px;
            letter-spacing: 2.56px;
        }
    }

    .btn-cancel-remove-product {
        border: none;
        background: none;
        outline: none;
        letter-spacing: 0.7px;
        text-transform: initial;
        font-weight: $font-weight-normal;
        color: $secondary;

        &:hover {
            text-decoration: underline;
        }

        @include media-breakpoint-up(md) {
            letter-spacing: 0.8px;
        }
    }
}