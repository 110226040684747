@import '@assets/reuse-mixin.scss';

.customer-acknowledge {
  &__form {
    &__cus-name {
      margin: 0 0 30px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }
    }

    &__signature__area {
      position: relative;

      &__label {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $cp-gray;
        pointer-events: none;
      }
    }
  }

  .cp-form {
    &__phone {
      width: 100%;
    }

    &__add-new {
      color: $secondary;
      cursor: pointer;
      padding-right: 0;
    }

    &__button {
      margin-top: 32px;
    }

    .form-control__canvas {
      height: 200px;
    }

    &__value-text {
      &.mail-value,
      &.dob-value,
      &.phone-value {
        flex: 1;
      }
    }

    &__buttonSignature {
      .btn-os-orange {
        &:disabled {
          color: $os-orange-dark;
        }
      }
    }

    &__group__item {
      .cp-input__Gender {
        input:disabled ~ label {
          border-color: $border-form;
          border-bottom: 0;
          border-top: 0;
        }
      }

      &:nth-child(1) {
        .cp-input__Gender {
          input:disabled ~ label {
            border-left: 0;
          }
        }
      }

      &:nth-child(3) {
        input:disabled ~ label {
          border-right: 0;
        }
      }
    }

    .form-group--checkboxes__label--custom {
      margin-bottom: 8px;
      padding-left: 0;

      &:before {
        content: none;
      }
    }
  }

  .disable-posOder {
    cursor: default;
    pointer-events: none;
  }

  .signature-canvas > img {
    height: 200px;
  }
}

.warning-popup {
  .osim-popup__header {
    display: none;
  }

  &__content {
    background-color: $white;
    text-align: center;

    &__title {
      font-weight: $font-weight-normal;
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }
    }

    &__desc {
      color: $gray-darker;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }

    &__info {
      &__line {
        display: block;
      }
    }

    &__btn {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__item {
        margin-bottom: 18px;
        font-size: $font-size-small;
        width: fit-content;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
