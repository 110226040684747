@import '@assets/reuse-mixin.scss';

.social-media {
  // position: absolute;
  // top: 65px;
  // right: 24px;
  z-index: 2;
  cursor: pointer;

  @include media-breakpoint-up(xl) {
    right: 32px;
  }

  @include media-breakpoint-up(xxl) {
    top: 82px;
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.active {
      .social-media__list-share {
        // right: 40px;
        opacity: 1;
        visibility: visible;
      }

      .social-media__icon {
        background-position: 0 -90px;

        @include media-breakpoint-up(xl) {
          background-position: 0 -120px;
        }
      }
    }
  }

  &__icon {
    width: 24px;
    height: 22px;
    background: url('../../assets/images/icons/os-icon--new.png') 0 -67px no-repeat;
    background-size: cover;

    @include media-breakpoint-up(xxl) {
      width: 32px;
      height: 30px;
      background-position: 0 -90px;
    }

    &:hover {
      background-position: 0 -90px;

      @include media-breakpoint-up(xxl) {
        background-position: 0 -120px;
      }
    }
  }

  &__list-share {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    opacity: 0;
    transition: 0.5s;
    visibility: hidden;
  }

  &__list-share {
    background-color: $white;
    padding: 5px;
    // right: calc(-100% - 30px);

    &::after {
      content: '';
      position: absolute;
      right: -6px;
      top: 50%;
      transform: translateY(-50%);
      width: 0px;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $white;
    }
  }

  #st-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    &.st-has-labels .st-btn,
    &.st-has-labels .st-btn.st-remove-label {
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 8px;
    }

    &.st-has-labels .st-btn.st-remove-label,
    .st-btn:last-child {
      margin: 8px;
    }

    .st-btn > img {
      top: 12px;
    }

    .st-btn > span {
      display: none;
    }
  }
}

.st-custom-button {
  background: url('../../assets/images/social-icon/social-copy.png') center top no-repeat;
  width: 24px;
  display: block;
  cursor: pointer;
  color: $gray-darker;
  font-size: $font-size-small;
  text-align: center;
  padding: 24px 0 0 0;
  margin-bottom: 6px;
  height: 0;
  overflow: hidden;
  background-size: contain;
  text-indent: -999px;

  @include media-breakpoint-up(xxl) {
    width: 32px;
    padding: 32px 0 0 0;
    margin-bottom: 10px;
  }

  &.email {
    background-image: url('../../assets/images/social-icon/social-email.png');
  }

  &.line {
    background-image: url('../../assets/images/social-icon/social-line.png');
  }

  &.facebook {
    background-image: url('../../assets/images/social-icon/social-facebook.png');
  }

  &.messenger {
    background-image: url('../../assets/images/social-icon/social-messenger.png');
  }

  &.telegram {
    background-image: url('../../assets/images/social-icon/social-telegram.png');
  }

  &.twitter {
    background-image: url('../../assets/images/social-icon/social-twitter.png');
  }

  &.wechat {
    background-image: url('../../assets/images/social-icon/social-wechat.png');
  }

  &.copyLink {
    background-image: url('../../assets/images/social-icon/social-copy.png');
    // background-position: center 12px;
  }

  &.whatsapp {
    background-image: url('../../assets/images/social-icon/social-whatsapp.png');
  }

  &.pinterest {
    background-image: url('../../assets/images/social-icon/social-pinterest.png');
  }

  &:hover,
  &:focus {
    color: $link-color;
  }
}

.content-sharing {
  --show-dialog: hidden;
  position: relative;

  .osim-popup__close-btn {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    right: inherit;
    z-index: 1;
    cursor: default;
  }

  .fake-modal {
    position: absolute;
    bottom: -45px;
    top: inherit;
    width: 100%;
    height: 100%;
    background: none;
    z-index: 1000;
    align-items: center;
    transition: all 0.5s;
    visibility: var(--show-dialog);

    @include media-breakpoint-up(xxl) {
      bottom: -60px;
    }

    .share-modal {
      --show-copied: none;
      max-width: 32px;
      border: none;
      padding: 0;
      // background: #fff;
      // min-height: 200px;
      // margin-top: auto;
      width: 100%;
      position: relative;
      z-index: 3;
      // top: 50%;
      // margin: 0 auto;
      // transform: translateY(-50%);

      // &__title {
      //   text-align: center;
      //   font-size: $font-size-small;
      //   font-weight: $font-weight-bold;
      //   color: $gray;
      //   text-transform: uppercase;
      //   margin-bottom: 48px;
      // }

      &__list {
        position: relative;

        .copied {
          display: var(--show-copied);
          text-align: center;
          position: absolute;
          right: 110%;
          bottom: 2px;
          background-color: $gray-dark;
          padding: 5px;
          color: $white;
          font-size: $font-size-smaller;
      
          &::after {
            content: '';
            position: absolute;
            right: -6px;
            top: 50%;
            transform: translateY(-50%);
            width: 0px;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 6px solid $gray-dark;
          }
        }
      }
    }
  }
}

.item-tooltip {
  &__label {
    background-color: $gray-dark;
    color: $white;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 15px;
    right: 40px;
    opacity: 0;
    transition: 0.5s;
    visibility: hidden;

    &::after {
      content: '';
      position: absolute;
      right: -6px;
      top: 50%;
      transform: translateY(-50%);
      width: 0px;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $gray-dark;
    }
  }

  &:hover {
    .item-tooltip__label {
      opacity: 1;
      visibility: visible;
    }
  }
}