body.no-scroll {
    overflow: hidden;
  }
  .preloading {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .load-icon {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .load-icon:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #59a2cd transparent #70c391 transparent;
    animation: load-icon 1.2s linear infinite;
  }
  @keyframes load-icon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }