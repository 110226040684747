@import '../../assets/reuse-mixin.scss';

.store-link {
    &__back-link {
        display: flex;
        align-items: center;
        //color: $os-blue;
        color: $link-color;
        margin-bottom: 20px;
        font-size: $font-size-small;
    
        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }
      }
}