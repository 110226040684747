@import'../../../assets/reuse-mixin.scss';

.cart-detail {
  a {
    //color: $os-blue;
    color: $link-color;
  
    &:hover {
      text-decoration: underline;
    }
  }

  &__empty {
    padding: 32px 20px;
    text-align: center;
    color: $gray-darker;
    font-size: $font-size-large;
    border-bottom: 1px solid $border-color;
    margin-bottom: 48px;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
      margin-bottom: 72px;
    }
  }

  &__empty-cp {
    text-align: center;
    padding: 50px 0px;

    &__notification {
      font-size: $h5-font-size;
      color: $gray-900;
      font-weight: $font-weight-normal;
      margin: 0;
      padding-bottom: 18px;
    }

    &__link {
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      //color: $os-blue;
      color: $link-color;
    }
  }

  &__cp-order-detail {
    padding: 32px 0;
  }
}
