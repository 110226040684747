@import '@assets/reuse-mixin.scss';

.contact-widget {
  &__content {
    &__info {
      font-size: $font-size-base;
      display: block;
      margin-bottom: 5px;
    }
  }
}