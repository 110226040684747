@import '@assets/reuse-mixin.scss';

:root {
  --colon-color: $cp-gray-light;
}

.countdown-timer {
  //max-width: 350px;
  width: fit-content;
  display: inline-block;

  //&__wrapper {
  //  padding-bottom: calc(135 / 350 * 100%);
  //  width: 100%;
  //  position: relative;
  //}

  &__container {
    //position: absolute;
    //left: 0;
    //top: 0;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    border-width: 1px;
    border-style: solid;
    border-color: $cp-gray-light;
    position: relative;
    @include media-breakpoint-up(sm) {
      padding: 20px 40px;
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &-item {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: $font-size-large;
    color: $cp-gray-light;
    text-align: center;
    margin-bottom: 15px;
    position: relative;
  }

  &__detail {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    position: relative;

    &-item {
      .timer-box {
        width: 45px;
        height: 40px;
        border-width: 1px;
        border-style: solid;
        border-color: $cp-gray-light;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        background-color: $white;

        & > p {
          color: #d5580c;
          font-size: $h4-font-size;
          margin-bottom: 0;
          font-weight: $font-weight-bold;
        }
      }

      &:not(:last-child) {
        .timer-box:after {
          content: ':';
          color: var(--colon-color);
          font-size: $font-size-base;
          position: absolute;
          width: 30px;
          left: calc(100% + 1px); // 1px is border width
          text-align: center;
        }
      }
    }

    &-label {
      font-size: $font-size-base;
      margin-bottom: 0;
      text-align: center;
      color: $cp-gray-light;
    }
  }
}