@import '@assets/reuse-mixin.scss';

.member-event {
  &__title {
    font-size: $h2-font-size-mobile;
    font-weight: $font-weight-normal;
    line-height: 2.8rem;
    color: $gray-darker;
    margin-bottom: 24px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: $h2-font-size;
      line-height: 4.6rem;
      margin-bottom: 32px;
      text-align: left;
    }
  }

  .title-upcoming {
    margin-top: 56px;

    @include media-breakpoint-up(md) {
      margin-top: 72px;
    }

  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 56px;

      @include media-breakpoint-up(md) {
        margin-bottom: 72px;
      }
    }

    .article {
      &__image {
        margin-bottom: 18px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }

        .list-tag {
          .osim-label {
            background-color: #FFFFFF;
          }
        }

        img {
          height: 100%;
        }
      }

      &__content {
        &__title {
          a:hover {
            color: unset;
          }

          font-size: $h2-font-size-mobile;
          font-weight: $font-weight-light;
          line-height: 2.8rem;
          margin-bottom: 16px;

          @include media-breakpoint-up(md) {
            font-size: $h3-font-size;
            line-height: 4.6rem;
            margin-bottom: 18px;
          }
        }

        &__link {
          margin-top: 0;
          line-height: 2.8rem;
          font-size: $h6-font-size-mobile;
          margin-bottom: 18px;

          @include media-breakpoint-up(md) {
            font-size: $font-size-base;
            margin-bottom: 24px;
          }
        }

        &__detail {
          margin-bottom: 23px;
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: .8px;

          @include media-breakpoint-up(md) {
            margin-bottom: 32px;
            font-size: 1.8rem;
            line-height: 1.4;
            letter-spacing: 1px;
          }

          p {
            margin: 0;
          }
        }

        // Comment to style button align left OSIM-5760
        // &__action {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }

        &__cant-register {
          margin: 0;
          margin-top: 36px;
          color: $primary;
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: .8px;

          @include media-breakpoint-up(md) {
            font-size: 2rem;
            margin-top: 48px;
            line-height: 1.4;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}