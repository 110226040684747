@import '@assets/reuse-mixin.scss';

.profile-widget {
  &__content {
    &__info {
      padding-bottom: 24px;
      border-bottom: 1px solid $gray;

      @include media-breakpoint-up(lg) {
        padding-bottom: 32px;
      }

      &__name {
        margin-bottom: 8px;
        color: $gray-darker;
        font-size: $font-size-large;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
          font-size: $h4-font-size;
        }
      }
    }

    &__setting {
      padding-top: 24px;

      @include media-breakpoint-up(lg) {
        padding-top: 32px;
      }

      &__link {
        display: block;
        margin-bottom: 18px;
        line-height: 1;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
          margin-bottom: 24px;
        }
      }

      &__btn-signout {
        padding: 6px 0 4px;
        text-align: center;

        .btn {
          width: 200px;
          color: $gray-darker;

          @include media-breakpoint-up(lg) {
            width: 240px;
          }
        }
      }
    }
  }
}