@import '../../../../assets/reuse-mixin.scss';

.wishlist-product-item {
  &__remove {
    position: absolute;
    top: -24px;
    right: 0;
    font-size: $font-size-base;
    color: $gray;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }

    &:hover {
      color: $link-color;
    }
  }

  .wishlist-item--horizontal {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .article__content__link {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .article__content {
      position: relative;
    }
    
    .article__image {
      &-link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .heart {
        z-index: $Z_INDEX_NORMAL;
      }
    }

    .article__content__description {
      @include line-clamp(2);
    }
  }
}