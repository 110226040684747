@import'@assets/reuse-mixin.scss';
$padding-bottom: 20px;

.cart-item-information {
  color: $gray-darker;

  .error-message {
    text-align: left;

    @include media-breakpoint-up(lg) {
      padding-right: 80px;
    }
  }

  span {
    display: inline-block;
  }

  .info-stock-message {
    display: block;
  }

  .error-message {
    margin-bottom: 8px;
  }

  .special-deal-message {
    color: $os-green;
    font-weight: $font-weight-normal;
    margin-bottom: 8px;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
      max-width: 85%;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h5-font-size;
    }

    & + .cart-item-information__body {
      margin-top: 0;
    }
  }

  &__body {
    position: relative;
    margin-bottom: 18px;

    @include media-breakpoint-up(lg) {
      margin: 28px 0 24px 0;
    }

    &__title {
      font-size: $h5-font-size;
      max-width: 270px;
      color: $gray-darker;
      letter-spacing: 1px;
      font-weight: $font-weight-normal;
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
        max-width: 50%;
      }

      @include media-breakpoint-up(lg) {
        // font-size: $h5-font-size;
        max-width: 270px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h3-font-size - 0.2;
        max-width: 325px;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 490px;
      }

      a {
        color: inherit;

        &:hover {
          color: $link-color;
          text-decoration: none;
        }
      }

      span {
        display: inline-block;
      }
    }

    &__remove-btn,
    &__total-amount {
      position: absolute;
      right: 0;
    }

    &__remove-btn {
      top: 5px;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      color: $gray;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
        top: -28px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $font-size-large;
      }

      &:hover {
        color: $link-hover-color;
      }

      &.edit-free-gift-btn {
        color: $link-color;
      }
    }

    &__total-amount {
      bottom: 0;
      text-align: right;

      @include media-breakpoint-up(lg) {
        top: 0;
        bottom: auto;
      }

      span,
      strong {
        display: block;
      }

      strong {
        font-weight: $font-weight-normal;
        font-size: $h5-font-size;
        color: $gray-darker;
        letter-spacing: normal;

        @include media-breakpoint-up(xl) {
          font-size: $h3-font-size - 0.2;
        }

        @include media-breakpoint-up(xxl) {
          letter-spacing: inherit;
        }
      }

      span.smaller-text {
        min-height: 16px;

        @include media-breakpoint-up(xl) {
          margin-top: 15px;
        }
      }

      .deposit-text {
        font-size: $font-size-smaller;
        color: $gray-600;
        
        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
          padding-top: 8px;
        }
      }
    }
  }

  &__footer {
    position: relative;
    font-size: $font-size-large;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size;
    }

    strong {
      font-weight: $font-weight-normal;
    }

    &__edit-btn {
      position: absolute;
      right: 0;
      top: 8px;

      @include media-breakpoint-up(lg) {
        top: 16px;
      }
    }

    &__color {
      display: inline-block;
      padding-right: 50px;
    }

    ol,
    ul {
      padding-left: 30px;
      color: $gray-darker;
      font-weight: $font-weight-normal;

      li {
        list-style-type: decimal;
      }

      .cart-products {
        &__item {
          &:first-child {
            .cart-products__item__header {
              padding-top: 0;
            }
          }

          &__header {
            padding: 8px 0;
  
            @include media-breakpoint-up(xl) {
              padding: 16px 0;
            }
          }

          &__color-name {
            padding-bottom: 0;
          }

          &__rental,
          &__warranty, 
          &__trade-in,
          &__additional-options {
            font-size: $font-size-large;
            margin-left: -30px;
            padding: 8px 0 8px 30px;

            @include media-breakpoint-up(xl) {
              padding: 16px 0 16px 30px;
              font-size: $h5-font-size;
            }

            @include media-breakpoint-up(xxl) {
              padding: 16px 0 16px 30px;
              font-size: $h4-font-size;
            }
          }
        }
      }
    }

    .cart-products {
      border-bottom: 1px solid $gray;
      padding-top: 8px;
      position: relative;

      @include media-breakpoint-up(lg) {
        padding-top: 16px;
      }

      &__item {
        &__product-name {
          letter-spacing: 0.9px;
        }

        &__color-name {
          padding-bottom: 8px;
        }

        &__rental,
        &__warranty,
        &__trade-in {
          padding: 8px 0;
          border-top: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          align-items: center;
          // width: 100%;
          margin-top: -1px;
          // margin-bottom: 5px;

          @include media-breakpoint-up(lg) {
            padding: 16px 0;
            // margin-bottom: 10px;
          }

          &:last-child {
            border-bottom: 1px solid $border-color;
          }

          &__contents {
            display: flex;
            // align-items: center;
            align-items: baseline;
            width: 100%;

            img {
              width: 20px;
            }

            .product-info-icon {
              margin-right: 5px;

              @include media-breakpoint-up(md) {
                margin-right: 10px;
              }
            }

            // span {
            //   margin-left: 5px;

            //   @include media-breakpoint-up(md) {
            //     margin-left: 10px;
            //   }
            // }
          }

          &__price {
            font-size: $font-size-base;

            @include media-breakpoint-up(lg) {
              font-size: $h5-font-size;
            }
          }
        }

        &__additional-options {
          padding: 8px 0;
          border-top: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          align-items: center;
          margin-top: -1px;

          @include media-breakpoint-up(lg) {
            padding: 16px 0;
            // margin-bottom: 10px;
          }

          &:last-child {
            border-bottom: 1px solid $border-color;
          }

          &__contents {
            display: flex;
            align-items: center;

            img {
              width: 20px;
            }

            .product-info-icon {
              &--additional-options {
                display: flex;
                margin-right: 5px;
  
                @include media-breakpoint-up(md) {
                  margin-right: 10px;
                }
              }
            }

            // span {
            //   margin-left: 5px;

            //   @include media-breakpoint-up(md) {
            //     margin-left: 10px;
            //   }
            // }
          }

          &__price {
            font-size: $font-size-base;

            @include media-breakpoint-up(lg) {
              font-size: $h5-font-size;
            }
          }
        }

        &__trade-in {
          // border-top: none;

          &__contents {
            &__wrap {
              width: calc(100% - 20px);
              
              @include media-breakpoint-up(lg) {
                width: calc(100% - 30px);
              }

              .link-text-underline {
                padding-left: 0;
                padding-top: 0;
              }
            }
          }
        }
      }
    }

    .cart-item-free-gift {
      &__price {
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }
      }
    }
  }

  .product-information {
    display: flex;

    &__quantity {
      width: 110px;

      &__inner {
        text-align: center;
        border: 1px solid $gray-lighter;

        &.disable {

          .button-minus,
          .button-add {
            color: $gray-300;
            cursor: default;
            pointer-events: none;
          }

          .icon-remove,
          .product-information__quantity__current {
            color: $gray-300;
          }
        }
      }

      .button-minus {
        border-right-width: 0;

        &.disable {
          color: $gray-300;
          cursor: default;
          pointer-events: none;
        }
      }

      .button-add {
        border-left-width: 0;

        &.disable {
          color: $gray-300;
          cursor: default;
          pointer-events: none;
        }
      }

      button {
        display: inline-block;
        width: 36px;
        height: 40px;
        border: none;
        padding: 2px 0;
        background: none;
        outline: none;
        text-align: top;
        font-size: $h5-font-size;
        color: $gray-darker;
        vertical-align: top;

        &.disable {
          color: $gray-300;
          cursor: default;

          &:hover {
            color: $gray-300;
          }

          .icon-remove {
            color: $gray-300;
          }
        }

        &:hover {
          color: $link-color;
        }
      }

      input {
        width: calc(100% - 72px);
        height: 40px;
        border: none;
        text-align: center;
        padding: 2px 0;
        background: none;
        font-size: $h5-font-size;
      }
    }

    &__price {
      font-size: $font-size-smaller;
      width: calc(100% - 120px);
      padding-left: 6px;

      @include media-breakpoint-up(md) {
        padding-left: 10px;
      }

      span,
      strong {
        display: block;
        letter-spacing: 0.6px;
      }

      strong {
        font-weight: $font-weight-normal;
        margin-bottom: 5px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: 5px;
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 0;
        }
      }
    }

    &__status {
      font-weight: $font-weight-normal;
      font-size: $font-size-large;
      margin: -4px 0 0;
      padding-bottom: 8px;
      border-bottom: 1px solid $border-color;

      @include media-breakpoint-up(lg) {
        margin: -10px 0 0;
        padding-bottom: 16px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size;
      }
    }
  }

  .option-name {
    width: 100%;
    display: block;
    max-width: calc(100% - 145px);

    @include media-breakpoint-up(xl) {
      max-width: calc(100% - 185px);
    }
  }

  .item-color {
    border-bottom: 1px solid $gray;
    padding: 8px 0;
    position: relative;

    @include media-breakpoint-up(lg) {
      padding: 16px 0;
    }

    & + div {
      border-top: 0;
    }

    .cart-item-information__footer__edit-btn {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}