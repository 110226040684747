.be-schedule-appointment__contact-detail {
  .subtitle {
    text-align: center;

    &-btn {
      //color: $secondary !important;
      color: $link-color !important;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .contact-detail {
    &__body {
      margin-top: 24px;
      @include media-breakpoint-up(md) {
        margin-top: 40px;
      }

      .schedule-error-message {
        text-align: center;
      }

      &-phone-number-input {
        & > .form-group {
          margin-bottom: 0;
        }
      }
    }
  }
}
