@import '@assets/reuse-mixin.scss';

.cart-promotion-codes {
  // .container {
  //   @include media-breakpoint-up(lg) {
  //     border-top: 1px solid $gray;
  //   }
  // }

  &__form {
    padding: 6px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 18px 0;
    }

    &__group {
      margin-bottom: 18px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 14px;
      }

      label {
        margin-bottom: 8px;
        display: inline-block;
        font-weight: $font-weight-bold;
        color: $gray-darker;
      }
    }

    &__input {
      display: flex;
      min-height: 48px;
      position: relative;

      input {
        width: calc(100% - 128px);
        font-size: $font-size-base;
        padding: 10px 24px;
        border: 1px solid $gray-darker;

        @include media-breakpoint-up(lg) {
          width: calc(100% - 150px);
          font-size: $h5-font-size;
        }

        &::placeholder {
          color: $gray;
        }

        &:focus {
          outline: none;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 100px $input-disabled-bg inset !important;
        }
      }

      .btn {
        border-left: 0;
        width: 128px;
        font-size: $font-size-small;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          width: 150px;
          font-size: $font-size-base;
        }

        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active:focus {
          box-shadow: none;
        }
      }

      .icon-close {
        cursor: pointer;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 152px;

        @include media-breakpoint-up(lg) {
          right: 174px;
        }

        &:hover {
          color: $gray-darker;
        }
      }

      &--full-width {
        input {
          width: 100%;
        }

        .icon-close {
          right: 24px;
        }
      }

      textarea {
        min-height: 116px;
        border-color: $gray-darker;
      }

      button {
        max-height: 58px;
      }
    }
  }

  .cart-promotion-code-view-more {
    border: 1px dashed $gray;
    height: auto;
    min-height: 90px;

    span {
      height: 100%;
      font-size: $font-size-small;
      letter-spacing: 2.56px;
      font-weight: $font-weight-normal;
      text-transform: uppercase;
      color: $secondary;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }
  }

  &__list-carts {
    margin-right: -20px;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__description {
      margin-bottom: 12px;

      @include media-breakpoint-up(md) {
        margin-right: 16px;
      }
    }
  }

  &__list-cart-tags {
    display: flex;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    ul.cart-promotion-codes__list-cart-tags__group {
      @include media-breakpoint-up(lg) {
        padding-left: 20px;
        margin-bottom: 6px;
      }
    }

    &__group {
      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      &__item {
        padding: 8px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        // min-width: 116px;
        background-color: $os-orange-lighter;
        font-size: $font-size-smaller;
        letter-spacing: 0.6px;
        font-weight: $font-weight-bold;
        margin: 0 16px 10px 0;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          font-size: $font-size-small;
          letter-spacing: 0px;
        }

        @include media-breakpoint-up(lg) {
          font-size: $font-size-smaller;
          letter-spacing: 0.6px;
        }

        @include media-breakpoint-up(xl) {
          font-size: $font-size-small;
          letter-spacing: 0;
        }

        span {
          &:hover {
            color: $black;
          }
        }

        .x-icon {
          position: relative;
          width: 18px;
          height: 18px;
          margin-left: 8px;

          &:hover {
            span,
            &::after {
              background-color: $black;
            }
          }

          span {
            width: 12px;
            height: 2px;
            background-color: $gray;
            transform: rotate(45deg);
          }

          &::after {
            content: '';
            position: absolute;
            background-color: $gray;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 2px;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }
  }
}

// coupon popup
.coupon-popup {
  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    margin-left: -18px;
    margin-right: -18px;
    max-height: 500px;
    overflow: auto;

    .cart-promotion-code-item {
      @include media-breakpoint-up(md) {
        height: 100%;
      }

      &__description {
        @include media-breakpoint-up(md) {
          height: 38px;
        }
      }
    }
  }

  &__info {
    font-size: $h5-font-size;
    color: $gray-darker;
    padding: 20px 0;

    &-title {
      font-size: $h3-font-size;
      font-weight: $font-weight-normal;
      text-align: center;
      margin-bottom: 30px;
    }

    &-text {
      margin-bottom: 40px;

      p + p {
        margin-top: 20px;
      }
    }

    &-validity {
      strong {
        font-weight: $font-weight-bold;
      }
    }

    &--single-coupon {
      .coupon-popup {
        &__info {
          &-title {
            font-size: $h4-font-size;
            max-width: 70%;
            margin: 0 auto 18px;

            @include media-breakpoint-up(lg) {
              font-size: $h3-font-size;
              max-width: 60%;
              margin: 0 auto 24px;
            }
          }
        }

        &__banner {
          position: relative;
          overflow: hidden;
          height: 0;
          padding-bottom: 215/325 * 100%;

          @include media-breakpoint-up(md) {
            padding-bottom: 380/675 * 100%;
          }

          &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: 0.6;
            }
          }
  
          &__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $gray-darker;

            &__desc,
            &__date,
            &__code {
              text-align: center;
              padding: 0 20px;

              @include media-breakpoint-up(lg) {
                padding: 0 32px;
              }
            }

            &__desc {
              font-weight: $font-weight-light;
              margin-bottom: 0;

              @include media-breakpoint-up(lg) {
                font-size: $font-size-large;
              }
            }

            &__date {
              margin-top: 14px;
              font-weight: $font-weight-normal;
              
              @include media-breakpoint-up(lg) {
                margin-top: 18px;
              }
            }

            &__code {
              padding: 8px 20px;
              margin: 18px 0 0;
              text-transform: uppercase;
              font-weight: $font-weight-normal;
              background: $os-orange-lightest;

              @include media-breakpoint-up(lg) {
                padding: 10px 20px;
                margin: 24px 0 0;
              }
            }
          }

          &--no-image {
            position: unset;
            height: auto;
            padding-bottom: 0;

            .coupon-popup__banner__content {
              position: unset;
            }
          }
        }

        &__list {
          margin: 36px 0 24px;
          
          @include media-breakpoint-up(md) {
            padding: 0 28px;
            margin: 40px 0;
          }
        }

        &__item {
          display: flex;
          width: 100%;
          padding: 0;
          margin: 24px 0 0;
          
          @include media-breakpoint-up(md) {
            margin: 32px 0 0;
          }

          &:first-child {
            margin: 0;
          }

          &__image {
            width: 120px;
            height: 120px;
            
            @include media-breakpoint-up(md) {
              width: 200px;
              height: 200px;
            }
            
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &__content {
            width: calc(100% - 120px);
            padding-left: 12px;
            color: $gray-darker;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include media-breakpoint-up(md) {
              width: calc(100% - 200px);
              padding-left: 60px;
              
            }

            &__title {
              font-size: $h5-font-size;
              font-weight: $font-weight-normal;
              margin-bottom: 0;

              @include media-breakpoint-up(lg) {
                font-size: $h4-font-size;
              }

              @include media-breakpoint-up(xl) {
                font-size: 3.0rem;
              }
            }

            &__sub-title {
              font-size: $font-size-small;
              margin: 6px 0 0;

              @include media-breakpoint-up(md) {
                font-size: $font-size-base;
              }

              @include media-breakpoint-up(lg) {
                margin: 14px 0 0;
                font-size: $h5-font-size;
                font-weight: $font-weight-normal;
              }
            }

            &__desc {
              margin: 16px 0 0;
              font-size: $font-size-smaller;

              @include media-breakpoint-up(md) {
                font-size: $font-size-small;
              }

              @include media-breakpoint-up(lg) {
                margin: 24px 0 0;
                font-size: $font-size-large;
              }
            }
          }
        }
      }
    }
  }

  &__item {
    width: 100%;
    padding: 0 18px;
    margin-bottom: 24px;

    @include media-breakpoint-up(sm) {
      width: 50%;
    }
  }

  &__action {
    text-align: center;
  }

  &__code {
    display: inline-block;
    text-align: center;
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
    color: $black;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      font-size: $h4-font-size;
    }
  }

  &__hint-text {
    display: inline-block;
    text-align: center;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    margin-bottom: 0;
    padding-right: 5px;

    @include media-breakpoint-up(md) {
      font-size: $h5-font-size;
    }
  }
}

// .cart-coupon-code {
//   padding-bottom: 40px;

//   &__title {
//     font-size: $font-size-base;
//     margin-bottom: 15px;

//     @include media-breakpoint-up(lg) {
//       font-size: $h3-font-size;
//     }
//   }

//   &__form {
//     margin-bottom: 40px;

//     @include media-breakpoint-up(md) {
//       display: flex;
//     }

//     input {
//       width: 100%;
//       padding: 15px;
//       margin-bottom: 10px;

//       @include media-breakpoint-up(md) {
//         padding: 15px 24px;
//         max-width: 330px;
//         margin-bottom: 0;
//       }
//     }

//     &__button {
//       button {
//         width: 100%;
//         padding: 12px;
//         font-size: $font-size-large;

//         @include media-breakpoint-up(md) {
//           min-width: 150px;
//           height: 100%;
//         }
//       }
//     }
//   }

//   &__col {
//     margin-bottom: 20px;
//   }

//   &__cart-item {
//     position: relative;
//     padding-bottom: 25%;

//     img {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//     }

//     &__info,
//     &__button {
//       position: absolute;
//       top: 0;
//       height: 100%;
//     }

//     &__info {
//       width: 68.5%;
//       left: 0;
//       padding: 12px 24px;
//       font-size: $font-size-base;

//       p {
//         font-size: $h3-font-size;
//         margin-bottom: 0;
//         color: $black;
//         white-space: nowrap;

//         @include media-breakpoint-up(md) {
//           font-size: $h4-font-size;
//         }
//       }
//     }

//     &__button {
//       width: 31.5%;
//       right: 0;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       color: $os-blue;
//       cursor: pointer;

//       &:hover {
//         text-decoration: underline;
//       }
//     }

//     &__view-more {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       border: 1px dashed $os-blue;
//     }
//   }
// }
