@import '@assets/reuse-mixin.scss';

.appointments-infor {
  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }

  &__name {
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
    }
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      font-weight: $font-weight-normal;
      color: $gray-darker;
      display: inline-block;
    }

    &__value {
      color: $cp-gray;
      margin-bottom: 0;

      .cp-icon {
        margin-left: 18px;
      }

      &--date-edit {
        padding-top: 12px;

        @include media-breakpoint-up(lg) {
          padding-top: 0px;
        }
      }

      &--pr-8 {
        padding-right: 8px;
      }
    }

    &__option {
      &--time {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(4, 0fr);

        @include media-breakpoint-up(lg) {
          grid-template-columns: repeat(5, 0fr);
        }
      }
    }

    &:not(.d-flex) {
      .appointments-infor__item__label {
        margin-bottom: 8px;
      }
    }

    &.d-flex {
      .appointments-infor__item__label {
        margin-right: 8px;
      }
    }
  }

  &__product {
    display: block;
    font-weight: $font-weight-normal;
    color: $gray;
    margin-bottom: 24px;
  }

  &__group {
    &--border-bottom {
      border-bottom: 1px solid $cp-background;
      margin-bottom: 24px;
      padding-bottom: 24px;
    }

    &__item {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .d-flex {
    display: flex;

    &--align-center {
      align-items: center;
    }

    &--space-between {
      justify-content: space-between;
    }
  }

  .d-flex-wrap {
    flex-wrap: wrap;
  }

  .w-160 {
    min-width: 160px;
  }

  .w-65 {
    min-width: 65px;
  }
}

.date-picker-os {
  font-family: $font-family-base;
  letter-spacing: 0.1rem;
  font-size: $font-size-base;

  .react-datepicker {

    &__current-month,
    &-time__header,
    &-year-header {
      font-size: $font-size-base;
    }

    &__day-name,
    &__day,
    &__time-name {
      width: 2.7rem;
      line-height: 2.7rem;
    }

  }
}

.radio-box {
  margin-right: 8px;

  &--time {
    margin: 0 8px 8px 0;
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked,
    &:hover {
      + label {
        border-color: $gray-900;
        box-shadow: inset 0 0 1px 1px $gray-900;
      }
    }
  }

  &__label {
    padding: 8px 10px;
    border: 1px solid $gray-300;
    margin: 0;
    color: $gray-900;
    font-size: $h6-font-size-mobile;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size-mobile;
    }
  }
}
