@import '@assets/reuse-mixin.scss';

.product-detail-header {
  &__product-item {
    text-align: center;
    margin-bottom: 24px;

    &:hover {
      img {
        transform: scale(1.1);
      }

      a {
        color: $link-hover-color;
      }
    }
    
    &__img {
      margin-bottom: 8px;
      padding-top: 100%;
      display: block;
      position: relative;
      
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s;
      }
    }

    &__name {
      color: $black;
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h4-font-size;
      }

      a {
        color: inherit;
        transition: all 0.3s;
      }
    }

    &--mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  
    &--desktop {
      display: none;
  
      @include media-breakpoint-up(lg) {
        display: block;
        max-width: 210px;
      }
    }
  }
}