@import '@assets/reuse-mixin.scss';

.product-review-submitted {
    .product-thank-you-page__bottom__content {
        padding-bottom: 0;

        @include media-breakpoint-up (lg) {

            &--center,
            .higher-four-star,
            .lower-two-star {
                margin: 0 auto;
            }

            &--center {
                width: 448px;
            }

            .higher-four-star {
                width: 512px;
            }

            .lower-two-star {
                width: 558px;
            }
        }
    }
}