@import "@assets/reuse-mixin.scss";

.wellness-listing {

  .tag-x-icon {
    display: inline-block;
    width: 8px;
    margin: 0 6px;

    @include media-breakpoint-up(md) {
      margin: 0 12px;
      width: 12px;
    }
  }

  .searchbox--autocomplete--wrap .autocomplete--input {
    max-width: 100%;
  }

  .searchbox--autocomplete--input {
    justify-content: space-between;

    input {
      flex-grow: 1;
    }
  }
}