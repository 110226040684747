@import '@assets/reuse-mixin.scss';

.send-order-customer {
  padding: 40px 0px;

  &__wrapper {
    max-width: 480px;
    margin: 0px auto;
  }

  &__head {
    padding-bottom: 24px;
    text-align: center;

    &__title {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      color: $gray-900;
      padding-bottom: 8px;
      margin: 0;
    }
    
    &__info {
      font-size: $font-size-base;
      color: $cp-gray;
      padding-bottom: 8px;
      margin: 0;
    }
  }

  &__content {
    &__form {
      padding-bottom: 18px;

      .form-group {
        margin-bottom: 18px;

        &--checkbox {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .osim-checkbox-label {
            font-size: 14px;
            color: $cp-gray;
          }

          .form-group-right {
            &__btn {
              border: none;
              background: none;
              outline: none;
              color: $os-blue;
              font-size: $font-size-small;
              font-weight: $font-weight-normal;
              padding: 0 24px 0 0;

              &:last-child {
                padding: 0;
              }

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .form-control {
        font-size: $font-size-small;
      }

      .form-btn {
        text-align: center;
        padding-top: 6px;

        &__send {
          min-width: 200px;
          font-size: $font-size-small;
        }
      }
    }

    &__link-back {
      text-align: center;

      .btn-link {
        font-size: $font-size-small;
        letter-spacing: 0.7px;
        font-weight: $font-weight-normal;
      }
    }
  }
}