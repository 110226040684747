@import '../../../../assets/reuse-mixin.scss';

.footer-languages {
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 40px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 9px 0;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }

    &__text {
      display: inline-block;
      text-transform: uppercase;
      font-weight: $font-weight-normal;
      font-size: $font-size-base;
      color: $gray-darker;
      margin: 0;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        padding-bottom: 25px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
      }
    }
  }

  &__content {
    z-index: $Z_INDEX_NORMAL; //INFO: floating on the overlay-collapse

    @include media-breakpoint-up(lg) {
      width: auto;
      position: relative;
    }

    &__seleted {
      margin-left: 60px;
      cursor: pointer;
      display: flex;
      align-items: baseline;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $font-size-large;
      }

      &__name {
        word-break: break-word;

        @include media-breakpoint-up(lg) {
          max-width: 120px;
        }
      }
    }
  }

  .icon-chevron-down-big {
    display: inline-block;
    margin-left: 5px;
    transition: 0.5s;
  }

  .collapse-show {
    transform: rotate(-180deg);
  }

  // .overlay-collapse {
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  // }
}

ul.language-selector {
  background-color: $white;
  margin-top: 20px;
  margin: 0 -16px -24px;

  @include media-breakpoint-up(md) {
    margin: 0 -32px;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    padding: 14px 0 16px;
    margin: 10px -24px 0;
  }
}

.language-selector {
  &__item {
    padding: 8px 16px;
    display: block;
    margin-bottom: 18px;
    transition: all 0.3s;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      padding: 8px 24px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 4px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: $os-brown-lighter;

      .language-selector__item__link {
        color: $gray-darker;
      }
    }

    &__link {
      color: $gray-darker;
      font-size: $font-size-large;
      text-decoration: none;
      transition: all 0.3s;

      @include media-breakpoint-up(lg) {
        color: $gray;
      }
    }
  }
}
