@import "@assets/reuse-mixin.scss";

.offer-details {
  .my-account-section__back-link {
    margin-top: 24px;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }

  &__container {
    margin-top: 24px;
    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
  }

  &__widget {
    width: 100%;
    border-style: solid;
    border-color: $gray-light;
    padding-top: 16px;
    padding-bottom: 24px;
    border-width: .5px 0;
    @include media-breakpoint-up(md) {
      padding-top: 36px;
      padding-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
      border-width: .5px;
    }

    &-content {
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      &.personalize-offer-widget__content {
        .content-image {
          @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
          }
        }

        .content-wrapper {
          .product-name {
            font-weight: 500;
            line-height: 3.2rem;
            font-size: $h2-font-size-mobile;
            margin-top: 8px;
            text-align: center;
            @include media-breakpoint-up(md) {
              text-align: left;
              font-size: $h3-font-size;
              line-height: 4.6rem;
            }
          }

          .product-price-offer {
            margin-top: 18px;
            @include media-breakpoint-up(md) {
              margin-top: 16px;
            }

            .offer-details-valid {
              margin-top: 24px;
              font-size: $font-size-base;
              line-height: 2.6rem;
              color: $primary;
              margin-bottom: 0;
              text-align: center;
              @include media-breakpoint-up(md) {
                margin-top: 16px;
                font-size: $h5-font-size;
                line-height: 2.8rem;
                text-align: left;
              }
            }
          }

          .personalize-offer-widget__content-description {
            font-size: $font-size-base;
            line-height: 2.4rem;
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;

            &:has(.total-price-value) {
              & > .total-price-value {
                display: block;
                margin-top: 8px;
                color: $gray-darker;
                @extend .product-name;
              }
            }

            @include media-breakpoint-up(md) {
              text-align: left;
              font-size: $h4-font-size;
              line-height: 2.8rem;
            }
          }

          .purchase-now-btn {
            width: 100%;
            margin-top: 32px;
            text-align: center;
            @include media-breakpoint-up(md) {
              text-align: left;
            }

            .view-cart-button {
              margin-top: 0;

              &.btn {
                color: $white;

                &.btn-disable {
                  color: #acacac;
                }
              }
            }
          }
        }
      }
    }
  }

  &__listing {
    @include media-breakpoint-up(md) {
      margin-top: 72px;
    }
    @include media-breakpoint-up(lg) {
      border-top: .5px solid $gray-light;
    }
  }
}
