@import'../../../assets/reuse-mixin.scss';

.add-to-cart {

  &__header {
    background-color: $white;
    padding: 8px 0 16px;
    border-bottom: 1px solid $gray-light;

    @include media-breakpoint-up(lg) {
      padding: 8px 0;
    }

    &__container {
      display: flex;

      @include media-breakpoint-up(xxl) {
        max-width: 1280px;
      }
    }

    &__image {
      width: 96px;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      @include media-breakpoint-up(lg) {
        width: 128px;
        height: 128px;
      }

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__contents {
      width: calc(100% - 96px);
      padding-left: 25px;

      @include media-breakpoint-up(lg) {
        width: calc(100% - 128px);
        padding-left: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__text {
        &__name {
          font-size: $font-size-base;
          letter-spacing: 0.8px;
          color: $gray-darker;
          margin-bottom: 8px;
          min-height: 44px;
          
          @include line-clamp(2);

          @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
            font-weight: $font-weight-normal;
          }

          @include media-breakpoint-up(lg) {
            margin-bottom: 4px;
            font-size: $h3-font-size;
            letter-spacing: 1.6px;
            // -webkit-line-clamp: 1;
            text-align: left;
          }

          span {
            display: inline-block;
          }
        }
      }

      &__button {
        display: inline-block;
        min-width: 200px;

        @include media-breakpoint-up(xl) {
          min-width: 240px;
          margin-left: 24px;
        }

        @include media-breakpoint-up(xxl) {
          margin-left: 50px;
        }
      }
    }

    &__success-text {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        font-size: $font-size-large;
        letter-spacing: 0.9px;
        color: $gray-darker;
      }
    }

    &--bundle {
      .add-to-cart {
        &__header {
          &__container {
            flex-direction: column;
            justify-content: center;

            @include media-breakpoint-up(lg) {
              flex-direction: row;
              justify-content: center;
            }
          }

          &__image {
            width: 100%;
            height: 60px;
            margin-bottom: 8px;

            @include media-breakpoint-up(md) {
              height: 80px;
            }

            @include media-breakpoint-up(lg) {
              width: fit-content;
            }

            @include media-breakpoint-up(xxl) {
              height: 128px;
            }

            &__item {
              width: 60px;
              height: 60px;
              margin-right: 20px;
              position: relative;

              @include media-breakpoint-up(md) {
                width: 80px;
                height: 80px;
                margin-right: 14px;
              }

              @include media-breakpoint-up(xxl) {
                width: 128px;
                height: 128px;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          &__contents {
            width: 100%;
            text-align: center;
            padding: 0;

            @include media-breakpoint-up(lg) {
              width: auto;
              flex-grow: 1;
              padding-left: 15px;
            }

            @include media-breakpoint-up(xxl) {
              padding-left: 56px;
            }
          }
        }
      }
    }
  }

  &__body {
    text-align: center;
    font-size: $font-size-base;
    color: $gray-darker;
    padding: 35px 20px 16px;

    @include media-breakpoint-up(lg) {
      padding: 37px 20px 28px;
    }

    &__icon {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      margin-bottom: 10px;

      @include media-breakpoint-up(lg) {
        width: 70px;
        height: 70px;
        margin-bottom: 24px;
      }

      img {
        height: auto;
      }
    }

    p.add-to-cart__body__success-text {
      color: $os-green;
      font-size: $h5-font-size;
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
      }
    }
  }
}