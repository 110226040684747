@import '@assets/reuse-mixin.scss';

.lead-info {
  padding: 24px 24px 32px;
  background-color: $white;
  margin-bottom: 24px;
  color: $gray-darker;

  &:last-child {
    margin-bottom: 0;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;

    &--space-between {
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    &--mg-bt-lg {
      margin-bottom: 18px;
    }

    &__block {
      min-width: 50%;

      @include media-breakpoint-up(md) {
        min-width: 30%;
        padding-right: 12px;
      }
    }
  }

  &__line {
    margin-bottom: 8px;
  }

  &__label,
  &__value {
    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }
  }

  &__view-detail,
  &__name,
  &__label {
    font-weight: $font-weight-normal;
  }

  &__name {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: $font-size-large;
  }

  &__type {
    margin-top: 8px;
    color: $cp-gray;
    display: block;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm){
      flex-direction: row;
    }
  }

  &__status {
    padding: 4px 12px;
    background: rgba(0, 174, 77, 0.06);
    border-radius: 12.5px;

    &:not(:first-child) {
      margin-bottom: 12px;
      @include media-breakpoint-up(sm) {
        margin-left: 12px;
        margin-bottom: 0;
      }
    }

    &__text {
      color: $os-green;
      font-weight: $font-weight-normal;
    }
  }
}