@import '@assets/reuse-mixin.scss';

.info-box {
  background-color: $cp-background;
  padding-bottom: 18px;

  &__content {
    background-color: $white;
    min-height: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding: 20px;
    padding-top: 200px;

    &__image {
      margin-bottom: 18px;
      max-width: 50px;
    }

    &__text {
      color: $os-green;
      margin-bottom: 24px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: $font-size-large;
      }
    }

    &__btn {
      .btn {
        @include media-breakpoint-up(md) {
          min-width: 280px;
        }
      }
    }
  }
}