@import '@assets/reuse-mixin';

.cart-mini-item {
  position: relative;

  &__icon-delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: auto;
  }

  &__infor-item {
    display: flex;

    &__image {
      width: 96px;

      @include media-breakpoint-up(lg) {
        width: 120px;
      }
    }

    &__contents {
      width: calc(100% - 96px);
      padding: 0 0 0 8px;
      font-size: $font-size-smaller;

      @include media-breakpoint-up(md) {
        padding: 0 0 0 12px;
      }

      @include media-breakpoint-up(lg) {
        width: calc(100% - 120px);
      }

      .item-name {
        font-size: $font-size-large;
        color: #272727;
        width: 70%;
        margin-bottom: 12px;

        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
        }

        @include media-breakpoint-up(lg) {
          width: 65%;
          font-size: $h4-font-size;
        }
      }
    }
  }

  &__other-infor-item {
    font-size: $font-size-smaller;
    margin: 10px 0 5px 0;
  }

  &__infor-price {
    &__total-amount {
      color: #272727;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      
      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }
      
      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }
  }
  
  &__infor-quantity {
    color: $gray-darker;
    font-size: $font-size-small;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
    }
  }

  &__infor-price-group {
    text-align: right;

    p {
      margin: 0;
    }
  }

  &__infor-price-old {
    font-size: $font-size-smaller;
    color: $gray;
    text-decoration: line-through;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-small;
    }
  }
}