@import '@assets/reuse-mixin.scss';

.osim-account {
  padding: 24px 0;

  @include media-breakpoint-up(lg) {
    padding: 40px 0;
  }

  &__heading {
    margin-bottom: 24px;
    
    @include media-breakpoint-up(lg) {
      margin-bottom: 48px;
    }

    &__title {
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  &__tab {
    &__wrap {
      max-width: 1000px;
      margin: 0 auto;
    }

    &__content {
      max-width: 480px;
      margin: 0 auto;
    }

    div.tab-content {
      border-top: none;
    }
  }

  //INFO: Customize form bootstrap

  .form-group {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    label {
      margin-bottom: 5px;
    }
  }

  .form-group--checkboxes {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }
  }

  .form-check-label {
    margin-left: 10px;
  }

  //INFO: Customize Tab

  .nav-item {
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    width: 50%;
    color: $gray;
    padding-bottom: 5px;
    border-bottom: 2px solid $gray-lightest;

    &:hover {
      text-decoration: none;
    }

    &.active {
      border-bottom: 2px solid $gray-darker;
      color: $gray-darker;
    }
  }

  .tab-content {
    padding: 36px 20px 32px;
    border-top: 1px solid $gray-lightest;
    border-bottom: 1px solid $gray-lightest;
    max-width: 1000px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      border: 2px solid $gray-lightest;
    }

    @include media-breakpoint-up(lg) {
      padding: 40px 20px;
    }
  }

  .tab-content {
    .fade:not(.show) {
      display: none;
    }
  }
}

.osim-account-form {
  @include media-breakpoint-up(md) {
    max-width: 550px;
  }

  &__social-login {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__btn {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-bottom: 0;
      }

      .btn {
        width: 280px;
        font-weight: $font-weight-normal;
        font-size: $font-size-small;
        letter-spacing: 2.24px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          width: 320px;
          font-size: $font-size-base;
          letter-spacing: 2.56px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 32px;
    text-align: center;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }
  }

  &__form {
    &__forgot-pass {
      margin-top: -20px;

      @include media-breakpoint-up(lg) {
        margin-top: -24px;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;

      &.form-group {
        margin-bottom: 18px;
        
        @include media-breakpoint-up(lg) {
          margin-bottom: 24px;
        }
      }

      &__item {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }

    &__subscription {
      white-space: pre-line;
    }

    .form-group {
      p {
        a {
          margin-left: 5px;

          &:hover {
            text-decoration: underline;
          }
        }

        &.osim-account-form__form__subscription {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.register-last-step {
  max-width: 1000px;
  margin: -24px auto 0;
  text-align: center;

  @include media-breakpoint-up(lg) {
    margin-top: -40px;
  }

  &__title {
    font-size: $h1-font-size-mobile;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size;
      color: $gray-darker;
      margin-bottom: 30px;
    }
  }

  &__wrap {
    padding: 20px;
    border-top: 1px solid $gray-lighter;
    border-bottom: 1px solid $gray-lighter;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;

    @include media-breakpoint-up(lg) {
      border: 1px solid $gray-lighter;
      padding: 40px;
      font-size: $h5-font-size;
      color: $gray-darker;
      margin: 0;
    }

    p {
      margin-bottom: 10px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
      }

      &.register-last-step__email {
        font-weight: $font-weight-normal;
      }

      &.register-last-step__resend-mail {
        span {
          color: $gray;
          margin-right: 5px;

          &:last-child {
            cursor: pointer;
            color: $link-color;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
