@import'@assets/reuse-mixin.scss';

.cart-item {
  padding-top: 24px;
   
  @include media-breakpoint-up(lg) {
    padding-top: 32px;
  }

  &__container {
    position: relative;
    max-width: 610px;
    margin: 0 auto;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .heart {
      top: 0;
      right: 0;

      @include media-breakpoint-up(lg) {
        top: 20px;
        right: 15px;
      }
    }
  }

  &__image-row {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    // @include media-breakpoint-up(lg) {
    //   // padding-top: 28px;
    //   max-width: 610px;
    // }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    > a {
      display: block;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__btn {
    text-align: center;
    margin-top: 18px;
  }

  &__btn-edit {
    min-width: 128px;
    color: $white !important;
  }

  &__btn-remove {
    margin-top: 10px;
    text-transform: none;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    letter-spacing: 1px;
    border: none;
    background: none;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
    }
    
    &:focus {
      outline: none;
    }
  }

  .link-text-underline {
    padding: 4px 0 0 34px;
  }
}