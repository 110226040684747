@import "../../assets/reuse-mixin.scss";

.recently-viewed-products {
	padding: 50px 0 12px;

	@include media-breakpoint-up(xl) {
		padding: 70px 0 24px;
	}

	&__title {
		margin-bottom: 24px;

		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}

	&__swiper {
		position: relative;

		&.center-item {
			.swiper-wrapper {
				justify-content: center;
			}
		}

		&.center-item-desktop {
			@include media-breakpoint-up(md) {
				.swiper-wrapper {
					justify-content: center;
				}
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			display: none;
			width: 44px;
			font-size: 20px;
			color: $gray;
			border-radius: 50%;
			transition: 0.5s;
			
			&:hover {
				color: $gray-darker;
			}

			@include media-breakpoint-up(lg) {
				display: flex;
			}

			&::after {
				font-size: $h4-font-size;
				font-weight: $font-weight-bold;
			}
		}

		.swiper-button-next {
			right: -32px;
		}

		.swiper-button-prev {
			left: -32px;
		}
	}

	&__contents {
		text-align: center;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		// height: 100%;
		transition: all 0.3s;
		
		&:hover {
			background-color: $os-brown-lightest;

			.heart {
				opacity: 1;
			}

			.recently-viewed-products__button,
			.show-notify-me {
				opacity: 1;
			}
		}

		@include media-breakpoint-up(lg) {
			padding: 0 40px 40px;
		}

		@include media-breakpoint-up(xxl) {
			padding: 0 60px 40px;
		}
	}

	&__image {
    width: 100%;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    margin: 10px 0 18px;
    display: block;

    @include media-breakpoint-up(lg) {
			margin: 24px 0;
    }

    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease;
    }
  }

	// &__thumb {
	// 	height: 120px;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: flex-end;
	// 	margin-bottom: 15px;

	// 	@include media-breakpoint-up(md) {
	// 		height: 170px;
	// 	}

	// 	@include media-breakpoint-up(lg) {
	// 		height: 220px;
	// 		margin-bottom: 20px;
	// 	}

	// 	img {
	// 		height: auto;
	// 		width: auto;
	// 		max-width: 100%;
	// 		max-height: 100%;
	// 	}
	// }

	&__name {
		font-size: $font-size-large;
		margin-bottom: 18px;

		@include line-clamp(2);

		@include media-breakpoint-up(xl) {
			font-size: $h4-font-size;
			margin-bottom: 24px;
		}

		a {
			color: $gray-darker;

			&:hover {
				color: $link-color;
			}
		}
	}

	&__selling-price {
		font-size: $font-size-large;
		color: $gray-darker;

		.product-card-price__member-wrapper {
			justify-content: center;
		}

		@include media-breakpoint-up(xl) {
			font-size: $h4-font-size;
		}
	}

	&__list-price {
		text-decoration: line-through;
		font-size: $font-size-smaller;
		color: $gray;
		margin-top: 4px;

		@include media-breakpoint-up(lg) {
			font-size: $font-size-base;
			margin-top: 8px;
		}
	}

	&__btn-group {
		margin-top: 24px;

		@include media-breakpoint-up(lg) {
			margin-top: 32px;
		}
	}

	&__button,
	.show-notify-me {
		display: block;
		max-width: 240px;
		font-size: $font-size-small;
		margin: 0 auto 12px;

		@include media-breakpoint-up(lg) {
			font-size: $font-size-base;
			opacity: 0;
			transition: all 0.3s;
			margin: 0 auto 16px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.heart {
		opacity: 0;
		display: none;

		@include media-breakpoint-up(xl) {
			display: inline-block;
		}
	}

	.list-tags {
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	&.skeleton__item {
		.recently-viewed-products__title {
			.text-line {
        @include media-breakpoint-up(lg) {
          @include skeleton($lines: 1, $line-height: 42px, $line-gap: 0);
        }
      }
		}
	}
}
