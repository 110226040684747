@import "@assets/reuse-mixin.scss";

.carousel-width-icon {
  padding: 30px 0 24px;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 50px 0 40px;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    margin: 0 auto;
    position: relative;
    
    @include media-breakpoint-up(xxl) {
      max-width: 1280px;
    }
  }

  &__heading {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__title {
      margin-bottom: 0;
    }
  
    &__descrition {
      margin-top: 12px;
      font-size: $font-size-base;
      // letter-spacing: 1.28px;
  
      @include media-breakpoint-up(lg) {
        margin-top: 15px;
        font-size: $font-size-large;
      }
  
      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
        // letter-spacing: 1.6px;
      }
    }
  }

  &__top {
    padding: 0 0 24px 0;

    @include media-breakpoint-up(lg) {
      max-width: 880px;
      margin: 0 auto;
      padding: 23px 0 55px 0;
    }

    .carousel-width-icon__item__image {
      cursor: pointer;
      position: relative;
      border-radius: 50%;
      border: 1px solid transparent;
      transition: 0.3s;
      overflow: hidden;
      width: 48px;
      margin: 0 auto 12px;

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }

      @include media-breakpoint-up(xl) {
        width: 72px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .swiper-slide {
      &.slide-selected {
        .carousel-width-icon__item__image {
          border-color: $gray-darker;
        }
      }
    }
  }

  &__bottom {
    .swiper-container {
      @include media-breakpoint-up(lg) {
        padding: 50px 0;
      }
    }

    .carousel-width-icon__item__image {
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }

      img {
        width: 100%;
      }
    }

    .carousel-width-icon__item__text {
      padding: 0 16px;

      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }

    .swiper-slide {
      @include media-breakpoint-up(lg) {
        transform: scale(1) translateY(-6px);
        // opacity: 0.8;
        transition: 0.5s;
      }

      .carousel-width-icon__item__content,
      .carousel-width-icon__item__text {
        opacity: 0;
      }
    }

    .swiper-slide-active {
      @include media-breakpoint-up(lg) {
        z-index: 1;
        transform: scale(1.2) translateY(0);
        // opacity: 1;

        .carousel-width-icon__item__image {
          img {
            box-shadow: 0 3px 6px rgba($black,0.32);
          }
        }
      }

      .carousel-width-icon__item__content,
      .carousel-width-icon__item__text {
        opacity: 1;
      }
    }
  }

  &__item {
    &__image {
      margin-bottom: 24px;
    }

    &__text {
      color: $gray-darker;
      font-size: $font-size-base;
      // letter-spacing: 1.28px;
      text-align: center;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      // @include media-breakpoint-up(xl) {
      //   font-size: $h5-font-size;
      //   // letter-spacing: 1.6px;
      // }
    }
  }

  &__button {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
      margin-top: 32px;
    }

    a {
      display: inline-block;
      min-width: 200px;

      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }
}
