@import'../../../assets/reuse-mixin.scss';

.back-to-top {
  background: url('../../../assets/images/icon-backtotop.png') 0 0 no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: -36px;
  z-index: $HEADER_STICK_ZINDEX - 1;
  transition: bottom 0.5s;

  @include media-breakpoint-up(lg) {
    width: 36px;
    height: 36px;
  }
  
  @include media-breakpoint-up(xxl) {
    // right: 164px;
    right: 48px; //INFO: Follow as new design
  }

  &.show {
    bottom: 40px;

    @include media-breakpoint-up(xl) {
      bottom: 72px;
    }

    &.has-social-icon {
      right: 40px;
      bottom: 90px;

      @include media-breakpoint-up(lg) {
        right: 36px;
        bottom: 94px;
      }
    }
  }

  &:hover {
    background-position: 0 -28px;

    @include media-breakpoint-up(lg) {
      background-position: 0 -36px;
    }
  }
}