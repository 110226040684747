@import "@assets/reuse-mixin";

.tax-message-detail-text {
  display: block;
  text-align: right;
  font-weight: $font-weight-bold;
  font-style: italic;
  width: 100%;
  font-size: $font-size-smaller;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base;
  }
}
