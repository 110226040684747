@import '@assets/reuse-mixin.scss';

.payment-success {
  text-align: center;
  padding: 20px 5px;
  color: $gray-darker;

  @include media-breakpoint-up(lg) {
    padding: 37px 0 40px;
  }

  .container {
    max-width: 740px;
    margin: 0 auto;
  }

  &__image {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;

    @include media-breakpoint-up(lg) {
      width: 70px;
      height: 70px;
      margin: 0 auto 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text,
  &__thankyou {
    font-weight: $font-weight-normal;
  }

  &__text {
    color: $os-green;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &__thankyou {
    font-size: $font-size-large;
    margin-bottom: 4px;

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
    }
  }

  &__desc {
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h4-font-size;
    }
  }
}