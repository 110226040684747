@import '@assets/reuse-mixin.scss';

.warranty-product {
  &:first-child {
    .warranty-product__wrap {
      border-top: 1px solid $gray-light;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  &__wrap {
    padding: 24px 20px;
    border-bottom: 1px solid $gray-light;
    color: $gray-darker;

    @include media-breakpoint-up(md) {
      border: 1px solid $gray-light;
      padding: 24px;
    }

    @include media-breakpoint-up(xl) {
      padding: 32px;
    }
  }

  &__order-id,
  &__order-date {
    font-size: $font-size-base;

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  &__order-date {
    margin-top: 4px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      padding-left: 16px;
      margin-left: 16px;
      border-left: 1px solid $gray-1000;
      line-height: 1;
    }
  }

  &__group {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: baseline;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 32px;
    }
  }

  &__info {
    display: flex;

    &__left {
      width: 120px;

      @include media-breakpoint-up(md) {
        width: 160px;
      }

      @include media-breakpoint-up(xl) {
        width: 200px;
      }

      .rating-star-icon {
        @include media-breakpoint-up(md) {
          width: 20px;
          height: 20px;
        }

        @include media-breakpoint-up(xl) {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__right {
      width: calc(100% - 120px);
      padding-left: 12px;
      display: flex;
      flex-direction: column;

      a {
        &:first-child {
          &:hover {
            text-decoration: none !important;
          }
        }
      }

      @include media-breakpoint-up(md) {
        width: calc(100% - 160px);
      }

      @include media-breakpoint-up(xl) {
        width: calc(100% - 200px);
        padding-left: 20px;
      }

      @include media-breakpoint-up(xxl) {
        padding-left: 60px;
      }
    }
  }

  &__image {
    width: 120px;
    height: 120px;

    @include media-breakpoint-up(md) {
      width: 160px;
      height: 160px;
    }

    @include media-breakpoint-up(xl) {
      width: 200px;
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: $font-weight-normal;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h3-font-size - 0.2;
    }
  }

  &__color,
  &__warranty {
    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size;
    }
  }

  &__color {
    margin-top: 18px;

    @include media-breakpoint-up(xl) {
      margin-top: 24px;
    }
  }

  &__warranty {
    margin-top: 12px;
    display: block;

    @include media-breakpoint-up(xl) {
      margin-top: 16px;
    }

    &--register {
      font-weight: $font-weight-normal;

      &:hover {
        text-decoration: underline;
      }

      @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
      }
    }
  }

  &__rating {
    margin-top: 18px;

    @include media-breakpoint-up(md) {
      margin-top: 4px;
    }

    &__star {
      display: flex;

      &__item {
        margin-right: 8px;
        line-height: 1;

        @include media-breakpoint-up(xl) {
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;
        }

        i {
          color: $primary;
          font-size: 19px;
          line-height: 1;

          @include media-breakpoint-up(xl) {
            font-size: 24px;
          }
        }
      }
    }

    &__review {
      display: block;
      margin-top: 12px;
      font-weight: $font-weight-normal;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__view-btn {
    margin-top: 24px;

    @include media-breakpoint-up(md) {
      margin-top: auto;
    }

    //@include media-breakpoint-up(xl) {
    //  margin-top: 64px;
    //}

    .btn {
      min-width: 200px;
      border-color: $gray-dark;
      cursor: pointer;

      @include media-breakpoint-up(xl) {
        min-width: 240px;
      }
    }
  }

  p {
    margin-bottom: 0;
  }
}