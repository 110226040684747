@import '@assets/reuse-mixin.scss';

.send-order-customer-modal {
  &__input {
    margin-bottom: 24px;
  }

  &__btn {
    text-align: center;
    margin-bottom: 10px;

    button {
      font-size: $font-size-small;
    }

    .btn-save {
      min-width: 128px;
    }

    .btn-cancel {
      background: none;
      outline: none;
      border: none;
      font-weight: $font-weight-normal;
    }
  }

  .form-group--group-select-input .form-group--select .customization-dropdown__control, .form-group--group-select-input .form-group--select .customization-dropdown__control {
    height: calc(2.4rem + 1.6rem);
    @include media-breakpoint-up(md) {
      height: calc(2.4rem + 1.6rem);
    }
  }
}

