@import '../../../../assets/reuse-mixin.scss';

.product-deals {
  &__list {
    &__item {
      &__image {
        width: 100%;
        margin-bottom: 18px;
        
        @include media-breakpoint-up(xl) {
          margin-bottom: 24px;
        }

        .product-also-bought-image {
          width: 100%;
        }
      }

      &__content {
        text-align: center;

        .question-tooltip-icon .item-tooltip__label {
          left: 100%;
          transform: translateY(-50%);
          top: 50%;
          margin-top: 0;
          margin-left: 4px;

          &:after {
            top: 50%;
            left: -4px;
            transform: translateY(-50%) rotate(225deg);
          }
        }
      }
    }
  }

  &__info {
    &__name,
    &__price {
      font-size: $font-size-large;
      color: $gray-darker;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size;
      }
    }

    &__name {
      color: $gray-darker;
      margin-bottom: 18px;
      transition: all .25s;

      @include line-clamp(1);

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }

      a {
        color: inherit;

        &:hover {
          color: $os-blue;
        }
      }
    }

    &__price {
      margin-bottom: 4px;
      
      @include media-breakpoint-up(lg) {
        margin-bottom: 8px;
      }
    }

    &__old-price {
      color: $gray-dark;
      font-size: $font-size-smaller;
      height: 20px;
      display: block;

      @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
      }
    }
  }
}