.free-gift-information-pop-up {
    &__header {
        display: flex;
        &__left {
            flex: 1;
        }
        &__right {
            flex: 1;
        }
    }
}