@import '@assets/reuse-mixin';

.header__search__suggestions {
  visibility: hidden;

  &.visible {
    visibility: visible;
  }

  &__item {
    &.highlight {
      color: $gray-darker;
      background-color: $os-brown-lighter;
    }
  }
}

.header__search__input__wrapper {
  .autocomplete--form {
    width: 100%;
    height: 100%;
  }
}