@import '@assets/reuse-mixin.scss';

.cp-page-header {
  background: $cp-background;
  padding: $CP_DEMO_STATUS 0 32px 0;

  &__wrap {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__left {
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      padding-left: 20px;
      margin-bottom: 0;
    }

    &-title {
      display: flex;
    }
  }

  &__title,
  &__label {
    font-weight: $font-weight-normal;
  }

  &__title {
    margin-bottom: 0;
    font-size: $h4-font-size;
  }

  &__label {
    color: $cp-gray;
    margin: 8px 0 0;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }
  }

  &__time-select {
    width: 224px;
    height: 48px;
    color: $gray-darker;
    cursor: pointer;

    .react-date-picker {
      width: 100%;
      height: 100%;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }

      &__calendar {
        z-index: 2;
      }

      &__wrapper {
        border-color: $cp-border-color;
      }

      &__inputGroup {
        padding-left: 24px;
        padding-right: 0;

        &__month {
          max-width: 33px;
          padding: 0 5px;
          text-align: center;
          appearance: none;
          cursor: pointer;
        }

        &__divider {
          display: none;
        }

        &__year {
          padding: 0;
        }
      }

      &__calendar-button {
        padding-right: 24px;
        padding-left: 0px;
      }

      &__clear-button {
        display: none;
      }
    }
  }

  &__btn {
    position: relative;

    .btn {
      min-width: 128px;
    }

    .cp-icon--filter {
      cursor: pointer;
    }
  }

  &__btn-close {
    padding-right: 24px;

    i {
      font-size: $font-size-base;
    }
  }

  &__btn-link {
    padding: 7px 0;
  }

  .cp-page-header__left {
    //display: flex;
  }

  .task-list_button {
    margin: 5px 0 0 5px;
  }

  .task-list_link {
    text-decoration: underline;
    font-size: 18px;
  }
}