@import'@assets/reuse-mixin.scss';

.os-carousel {
  padding: 25px 0;

  @include media-breakpoint-up(md) {
    padding: 40px 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 40px 0 60px;
  }

  &__wrap {
    text-align: center;

    @include media-breakpoint-up(xl) {
      max-width: 880px;
      margin: 0 auto;
    }
  }

  &__description {
    font-size: $font-size-base;
    color: $gray-900;
    padding: 0 0 25px 0;

    @include media-breakpoint-up(md) {
      font-size: $h6-font-size;
      padding: 0 0 32px 0;
    }
  }

  &__item {
    cursor: pointer;
    
    &__title {
      text-align: center;
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
      text-transform: uppercase;
      color: $gray-darker;
      margin-top: 24px;
      opacity: 0;
      transition: 0.7s all;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }

    &.swiper-slide-active {
      .os-carousel__item__title {
        opacity: 1;
      }
    }
  }

  img {
    width: 100%;
  }

  &--text-light {
    color: $white;
    
    .os-carousel {
      &__heading,
      &__description {
        color: $white;
      }
    }
  }

  &--indicator-light {
    &.os-carousel {
      .swiper-pagination-bullet {
        border-color: $white;
      }
  
      .swiper-pagination-bullet-active {
        background-color: $white;
      }
    }
  }

  // Customize Swiper have pagination-bullets

  .swiper-container {
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      padding-bottom: 60px;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 80px;
    }
  }

  @include pagination-bullets-custom();

  &.skeleton__item {
    .image-wrap {
      padding-bottom: 800/1920 * 100%;
    }
  }
}