@import '../../../../assets/reuse-mixin.scss';

.product-banner {
  position: relative;
  color: $gray-darker;

  &.text-white {
    color: $white;
  
    h1,
    h2 {
      color: $white;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 560/375 * 100%;

    @include media-breakpoint-up(md) {
      padding-bottom: 800/1920 * 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrapper-contents {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(lg) {
      > * {
        height: 100%;
      }
    }
  }

  &__contents {
    text-align: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 48px;
    width: 100%;

    @include media-breakpoint-up(md) {
      justify-content: center;
      text-align: left;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      max-width: 50%;
      height: 100%;
    }

    &__title {
      padding: 0 20px;
      
      @include media-breakpoint-up(md) {
        padding: 0;
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
        font-size: $h3-font-size;
      }
    }

    &__sub-title {
      text-transform: uppercase;
      margin-bottom: 3px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 5px;
      }
    }

    &__description {
      font-size: $font-size-base;
      margin-bottom: 20px;
      font-weight: $font-weight-light;
      @include line-clamp(3);

      @include media-breakpoint-up(md) {
        @include line-clamp(2);
      }

      @include media-breakpoint-up(xl) {
        @include line-clamp(3);
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
        margin-bottom: 30px;
        max-width: 600px;
      }
    }

    &__btn {
      &__item {
        width: 200px;

        @include media-breakpoint-up(lg) {
          width: 240px;
        }
      }
    }

    .btn-link,
    .btn-link-white {
      margin-top: 10px;
    }
  }

  .content-position {
    display: flex;
    height: 100%;
    padding-top: 82px;
  
    @include media-breakpoint-up(md) {
      align-items: center;
      padding-top: 0;
    }
  
    &.content-right {
      justify-content: flex-end;
  
      .content-wrapper {
        @include media-breakpoint-up(md) {
          padding-right: 0;
          padding-left: 40px;
        }
  
        @include media-breakpoint-up(xl) {
          padding: 0 50px 0 70px;
        }
  
        @include media-breakpoint-up(xxl) {
          padding: 0 85px 0 110px;
        }
      }
    }
  
    .content-wrapper {
      @include media-breakpoint-up(md) {
        max-width: 50%;
        padding-right: 50px;
      }
  
      @include media-breakpoint-up(xl) {
        padding-right: 100px;
      }
  
      @include media-breakpoint-up(xxl) {
        padding-right: 200px;
      }
    }
  }

  &.skeleton__item {
    border-bottom: 1px solid $gray-200;

    .loading__default {
      @include centerer(true, true)
    }
  }
}