@import '@assets/reuse-mixin.scss';

.popup-banner {
  &__content {
    .modal-dialog {
      align-items: center;
      height: auto;
      min-height: 100%;
    }

    .modal-content {
      padding: 0;
      margin-top: 0;

      @include media-breakpoint-up(lg) {
        width: 75%;
        max-width: 1400px;
      }
    }

    .osim-popup__header {
      margin-bottom: 0;
      min-height: 0;
    }

    .osim-popup__close-btn {
      z-index: 1;
    }

    .main-banner {
      &__background {
        padding-bottom: 1080/1920 * 100%; //INFO: Follow video ratio of customer's site

        .popup-banner-video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: all 1.5s;
          transform: unset;
        }

        :not(.hide-video-cover) {
          opacity: 1;
        }

        &__video-cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: $Z_INDEX_NORMAL;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 1;
          transition: all 1.25s;

          img {
            position: absolute !important;
            width: 100%;
            height: 100% !important;
            object-fit: cover;
          }
        }

        &.hide-video-cover {
          .main-banner {
            &__background {
              &__video-cover {
                opacity: 0;
                z-index: 0;
              }
            }
          }

          .popup-banner-video {
            opacity: 1;
          }
        }
      }

    }

  }

  &__countdown {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 45px 20px;
    @include media-breakpoint-up(md) {
      padding: 16px 40px;
    }
    @include media-breakpoint-up(xl) {
      padding: 33.5px 80px;
    }

    &-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      @include media-breakpoint-up(md) {
        display: unset;
        justify-content: unset;
      }
    }

    .countdown-timer {
      @include media-breakpoint-up(xl) {
        &__container {
          padding: 30px;
        }

        &__title {
          font-size: 2.5rem;
          margin-bottom: 10px;
        }

        &__detail {
          &-item {
            .timer-box {
              width: 80px;
              height: 70px;
              margin-bottom: 20px;

              & > p {
                font-size: $h2-font-size;
              }
            }
          }

          &-label {
            font-size: $font-size-base;
          }
        }
      }
    }

    &-desktop {
      &.content-left {
        left: 0;
      }

      &.content-right {
        right: 0;
      }

      &.content-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &.content-top-center {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.content-bottom-center {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.content-bottom-left {
        bottom: 0;
        left: 0;
      }

      &.content-bottom-right {
        bottom: 0;
        right: 0;
      }

      &.content-center-left {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &.content-center-right {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &.content-top-left {
        top: 0;
        left: 0;
      }

      &.content-top-right {
        top: 0;
        right: 0;
      }
    }

    &-mobile {
      &.content-top {
        top: 0;
      }

      &.content-middle {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &.content-bottom {
        bottom: 0
      }
    }
  }
}
