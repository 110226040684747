@import '@assets/reuse-mixin.scss';

.campaign-QRCode {
  .category-landing--view {
    &.--cp-view {
      .category-product {
        &__contents {
          padding: 24px 0;
        }
  
        &__image {
          @include media-breakpoint-up(md) {
            margin-right: 20px;
          }
        }
  
        &__main {
          &__name {
              margin: 0;
          }

          &__QRCode {
            margin-top: 24px;

            &__wrap {
              padding: 6px;
              border: 1px solid $border-form;
              width: 120px;
              height: 120px;
            }
          }

          &__sort-decs {
            margin-top: 24px;
          }
        }
      }
    }
  }
}