@import '@assets/reuse-mixin.scss';

.warranty-buying {

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  &-list {
    &__list {
      padding-bottom: 10px;
      border-bottom: 1px solid $gray;

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }

    &__item-wrap {
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      border: 1px solid $gray-light;
      padding: 16px;
      transition: all 0.3s;
      height: 100%;
      cursor: pointer;
      position: relative;

      @include media-breakpoint-up(lg) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      @include media-breakpoint-up(xxl) {
        padding: 30px;
      }

      &:hover {
        border: 1px solid $gray-darker;
      }

      &.active {
        border: 1px solid $gray-darker;
      }

      .check-mark {
        position: absolute;
        right: -3px;
        bottom: -6px;

        @include media-breakpoint-up(md) {
          right: -5px;
          bottom: -6px;
        }
      }

      &.disable {
        color: $gray-light;
      }

      &__name {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
          margin-bottom: 4px;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h4-font-size;
          font-weight: $font-weight-light;
        }
      }

      &__price {
        font-weight: bold;
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h5-font-size;
        }
      }
    }

    a {
      display: block;
      padding-bottom: 8px;
      text-decoration: underline;
      font-size: $font-size-smaller;

      @include media-breakpoint-up(md) {
        padding-bottom: 16px;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
      }

      :hover {
        text-decoration: underline !important;
      }
    }
  }

  .warranty-buying-list {
    padding-bottom: 8px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }

  .warranty-buying-list-cp {
    .warranty-buying-list__item-wrap {
      margin-bottom: 16px;
    }

    .warranty-buying-list__item {
      flex-direction: row;
      padding: 16px;

      &__name {
        margin: 0;
        font-size: $font-size-base;
        color: $gray-900;
      }

      &__price {
        font-size: $font-size-base;
        color: $gray-900;
      }
    }
  }
}