@import '@assets/reuse-mixin.scss';

.special-request {

    &__title {
        font-size: 3.5rem;
        margin-bottom: 15px;
        color: $gray-darker;
        font-weight: $font-weight-normal;
    }

    &__description {
        font-size: $font-size-base;
        word-break: break-word;
        color: $gray-darker;
        font-weight: $font-weight-normal;
    }
}