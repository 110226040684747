@import "@assets/reuse-mixin.scss";
@import "../ContactUsCTA/ContactUsCTA";

.product-support-cta {
  &__box {
    @extend .contact-us-cta__box;
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
      padding-left: 12px;
      padding-right: 12px;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__detail {
    margin-top: 30px;
    transform: scale(1);
    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
  }

  &__swiper {
    padding: 0 24px;
    @include media-breakpoint-up(lg) {
      padding: 0 36px;
    }

    &-title {
      text-align: center;
      font-size: $h4-font-size-mobile;
      line-height: 2.8rem;
      font-weight: $font-weight-normal;
      margin-bottom: 18px;
      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
        line-height: 4rem;
        margin-bottom: 26px;
      }
    }

    &-container {
      .swiper {
        &-item {
          width: 100%;
          cursor: pointer;
        }

        &-image {
          width: 100%;
          padding-bottom: 100%;
          position: relative;
          border: 1px solid $gray-light;
          
          &.active {
            border: 1px solid $gray-900;
          }

          & > img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: contain;
          }
        }

        &-name {
          margin-top: 8px;
          line-height: 2.4rem;
          font-size: $h5-font-size-mobile;
          font-weight: $font-weight-normal;
          @include media-breakpoint-up(md) {
            font-size: $h5-font-size;
            line-height: 2.8rem;
          }
        }
      }

      .swiper-button-next, .swiper-button-prev {
        color: $gray;
        position: fixed;
        width: fit-content;
        // @include media-breakpoint-down(sm) {
        //   display: none;
        // }

        &::after {
          font-size: 2.4rem;
        }
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-button-prev {
        left: 0;
      }

      //.swiper-button-next,
      //.swiper-button-prev {
      //  transform: translateY(-50%);
      //  height: 100%;
      //  margin: 0;
      //  background-color: $white;
      //  color: $gray;
      //
      //  &::after {
      //    font-size: 2.5rem;
      //  }
      //}
      //
    }
  }
}