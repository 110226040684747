@import '@assets/reuse-mixin.scss';

.product-list {
  padding-top: 18px;
  font-weight: $font-weight-normal;
  color: $gray-darker;

  ol li {
    list-style-type: decimal;

    &::marker {
      padding-left: 30px;
    }
  }

  .delivery-row {
    padding: 24px 0 0;

    @include media-breakpoint-up(md) {
      padding: 32px 0 0;
    }
    
  }

  .flex-name {
    display: flex;
    align-items: center;
    
    &--space-between {
      justify-content: space-between;
    }
  }

  &__item {
    &__color {
      border-bottom: 1px solid $gray;
      padding: 8px 0;

      @include media-breakpoint-up(lg) {
        padding: 16px 0;
      }
    }

    .delivery-col {
      &-left {
        @include media-breakpoint-up(lg) {
          padding-right: 20px;
        }

        @include media-breakpoint-up(xl) {
          padding-right: 40px;
        }
      }

      &-right {
        &__message {
          position: relative;

          & > .edit-free-gift-btn {
            top: 0;
          }
        }
      }
    }
  }

  &__image {
    padding-bottom: 24px;
    max-width: 450px;
    position: relative;
      
    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      max-width: 520px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  &__header {
    border-bottom: 1px solid $gray;
    padding-bottom: 8px;
    display: flex;

    @include media-breakpoint-up(lg) {
      padding-bottom: 16px;
    }

    &__information {
      max-width: calc(100% - 135px);
      
      @include media-breakpoint-up(lg) {
        max-width: calc(100% - 170px);
      }

      @include media-breakpoint-up(xxl) {
        max-width: calc(100% - 200px);
      }
    }

    &__name,
    &__price {
      font-size: $h5-font-size;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size - 0.2;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size + 0.2;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h3-font-size - 0.2;
      }
    }

    &__name {
      margin-bottom: 15px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }
    }

    &__price {
      max-width: 140px;
      text-align: right;
      margin-left: auto;
      
      @include media-breakpoint-up(md) {
        max-width: 180px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 220px;
      }

      span {
        display: block;
      }

      &__box {
        margin-top: 20px;
      }
    }

    &__status {
      font-weight: $font-weight-normal;
      margin-top: 4px;

      @include media-breakpoint-up(lg) {
        margin-top: 8px;
      }
    }
  }

  .product-list__products {
    padding-left: 20px;

    @include media-breakpoint-up(md) {
      padding-left: 30px;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 20px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 30px;
    }

    &__item {
      padding: 8px 0 0 5px;

      @include media-breakpoint-up(lg) {
        padding: 16px 0 0 5px;
      }

      &__information {
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
        }

        span {
          display: block;
        }
      }

      &__warranty {
        padding: 8px 0 8px 20px;
        margin-left:-20px;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;

        @include media-breakpoint-up(md) {
          padding: 8px 0 8px 30px;
          margin-left: -30px;
        }

        @include media-breakpoint-up(lg) {
          padding: 16px 0 16px 20px;
          margin-left:-20px;
        }

        @include media-breakpoint-up(xl) {
          padding: 16px 0 16px 30px;
          margin-left: -30px;
        }

        &__name {
          img {
            width: 20px;

            @include media-breakpoint-up(md) {
              width: 30px;
            }
            @include media-breakpoint-up(lg) {
              width: 20px;
            }
            @include media-breakpoint-up(xl) {
              width: 30px;
            }
          }

          span {
            display: inline-block;
            padding-left: 10px;
          }
        }
      }

      &__additional-options {
        padding: 8px 0 8px 20px;
        margin-left:-20px;
        border-bottom: 1px solid $gray;

        @include media-breakpoint-up(md) {
          padding: 8px 0 8px 30px;
          margin-left: -30px;
        }

        @include media-breakpoint-up(lg) {
          padding: 16px 0 16px 20px;
          margin-left:-20px;
        }

        @include media-breakpoint-up(xl) {
          padding: 16px 0 16px 30px;
          margin-left: -30px;
        }

        &__name {
          img {
            width: 20px;

            @include media-breakpoint-up(md) {
              width: 30px;
            }
            @include media-breakpoint-up(lg) {
              width: 20px;
            }
            @include media-breakpoint-up(xl) {
              width: 30px;
            }
          }

          span {
            display: inline-block;
            padding-left: 10px;
          }
        }
      }
    }
  }

  &__group {
    border-bottom: 1px solid $gray;

    &:last-child {
      border-bottom: 0;
    }

    &__header {
      padding: 8px 0;

      @include media-breakpoint-up(lg) {
        padding: 16px 0;
      }

      &__name {
        width: calc(100% - 140px);

        @include media-breakpoint-up(xl) {
          max-width: calc(100% - 185px);
        }
        
        .product-info-icon {
          margin-right: 10px;
        }

        img {
          width: 20px;

          @include media-breakpoint-up(md) {
            width: 30px;
          }

          @include media-breakpoint-up(lg) {
            width: 20px;
          }

          @include media-breakpoint-up(xl) {
            width: 30px;
          }
        }

        span {
          display: inline-block;
          // padding-left: 10px;
          width: calc(100% - 26px);

          @include media-breakpoint-up(lg) {
            width: calc(100% - 28px);
          }
        }
      }

      .link-text-underline {
        padding-left: 32px;
      }

      .option-name {
        max-width: calc(100% - 30px);
      }
    }

    &__body {
      padding: 0 0 8px 25px;
      font-weight: $font-weight-light;
      margin-top: -4px;
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 0 16px 28px;
        margin-top: -10px;
      }

      p {
        margin-bottom: 2px;
        letter-spacing: 1.28px;
        word-break: break-word;

        @include media-breakpoint-up(lg) {
          margin-bottom: 2px;
          letter-spacing: 1.6px;
        }

        &.smaller-text {
          letter-spacing: 0.6px;

          @include media-breakpoint-up(lg) {
            letter-spacing: 0.8px;
          }
        }
      }
    }
  }

  &__subtotal {
    padding: 8px 0;
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
    border-bottom: 1px solid $gray;

    @include media-breakpoint-up(md) {
      font-size: $h4-font-size - 0.2;
    }

    @include media-breakpoint-up(lg) {
      padding: 16px 0;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size + 0.2;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h3-font-size - 0.2;
    }

    p {
      margin: 0;
    }
  }

  &__deposit {
    padding: 8px 0;
    border-bottom: 1px solid $gray;
    
    @include media-breakpoint-up(lg) {
      padding: 16px 0;
      border-bottom: none;
    }

    &__item {
      padding-bottom: 8px;
      font-size: $font-size-base;
      font-weight: $font-weight-light;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-up(lg) {
        padding-bottom: 16px;
        font-size: $font-size-large;
      }

      &:first-child {
        padding-bottom: 16px;
        font-weight: $font-weight-normal;
        // font-size: $h3-font-size-mobile;
        
        // @include media-breakpoint-up(lg) {
        //   font-size: $h3-font-size - 0.2;
        // }

        font-size: $h5-font-size;

        @include media-breakpoint-up(md) {
          font-size: $h4-font-size - 0.2;
        }
    
        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size + 0.2;
        }
    
        @include media-breakpoint-up(xxl) {
          font-size: $h3-font-size - 0.2;
        }
      }
      
      &:last-child {
        padding-bottom: 0px;
      }

      &--free-gift {
        font-size: $font-size-smaller;
        padding-left: 28px;
        
        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
          padding-left: 36px;
        }
      }
      
      &--block {
        display: block;

        span:first-child {
          font-weight: $font-weight-normal;
        }

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }
      }

      &--border-top {
        padding-top: 8px;
        border-top: 1px solid $gray;
        
        @include media-breakpoint-up(lg) {
          padding-top: 16px;
        }
      }
      
      &--border-top-mobile {
        padding-top: 8px;
        border-top: 1px solid $gray;
        
        @include media-breakpoint-up(lg) {
          padding-top: 16px;
          border: none;
        }
      }

      &--fwRegular {
        font-weight: $font-weight-normal;
      }

      &--color-orange {
        color: $primary;
        font-size: $font-size-smaller;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
        }
      }

      &--align-item-top {
        align-items: baseline;
      }

      &__box {
        margin: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

      }
    }
  }

  &__button-pay-balance {
    padding-top: 24px;
    text-align: center;

    button, a {
      min-width: 200px;
    }
    
    @include media-breakpoint-up(lg) {
      padding-top: 32px;
      text-align: right;

      button, a {
        min-width: 240px;
      }
    }
  }
}
.special-deal-message {
  color: $os-green;
  font-weight: $font-weight-normal;
  margin-bottom: 8px;
  font-size: $font-size-base;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-large;
  }

  @include media-breakpoint-up(xxl) {
    font-size: $h5-font-size;
  }
}