@import '@assets/reuse-mixin.scss';

.payment-method-order {
  padding-bottom: 24px;

  @include media-breakpoint-up(md) {
    padding: 24px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 32px 0;
  }

  &__title {
    font-weight: $font-weight-normal;
    margin: 0;
  }

  &__detail {
    margin-top: 4px;

    @include media-breakpoint-up(lg) {
      margin-top: 8px;
      font-size: $h5-font-size;
    }
  }

  &__invoice {
    margin-top: 18px;

    @include media-breakpoint-up(lg) {
      margin-top: 24px;
    }

    &__link {
      font-size: $font-size-smaller;
      text-transform: uppercase;
      font-weight: $font-weight-bold;

      @include media-breakpoint-up(lg) {
        margin-top: 24px;
        font-size: $font-size-small;
      }
    }
  }
}