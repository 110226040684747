@import '@assets/reuse-mixin.scss';

.two-column11 {
  .special-deal.frequently-bought-together {
    background: transparent;
    // margin-left: -$grid-gutter-width/2;
    // margin-right: -$grid-gutter-width/2;

    // @include media-breakpoint-up(md) {
    //   margin-left: 0;
    //   margin-right: 0;
    // }
  }

  .col-md-6 {
    &:first-child {
      .special-deal.frequently-bought-together {
        @include media-breakpoint-up(md) {
          padding-right: 20px;
          padding-left: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/4});
        }

        @include media-breakpoint-up(lg) {
          padding-left: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/4});
        }

        @include media-breakpoint-up(xl) {
          padding-right: 50px;
          padding-left: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/4});
        }

        @media only screen and (min-width: 1600px) {
          padding-right: 75px;
        }

        @include media-breakpoint-up(xxl) {
          padding-left: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/4});
        }
      }
    }

    &:last-child {
      .special-deal.frequently-bought-together {
        @include media-breakpoint-up(md) {
          padding-left: 20px;
          padding-right: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/4});
        }

        @include media-breakpoint-up(lg) {
          padding-right: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/4});
        }

        @include media-breakpoint-up(xl) {
          padding-left: 50px;
          padding-right: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/4});
        }

        @media only screen and (min-width: 1600px) {
          padding-left: 75px;
        }

        @include media-breakpoint-up(xxl) {
          padding-right: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/4});
        }
      }
    }
  }

  &.full-width {
    .container {
      max-width: 100%;
      padding: 0;

      > .row {
        margin: 0;

        > [class^='col-'] {
          padding: 0;
        }
      }
    }
  }

  // accordion component
  .accordion {
    &__heading {
      margin: 0 0 36px;
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
        margin: 0 0 48px;
        text-align: left;
      }
    }

    &-wrap {
      width: auto;
      padding: $grid-gutter-width/2;

      @include media-breakpoint-up(md) {
        padding-left: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
      }

      @include media-breakpoint-up(lg) {
        padding: 30px;
        padding-left: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
      }

      @include media-breakpoint-up(xl) {
        padding-left: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
      }

      @include media-breakpoint-up(xxl) {
        padding: 120px 60px 40px 60px;
        padding-left: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
      }

      p {
        margin-top: 16px;
      }
    }

    &__collapse__title {
      margin-top: 10px;

      @include media-breakpoint-up(lg) {
        margin-top: 16px;
      }
    }

    &__info-list {
      border: none;
      margin: 0;
      padding: 0;

      &__item {
        border-bottom: 1px solid rgba($gray, 0.5);
        padding-bottom: 10px;

        @include media-breakpoint-up(lg) {
          padding-bottom: 16px;
        }

        // &:last-child {
        //   border: none;
        // }
      }
    }
  }

  // images component
  .image-main {
    img {
      width: 100%;
    }

    &.skeleton__item {
      height: 100%;
      border-bottom: 1px solid transparent;
    }
  }
}

.osim-support-section {
  padding: 16px 0 24px;
  border-top: 1px solid $gray-300;
  color: $gray-darker;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 40px 20px;
    margin-bottom: 40px;
    border: 1px solid $gray-300;
  }

  &__title {
    font-size: $h2-font-size-mobile;
    margin: 0;
    padding-bottom: 8px;
    
    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size;
      padding-bottom: 18px;
    }
  }

  &__info {
    font-size: $font-size-base;
    margin: 0;
    padding-bottom: 24px;
    
    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      padding-bottom: 40px;
    }
  }

  &__button {
    a {
      min-width: 200px;
      
      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }
}
