@import '@assets/reuse-mixin.scss';

.order-search {
  background: $cp-background;

  &__box {
    width: 100%;
    height: 48px;
    border: 1px solid $cp-border-color;
    position: relative;

    &__input {
      border: none;
      width: 100%;
      height: 100%;
      padding: 16px 24px;
      outline: none;

      &:focus {
        outline: none;
      }
    }

    &__icon {
      position: absolute;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
      cursor: pointer;
      width: 24px;
    }
  }
}