@import '@assets/reuse-mixin.scss';

.howtoplay-popup {
  margin: -24px auto 0;
  max-width: 560px;

  @include media-breakpoint-up(md) {
    margin: -32px auto 0;
    padding-bottom: 80px;
  }

  &__content {
    font-size: $font-size-base;
    margin-top: 36px;
    max-height: 45vh;
    overflow-y: auto;

    @include scrollbar-custom();

    @include media-breakpoint-up(md) {
      padding-right: 16px;
      margin-right: -16px;
    }

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      margin-top: 48px;
    }
  }
}
