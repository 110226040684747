@import '@assets/reuse-mixin.scss';

.spin-win-heading {
  padding-top: 15px;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }

  &__title {
    color: $white;
    text-align: center;
    padding: 0 35px;
    margin-bottom: 0;
    line-height: 1.2;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .spin-wheel-icon {
    cursor: pointer;
  }
}