@import '@assets/reuse-mixin.scss';

// Campaign Detail Banner
.campaign {
  padding-top: 32px;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
    padding-top: 40px;
  }
  
  &__title {
    color: $gray-darker;
    font-size: $h1-font-size-mobile;
    text-align: center;
    max-width: 600px;
    margin: 0 auto 8px;

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
      margin-bottom: 16px;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h2-font-size;
      max-width: 800px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h1-font-size;
    }
  }

  &__valid-text {
    text-align: center;
    font-size: $font-size-base;
    color: $gray-darker;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }

  &__countdown {
    text-align: center;
    font-size: $font-size-small;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-large;
    }
  }

  &__image-link {
    display: block;
    margin: 18px 0 36px;

    @include media-breakpoint-up(lg) {
      margin: 32px 0 72px;
    }

    .campaign__image {
      margin: 0;
    }
  }

  &__image {
    margin: 18px 0 36px;

    @include media-breakpoint-up(lg) {
      margin: 32px 0 72px;
    }
  }

  &.skeleton__item {
    .image-wrap {
      padding-bottom: calc(600/1060 * 100%)
    }
  }

  &.--cp-view {
    background-color: $cp-background;

    .campaign {
      &__title,
      &__label {
        font-weight: $font-weight-normal;
      }

      &__title {
        max-width: none;
        font-size: $h4-font-size;
        text-align: left;
        margin: 0 24px;
      }

      &__label {
        color: $cp-gray;
        margin: 3px 24px 0 24px;
        font-size: $font-size-small;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base;
        }
      }

      &__image {
        margin: 32px 0 0;
      }
    }
  }
}
