@import '@assets/reuse-mixin.scss';
.input-nomal {
  width: auto;
  height: 36px;
  outline: none;
  padding: 10px 24px;
  letter-spacing: 0.7px;
  font-size: $font-size-small;
  border: 1px solid $border-form;
  color: $gray-darker;
}

.cp-input {
  input[type='text'],
  input[type='tel'] {
    @extend .input-nomal;
  
    &:disabled {
      border-color: $gray-lighter;
      color: $gray;
    }
  }

  input[type='text'],
  input[type='tel'] {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $gray-darker;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $gray-darker;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $gray-darker;
    }

    &:disabled {
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray;
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray;
      }
    }
  }

  input:disabled ~ label {
    cursor: auto;
    color: $gray;
    
    &::before {
      border-color: $gray-lighter;
      background-color: $gray-lighter;
    }
  }
}

.cp-checkbox-icon {
  &.cp-form__social-icon__item {
    position: relative;
    border: none;
    padding: 0;
  }

  &__input {
    cursor: pointer;
    position: absolute;
    opacity: 0;

    &:checked ~ label {
      background: $os-orange-lightest;
    }
  }

  &__icon {
    margin: 0;
    cursor: pointer;
    width: 100%;
    border: 1px solid $os-orange;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    border-radius: 17px;

    img {
      max-height: 20px;
      width: auto;
    }

    &.is-checked{
      background: $os-orange-lightest;
    }
  }
}