@import'@assets/reuse-mixin.scss';

.two-col-image-accordion {
  &__wrap {
    display: flex;
    flex-direction: column;
  
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__image,
  &__accordion {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &__image {
    @include media-breakpoint-up(lg) {
      order: 1;
    }

    img {
      width: 100%;
    }
  }

  &__accordion {
    @include media-breakpoint-up(lg) {
      order: 2;
    }

    .accordion {
      &__heading {
        margin: 0 0 36px;
        text-align: center;

        @include media-breakpoint-up(lg) {
          text-align: left;
        }

        @include media-breakpoint-up(xl) {
          margin: 0 0 48px;
        }

        &__title {
          margin-bottom: 0;
          font-weight: $font-weight-bold;
        }

        &__desc {
          font-size: $font-size-base;
          margin-top: 16px;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-large;
            margin-top: 24px;
          }

          @include media-breakpoint-up(xl) {
            font-size: $h5-font-size;
          }
        }
      }

      &-wrap {
        width: auto;
        padding: 24px $grid-gutter-width/2;

        @include media-breakpoint-up(md) {
          padding: 24px calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(lg) {
          padding: 30px;
          margin: auto 0;
          padding-right: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        @include media-breakpoint-up(xl) {
          padding: 40px;
          padding-right: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xxl) {
          padding: 120px 90px 40px 90px;
          padding-right: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
        }

        p {
          // margin-top: 16px;
          // Follow CR Request Mesuarement
          margin-top: 20px;

          @include media-breakpoint-up(lg) {
            margin-top: 10px;
          }
        }
      }

      &__collapse__title {
        margin-top: 10px;

        @include media-breakpoint-up(xl) {
          margin-top: 16px;
        }
      }

      &__info-list {
        border: none;
        margin: 0;
        padding: 0;

        &__item {
          border-bottom: 1px solid rgba($gray, 0.5);
          padding-bottom: 10px;

          @include media-breakpoint-up(xl) {
            padding-bottom: 16px;
          }
        }
      }
    }
  }

  &--accordion-left {
    .two-col-image-accordion {
      &__image {
        @include media-breakpoint-up(lg) {
          order: 2;
        }
      }

      &__accordion {
        @include media-breakpoint-up(lg) {
          order: 1;
        }

        .accordion {
          &-wrap {
            @include media-breakpoint-up(lg) {
              padding: 30px;
              padding-left: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
            }

            @include media-breakpoint-up(xl) {
              padding: 40px;
              padding-left: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
            }

            @include media-breakpoint-up(xxl) {
              padding: 120px 90px 40px 90px;
              padding-left: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
            }
          }
        }
      }
    }
  }

  &--text-light {
    color: $white;

    .accordion {
      &__heading {
        &__title {
          color: $white;
        }
      }

      &__info-list {
        color: $white;

        &__item {
          border-color: $white;
        }
      }
    }
  }

  &.not-full-width {
    .two-col-image-accordion {
      &__accordion {
        .accordion-wrap {
          padding: 25px 30px;

          @include media-breakpoint-up(lg) {
            padding: 30px;
          }
        }
      }
    }

    &--mobile {
      .two-col-image-accordion {
        &__image {
          @include media-breakpoint-down(md) {
            padding: 0 30px;
          }
        }
      }
    }
  }

  &.has-top-padding {
    margin-top: 50px;
  }

  &.has-bottom-padding {
    margin-bottom: 50px;
  }
}