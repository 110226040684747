@import '@assets/reuse-mixin.scss';

.custom-pagination {
  // position: absolute;
  // bottom: 24px;
  // left: 50%;
  // transform: translateX(-50%);
  padding-top: 14px;
  display: flex;
  // color: #FFFFFF80;
  color: $gray-darker;
  align-items: center;
  margin: 0 auto;
  width: fit-content;

  @include media-breakpoint-up(md) {
    padding-top: 10px;
  }

  &__prev {
    margin-right: 14px;
    cursor: pointer;
  }

  &__next {
    margin-left: 14px;
    cursor: pointer;
  }

  &__prev,
  &__next {
    font-size: 26px;

    @include media-breakpoint-up(md) {
      font-size: 40px;
    }
  }

  .custom-pagination-bullets {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 6px;
    
    @include media-breakpoint-up(md) {
      font-size: 40px;
      height: 24px;
    }
    
    @include media-breakpoint-up(xxl) {
      height: 32px;
      margin-bottom: 8px;
    }

    &__icon {
      // background-color: rgba($white, 0.5);
      // background-color: $gray-dark;
      // height: 10px;
      // width: 2px;
      // margin-right: 10px;
      display: inline-block;
      overflow: hidden;
      text-indent: -999rem;
      // transition: 0.2s all;
      background-color: transparent;
      opacity: 1;
      width: 5px;
      height: 5px;
      transition: all 0.3s;
      border-radius: 50%;
      outline: none;
      border: 1px solid $gray-darker;
      margin: 0 5px;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        width: 8px;
        height: 8px;
        margin: 0 8px;
      }

      &.is-active {
        // margin-top: -8px;
        // background-color: $white;
        // background-color: $gray-darker;
        // height: 14px;
        // transform: translateY(-5px);
        height: 16px;
        border-radius: 30px;
        background-color: $gray-darker;

        @include media-breakpoint-up(md) {
          height: 24px;
        }

        @include media-breakpoint-up(xxl) {
          height: 32px;
        }
      }
    }
  }
}