@import "../../../assets/reuse-mixin.scss";

.video-banner {
  position: relative;

  padding-bottom: 666/375 * 100%;

  @include media-breakpoint-up(md) {
    padding-bottom: 1080/1920 * 100%;
  }

  &__player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 550px;
    text-align: center;
    padding: 0 25px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;

    @include media-breakpoint-up(lg) {
      max-width: 750px;
    }

    .btn-full {
      &.hidden {
        display: none;
      }
    }

    .control {
      &--button {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        border: 1px solid #fff;

        &--icon {
          width: 40px;
          height: 40px;
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50% - 20px);
          z-index: 2;
          transform: rotate(-90deg) scale(.8);
          transition: -webkit-clip-path .3s ease-in .1s,shape-inside .3s ease-in .1s,transform .8s cubic-bezier(.85,-.25,.25,1.425);
  
          &.pause {
            transform: rotate(0);
  
            &:before {
              -webkit-clip-path: polygon(30% 0,90% 52%,90% 52%,30% 52%);
              shape-inside: polygon(0 0,100% 52%,100% 52%,0 52%);
            }
  
            &:after {
              -webkit-clip-path: polygon(30% 49.5%,80% 49.5%,90% 49.5%,30% 100%);
              shape-inside: polygon(30% 49.5%,80% 49.5%,90% 49.5%,30% 100%);
            }
          }
    
          &::before, &::after {
            content: "";
            position: absolute;
            background: #fff;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: inherit;
          }
  
          &:after {
            -webkit-clip-path: polygon(0 60%,100% 60%,100% 90%,0 90%);
            shape-inside: polygon(0 60%,100% 60%,100% 90%,0 90%);
          }
  
          &:before {
            -webkit-clip-path: polygon(0 10%,100% 10%,100% 40%,0 40%);
            shape-inside: polygon(0 10%,100% 10%,100% 40%,0 40%);
          }
        }
      }
    }
    
  }

  &__youtube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-player {
  position: relative;
  height: 0;
  padding-bottom: 9/16 * 100%;

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .button-video-control {
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }
}