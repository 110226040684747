@import '@assets/reuse-mixin.scss';

.cp-select {
  font-size: $font-size-small;

  span.css-1okebmr-indicatorSeparator {
    width: 0;
  }

  .css-g1d714-ValueContainer {
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;

    .css-1wa3eu0-placeholder {
      color: $gray-darker;
      font-weight: $font-weight-normal;
    }
  }

  &__control {
    &.css-yk16xz-control,
    &.css-1pahdxg-control {
      border-radius: 0;
      cursor: pointer;
      height: 36px;
      min-height: auto;

      &:hover {
        border-color: $border-form;
      }
    }

    &.css-yk16xz-control {
      border: 1px solid $border-form;
    }

    &.css-1pahdxg-control {
      border-color: $border-form;
      box-shadow: none;
      z-index: 9999
    }
  }

  &__menu {
    &.css-26l3qy-menu {
      background-color: $white;
      box-shadow: none;
      border-radius: 0;
      border-top: none;
      margin: 0;
      box-shadow: 0px 3px 6px rgba($black, 0.3);
      z-index: 2;
    }
  }

  &__single-value {
    color: $gray-darker;
    font-weight: $font-weight-normal;
  }

  &__placeholder {
    margin-left: 0;
    margin-right: 0;
  }

  div.cp-select__option {
    display: flex;
    align-items: center;
    padding-left: calc(#{$input-padding-x-lg} - 0.4rem);
    margin-bottom: 4px;
    cursor: pointer;
    color: $gray;

    &:last-child {
      margin-bottom: 0;
    }

    &--is-focused,
    &:hover {
      background-color: $os-brown-lighter;
      color: $gray-darker;
      font-weight: $font-weight-normal;

      &:active {
        background-color: $os-brown-lighter;
      }
    }

    &--is-selected {
      background-color: $os-brown-lighter;
      color: black;
      font-weight: $font-weight-normal;
    }
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0 16px 0 8px;
  }

  svg.css-6q0nyr-Svg {
    color: $gray-darker;
  }

  .cp-select__value-container--is-multi {
    width: calc(100% - 88px);
    overflow: unset;
  }
}

.select-small {
  .cp-select {
    .css-g1d714-ValueContainer {
      padding-left: 10px;
    }

    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      padding: 0 8px 0 2px;
    }
  }
}