@import '@assets/reuse-mixin.scss';

.product-card-price {
  &__member {
    &-wrapper {
      display: flex;
    }
  }

  &__usual {
    text-decoration: unset !important;
    margin-top: 4px;
  }

  &__deposit-price {
    color: inherit;
  }
}

.question-tooltip-icon {
  margin-left: 4px;
  align-self: center;

  &.item-tooltip {
    display: inline;
    position: relative;
  }

  .item-tooltip {

    &__label {
      background-color: $white;
      border: 1px solid $secondary;
      width: max-content;
      max-width: 150px;
      top: unset;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 6px;
      padding: 6px;
      z-index: 1;
      line-height: $line-height-base;
      text-align: initial;

      p {
        margin-bottom: 0;
        color: $gray-darker;
      }

      &::after {
        width: 6px;
        height: 6px;
        background-color: $white;
        border-top-width: 1px;
        border-color: $secondary;
        border-right-width: 1px;
        border-style: solid;
        border-left-width: 0;
        border-bottom-width: 0;
        top: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    width: 14px;
    height: 14px;
    @include media-breakpoint-up(md) {
      width: 20px;
      height: 20px;
    }

    .icon-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}