@import'../../../../assets/reuse-mixin.scss';

.OSIM-benefit {
  padding: 24px 0 0;

  @include media-breakpoint-up(lg) {
    padding: 40px 0 0;
  }

  &--bgr-color {
    background-color: $os-orange-lighter;
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__list {
    [class*="col-"] {
      margin-bottom: 20px;

      &:last-child {

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
      }

      @include media-breakpoint-up(xxl) {
        margin-bottom: 65px;
      }
    }
  }

  &__item {
    padding: 0 40px;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0 10px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 20px;
    }

    &__icon {
      margin-bottom: 18px;
      height: 60px;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-width: 60px;
      }
    }

    &__button {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
    }


    &__title {
      font-size: $h4-font-size;
      max-width: 260px;
      margin: 0 auto;
      color: $black;
      font-weight: $font-weight-normal;
      // letter-spacing: 1.2px;

      @include media-breakpoint-up(md) {
        max-width: inherit;
        margin: 0 auto 20px auto;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size + 0.4;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h3-font-size;
        // letter-spacing: 1.6px;
      }
    }

    &__description {
      font-size: $font-size-base;
      color: $gray-900;
      margin-bottom: 18px;
      @include line-clamp(2);

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }
    }

    &__button {
      a {
        //color: $os-blue;
        color: $link-color;
        font-weight: bold;
        letter-spacing: 2.24px;
        font-size: $font-size-mobile;

        &::before,
        &::after {
          top: 45%;
        }

        @include media-breakpoint-up(md) {
          letter-spacing: 2.56px;
        }
      }
    }
  }

  &.skeleton__item {
    .OSIM-benefit__item__icon {
      .image-wrap {
        width: 40px;
        height: 40px;

        @include media-breakpoint-up(md) {
          width: 60px;
        height: 60px;
        }
      }
    }
  }
}