@import '@assets/reuse-mixin';

.profiles {
  background-color: $cp-background;
  padding: 26px 0 40px 0;

  &__row {
    margin-right: -3px;
    margin-left: -3px;
  }

  &__col {
    padding-right: 3px;
    padding-left: 3px;
    margin-bottom: 6px;
  }
}

.customer {
  display: flex;
  align-items: center;
  margin-bottom: 23px;

  &__name {
    color: $gray-darker;
    font-size: $h4-font-size;
    font-weight: $font-weight-normal;
    letter-spacing: 1.2px;
    margin-right: 12px;
  }

  &__rank {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
    }
  }
}

.box-data {
  background-color: $body-bg;
  padding: 20px;
  // margin-bottom: 6px;
  height: 100%;

  .icon-place {
    width: 33px;
    height: 25px;
  }

  .data-place {
    width: calc(100% - 33px);
    padding-left: 16px;
    color: $gray-darker;
    font-size: $font-size-base;
    line-height: 1.06;
    letter-spacing: 0.19px;
    display: flex;

    &.have-product {
      display: block;

      .data-text {
        padding-right: 5px;
      }
    }
  }

  .data-text {
    display: inline-block;
    flex: 1;
  }

  &__social-icon {
    img {
      margin-right: 14px;
      max-width: 24px;
      cursor: pointer;
    }
  }

  &__no-data {
    display: flex;
    align-items: center;
  }
}

.flex-content {
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  .left-place,
  .right-place {
    width: 50%;
  }
}

.contact-infor {
  &.box-data {
    padding: 30px 20px 0 20px;
    height: auto;
  }

  .phone-value {
    img {
      margin-left: 16px;
      max-width: 18px;
      cursor: pointer;
    }
  }
}

.cp-contact-info {
  width: 100%;
}

.box-data {
  &__header {
    margin-bottom: 24px;

    .box-data__notice {
      font-weight: $font-weight-normal;
    }
  }

  &__notice {
    color: #7d7d7d;
    letter-spacing: 0.7px;
    font-size: $font-size-small;
    margin-bottom: 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 0 -5px 20px -5px;
  }

  &__col-1 {
    width: 62%;
    padding: 0 5px;
  }

  &__col-2 {
    width: 38%;
    padding: 0 5px;
  }

  &__title {
    color: $gray-darker;
    margin-bottom: 0;
    font-weight: $font-weight-normal;
    font-size: $font-size-large;
    letter-spacing: 0.9px;

    & + .box-data__notice {
      margin-top: 8px;
    }

    &.--sub {
      color: $black;
      font-size: $font-size-base;
      letter-spacing: 0.8px;
    }
  }

  &__link {
    //color: $secondary;
    color: $link-color;
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    letter-spacing: 0.7px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .highlight-text {
    padding: 4px 12px;
    background-color: $gray-lighter;
    color: $gray-darker;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    letter-spacing: 0.7px;
    min-width: 112px;
    text-align: center;

    &--radius {
      border-radius: 14px;
    }
  }
}

.product-interest {
  .highlight-text {
    font-weight: $font-weight-normal;
    letter-spacing: 0.8px;
  }
}

.customer-voucher {
  &-popup {
    .modal-dialog {
      align-items: center;
    }
  
    .modal-content {
      width: 100%;
      max-width: 720px;
      margin-top: inherit;
    }
  
    .osim-popup__close-btn {
      top: 24px;
    }
  }

  &__row {
    margin-right: -12px;
    margin-left: -12px;
    max-height: 330px;
    overflow: auto;

    @include  scrollbar-custom();
  }

  &__col {
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 24px;
  }
}
