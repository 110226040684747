@import '../../../../assets/reuse-mixin.scss';

.product-hub-carousel {
  .os-carousel {
    padding-top: 0;

    .swiper-slide-active {
      .os-carousel__item__title,
      .os-carousel__item__description {
        display: block;
        opacity: 1;
      }
    }
    &__item {
      &__title,
      &__description {
        display: none;
        text-align: center;
      }

      &__title {
        font-size: $h5-font-size;
        line-height: 1.4;
        letter-spacing: 1px;
        margin: 17px 0 6px 0;

        @include media-breakpoint-up(xl) {
          font-size: $h3-font-size;
          line-height: 1.44;
          letter-spacing: 1.6px;
          font-weight: $font-weight-normal;
          margin: 20px 0 12px 0;
        }
      }

      &__description {
        margin-bottom: 0;
        line-height: 1.63;

        @include media-breakpoint-up(xl) {
          line-height: 1.6;
          letter-spacing: 1.6px;
        }
      }
    }

    .swiper-container {
      padding-bottom: 40px;
    }
  }
}