@import '../../../assets/reuse-mixin.scss';

// ShippingInfo Component
.shipping-info {
  &__wrap {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    // @include media-breakpoint-up(lg) {
    //   // padding: 0 30px;
    //   justify-content: flex-start;
    // }

    // @include media-breakpoint-up(xxl) {
    //   padding: 0 80px;
    // }
  }

  &__item {
    display: flex;
    width: 100%;
    margin-top: 18px;
    // margin-right: 20px;

    &:first-child {
      margin-top: 0;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
      padding-right: 20px;

      &:nth-child(2) {
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      width: 100%;

      &:nth-child(2) {
        margin-top: 18px;
      }
    }

    @include media-breakpoint-up(xxl) {
      width: 50%;
      margin-top: 24px;

      &:nth-child(2) {
        margin-top: 0;
      }
    }

    // @include media-breakpoint-up(xl) {
    //   margin-right: 45px;
    // }

    // @include media-breakpoint-up(xxl) {
    //   margin-right: 100px;
    // }

    // &:nth-child(3) {
    //   margin: 18px 0 0;

    //   @include media-breakpoint-up(md) {
    //     margin: 0;
    //   }

    //   @include media-breakpoint-up(lg) {
    //     margin: 32px 0 0;
    //   }
    // }

    &__icon {
      width: 20px;
      padding-top: 4px;
    }

    &__text {
      width: calc(100% - 20px);
      padding-left: 6px;

      @include media-breakpoint-up(lg) {
        padding-left: 16px;
      }

      &__title {
        display: block;
        font-size: $font-size-base;
        font-weight: bold;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }
      }

      &__desc {
        font-size: $font-size-smaller;
        color: $gray-dark;

        &.link-store {
          margin-left: 2px;
          cursor: pointer;
          //color: $secondary;
          color: $link-color;
          text-decoration: underline;
        }

        @include media-breakpoint-up(lg) {
          font-size: $font-size-small;
        }
      }
    }

    a.shipping-info__item__link {
      text-decoration: underline;
      display: inline-block;
      margin-left: 3px;
      //color: $os-blue;
      color: $link-color;
      cursor: pointer;

      &:hover {
        //color: $os-blue;
        color: $link-color;
        text-decoration: underline;
      }
    }
  }
}

.shipping-info-cp {
  .shipping-info__wrap {
    @include media-breakpoint-up(xl) {
      flex-wrap: nowrap;
    }
  }

  .shipping-info__item {
    @include media-breakpoint-up(xl) {
      margin: 0;
      padding-right: 24px;
    }

    &:last-child {
      padding-right: 0px;
    }

    &__icon {
      width: auto;
      height: auto;
      padding: 0px;

      .fulfillment-icon {
        width: 17px;
        height: 14px;
      }
    }

    &__text {
      padding-left: 8px;

      &__title {
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
        color: $gray-900;
      }

      &__desc {
        font-size: $font-size-smaller;
        color: $gray-600;
        font-weight: $font-weight-bold;
      }

      .link-text-underline {
        font-size: $font-size-smaller;
        font-weight: $font-weight-bold;
      }

      .link-store {
        //color: $os-blue;
        color: $link-color;
      }
    }
  }
}
