@import '@assets/reuse-mixin.scss';

.show-tier-information {
  width: 100%;
  text-align: center;
  padding: 32px 0;
  @include media-breakpoint-up(md) {
    padding: 40px 0;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      max-width: 32px;
      width: 100%;
      @include media-breakpoint-up(md) {
        max-width: 40px;
      }

      &__wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 100%;

        & > img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
    }

    &-text {
      font-size: $h5-font-size;
      line-height: 3rem;
      font-weight: 400;
      margin-bottom: 0;
      margin-left: 8px;

      @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
        margin-left: 16px;
      }
    }
  }

  &__description {
    margin-top: 20px;
    color: $gray-darker;
    @include media-breakpoint-up(md) {
      margin-top: 16px;
    }

    &-text {
      font-size: $h6-font-size-mobile;
      line-height: 2rem;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
        line-height: 2.4rem;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      & > span {
        font-weight: $font-weight-normal;
      }
    }
  }
}