@import '@assets/reuse-mixin.scss';

.contact-info {
  padding: 24px 0;
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;

  @include media-breakpoint-up(md) {
    padding: 0;
    border: none;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding: 40px 0;
      border: 1px solid $gray-lighter;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__form {
    .form-group {
      margin: 0 auto;
      margin-bottom: 24px;
      max-width: 480px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
        position: relative;
      }
    }

    // .form-control {
    //   font-size: $font-size-base;
    //   padding: 15px 22px;
    //   height: 48px;

    //   @include media-breakpoint-up(lg) {
    //     font-size: $h5-font-size;
    //     padding: 15px 24px;
    //     height: 56px;
    //     max-width: 480px;
    //   }
    // }

    .form-bg-gray {
      background-color: $gray-lighter;
      border: 1px solid $gray-lighter;
    }

    &__label {
      margin-bottom: 5px;
    }

    &__change {
      display: inline-block;
      margin-top: 18px;
      cursor: pointer;
      color: $secondary;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-size: $font-size-small;

      @include media-breakpoint-up(lg) {
        position: absolute;
        margin-top: 0;
        top: 50%;
        right: 0;
        transform: translate(calc(100% + 32px),-50%);
        font-size: $font-size-base;
      }
    }
  }

  &__btn {
    // @include media-breakpoint-up(lg) {
    //   padding-top: 16px;
    // }

    &__item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .btn {
        min-width: 200px;

        @include media-breakpoint-up(lg) {
          min-width: 240px;
        }
      }
    }
  }
}

.security-verification {
  &__image {
    margin-bottom: 32px;
  }

  &__info {
    margin: 0 auto 24px;
    font-size: $font-size-base;
    color: $gray-darker;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      max-width: 50%;
    }
  }

  .contact-info__btn {
    padding-top: 0;
  }
}