@import '@assets/reuse-mixin.scss';

.payment-processing {
  text-align: center;
  color: $gray-darker;
  font-weight: $font-weight-normal;
  padding: 100px 42px 32px;

  @include media-breakpoint-up(lg) {
    padding: 160px 20px 48px;
    max-width: 880px;
    margin: 0 auto;
  }

  .loading {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &__title {
    font-size: $h4-font-size;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      font-size: $h1-font-size;
      margin-bottom: 16px;
    }
  }

  &__description {
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }
}