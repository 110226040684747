@import'@assets/reuse-mixin.scss';

.carousel-width-icon-bottom {
  padding: 32px 0 20px;

  @include media-breakpoint-up(lg) {
    padding: 56px 0 26px;
  }

  &__heading {
    margin-bottom: 18px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    &__title {
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }
    
    &__desc {
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }
    }
  }

  &__gallery {
    margin-bottom: 32px;

    &__item {
      img {
        width: 100%;
      }
    }
  }
  
  &__thumnail {
    &__item {
      &__image-line {
        border-radius: 50%;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
      }

      &__image {
        cursor: pointer;
        position: relative;
        border-radius: 50%;
        border: 1px solid transparent;
        transition: 0.3s;
        overflow: hidden;
        width: 24px;

        &::before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 100%;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      &.slide-selected {
        .carousel-width-icon-bottom__thumnail__item {
          &__image-line {
            border: 1px solid $gray;
          }

          &__image {
            width: 44px;
          }
        }
      }
    }
  }
}