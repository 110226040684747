@import'../../../../../../assets/reuse-mixin.scss';

.gifting-option {
  padding: 8px 0;

  @include media-breakpoint-up(lg) {
    padding: 16px 0;
  }

  p {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
  }

  &__body {
    padding-left: 30px;
    font-size: $font-size-large;
    font-weight: $font-weight-light;
    line-height: 2.8rem;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      font-size: $h5-font-size;
    }

    &--name-item {
      padding-right: 20px;

       > p {
         word-break: break-word;
       }
    }

    .normal-text {
      font-weight: $font-weight-normal;
    }
  }

  &__icon {
    width: 30px;
    padding-right: 5px;
  }

  &__contents {
    width: calc(100% - 30px);
    font-weight: $font-weight-normal;

    &--title {
      font-size: $font-size-large;
      color: $gray-darker;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size;
      }
    }

    &__image {
      transition: 0.5s;
    }

    .justify-space-between {
      align-items: center;
    }

    .link-text {
      cursor: pointer;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__collapse {
    padding-left: 30px;
    margin-right: -20px;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }

    &__header {
      padding-right: 20px;

      @include media-breakpoint-up(md) {
        padding-right: 0;
      }
    }

    &__description {
      font-size: $font-size-small;
      letter-spacing: 0.8px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
      }
      @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
      }
    }

    &__price {
      font-weight: $font-weight-normal;
      letter-spacing: 1.6px;
    } 

    &__group {
      padding: 5px 0 20px 0;

      @include media-breakpoint-up(md) {
        padding: 10px 0 20px 0;
      }
    }

    &__title {
      margin-bottom: 5px;
    }

    &__textarea {
      padding-right: 50px;

      @include media-breakpoint-up(md) {
        padding-right: 0;
      }
      
      textarea {
        display: block;
        width: 100%;
        min-height: 80px;
        padding: 15px;
        resize: none;
        font-size: $font-size-small;

        &:focus-visible {
          outline: none;
          border-radius: 0;
        }

        @include media-breakpoint-up(md) {
          margin-left: 1px;
        }
  
        @include media-breakpoint-up(lg) {
          padding: 15px 20px;
          margin: 0;
          font-size: $h5-font-size;
          min-height: 120px;
        }
      }

      .noti-text {
        display: block;
        margin-top: 5px;
        color: $gray-dark;
        font-size: $font-size-smaller;
        line-height: 1.7;
        font-weight: $font-weight-light;

        @include media-breakpoint-up(md) {
          font-size: $font-size-small;
        }

        @include media-breakpoint-up(lg) {
          font-size: $font-size-smaller;
        }

        @include media-breakpoint-up(xl) {
          font-size: $font-size-small;
        }
      }
    }


    &__button {
      text-align: center;
      padding-right: 50px;
      margin-top: 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include media-breakpoint-up(md) {
        text-align: right;
        padding-right: 0;
        margin-top: 0;
        justify-content: flex-end;
      }

      button {
        min-width: 200px;
        padding: 8px;
        font-size: $font-size-small;
        margin: 0 8px;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base;
          margin: 0 15px;

          &:last-child {
            margin-right: 0;
          }
        }

        &.btn-link {
          min-width: auto;
          order: 1;

          @include media-breakpoint-up(md) {
            order: 0;
          }
        }

        &.btn-save {
          order: 0;

          @include media-breakpoint-up(md) {
            order: 1;
          }
        }
      }
    }

    .justify-space-between {
      align-items: center;
    }
  }

  .collapse:not(.show) {
    display: block;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }
}