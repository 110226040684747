@import '@assets/reuse-mixin.scss';

.os-breadcrumb {
  padding-top: 25px;

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    font-size: $font-size-large;
  }

  &__item {
    padding: 5px 0;
    display: inline-block;

    &:first-child {
      .os-breadcrumb__item__link {
        padding-left: 0;

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &__link {
      position: relative;
      padding-left: 16px;
      margin-right: 6px;
      color: $gray;
      font-weight: $font-weight-normal;
      display: block;
      
      @include media-breakpoint-up(lg) {
        margin-right: 10px;
      }

      // &::before,
      &::after {
        content: ">";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        // background: $gray;
        // width: 6px;
        // height: 1.5px;
        // transform-origin: 6px center;

        // @include media-breakpoint-up(lg) {
        //   width: 9px;
        //   transform-origin: 8px center;
        //   top: 11px;
        // }
      }

      // &::before {
      //   transform: rotate(-35deg);
      // }

      // &::after {
      //   transform: rotate(35deg);
      // }

      &:hover {
        color: $gray-darker;
        text-decoration: none;
      }
    }
  }

  &--has-icon {
    &.os-breadcrumb {
      &__wrap {
        padding-left: 36px;
        position: relative;
    
        @include media-breakpoint-up(lg) {
          padding-left: 40px;
        }
      }
    }

    .os-breadcrumb {
      &__item {
        &:first-child {
          .os-breadcrumb__item__link {
            position: absolute;
            top: 0;
            left: 0;
    
            @include media-breakpoint-up(xl) {
              top: 4px;
            }
          }
        }

        &__icon {
          width: 26px;
          height: 24px;
          display: inline-block;
          background: url('../../../assets/images/icons/breadcrumb-icon.png') 0 0 no-repeat;
          background-size: cover;
          margin-bottom: -8px;
    
          @include media-breakpoint-up(lg) {
            margin-bottom: -6px;
          }
        }
      }
    }
  }
}