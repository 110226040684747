@import "@assets/reuse-mixin.scss";

.featured-article-item {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  height: 100%;
  color: $gray-darker;

  &:hover {
    .featured-article-item__image__item {
      transform: scale(1.2);
    }
  }

  &__wrap {
    margin-bottom: 8px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }

  &__image {
    width: 100%;
    height: 0;
    padding-bottom: 66%;
    position: relative;
    overflow: hidden;

    &__link {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &__item {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease;
    }
  }

  &__tags {
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }

    &__item {
      display: inline-block;
      padding: 3px 14px;
      border: 1px solid $os-orange;
      border-radius: 40px;
      margin-right: 15px;
      font-size: $font-size-smaller;
      letter-spacing: 0.6px;
      margin-bottom: 8px;
      text-align: center;
      color: $gray-darker;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
        letter-spacing: 0.7px;
        padding: 5px 30px;
      }

      &--select {
        background-color: $os-orange;
      }

      &.selected {
        background-color: $os-orange;
      }
    }
  }

  &__title {
    margin-bottom: 6px;
    color: $gray-darker;
    font-size: $h5-font-size;
    // letter-spacing: 1px;
    // line-height: 2.4rem;
    font-weight: $font-weight-normal;

    @include line-clamp(2);

    @include media-breakpoint-up(lg) {
      margin-bottom: 14px;
      font-size: $h4-font-size;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size + 0.4;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h3-font-size;
      // letter-spacing: 1.6px;
      // line-height: 4rem;
    }

    a {
      color: $gray-darker;
      &:hover {
        color: $link-hover-color;
      }
    }
  }

  &__sub-info {
    color: $gray;
    font-size: $font-size-small;
    letter-spacing: 0.7px;
    line-height: 2rem;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
      letter-spacing: 0.8px;
      line-height: 2.4rem;
    }

    p {
      display: inline-block;
    }

    p ~ p {
      margin-left: 8px;
      position: relative;
  
      &::before {
        content: "|";
        margin-right: 8px;
      }
    }
  }
}
