@import '@assets/reuse-mixin.scss';

.promotion-listing {
  &__title {
    font-size: $font-size-small;
    color: $gray;
    font-weight: $font-weight-bold;
    letter-spacing: 2.52px;
    text-transform: uppercase;
    line-height: 2.2rem;
    padding: 24px 0 16px 0;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
      letter-spacing: 2.88px;
      padding: 40px 0 32px 0;
    }
  }

  &.skeleton__item {
    .promotion-listing-item__contents {
      width: 100%;
    }
  }

  &.cp-promotion {
    background-color: $cp-background;
    .promotion-listing {
      &__title {
        text-align: left;
        color: $gray-darker;
        padding: 32px 0 32px 24px;
        font-size: $h4-font-size;
        text-transform: none;
        letter-spacing: 1.2px;
        font-weight: $font-weight-normal;
      }

      &__list {
        background-color: $white;
        padding: 24px 32px;

        li:last-child .promotion-listing-item {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 8px;
        }
      }
    }

    .promotion-listing-item {
      border-bottom: 1px solid $gray-lighter;
      padding-bottom: 24px;
      margin-bottom: 24px;

      .row {
        flex-direction: row;
      }

      &__contents {
        padding-left: 12px;

        &__title {
          @include media-breakpoint-down(lg) {
            font-size: $font-size-large;
            letter-spacing: 0.9px;
            line-height: 1.39;
            font-weight: $font-weight-normal;
          }
        }

        &__desription {
          font-weight: $font-weight-light;
          @include media-breakpoint-down(lg) {
            font-size: $font-size-small;
            letter-spacing: 0.7px;
            line-height: 1.71;
            color: $cp-gray;
          }
        }

        &__link {
          margin-bottom: 20px;

          a {
            min-width: 140px;
          }
        }

        &__date {
          display: none;
        }

        &__countdown {
          @include media-breakpoint-up(xl) {
            font-size: $font-size-base;
          }
          .countdown__time {
            color: $gray-darker;
          }
        }
      }
    }
  }
}