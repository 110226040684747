@import '@assets/reuse-mixin.scss';

.termsconditions-modal {
  &__content {
    margin: 0;
    font-size: $font-size-base;
    color: $gray-900;
    
    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }
}