@import '@assets/reuse-mixin.scss';

.product-registration-step {
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    margin-top: 8px;
    margin-bottom: 40px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 12px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:not(:last-child) {
        margin-right: 80px;
        @include media-breakpoint-up(md) {
          margin-right: 120px;
        }

        .icon-wrapper:after {
          content: '';
          display: block;
          position: absolute;
          height: 1px;
          top: 20px;
          width: calc(100% + 80px - 8px);
          left: calc(100% + 4px);
          background-color: #6d7278;
          @include media-breakpoint-up(md) {
            top: 26px;
            width: calc(100% + 120px - 26px);
            left: calc(100% + 4px);
          }
        }
      }

      .icon {
        &-wrapper {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid $gray-600;
          margin-bottom: 8px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          @include media-breakpoint-up(md) {
            width: 52px;
            height: 52px;
            line-height: 52px;
            margin-bottom: 12px;
          }
        }

        &-img {
          width: 20px;
          height: 23px;
          filter: brightness(0%);

          @include media-breakpoint-up(lg) {
            width: 25px;
            height: 29px;
          }
        }

        &-text {
          color: $gray-darker;
          font-size: $font-size-small;
          @include media-breakpoint-up(md) {
            font-size: $font-size-base;
          }
        }
      }
    }

    .active {
      .icon-wrapper {
        border-color: $primary;
      }
    }

    .focus {
      .icon {
        &-wrapper {
          background-color: $primary;
          border-color: $primary;


          &:after {
            background-color: $os-orange-dark;
          }
        }

        &-img {
          filter: brightness(0%) invert(1);
        }
      }
    }
  }

  &__description {
    font-size: 1.6rem;
    width: 327px;
    text-align: center;
    margin: 0 auto;
    color: $gray-900;
    line-height: 1.5;
    letter-spacing: 1.28px;
    padding: 24px 0 15px 0;

    @include media-breakpoint-up(lg) {
      width: 480px;
      font-size: 2rem;
      line-height: 1.4;
      letter-spacing: 1.6px;
      padding: 24px 0 20px 0;
    }
  }
}