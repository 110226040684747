@import '@assets/reuse-mixin';

.cart-mini-total-amount {
  font-size: $font-size-base;

  &__list-price {
    border-bottom: 1px solid $gray-300;
    border-top: 1px solid $gray-300;
    padding: 15px 20px 15px 20px;
  }

  &__total-price {
    margin-bottom: 24px;
    color: $gray-darker;
    font-weight: $font-weight-normal;

    &__label {
      font-size: $font-size-large;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }

    &__value {
      font-size: $h4-font-size;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size + 0.6;
      }
    }
  }
}