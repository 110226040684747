@import "@assets/reuse-mixin.scss";

$fs-title: 4rem;
$fs-title-mobile: 2rem;

.trade-in-special {
  background-color: $os-brown-lighter;
  padding: 24px 0;
  margin-bottom: 24px;
  
  @include media-breakpoint-up(lg) {
    margin-bottom: 72px;
    padding: 72px 0;
  }
  
  .container {
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }

  &__title {
    font-size: $fs-title-mobile;
    font-weight: $font-weight-normal;
    margin-bottom: 24px;
    text-transform: capitalize;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
      text-align: center;
      font-size: $fs-title;
    }
  }
}

.trade-in-special__details-swiper {

  .swiper-container {
    @include media-breakpoint-up(md) {
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 80px;
    }
  }

  .swiper-button {
    color: $gray-500;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    &::after {
      font-size: $h4-font-size;
      left: 0;
    }
  }

  .swiper-button-next {
    &::after {
      right: 0;
    }
  }

  &__item {
    font-weight: $font-weight-normal;
    height: auto;

    &:hover {
      .item__content__link {
        img {
          transform: scale(1.1);
        }
      }
    }

    .item {
      max-width: 240px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &__content {
        width: 100%;

        &__link {
          width: 100%;
          margin-bottom: 10px;
          overflow: hidden;
          padding-bottom: 100%;
          position: relative;
          display: block;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform ease .3s;
          }
        }
    
        
        &__title {
          font-weight: $font-weight-normal;
          margin: 18px 6px 0;
          text-align: center;
          font-size: $h4-font-size-mobile;
          
          @include media-breakpoint-up(xl) {
            font-size: $h4-font-size;
            margin: 24px 20px 0;
          }
          
          &__name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: $gray-900;
          }
        }
    
        &__price {
          text-align: center;
          margin-top: 24px;
          color: $gray-900;
          font-size: $font-size-large;
          line-height: normal;

          @include media-breakpoint-up(lg) {
            font-size: $h4-font-size;
          }
          
          &__text { 
            &--new {
              margin-bottom: 8px;
            }
            
            &--card {
              font-weight: $font-weight-light;
              display: block;
              color: $gray-500;
            }
  
            &--cost {
              position: relative;
              font-weight: $font-weight-light;
              color: $gray-500;
              font-size: $font-size-smaller;
  
              @include media-breakpoint-up(lg) {
                font-size: $font-size-base;
              }
            }
          }
        }
      }

      &__button {
        width: 100%;
        margin-top: 3.2rem;
      }
    }
  }
}