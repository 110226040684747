@import '@assets/reuse-mixin.scss';

@mixin setPaddingTopForMobileScreen() {
  &[aria-rowcount='1'] {
    padding-top: calc(930 / 375 * 100%);
  }

  &[aria-rowcount='2'] {
    padding-top: calc(1030 / 375 * 100%);
  }

  &[aria-rowcount='3'] {
    padding-top: calc(1230 / 375 * 100%);
  }
}

.banner-with__popup {
  // padding: 30px 0;
  position: relative;
  height: 0;

  &[aria-current='mobile'] {
    @include setPaddingTopForMobileScreen;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(1290 / 768 * 100%);
  }

  @include media-breakpoint-up(lg) {
    // padding: 120px 0;
    padding-top: calc(820 / 1024 * 100%);
  }

  @include media-breakpoint-up(xl) {
    padding-top: calc(1140 / 1920 * 100%);
  }

  // @include media-breakpoint-up(xxl) {
  // padding-left: 300px;
  // padding-right: 300px;
  // }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__container {
    width: 100%;
    //min-height: 600px;
    // border: 1px solid $gray-light;
    // background-color: rgba(0, 0, 0, .5);
    position: relative;
    padding: 15px 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;

    @include media-breakpoint-up(sm) {
      max-width: 580px;
      margin: 0 auto;
    }
    @include media-breakpoint-up(lg) {
      max-width: 1260px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .small-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrapper {
    @include media-breakpoint-up(sm) {
      width: 100%;
      padding-bottom: calc(900 / 580 * 100%);
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      position: relative;
      padding-bottom: calc(700 / 1260 * 100%);
    }
  }

  &__box {
    @include media-breakpoint-up(sm) {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      padding: 30px 15px;
    }
    @include media-breakpoint-up(xl) {
      padding: 60px 30px;
    }

    &.banner-with__popup__swap__item__position {
      .banner-with__popup-image {
        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
      }

      .banner-with__popup-box {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
      }
    }
  }

  &-image {
    width: 100%;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      max-width: 600px;
    }

    &__wrapper {
      width: 100%;
      padding-bottom: calc(400 / 500 * 100%);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(lg) {
        padding-bottom: calc(500 / 600 * 100%);
      }
    }

    .product-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .product-image-bgr {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 100%;
      object-fit: contain;
      max-width: 517px;
      filter: brightness(0) opacity(0.5);
    }
  }

  &-box {
    z-index: 1;
    @include media-breakpoint-up(sm) {
      padding: 0 15px;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 20px;
      padding-right: 0;
      flex: 1;
    }
  }

  &-content {
    padding-left: 10px;
    padding-right: 10px;
    color: $white;
    @include media-breakpoint-up(sm) {
      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
      padding-right: 0;
      padding-left: 0;
      //min-height: 110px;
      //height: 100%;
    }

    p {
      margin-bottom: 0;
    }

    &__header,
    &__title {
      text-transform: uppercase;
    }

    &__header {
      font-size: $font-size-base;
      margin-bottom: 5px;
      line-height: 1;
      letter-spacing: 2.3px;
      text-align: center;
      @include media-breakpoint-up(sm) {
        font-size: $font-size-large;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }

    &__title {
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
      text-align: center;

      @include media-breakpoint-up(sm) {
        font-size: $h1-font-size-mobile;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
      @include media-breakpoint-up(xl) {
        font-size: $h2-font-size;
      }
    }

    &__tagline {
      font-size: $font-size-large;
      text-align: center;
      @include media-breakpoint-up(sm) {
        font-size: $h4-font-size-mobile;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
        font-size: $h4-font-size;
      }
    }

    &__description {
      font-size: $font-size-small;
      margin-top: 15px;
      text-align: center;
      @include media-breakpoint-up(sm) {
        font-size: $h5-font-size-mobile;
      }
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
        text-align: left;
      }
    }
  }

  &-feature {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    @include media-breakpoint-up(lg) {
      padding-right: 0;
      padding-left: 0;
    }

    &__title {
      font-size: $font-size-small;
      color: $white;
      margin-bottom: 5px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      text-align: center;
      @include media-breakpoint-up(sm) {
        font-size: $font-size-base;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }

    &__addon {
      display: grid;
      grid-template-columns: repeat(3, 100px);
      gap: 10px;
      justify-content: center;
      @include media-breakpoint-up(sm) {
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(5, 100px);
      }
      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }

      &-item {
        width: 100%;
        padding-top: 100%;
        position: relative;
        //border: 1px solid $gray-light;
        &:hover {
          background-color: rgba(51, 51, 51, 0.4);
        }

        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      //Customize Swiper
      //$button-width: 44px;
      //.swiper-container,
      //.swiper-wrapper {
      //  height: 100%;
      //  position: static; //INFO: set position for swiper button
      //}
      //.swiper-button-next,
      //.swiper-button-prev {
      //  width: $button-width;
      //  color: $white;
      //  transition: 0.5s;
      //  transform: translateY(0);
      //
      //  &:hover {
      //    color: $gray;
      //  }
      //
      //  &::after {
      //    font-size: $font-size-large;
      //    font-weight: $font-weight-bold;
      //
      //    @include media-breakpoint-up(lg) {
      //      font-size: 24px;
      //    }
      //  }
      //}
      //
      //.swiper-button-next {
      //  right: -$button-width;
      //}
      //
      //.swiper-button-prev {
      //  left: -$button-width;
      //}
    }

    &__learn {
      margin: 8px 0 30px;
      color: $white;
      font-size: $font-size-smaller;
      text-align: center;
      @include media-breakpoint-up(sm) {
        font-size: $font-size-large;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }

      > a {
        font-size: $font-size-smaller;
        font-weight: $font-weight-bold;
        color: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: unset;
        }

        @include media-breakpoint-up(sm) {
          font-size: $font-size-small;
        }
      }
    }
  }

  &-btn {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      margin-bottom: 0;
    }

    .btn {
      min-width: 280px;
      border: none;
      background-color: transparent;
      outline: none;

      &.btn-primary {
        &:hover {
          &:before {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  &-icon {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(lg) {
      justify-content: start;
    }

    &__container {
      width: 100%;
      max-width: 400px;
      @include media-breakpoint-up(lg) {
        max-width: 500px;
      }
    }

    &__wrapper {
      position: relative;
      padding-bottom: calc(200 / 400 * 100%);
      @include media-breakpoint-up(lg) {
        padding-bottom: calc(200 / 500 * 100%);
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: contain;
    }
  }
}

.banner-border {
  display: none;
  @include media-breakpoint-up(lg) {
    &__corner {
      position: absolute;
      top: 15px;
      left: 15px;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      border: 1px solid $gray-light;
      z-index: 0;

      &__top {
        &:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border: 1px solid $gray-light;
          background: transparent;
          top: -1px;
          left: -1px;
          border-top: 1px solid rgba(0, 0, 0, 0.9);
          border-left: 1px solid rgba(0, 0, 0, 0.9);
        }

        span {
          display: block;
          border: 1px solid $gray-light;
          width: 8px;
          height: 8px;
          margin: 0 auto;
          transform: rotate(45deg);
          background: $black;
          left: 0;
          right: 0;
          position: absolute;
          top: -5px;
          border-top: 0;
          border-left: 0;
        }

        &:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border: 1px solid $gray-light;
          background: transparent;
          top: -1px;
          right: -1px;
          border-top: 1px solid rgba(0, 0, 0, 0.9);
          border-right: 1px solid rgba(0, 0, 0, 0.9);
        }
      }

      &__bottom {
        &:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border: 1px solid $gray-light;
          background: transparent;
          bottom: -1px;
          left: -1px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.9);
          border-left: 1px solid rgba(0, 0, 0, 0.9);
        }

        span {
          display: block;
          border: 1px solid #898989;
          width: 8px;
          height: 8px;
          margin: 0 auto;
          transform: rotate(45deg);
          background: #0e1016;
          left: 0;
          right: 0;
          position: absolute;
          bottom: -5px;
          border-right: 0;
          border-bottom: 0;
        }

        &:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border: 1px solid #898989;
          background: transparent;
          bottom: -1px;
          right: -1px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.9);
          border-right: 1px solid rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
}

.popover-item {
  &__title {
    // font-size: $font-size-small;
    font-size: $font-size-smaller;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: $gallery-gray;
    padding: 7px 20px;
    @include media-breakpoint-up(lg) {
      // font-size: $font-size-smaller;
      font-size: $font-size-small;
    }
  }

  &__content {
    padding: 20px;
    font-size: $font-size-smaller;
    @include media-breakpoint-up(lg) {
      font-size: $font-size-small;
    }
  }
}
