@import '@assets/reuse-mixin.scss';

.customer-product {
    background-color: $cp-background;
    padding-bottom: 18px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 32px;
    }

    &__list {
        background-color: $body-bg;
        padding: 0 32px;
    }
}