@import '@assets/reuse-mixin.scss';

.addresses-widget {
  &__content {
    &__info {
      &__address-name {
        margin-bottom: 8px;
        font-weight: $font-weight-normal;
        font-size: $font-size-large;
        line-height: 1;

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
          font-size: $h5-font-size;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size;
        }
      }

      &__line {
        display: block;
        line-height: 1.7;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
          line-height: 1.6;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }

        &.no-address {
          margin-bottom: 24px;

          @include media-breakpoint-up(xl) {
            margin-bottom: 48px;
          }
        }
      }
    }
  }
}