@import "@assets/reuse-mixin.scss";

.view-all-so-modal {
  .modal-content {
    @include media-breakpoint-up(lg) {
      width: 100%;
      max-width: 800px;
    }
  }

  &__box {
    padding: 0;

    &-body {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      &__item {
        width: calc(50% - 12px);
        margin-bottom: 24px;
        padding: 16px 12px;
        border: 0.5px solid $gray-light;

        &:nth-child(odd) {
          margin-right: 12px;
        }

        &:nth-child(even) {
          margin-left: 12px;
        }

        & > .box-data {
          &__row {
            margin: 0;

            .box-data {
              &__col-1 {
                padding-left: 0;

                .sale-order-id {
                  font-size: $h6-font-size-mobile;
                  margin-bottom: 8px;

                  & > span {
                    color: $os-blue;
                  }
                }

                .box-data__notice {
                  line-height: 2.1rem;

                  & > .fw-bold {
                    font-weight: $font-weight-normal;
                    color: $gray-darker;
                  }
                }
              }

              &__col-2 {
                padding-right: 0;

                .highlight-text {
                  min-width: 100px;
                }

                .so-view-detail-link {
                  text-align: right;
                  margin-bottom: 8px;
                  line-height: 1.7rem;
                  display: block;
                  font-size: $h6-font-size-mobile;
                }
              }
            }
          }

          &__title {
            &.--sub {
              font-size: $h5-font-size-mobile;
              line-height: 2.4rem;
              margin: 8px 0;
            }
          }
        }

        .sale-order-price {
          margin-bottom: 0;
          font-size: $h6-font-size-mobile;
        }
      }
    }

    &-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

