@import'../../../../assets/reuse-mixin.scss';

.cart-list {
  padding-bottom: 20px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 50px;
  }

  @include media-breakpoint-up(xxl) {
    padding-bottom: 70px;
  }

  &__item {
    &:last-child {
      border-bottom: 1px solid $gray;

      .cart-item-total-price {
        border-bottom: 0;
      }
    }
  }

  &.skeleton__item {
    .image-wrap {
      padding-bottom: 100%;
    }
  }
}