@import '@assets/reuse-mixin.scss';

.qA-popup {
  position: fixed;
  top: $CP_HEADER_HEIGHT + $CP_DEMO_STATUS;
  right: -100%;
  width: 100%;
  //max-width: 672px;
  max-width: 720px;
  max-height: 85vh;
  background-color: $white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px $gray-dark;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: $POPUP_ZINDEX;
  overflow-y: auto;
  @include scrollbar-custom();

  &--center {
    top: 50%;
    transform: translate(50%, -50%);
    max-width: 720px;
    padding: 24px 8px;

    .qA-popup {
      &__header {
        border-bottom: 0;
        flex-direction: row-reverse;
        padding-right: 12px;
      }

      &__title {
        border-left: 0;
        text-align: center;
        line-height: 1;
        letter-spacing: 1px;
        font-weight: $font-weight-normal;
        font-size: $h5-font-size;
      }
    }
  }

  &__header {
    display: flex;
    border-bottom: 1px solid $gray-dark;
  }

  &__header__no-border {
    display: flex;
    justify-content: flex-end;
    border-bottom: none;
  }

  &__close-btn {
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-icon {
      display: block;
      margin: 11px 0;
      width: 16px;
      height: 22px;
      position: relative;
      cursor: pointer;

      span {
        display: block;
        background-color: $gray-darker;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;

        &:first-child {
          transform: translateY(-50%) rotate(45deg);
        }

        &:last-child {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }

  &__close-btn-right {
    justify-content: end;
  }

  &__title {
    color: $gray-darker;
    width: calc(100% - 38px);
    border-left: 1px solid $gray-dark;
    line-height: 44px;
    letter-spacing: 0.7px;
    font-size: $font-size-small;
    margin-bottom: 0;
    padding: 0 20px;
    align-items: center;
  }

  &__description {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.7px;
    margin-right: 20px;
    align-self: center;
    color: $os-blue;
    cursor: pointer;
    flex-shrink: 0;
  }

  &__body {
    padding: 24px 24px 32px;
  }

  &__body__QACustomerBasicProfile {
    padding: 0 20px 24px 20px;
  }
}