@import'../../../../assets/reuse-mixin.scss';

.sub-navigation {
  height: 42px;
  z-index: $PRODUCT_DETAIL_HEADER_ZINDEX - 1;
  top: $HEADER_MOBILE_HEIGHT;
  position: sticky;
  
  @include media-breakpoint-up(md) {
    top: calc(#{$HEADER_DESKTOP_HEIGHT});
    height: 57px;
  }
  
  &__wrap {
    left: 0;
    width: 100%;
    // z-index: $PRODUCT_DETAIL_HEADER_ZINDEX - 1;
    background-color: $white;
    color: $gray-darker;
    border-bottom: 1px solid $gray-lighter;
    padding: 13px 0;
    // position: fixed;
    // top: $HEADER_MOBILE_HEIGHT;
  
    @include media-breakpoint-up(md) {
      // top: calc(#{$HEADER_DESKTOP_HEIGHT});
      padding: 20px 0 15px;
    }
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }

  &__nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;

    @include media-breakpoint-up(md) {
      justify-content: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__nav-item {
      margin-right: 24px;
      padding: 0;
      width: inherit;
      color: $gray-darker;
      text-transform: uppercase;
      font-size: $font-size-smaller;
      font-weight: normal;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
  
      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
        margin-right: 80px;
      }
  
      @include media-breakpoint-up(xl) {
        margin-right: 160px;
      }
  
      &:last-child {
        margin-right: 0;
      }
  
      &.active {
        border: none;
      }

      &:hover {
        color: $link-hover-color;

        a {
          color: $link-hover-color;
        }
      }

      a {
        color: $gray-darker;
        text-transform: uppercase;
        font-size: $font-size-smaller;
        font-weight: normal;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base;
        }
      }

      &.skeleton__item {
        min-width: 100px;

        @include media-breakpoint-up(md) {
          min-width: 150px;
        }

        .text-line {
          @include skeleton($lines: 1, $line-height: 22px, $line-gap: 0, $background: transparent);
        }

      }
    }
  }
}