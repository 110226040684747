.be-schedule-appointment {
  &__schedule-experience {
    .schedule-experience {
      &__body {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        &-item {
          .select-store__content__list {
            padding-left: 0;
            padding-right: 0;
            @include media-breakpoint-up(lg) {
              margin-top: 0;
              padding-right: 50px;
            }
          }

          .react-datepicker {
            border: none;
            font-family: $font-family-base;
            font-size: $font-size-base;
          }

          .select-store__content__question {
            padding-left: 0;
            padding-right: 0;
            @include media-breakpoint-up(md) {
              padding-left: 50px;
            }
          }

          &__timeslot-select__wrapper, &__guest-select__wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .select-store__date__option__list__item {
              &--disabled {
                color: $cp-border-color;
                pointer-events: none;
                opacity: .7;
              }

              .form-group {
                margin-bottom: 12px;
                border-color: transparent;

                &:has(input[type="radio"]) {
                  &:hover {
                    border-color: $gray-darker;
                  }
                }

                &:has(input:checked) {
                  color: $gray-darker;
                  border-color: $gray-darker;
                }
              }
            }
          }

          &__timeslot-select {
            margin-bottom: 32px;
            @include media-breakpoint-up(md) {
              margin-bottom: 72px;
            }
          }
        }
      }
    }
  }
}