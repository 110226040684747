@import '@assets/reuse-mixin.scss';

.password-input {
  position: relative;

  &__icons {
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
    position: absolute;
    right: .8rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
      border: none;
    }

    .eye-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &__visible {
        display: none;
      }
    }

    &.active {
      .eye-icon {
        &__invisible {
          display: none;
        }

        &__visible {
          display: block;
        }
      }
    }
  }
}