@import '@assets/reuse-mixin.scss';

.banner {
  position: relative;

  &__bg {
    position: relative;
    padding-bottom: calc(375 / 471  * 100%);

    @include media-breakpoint-up(md) {
      padding-bottom: calc(750 /1920  * 100%);
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba($color: $black, $alpha: .4);
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin-top: 24px;

    &__title {
      color: $white;
    }

    &__desc {
      color: $white;
      font-size: $font-size-small;
      text-align: center;
      max-width: 840px;
      padding: 0 25px;

      @include media-breakpoint-up(md) {
      font-size: $font-size-base;
      }
    }
  }
}
