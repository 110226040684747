@import '@assets/reuse-mixin.scss';

.store-experiences {
  color: $gray-darker;
  margin-top: 36px;

  @include media-breakpoint-up(lg) {
    margin-top: 72px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 40px;
    
    @include media-breakpoint-up(xl) {
      margin-bottom: 45px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
    }

    &__item {
      max-width: 70%;
      text-align: center;
      margin-bottom: 50px;

      @include media-breakpoint-up(md) {
        max-width: 33%;
        padding: 0 30px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 30%;
        padding: 0 40px;
      }

      @include media-breakpoint-up(xxl) {
        padding: 0 105px;
      }

      &__icon {
        margin-bottom: 24px;

        .store-experiences-icon {
          width: 40px;
        }
      }

      &__text {
        font-size: $font-size-large;
        margin-bottom: 0;
      }
    }
  }

  &__btn-book {
    text-align: center;

    &__item {
      font-size: $font-size-base;
      width: 192px;
      padding: 11px 25px;
    }
  }
}