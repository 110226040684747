@import '@assets/reuse-mixin.scss';

.my-appointment__tabs__content {
  text-align: left;

  @include media-breakpoint-up(md) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.my-appointment__item {
  padding-bottom: 24px;
  
  &:last-child {
    padding-bottom: 0px;
  }

  &__title {
    padding-bottom: 12px;

    span {
      font-size: $h4-font-size-mobile;
      color: $gray-900;
      font-weight: $font-weight-normal;
      padding-right: 12px;
      
      &:last-child {
        padding-right: 0px;
      }

      &:nth-child(n+2) {
        padding-left: 12px;
        border-left: 1px solid $gray-200;
      }

      @include media-breakpoint-up(md) {
        padding-right: 23px;
        font-size: $h4-font-size;

        &:nth-child(n+2) {
          padding-left: 23px;
        }
      }
    }
  }

  &__box {
    position: relative;
    display: flex;
    // align-items: center; 
    justify-content: space-between;  
    padding-bottom: 8px;  
    
    &__address {

      &__title {
        font-weight: $font-weight-normal;
        font-size: $h5-font-size-mobile;
        margin-bottom: 8px;
  
        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
        }
      }

      p {
        margin-bottom: 4px;
        color: $gray-900;
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;

    a {
      text-transform: none;
      font-weight: $font-weight-normal;
      letter-spacing: normal;
      padding: 0;
    }
  }
}