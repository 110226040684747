@import '@assets/reuse-mixin.scss';

.wellness-detail-header {
  padding-top: 18px;
  font-size: $font-size-base;
  // letter-spacing: 1.28px;

  @include media-breakpoint-up(lg) {
    padding-top: 40px;
    font-size: $font-size-large;
  }

  @include media-breakpoint-up(xl) {
    font-size: $h5-font-size;
    // letter-spacing: 1.6px;
  }

  // .smaller-container {
  //   @include media-breakpoint-up(xl) {
  //     max-width: 1107px;
  //   }
  // }

  &__tag-item {
    display: inline-block;
  }

  .featured-article-item__tags {
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
    }

    &__item {
      cursor: pointer;

      &:hover {
        background-color: $os-orange;
        color: $gray-darker;
      }
    }
  }

  &__title {
    margin-bottom: 8px;
    // letter-spacing: 1.4px;
    font-size: $h1-font-size-mobile;

    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
      // letter-spacing: 2.5px;
      font-size: $h3-font-size + 0.4;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h2-font-size;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h1-font-size;
    }
  }

  &__description {
    margin-bottom: 6px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 12px;
    }
  }

  .featured-article-item__sub-info {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &__banner {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
    }
  }

  & + .rich-text-content {
    padding-top: 0;

    .rich-text-content {
      padding-top: 0;
    }
  }
}