@import "@assets/reuse-mixin.scss";

.save-so-success {
  background-color: $cp-background;
  padding-bottom: 18px;

  &-container {
    background-color: $white;
    padding-top: 62px;
    padding-bottom: 62px;
  }

  &-wrapper {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  &__header {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      &-icon {
        max-width: 50px;
        width: 100%;
        margin-bottom: 18px;

        &-wrapper {
          width: 100%;
          padding-bottom: 100%;
          position: relative;
          overflow: hidden;
        }

        &-img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: contain;
        }
      }

      &-title {
        font-size: $h6-font-size;
        line-height: 2rem;
        color: $success;
        margin-bottom: 18px;
      }

      &-description {
        color: $cp-gray;
        font-size: $font-size-base;
        line-height: 2.4rem;
      }
    }
  }

  &__body {
    .order {
      &-info {
        text-align: center;

        &__id {
          font-weight: $font-weight-normal;
          font-size: $h6-font-size;
          line-height: 2rem;
          margin-bottom: 8px;
        }

        &__description {
          font-size: $font-size-base;
          line-height: 2.4rem;
          color: $cp-gray;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          &.created-expired {
            & > span {
              font-weight: $font-weight-normal;
            }
          }
        }
      }

      &-detail {
        max-width: 280px;
        width: 100%;
        margin: auto auto 32px;

        &__title {
          font-weight: $font-weight-normal;
          line-height: 2.4rem;
          font-size: $font-size-base;
          color: $gray-darker;
          margin-bottom: 8px;
        }

        &__item-line {
          &__label, &__value {
            font-size: $font-size-small;
            color: $cp-gray;
          }
        }

        &__total {
          margin-top: 18px;

          &-label, &-value {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: 2.4rem;
            color: $gray-darker;
            margin-bottom: 0;
          }
        }

        &__box {
          padding-bottom: 16px;
        }
      }
    }

    &-loading {
      margin-bottom: 32px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    width: 100%;
    margin: auto;

    .footer {
      &-btn {
        width: fit-content;
        text-align: center;
        margin-bottom: 18px;
      }
    }
  }
}
