@import '@assets/reuse-mixin.scss';

.register-event {
  padding: 72px 0;
  text-align: center;

  &__form {
    &__head {
      .title {
        font-size: $h1-font-size-mobile;
        line-height: 1.5;
        letter-spacing: 1.28px;
        color: $gray-900;
        padding: 36px 0 24px 0;

        @include media-breakpoint-up(lg) {
          font-size: $h1-font-size;
          line-height: 1.4;
          letter-spacing: 1.6px;
          padding: 48px 0 40px 0;
        }
      }

      .subtitle {
        &-text {
          display: inline-block;
          font-size: $h5-font-size-mobile;
          line-height: 2.4;
          letter-spacing: 1.28px;
          margin-bottom: 0;
          @include media-breakpoint-up(lg) {
            letter-spacing: 1.6px;
            line-height: 2.8;
            font-size: $h5-font-size;
          }
        }

        &-btn {
          color: $link-color !important;
          font-weight: $font-weight-normal;
          cursor: pointer;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }

    &__body {
      margin-top: 24px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(lg) {
        margin-top: 40px;
      }
    }

    &__content {
      &__section {
        max-width: 480px;
        margin: 0 auto;

        .form-group {
          .error-validate {
            text-align: left;
          }

          &__birth {
            & > label {
              text-align: left;
            }
          }

          .change-phone-number-link {
            text-align: left;
            margin-top: 12px;
          }

          &.contact-detail__body-phone-number-input {
            .form-group--group-select-input {
              margin-bottom: 0;

              .form-group--input {
                & > .error-validate {
                  text-align: left;
                }
              }
            }
          }
        }

        .section {
          &-label {
            margin-bottom: 16px;
            text-align: center;
            @include media-breakpoint-up(lg) {
              text-align: left;
            }
          }
        }

        &.otp-input-section {
          .resend-otp {
            width: 100%;
            display: flex;
            justify-content: end;
            align-items: center;

            &__btn {
              padding: 0;
              text-transform: capitalize;

              &:before {
                content: unset;
              }
            }
          }
        }
      }
    }
  }
}
