@import "@assets/reuse-mixin.scss";

.well-being-products {
  padding: 30px 0;

  &__title {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  &__swiper {
    position: relative;

    &.center-item {
      .swiper-wrapper {
        justify-content: center;
      }
    }

    &.center-item-desktop {
      @include media-breakpoint-up(md) {
        .swiper-wrapper {
          justify-content: center;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
      width: 12px;
      font-size: 20px;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
      
      &:active,
      &:focus {
        outline: none;
      }
    
      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 24px;
      color: $gray-darker;
    }

    .swiper-button-next {
      right: -22px;
    }

    .swiper-button-prev {
      left: -22px;
    }
  }

  .well-being-product-item {
    text-align: center;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    &__container {
      max-width: 240px;
      margin: 0 auto;
    }
  
    &__thumb {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 15px;
  
      @include media-breakpoint-up(md) {
        height: 170px;
      }
  
      @include media-breakpoint-up(lg) {
        height: 220px;
        margin-bottom: 20px;
      }
      
      img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  
    &__name,
    &__selling-price {
      color: $gray-darker;
      font-weight: $font-weight-normal;
      margin-top: 15px;
      font-size: $font-size-large;
      letter-spacing: 0.9px;
  
      @include media-breakpoint-up(md) {
        margin-top: 20px;
        font-size: $h4-font-size;
        letter-spacing: 1.2px;
      }
    }

    &__name {
      @include line-clamp(2);

      &__link {
        color: $gray-darker;

        &:hover {
          //color: $secondary;
          color: $link-color;
        }
      }
    }
  
    &__list-price {
      text-decoration: line-through;
      color: $gray;
      font-size: $font-size-small;
      letter-spacing: 0.6px;
  
      @include media-breakpoint-up(md) {
        margin-top: 5px;
        font-size: $font-size-large;
        letter-spacing: 0.8px;
      }
    }
  
    &__button {
      margin-top: 24px;
      overflow: hidden;
      
      @include media-breakpoint-up(md) {
        margin-top: 32px;
      }
  
      .btn {
        display: inline-block;
        width: 240px;
        max-width: 100%;
        text-align: center;
      }
    }
  }

  &.skeleton__item {
    .well-being-products__swiper {
      display: flex;
      justify-content: space-between;

      .image-wrap {
        padding-bottom: 100%;
      }

      .skeleton__item__container {
        width: calc(100%/2 - 15px);

        @include media-breakpoint-up(md) {
          width: calc(100%/3 - 30px);
        }

        @include media-breakpoint-up(lg) {
          width: calc(100%/4 - 30px);
        }
      }
    }
  }
}
