@import '@assets/reuse-mixin.scss';

.help-me-decide-result {
  // heading
  &__heading {
    text-align: center;
    padding: 32px 0;

    @include media-breakpoint-up(lg) {
      padding: 48px 0;
    }

    &__title {
      color: $gray;
      font-size: $font-size-small;
      text-transform: uppercase;
      margin: 0 0 10px;
      font-weight: $font-weight-bold;
      letter-spacing: 0.3rem;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }

    &__description {
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }
  }

  &__bar {
    display: flex;
    align-content: space-around;
    align-items: baseline;
    border-top: 2px solid $gray-light;
    padding: 20px 0;

    &__count {
      font-size: $font-size-large;
    }

    &__sort {
      margin-left: auto;
    }
  }

  &__list {
    max-width: 980px;
    margin: 0 auto;

    .article {
      margin-bottom: 32px;

      &__image {
        @include media-breakpoint-up(md) {
          width: 240px;
        }
    
        @include media-breakpoint-up(lg) {
          width: 390px;
        }
      }
  
      &__content {
        padding: 0 0 0 20px;

        @include media-breakpoint-up(md) {
          width: calc(100% - 240px);
          padding: 0 0 20px 20px;
        }
  
        @include media-breakpoint-up(lg) {
          width: calc(100% - 390px);
        }
  
        @include media-breakpoint-up(xl) {
          padding: 0 0 20px 90px;
        }
        
        &__link {
          margin-right: auto;
          margin-left: auto;

          @include media-breakpoint-up(md) {
            margin-left: 0;
          }

          &.bundle-link {
            @include media-breakpoint-up(md) {
              display: none
            }
          }
        }
  
        &__action {
          a {
            @include media-breakpoint-up(lg) {
              margin-bottom: 20px;
              margin-right: 20px;
            }
          }
  
          a:last-child {
            @include media-breakpoint-up(lg) {
              margin-bottom: 20px;
              margin-left: 0;
            }
          }
        }

        .article__content__action {
          & > .btn {
            width: 100%;
            max-width: calc(50% - 10px);
          }
        }
      }
    }
  }

  

}