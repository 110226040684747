@import '../../../../assets/reuse-mixin.scss';

.two-column-CTA-Button {
  &__col {
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: -50%;
      right: 0;
      width: 1px;
      height: 185px;
      background-color: gray;

      @include media-breakpoint-up(xl) {
        height: 241px;
        top: -60%;
      }

      @include media-breakpoint-up(xxl) {
        top: -75%;
      }
    }

    &::before {
      width: 5px;
      height: 32px;
      border-radius: 3px;
      right: -2px;

      @include media-breakpoint-up(xl) {
        height: 56px;
      }
    }

    &:last-child {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &__contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0 0;
    transition: 0.5s;

    @include media-breakpoint-up(md) {
      padding: 35px 0 0;
    }

    @include media-breakpoint-up(xl) {
      padding: 45px 0 0;
    }

    &:hover {
      transform: scale(1.2);

      .two-column-CTA-Button__contents__title {
        color: $black;
      }
    }

    &__image {
      height: 32px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }

      img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }

    &__title {
      color: $gray-dark;
      // font-size: $font-size-small;
      font-size: $font-size-base;
      text-align: center;
      padding: 0 22px;

      @include media-breakpoint-up(md) {
        // font-size: $font-size-base;
        font-size: $font-size-large;
      }

      @include media-breakpoint-up(lg) {
        // font-size: $font-size-base;
        font-size: $h4-font-size;
      }

      > a {
        color: inherit;
        font-size: inherit;
        margin-right: 4px;
      }
    } 
  }

  .skeleton__item {
    .two-column-CTA-Button__contents__image {
      width: 35px;
      height: 35px;
    }
  }
}