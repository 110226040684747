@import '@assets/reuse-mixin.scss';

.cp-login-header {
  .cp-header__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__logo {
    display: block;
    max-width: 115px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}

.cp-login-content {
  position: relative;
  height: calc(100vh - 140px);

  &__background,
  &__form-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__background {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__form-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    width: 80%;
    max-width: 368px;
    padding: 24px;
    background: $white;

    &__title {
      font-size: $h4-font-size;
      font-weight: $font-weight-normal;
      margin-bottom: 24px;
      text-align: center;
    }

    &__desc,
    &__info {
      font-size: $font-size-smaller;
      color: $cp-gray;
    }

    &__desc {
      margin-bottom: 8px;
    }

    &__info {
      margin: 0 auto 18px;
      text-align: center;
      max-width: 90%;
    }

    &__btn {
      text-align: center;

      &__item {
        display: inline-block;
        width: 100%;
        margin-top: 18px;
        text-align: center;

        &:first-child {
          margin-top: 0;
        }

        &.os-btn-link {
          width: auto;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .form-group {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    
    .form-group__label {
      font-size: $font-size-base;
    }
  }

  &__false {
    display: block;
    margin-bottom: 10px;
    margin-top: -10px;
  }
}

.cp-login-footer {
  background: $os-brown-lightest;
  color: $gray-darker;
  font-size: $font-size-small;
  
  &__content {
    padding: 25px 0;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    &__left {
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &__right {
      &__item {
        color: $gray-darker;
        padding: 0 6px;
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 60%;
          transform: translateY(-50%);
          display: block;
          background: $gray-darker;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}