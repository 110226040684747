.cart-seller__form {
  &-custom {
    .cart-promotion-codes__form__input {
      &:not(:has(button[type="submit"])) {
        & > .checkout-input__input {
          width: 100%;
        }
      }
    }
  }
}