@import '@assets/reuse-mixin.scss';

.select-store__date {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  > div {
    width: 100%;
  }

  &__title {
    color: $primary;
  }

  &__guide {
    margin-top: 12px;
    margin-left: 6px;

      @include media-breakpoint-up(lg) {
        margin-left: 16px;
      }
    
    &__item {
      margin-top: 8px;
      display: inline-block;
      margin-right: 24px;

      @include media-breakpoint-up(lg) {
        margin-right: 40px;
      }

      &__square {
        display: inline-block;
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-right: 12px;

        @include media-breakpoint-up(lg) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__option {
    margin-top: 20px;

    &__list {
      margin-top: 12px;

      &__item {
        display: inline-block;
        margin-left: 8px;
        margin-right: 8px;
        
        .form-group {
          border: 1px solid $gray-300;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            border-color: $gray-900;
          }

          &:has(input:checked) {
            border-color: $gray-900;
          }

          label {
            padding: 8px;
            margin-bottom: 0;
            cursor: pointer;
            width: 100%;
          }
        }

      }
    }
  }

  .react-datepicker {
    &.date-picker {
      font-family: $font-family-base;
      border: none;
      border-radius: 0;
      font-size: $font-size-small;
      width: 100%;
      font-weight: $font-weight-normal;
      
      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    
      .react-datepicker {
        &__header {
          border: none;
          background-color: transparent;
        }
  
        &__month {
          margin: 0;
        }

        &__current-month {
          font-size: $h4-font-size-mobile;
          font-weight: $font-weight-light;
          
          @include media-breakpoint-up(lg) {
            font-size: $h4-font-size;
          }
        }

        &__navigation {
          top: 16px;
          height: 25px;
          width: 10px;

          span {

            &::before {
              border-width: 1px 1px 0 0;
              height: 10px;
              width: 10px;
              border-color: $gray-900;
            }
          }

          &:focus {
            outline: none;
            border: none;
          }
        }

        &__week {
          padding: 0;
        }
      
        &__day {
          width: calc(100% / 7 - 6px);
          height: 40px;
          margin: 0 6px 6px 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: $gray-900;
          font-size: $font-size-small;
          
          &:last-child {
            margin-right: 0;
          }
  
          @include media-breakpoint-up(lg) {
            width: calc(100% / 7 - 16px);
            margin: 0 16px 16px 0;
            font-size: $font-size-base;
          }
        
          &:hover {
            background-color: transparent;
            border-radius: 0;
            border: 1px solid $gray-900;
            color: $gray-900;
          }

          &-name {
            width: calc(100% / 7 - 6px);
            height: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
      
            &:last-child {
              margin-right: 0;
            }

            @include media-breakpoint-up(lg) {
              width: calc(100% / 7 - 16px);
              height: 50px;
              margin-right: 16px;
            }
          }
          
          &--selected {
            background-color: transparent;
            border-radius: 0;
            border: 1px solid $gray-900;
            color: $gray-900;
          }
      
          &--disabled {
            color: $gray-500;
            border-color: transparent;
      
            &:hover {
              color: $gray-500;
              border-color: transparent;
            }
          }
    
          &--today {
            font-weight: $font-weight-normal
          }

          &--keyboard-selected {
            background-color: transparent;
          }
        }
      
        &__month-container {
          float: unset;
        }
      }
    }
  }
}