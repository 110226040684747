@import'../../../../../../assets/reuse-mixin.scss';

.cart-item-total-price {
  padding: 8px 0;
  border-bottom: 1px solid $gray;
  
  @include media-breakpoint-up(lg) {
    padding: 16px 0;
  }

  &__subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-darker;
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
    letter-spacing: 1px;
  
    @include media-breakpoint-up(md) {
      font-size: $h4-font-size;
    }
  
    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  
    @include media-breakpoint-up(xl) {
      font-size: $h3-font-size - 0.2;
    }
  
    p {
      margin: 0;
    }
  }

  &__deposit {
    padding-top: 8px;
    
    @include media-breakpoint-up(lg) {
      padding-top: 16px;
    }

    &__item {
      padding-bottom: 8px;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      
      @include media-breakpoint-up(lg) {
        padding-bottom: 12px;
        font-size: $font-size-large;
      }

      &:last-child {
        padding-bottom: 0px;
      }

      &--pre-order {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $gray-900;
      }

      &--payment {
        color: $success;

        .info {
          font-weight: $font-weight-light;
        }
      }
    }
  }
}
