@import '@assets/reuse-mixin.scss';

.banner-with-rich-text-overlay {
  &__wrapper {
    position: relative;
    padding-bottom: 675/375 * 100%;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      padding-bottom: 777/1520 * 100%;
      margin-bottom: 32px;
    }
  }

  &__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  &__contents {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: $gray-darker;
    opacity: 0.8;
    padding: 36px 24px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @include media-breakpoint-up(md) {
      left: auto;
      right: 0;
      width: 40%;
      height: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-content: flex-end;
      overflow: visible;
      background-color: rgba($color: $black, $alpha: 0.9);
    }

    &__container {
      @include media-breakpoint-up(md) {
        max-width: 390px;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    &__title {
      font-weight: $font-weight-normal;
      font-size: $font-size-large;
      letter-spacing: 0.9px;
      color: $white;
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
        letter-spacing: 1.6px;
        margin-bottom: 16px;
      }
    }

    &__description {
      font-size: $font-size-base;
      letter-spacing: 1.28px;
      color: $white;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
        letter-spacing: 1.6px;
      }
    }
  }

  &__button {
    a {
      min-width: 200px;

      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }
}