@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon/icomoon.eot?8p0eac');
  src:  url('./fonts/icomoon/icomoon.eot?8p0eac#iefix') format('embedded-opentype'),
    url('./fonts/icomoon/icomoon.ttf?8p0eac') format('truetype'),
    url('./fonts/icomoon/icomoon.woff?8p0eac') format('woff'),
    url('./fonts/icomoon/icomoon.svg?8p0eac#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-remove:before {
  content: "\e903";
}
.icon-filter_list:before {
  content: "\e904";
}
.icon-chevron-down-big:before {
  content: "\e907";
}
.icon-arrow-prev:before {
  content: "\e908";
}
.icon-arrow-next:before {
  content: "\e909";
}
.icon-hamburger:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-chevron-up-big:before {
  content: "\e910";
}
.icon-chevron-left-big:before {
  content: "\e911";
}
.icon-facebook:before {
  content: "\e912";
}
.icon-twitter:before {
  content: "\e913";
}
.icon-location:before {
  content: "\e914";
}
.icon-chevron-right-big:before {
  content: "\e916";
}
.icon-search:before {
  content: "\e91e";
}
.icon-heart:before {
  content: "\e92f";
}
.icon-heart-selected:before {
  content: "\e930";
}
.icon-list:before {
  content: "\e939";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-thumbs-down:before {
  content: "\e94e";
}
.icon-thumbs-up:before {
  content: "\e94f";
}
.icon-user:before {
  content: "\e971";
}
.icon-enlarge:before {
  content: "\e98b";
}
.icon-shrink:before {
  content: "\e98c";
}
.icon-download:before {
  content: "\e9c7";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-loop:before {
  content: "\ea2e";
}
.icon-share:before {
  content: "\ea82";
}
.icon-chevron-left:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-chevrons-left:before {
  content: "\e901";
}
.icon-chevrons-right:before {
  content: "\e902";
}