@import '@assets/reuse-mixin.scss';

.payment-options {
  width: 100%;
  background-color: $os-brown-lightest;
  //@include media-breakpoint-up(sm) {
  //  margin-top: 45px;
  //}

  &__container {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .image-item {
      display: inline-block;
      width: auto;
      max-height: 32px;
      object-fit: contain;
      margin-bottom: 8px;
      margin-right: 16px;
      @include media-breakpoint-up(md) {
        margin-right: 33px;
      }
    }
  }

  &__title {
    font-size: $font-size-small;
    color: $gray-dark;
    font-weight: $font-weight-normal;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 8px;
    @include media-breakpoint-up(lg) {
      width: unset;
      font-size: $font-size-base;
      margin-right: 18px;
    }
  }
}
