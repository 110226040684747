@import '@assets/reuse-mixin.scss';

.animated-fast,
.animated-on-scroll-fast {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  opacity: 0;
}

.animated,
.animated-on-scroll {
  animation-duration: 0.7s;
  animation-fill-mode: both;
  opacity: 0;
}

.animated-slow,
.animated-on-scroll-slow {
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 0;
}

@for $i from 1 through 30 {
  .delay-#{$i}00 {
    animation-delay: #{$i * 100}ms;
    transition-delay: #{$i * 100}ms;

    &:before,
    &:after {
      animation-delay: #{$i * 100}ms;
      transition-delay: #{$i * 100}ms;
    }
  }
}

// ANIMATION CLASS
// -------------------------
// 1. Fade In
.fadeIn {
  animation-name: fadeIn;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.fadeInRight {
  animation-name: fadeInRight;
}

.fadeInDown {
  animation-name: fadeInDown;
}

// ANIMATION KEYFRAMES
// -------------------------
// 1. Fade In
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 3rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-3rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(3rem, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -3rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes sliderScrollRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-280px * 4));
  }
}

@include media-breakpoint-up(sm) {
  @keyframes sliderScrollRightToLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-480px * 4));
    }
  }
}

@keyframes scrolling {
  0% {
      transform: translateX(0);
  }

  100% {
      transform: translateX(calc((-1 * var(--marquee-element-width) * var(--marquee-elements))));
  }
}
