@import '@assets/reuse-mixin.scss';

.dashboard {
  background: $cp-background;

  &__title {
    font-size: $h4-font-size;
    font-weight: $font-weight-normal;
    line-height: 1.17;
    letter-spacing: 1.2px;
    color: $gray-darker;
    padding: 24px 24px 12px 24px;
    background-color: $white;
    margin-bottom: 0;
  }
}

.dashboard-group-links {
  background: $cp-background;
  
  .row {
    margin: 0 -12px;
  }

  .col-md-4 {
    padding: 0 12px;
    margin-bottom: 24px;
  }
}