@import '@assets/reuse-mixin.scss';

.partner-special {
  &__subtitle {
    margin-top: 24px;
    margin-bottom: 0;
    color: $gray-1000;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin-left: 48px;
    }
  }

  &__details {
    width: 100%;
    margin-top: 16px;
    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }

    &-swiper {
      &__item {
        width: 100%;

        & > .partner-logo {
          cursor: pointer;
          width: 100%;
          padding-bottom: calc(80 / 128 * 100%);
          position: relative;
          @include media-breakpoint-up(md) {
            padding-bottom: calc(186 / 280 * 100%);
          }

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: contain;
          }
        }

        & > .description {
          font-size: $font-size-mobile;
          line-height: 1.8rem;
          color: $black;
          text-align: center;
          margin-top: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          @include media-breakpoint-up(lg) {
            font-size: $h5-font-size;
            line-height: 2.4rem;
            color: $gray-1000;
            margin-top: 8px;
          }
        }
      }
    }
  }

  &__cta {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      margin-top: 48px;
    }
  }

  &-popup {
    position: relative;

    &__image {
      position: relative;
      height: 200px;
      
      @include media-breakpoint-up(md) {
        height: 366px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__btn {
      position: absolute;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
    }
  }
}