.be-schedule-appointment__personalize-experience {
  .personalize-experience {
    &__body {
      &-item {
        &:not(:first-child) {
          margin-top: 16px;
          @include media-breakpoint-up(md) {
            margin-top: 43px;
          }
        }

        .additional-selection {
          max-width: 760px;
          width: 100%;
          margin: 0 auto;

          &__body {
            &-question {
              &__title {
                color: $gray-darker;
                font-size: $font-size-base;
                margin-bottom: 16px;
                @include media-breakpoint-up(md) {
                  font-size: $h6-font-size;
                  margin-bottom: 24px;
                }
              }
            }

            &-answer {
              &__radio {
                margin-bottom: 8px;
                @include media-breakpoint-up(md) {
                  margin-bottom: 18px;
                }

                & > .osim-radio {
                  margin-bottom: 8px;
                  @include media-breakpoint-up(md) {
                    margin-bottom: 18px;
                  }
                }
              }

              &__checkbox {
                margin: 0;

                .osim-checkbox {
                  margin-bottom: 8px;
                  @include media-breakpoint-up(md) {
                    margin-bottom: 18px;
                  }
                }
              }

              &__dropdown {
                margin: 0;
              }
            }
          }
        }
      }

      & > .error-validate {
        max-width: 760px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .product-select-swiper {
    padding-top: 0;
    padding-bottom: 0;

    .select-product__swiper {
      &__item {
        &__content {
          &__subtitle {
            margin-top: 24px;
            margin-bottom: 16px;
            @include media-breakpoint-up(md) {
              margin-top: 34px;
            }
          }
        }
      }

      .swiper-button {
        &-next, &-prev {
          position: absolute;
        }

        &-next {
          // right: 0;
          right: 12%;
        }

        &-prev {
          // left: 0;
          left: 12%;
        }
      }
    }
  }
}
