@import '@assets/reuse-mixin.scss';

.dashboard-link {
  display: block;
  padding: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: $white;
  text-align: center;
  height: 100%;
  color: $gray-darker;

  &:hover {
    color: $gray-darker;
  }

  &.--disable-link {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    box-shadow: none;

    .dashboard-link__name {
      color: $gray-darker;
    }
  }

  &__name {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: 2;
    letter-spacing: 0.7px;
    margin-bottom: 16px;
    color: $secondary;
  }

  &__number {
    font-size: $h1-font-size-mobile;
    font-weight: $font-weight-normal;
    line-height: 1.54;
    letter-spacing: 1.4px;
    margin-bottom: 8px;
  }

  &__description {
    display: block;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    line-height: 1.38;
    letter-spacing: 0.8px;
  }
}