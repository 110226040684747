@import '@assets/reuse-mixin.scss';

.sf-express {
  // @include media-breakpoint-up(lg) {
    // padding: 0 44px 20px;
  // }

  &__des {
    padding-top: 14px;
    text-align: center;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      padding-top: 22px;
    }
  }

  &__wrap {
    margin-top: 24px;
    max-height: 35vh;
    overflow-y: auto;

    @include scrollbar-custom();

    @include media-breakpoint-up(lg) {
      margin-top: 32px;
      max-height: 40vh;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 44px 20px;
    }
  }

  &__item {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__name-store {
      margin-bottom: 5px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 9px;
      }
    }

    &__address-store {
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }
  }

  .store-locator {
    &__modal {
      max-width: 480px;
      margin: 18px auto 0;
  
      @include media-breakpoint-up(lg) {
        margin: 14px auto 0;
      }
    }
  }
}
