@import '@assets/reuse-mixin.scss';

.promotion-detail-bundle {
  color: $gray-darker;

  &__banner {
    position: relative;
    color: $gray-darker;
    padding-bottom: calc(666 / 375 * 100%);

    @include media-breakpoint-up(md) {
      padding-bottom: calc(640 / 1920 * 100%);
    }

    &.text-white {
      color: $white;

      h1,
      h2 {
        color: $white;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__wrapper-contents {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include media-breakpoint-up(lg) {
        > * {
          height: 100%;
        }
      }
    }

    &__contents {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 48px;
      width: 100%;

      @include media-breakpoint-up(md) {
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        padding-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        max-width: 50%;
        // height: 100%;
      }

      &.text-bottom {
        justify-content: flex-end;
      }

      &__title {
        margin-bottom: 15px;
        font-size: $h1-font-size-mobile;
        letter-spacing: 0.56px;
        line-height: 3.6rem;
        font-weight: $font-weight-light;

        @include media-breakpoint-up(xl) {
          margin-bottom: 24px;
          font-size: $h1-font-size;
          letter-spacing: 2.5px;
          line-height: 5.8rem;
        }
      }

      &__description {
        font-size: $font-size-base;
        margin-bottom: 24px;
        font-weight: $font-weight-light;
        letter-spacing: 0.8px;

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size;
          margin-bottom: 30px;
          max-width: 600px;
        }
      }

      &__btn {
        margin-bottom: 8px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 18px;
        }

        &__item {
          min-width: 200px;
          padding: 6.5px 47px;
          letter-spacing: 2.24px;

          @include media-breakpoint-up(xl) {
            min-width: 240px;
            letter-spacing: 2.56px;
            padding: 7px 54px;
          }
        }
      }

      .btn-link,
      .btn-link-white {
        margin-top: 10px;
      }

      &__date {
        font-size: $font-size-base;

        @include media-breakpoint-up(xl) {
          font-size: $h5-font-size;
        }
      }
    }

    .content-position {
      display: flex;
      height: 100%;
      padding-top: 40px;

      @include media-breakpoint-up(md) {
        align-items: center;
        padding-top: 0;
      }

      .content-wrapper {
        @include media-breakpoint-up(md) {
          max-width: 50%;
        }

        @include media-breakpoint-up(lg) {
          padding-right: 55px;
        }

        @include media-breakpoint-up(xl) {
          padding-right: 70px;
        }
      }

      //position desktop
      &.content-center {
        @include media-breakpoint-up(md) {
          justify-content: center;
          padding-top: 0;
          align-items: center;
        }

        .content-wrapper {
          @include media-breakpoint-up(md) {
            align-items: center;
            text-align: center;
            padding: 0 10px;
          }

          @include media-breakpoint-up(lg) {
            padding: 0 30px;
          }

          @include media-breakpoint-up(xl) {
            padding: 0 60px;
          }
        }
      }

      &.content-right {
        @include media-breakpoint-up(md) {
          justify-content: flex-end;
          padding-left: 30px;
        }

        .content-wrapper {
          @include media-breakpoint-up(md) {
            padding-right: 0;
            padding-left: 40px;
          }

          @include media-breakpoint-up(xl) {
            padding-left: 70px;
          }
        }
      }

      &.content-top-center {
        @include media-breakpoint-up(md) {
          justify-content: center;
          align-items: flex-start;
          padding-top: 60px;
        }

        .content-wrapper {
          @include media-breakpoint-up(md) {
            align-items: center;
            text-align: center;
            padding: 0 10px;
          }

          @include media-breakpoint-up(lg) {
            padding: 0 30px;
          }
        }
      }

      &.content-bottom-center {
        @include media-breakpoint-up(md) {
          justify-content: center;
          align-items: flex-end;
          padding-bottom: 60px;
        }

        .content-wrapper {
          @include media-breakpoint-up(md) {
            align-items: center;
            text-align: center;
            padding: 0 10px;
          }

          @include media-breakpoint-up(lg) {
            padding: 0 30px;
          }
        }
      }

      // position mobile
      &.content-top {
        .content-wrapper {
          @include media-breakpoint-down(sm) {
            align-items: center;
            text-align: center;
            padding: 0 10px;
          }
        }
      }

      &.content-middle {
        @include media-breakpoint-down(sm) {
          justify-content: center;
          padding-top: 0;
          align-items: center;
        }

        .content-wrapper {
          @include media-breakpoint-down(sm) {
            align-items: center;
            text-align: center;
            padding: 0 10px;
          }
        }
      }

      &.content-bottom {
        @include media-breakpoint-down(sm) {
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 87px;
        }

        .content-wrapper {
          @include media-breakpoint-down(sm) {
            align-items: center;
            text-align: center;
            padding: 0 10px;
          }
        }
      }
    }

    &.skeleton__item {
      position: relative;
      border-bottom: 1px solid $gray-200;
      
      .loading {
        @include centerer(true, true)
      }
    }
  }

  .bundle-title {
    color: $gray-darker;
    font-weight: $font-weight-normal;
    font-size: $h4-font-size;
    letter-spacing: 1.2px;
    line-height: 2.8rem;

    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size;
      letter-spacing: 1.2px;
      line-height: 4.6rem;
    }
  }

  .bundle-sub-title {
    color: $gray-darker;
    font-weight: $font-weight-normal;
    font-size: $h4-font-size;
    letter-spacing: 1.2px;
    line-height: 2.8rem;

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
      letter-spacing: 1.6px;
      line-height: 4rem;
    }
  }

  .bundle-description {
    font-size: $font-size-base;
    letter-spacing: 1.28px;
    line-height: 2.6rem;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      letter-spacing: 1.6px;
      line-height: 2.8rem;
    }
  }
}

//promotion-detail-bundleHeader Component
.promotion-detail-bundle__header {
  width: 100%;
  z-index: $PRODUCT_DETAIL_HEADER_ZINDEX;
  background-color: $white;
  color: $gray-darker;
  // border-bottom: 1px solid $gray-lighter;
  position: relative;
  box-shadow: 0px 3px 6px rgba($gray-light, 0.25);

  &.promotion-detail-bundle__header-sticky {
    position: sticky;
    top: 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    min-height: 54px;

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }

    @include media-breakpoint-up(md) {
      padding-right: $grid-gutter-width/2;
    }
  }

  &__text {
    padding: 8px 0;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
    }

    @include media-breakpoint-up(xl) {
      flex-grow: 1;
      padding: 5px 0;
      flex-direction: row;
      margin-right: 20px;
      justify-content: space-between;
      align-items: center;
    }

    &__name {
      font-size: $font-size-base;
      margin-bottom: 3px;
      font-weight: $font-weight-normal;
      line-height: 1;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }
    }

    &__price {
      display: flex;
      flex-direction: column-reverse;
      @include media-breakpoint-up(xl) {
        align-items: flex-end;
        flex-direction: row;
      }

      .product-detail-header__price {
        &__list-price {
          font-size: $font-size-base;
          font-weight: $font-weight-normal;
          display: inline-block;
          margin-right: 5px;

          @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
            order: 2;
            margin-left: 0;
          }
          @include media-breakpoint-up(xl) {
            margin: 0 0 0 10px;
          }
          @include media-breakpoint-up(xxl) {
            font-size: $h3-font-size;
          }

          &.with-tooltip {
            display: flex;
          }
        }

        &__selling-price {
          color: $gray;
          @include media-breakpoint-up(md) {
            margin-left: 0;
          }

          @include media-breakpoint-up(lg) {
            order: 1;
          }
        }
      }

      &__current {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        display: inline-block;
        margin-right: 5px;

        @include media-breakpoint-up(md) {
          font-size: $h4-font-size;
          order: 2;
          margin: 0 0 0 10px;
        }

        @include media-breakpoint-up(lg) {
          font-size: $h3-font-size;
        }
      }

      &__old {
        color: $gray;

        @include media-breakpoint-up(lg) {
          order: 1;
        }
      }
    }
  }

  &__btn {
    width: 148px;
    
    @include media-breakpoint-up(lg) {
      width: 240px;
    }
    
    .btn {
      height: 100%;
      width: 148px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        width: 240px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}