@import "@assets/reuse-mixin.scss";

.promotion-swiper {
  margin-bottom: 24px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 32px;
  }

  &__container {
    margin-right: -$grid-gutter-width/2;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }

  .swiper-container {
    @include media-breakpoint-up(xxl) {
      padding: 0 85px;
    }
  }

  &__title {
    margin-bottom: 18px;
    font-size: $h4-font-size;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
      font-size: $h4-font-size + 0.4;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h3-font-size;
    }
  }

  &__slider {
    padding-top: 6px;
    cursor: pointer;
    font-size: $font-size-smaller;
    height: auto;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xxl) {
      max-width: 200px;
    }

    &:hover {
      .promotion-swiper__slider__image {
        border-color: $gray-darker;
      }
    }

    &__image {
      // border: 1px solid transparent;
      border: 1px solid $gray-light;
      position: relative;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    &.selected {
      position: relative;

      .promotion-swiper__slider__image {
        border-color: $gray-darker;
      }
    }

    &.out-stock {
      pointer-events: none;
      filter: grayscale(0.8) opacity(0.5);
    }

    &__name {
      margin-top: 8px;
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }
    }

    &__price {
      padding-top: 12px;
      margin-top: auto;
      height: 70px;
      font-size: $font-size-small;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
        height: 75px;
      }

      &__selling-price {
        display: block;
        font-weight: 600;
        @include media-breakpoint-up(xxl) {
          font-size: $font-size-large;
        }

        &.with-question-tooltip {
          display: flex;

          .price-with-member-text {
            margin-bottom: 0;
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            .member-text {
              display: block;
              margin-right: 2px;
            }

            .member-price {
              display: flex;
              justify-content: center;
              align-items: center;

              .question-tooltip-icon {
                align-self: center;

                .item-tooltip__label {
                  left: 100%;
                  right: unset;
                  transform: unset;
                  top: unset;
                  bottom: 0;

                  &:after {
                    transform: rotate(225deg);
                    left: -4px;
                    bottom: 4px;
                    top: unset;
                  }
                }
              }
            }
          }

          .question-tooltip-icon {
            flex-shrink: 0;
            align-self: flex-start;
            &__wrapper {
              @include media-breakpoint-up(md) {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
      
      &__list-price {
        display: block;
        color: $gray;
        text-decoration: line-through;
        font-size: $font-size-smaller;

        @include media-breakpoint-up(xxl) {
          font-size: $font-size-small;
        }
        &.with-usual-text {
          text-decoration: none;
        }
      }
    }

    &.hidden {
      display: none;
    }

    &:last-child {
      .member-price {
        .question-tooltip-icon {
          .item-tooltip__label {
            @include media-breakpoint-down(sm) {
              right: 100% !important;
              left: unset !important;
              margin-right: 4px;

              &:after {
                left: unset !important;
                transform: rotate(45deg) !important;
                right: -4px;
              }
            }
          }
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    color: $gray-darker;
    top: 40%;
    width: 12px;
    height: auto;
    z-index: $Z_INDEX_NORMAL;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 2rem;

    @include media-breakpoint-up(xl) {
      font-size: 2.4rem;
    }
  }

  .swiper-button-prev {
    left: -24px;

    @include media-breakpoint-up(xl) {
      left: -30px;
    }
  }

  .swiper-button-next {
    right: -24px;

    @include media-breakpoint-up(xl) {
      right: -30px;
    }
  }

  .swiper-button-lock {
    display: none;
  }
}
