@import '@assets/reuse-mixin.scss';

.cp-review {
  background-color: $cp-background;
  padding-bottom: 40px;

  &__wrap {
    padding: 24px 12px 32px;
    background-color: $white;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 32px;
    }
  }

  &__content {
    &__info {
      &__title {
        font-weight: $font-weight-normal;
        margin-bottom: 18px;
        text-align: center;

        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
        }
      }

      &__box {
        padding: 12px 12px 6px;
        border: 1px solid $gray-lighter;
        margin-bottom: -1px;
        border-top: none;
        border-bottom: none;

        @include media-breakpoint-up(md) {
          padding: 18px 18px 6px;
        }

        .cp-form__group {
          &--w-100 {
            width: 100%;
          }
          .mail-value,
          .phone-value {
            flex: 1;
          }
        }

        .osim-radio {
          display: inline-flex;
          align-items: center;

          &-title {
            padding-left: 10px;
            color: #7d7d7d;
            margin-bottom: 0;
          }
        }
      }

      &:first-child {
        .cp-review__content__info__box {
          border-top: 1px solid $gray-lighter;
        }
      }
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    &__item {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      &.btn {
        min-width: 128px;
      }
    }
  }
}
