.product-image {
  position: relative;

  &__menu {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;

    &__item {
      font-size: 4rem;
      display: block;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .light-box {
    &-item {
      display: inline;

      img {
        max-height: 100vh;
        max-width: 100vw;
      }
    }
  }
}
