@import '@assets/reuse-mixin.scss';

.auto-scrolling-banners {
    margin: 30px 0;

    &__header {
        padding: 0 15px;
        text-align: center;

        &__content {
            font-size: 2.4rem;
            margin: 0 auto;
            color: $black;
            padding-bottom: 25px;
        }

        &__button {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 25px;

            a {
                color: $white;
            }
        }
    }
}