@import '@assets/reuse-mixin.scss';

.product-interest__item {
  padding: 24px;
  background-color: $white;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;

    &__left {
      width: 160px;
      margin-right: 30px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-right: 0;
      }
      @include media-breakpoint-up(lg){
        width: 320px;
      }

      .product-interest__item__image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        padding-top: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-right {
      flex: 1;
      @include media-breakpoint-down(sm) {
        margin-top: 18px;
      }
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .product-interest__item {
        &__title {
          &__name {
            font-size: $h5-font-size;
            font-weight: $font-weight-normal;
            margin-bottom: 0;
            @include media-breakpoint-down(sm){
              font-size: $h3-font-size-mobile;
            }
            @include media-breakpoint-up(lg){
              font-size: $h3-font-size;
            }
          }
        }

        &__badge {
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          &__item {
            display: inline-block;
            padding: 3px 22px;
            font-weight: $font-weight-normal;
            background-color: $gray-lighter;
            border-radius: 20px;
            font-size: $font-size-smaller;
            color: $gray-darker;
            @include media-breakpoint-up(lg){
              font-size: $font-size-small;
            }
          }
        }

        &__captured__date {
          margin-top: 16px;

          &__text {
            font-size: $font-size-base;
            color: $cp-gray;
            @include media-breakpoint-down(sm){
              font-size: $font-size-small;
            }
            @include media-breakpoint-up(lg){
              font-size: $font-size-large;
            }
          }
        }
      }
    }
  }

  &__question {
    &__navigate {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: $h4-font-size;
        cursor: pointer;
        //transition: ;
      }

      &__active {
        transform: rotate(180deg);
      }
    }

    &__check__box {
      margin-top: 18px;

      .osim-checkbox-label {
        color: $cp-gray;
        @include media-breakpoint-up(lg){
          font-size: $font-size-large;
        }
      }
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
      }

      &__item {
        padding: 17.5px 0;

        &:not(:last-child) {
          border-bottom: 1px solid $cp-background;
        }

        .question,
        .answer {
          font-size: $font-size-small;
          @include media-breakpoint-up(lg){
            font-size: $font-size-large;
          }
        }

        .question {
          color: $black;
          margin-bottom: 8px;
          font-weight: $font-weight-normal;
          span{
            color: #FF0000;
          }
        }

        .answer {
          color: $gray-darker;
        }
      }
    }
  }


}