@import '@assets/reuse-mixin.scss';

.select-product {
  text-align: center;
  padding: 48px 0;

  // @include media-breakpoint-up(xl) {
  //   padding: 48px 356px;
  // }

  &__swiper {
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.swiper-slide-active {
        .select-product__swiper__item {
          &__image {
            filter: grayscale(0);
            transform: scale(1);
          }
          
          &__content {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &__image {
        // min-width: 203px;
        max-width: 371px;
        filter: grayscale(1);
        transform: scale(.8);
      }

      &__content {
        opacity: 0;
        visibility: hidden;
        width: 150%;
        display: flex;
        align-items: center;
        flex-direction: column;
        
        &__subtitle {
          color: $os-green;
        }

        &__title {
          font-weight: $font-weight-bold;
        }

        &__desc {
          color: $gray-900;
          max-width: 752px;

          @include line-clamp(2)
        }
      }

    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    border: none;
    background-color: transparent;
    
    &::after {
      content: '';
      width: 12px;
      height: 12px;
      border-left: 1px solid $gray-900;
      border-top: 1px solid $gray-900;
    }
  }

  .swiper-button-next {
    // right: 25%;
    right: 12%;
    top: 12%;

    &::after {
      transform: rotate(135deg);
    }
  }

  .swiper-button-prev {
    // left: 25%;
    left: 12%;
    top: 12%;

    &::after {
      transform: rotate(-45deg);
    }
  }
}