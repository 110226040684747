@import "../SupportCentre";

.download-osim-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &__image {
    max-width: 240px;
    width: 100%;
    margin-bottom: 24px;
    @include media-breakpoint-up(md) {
      max-width: 360px;
      margin-right: 30px;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 60px;
    }

    &-wrapper {
      width: 100%;
      padding-bottom: 100%;
      position: relative;

      & > img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }
  }

  &__content {
    width: 100%;

    &-title {
      font-weight: 500;
      font-size: $h3-font-size-mobile;
      line-height: 2.6rem;
      margin-bottom: 8px;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
        font-size: $h3-font-size;
        line-height: 4.6rem;
      }
    }

    &-subtitle {
      font-size: $h6-font-size-mobile;
      line-height: 2rem;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
        line-height: 2.8rem;
      }
    }

    &-qr-code {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-up(md) {
        margin-top: 24px;
      }

      &__item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:not(:last-child) {
          margin-right: 18px;
          @include media-breakpoint-up(md) {
            margin-right: 24px;
          }
        }

        .qr-code {
          &-image {
            max-width: 170px;
            width: 100%;
            display: none;
            @include media-breakpoint-up(md) {
              display: block;
            }

            &__wrapper {
              width: 100%;
              position: relative;
              padding-bottom: 100%;

              & > img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: contain;
              }
            }
          }

          &-cta {
            width: 100%;
            margin-top: 24px;

            & > .btn {
              width: 100%;

              & > a {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}