@import '../../../assets/reuse-mixin.scss';

.other-wellness-contents {
    padding: 30px 0;

    &__title {
        margin-bottom: 20px;
    }

    &__item {
        margin-bottom: 30px;
    }
}