@import '@assets/reuse-mixin.scss';

$toggle-width: 8rem;
$slider-size: 3rem;
$padding: 0.25rem;

// * {
//   box-sizing: border-box;
//   font-family: monospace;
// }

.tradein-toggle {
  align-items: center;
  background-color: white;
  border: 1px solid gray;
  border-radius: 3rem;
  color: #fff;
  display: flex;
  height: calc(#{$slider-size} + #{$padding * 3});
  justify-content: space-around;
  padding: 0.25rem;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: $toggle-width;
  margin: 0 auto;

  &--checked {
    background-color: white;
    border-color: gray;
  }

  &__slider {
    background-color: $gray;
    border-radius: 50%;
    content: "";
    left: $padding;
    height: $slider-size;
    width: $slider-size;
    position: absolute;
    transform: translateX(0);
    transition: all 0.2s ease-out;
  }

  &__text {
    text-align: center;
    width: 50%;
  }

  input:checked + .tradein-toggle__slider {
    background-color: $secondary;
    transform: translateX(calc(#{$toggle-width} - #{$slider-size} - #{$padding * 3}));
  }

  input[type="checkbox"] {
    display: none;
  }
}