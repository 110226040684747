@import "../../../assets/reuse-mixin.scss";

.account-checking {
  padding-top: 25px;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }

  &__tab {
    border-top: 1px solid $gray-lighter;

    @include media-breakpoint-up(md) {
      border: none;
    }

    &__content {
      padding: 36px 0;
      max-width: 480px;
      margin: 0 auto;

      &__header {
        margin-bottom: 36px;
      }

      &__body {
        padding-bottom: 24px;
      }
    }
  }

  &__social-login {
    display: flex;
    width: 280px;
    padding: 8px;
    margin: 0 auto;
    margin-bottom: 17px;
    border: 1px solid $gray-darker;
    align-items: center;
    justify-content: center;
    color: $gray-darker;
    font-size: $font-size-small;

    @include media-breakpoint-up(md) {
      width: 320px;
      font-size: $font-size-base;
    }

    img {
      max-height: 20px;
      margin-right: 20px;

      @include media-breakpoint-up(md) {
        max-height: 24px;
        margin-right: 24px;
      }
    }
  }

  &__form {
    padding-top: 20px;

    .error-validate {
      &--custom {
        margin-bottom: 25px;
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: 16px;
    }

    &__text,
    &__group {
      color: $gray-darker;
    }

    &__group {
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 32px;
      }
    }

    &__text {
      text-align: center;
      font-size: $font-size-base;
      margin-bottom: 22px;

      @include media-breakpoint-up(md) {
        text-align: left;
        font-size: $h5-font-size;
        margin-bottom: 26px;
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      margin-top: -6px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      &__submit,
      &__link {
        a,
        button {
          text-transform: uppercase;
          font-size: $font-size-small;
          font-weight: $font-weight-bold;
          min-width: 240px;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
            min-width: 320px;
          }
        }
      }

      &__submit {
        padding-bottom: 16px;
      }

      &__link {
        .btn-link::before,
        .btn-link::after {
          height: 2px;
        }
      }
    }

    .small-description {
      font-size: $font-size-smaller;
      display: block;
      margin-top: 5px;
      color: $gray-dark;
      margin-bottom: -3px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
        margin-bottom: -5px;
      }
    }
  }

  .forgot-password-link {
    text-align: right;
    //color: $secondary;
    color: $link-color;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.checkout-input {
  font-size: $font-size-small;
  position: relative;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-base;
  }

  // &.input-error-validate {
  //   input {
  //     border-color: $primary;
  //   }
  // }

  .error-validate {
    color: $primary;
    font-size: $font-size-smaller;
    display: block;
    margin-top: 5px;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
    }
  }

  &__input {
    width: 100%;
    background: $white;
    font-size: $font-size-base;
    color: $gray-darker;

    &:focus {
      border: 1px solid $gray-darker;
      outline: none;
      box-shadow: none;

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }

      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent;
      }
      &::placeholder {
        color: transparent;
      }
    }

    &:focus ~ label,
    &.input-valid ~ label {
      top: -10px;
      opacity: 1;
      visibility: visible;
    }

    &.input-valid {
      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }
    }
  }

  &__label {
    position: absolute;
    top: 8px;
    left: 10px;
    padding: 0 10px;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    font-size: $font-size-small;
    transition: 0.3s;
    color: $gray;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }
  }
}

.nav-item {
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  width: 50%;
  color: $gray;
  padding: 10px 5px 8px 5px;
  border-bottom: 2px solid $gray-lighter;
  font-size: $font-size-smaller;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
    padding: 15px 8px;
  }

  &:hover {
    text-decoration: none;
    color: $gray-darker;
  }

  &.active {
    border-bottom: 2px solid $gray-darker;
    color: $gray-darker;
  }
}

.tab-content {
  .fade:not(.show) {
    display: none;
  }
}
