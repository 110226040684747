@import '@assets/reuse-mixin.scss';

.one-column {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;

    .my-account-widget,
    .my-account-section {
      width: calc(100% - #{$grid-gutter-width});
      margin-left: $grid-gutter-width/2;
      margin-right: $grid-gutter-width/2;
  
      @include media-breakpoint-up(md) {
        width: calc(50% - #{$grid-gutter-width});
      }
    }
  }
}