@import '@assets/reuse-mixin.scss';

.main-banner {
  position: relative;
  color: $gray-darker;

  &.text-white {
    color: $white;
  
    h1,
    h2 {
      color: inherit;
    }
  }

  &.text-align-left {
    @include media-breakpoint-up(md) {
      .content-wrapper {
        text-align: left !important;
        align-items: flex-start !important;
      }
    }
  }

  &.text-align-right {
    @include media-breakpoint-up(md) {
      .content-wrapper {
        text-align: right !important;
        align-items: flex-end !important;

        .btn-play-video {
          justify-content: flex-end;
        }

        .btn-link {
          margin: 12px 0 0 auto;
    
          @include media-breakpoint-up(xxl) {
            margin: 24px 0 0 auto;
          }
        }
      }
    }
  }

  &.text-align-center {
    @include media-breakpoint-up(md) {
      .content-wrapper {
        text-align: center !important;
        align-items: center !important;

        .btn-play-video {
          justify-content: center;
        }

        .btn-link {
          margin: 12px 0 0;
    
          @include media-breakpoint-up(xxl) {
            margin: 24px 0 0;
          }
        }
      }
    }
  }

  &.not-full-width {
    .content-wrapper {
      padding: 0 5px !important;

      @include media-breakpoint-up(md) {
        padding: 0 25px !important;
      }
    }
  }

  &__heading {
    padding: 24px 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding: 40px 0 32px;
    }

    &__title {
      color: $gray;
      font-size: $font-size-small;
      text-transform: uppercase;
      margin: 0;
      font-weight: $font-weight-bold;
      letter-spacing: 0.3rem;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }
  }
  
  &__background {
    background-color: $black;
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 666/375 * 100%;

    @include media-breakpoint-up(md) {
      padding-bottom: 800/1920 * 100%;
    }

    &--white {
      background: none;
    }

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video {
      top: 50%;
      height: auto;
      transform: translate(0, -50%);

      @include media-breakpoint-up(md) {
        width: auto;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }

      & + .main-banner__background:after {
        content: "";
        background: $black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.no-ratio {
      background: none;
      height: auto;
      padding-bottom: 0;

      img,
      video {
        position: static;
        width: 100%;
        height: auto;
        transform: translate(0,0)
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .container {
      height: 100%;
    }

    &__container {
      padding-top: 30px;
      padding-bottom: 30px;
      height: 100%;
      display: flex;

      @include media-breakpoint-up(md) {
        // padding-top: 0;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    &__wrap {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      // letter-spacing: 5px;
      // line-height: 24px;
    }

    &__logo {
      margin-bottom: 12px;
      display: flex;
      align-items: flex-end;

      @include media-breakpoint-up(xl) {
        display: block;
        margin-bottom: 24px;
      }

      &__image {
        max-width: 200px;
        height: 76px;
        margin: 0 24px 0 0;

        @include media-breakpoint-up(xl) {
          max-width: 200px;
          max-height: 120px;
          height: auto;
          margin: 0 0 24px 0;
        }

        &:last-child {
          margin: 0;
        }
      }

      img {
        margin: 0 10px 0 0;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__title {
      // text-transform: uppercase;
      margin-bottom: 0;
      font-weight: $font-weight-light;
      font-size: $h4-font-size + 0.4;
      white-space: break-spaces;
      // font-size: $h3-font-size - 0.2;

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size + 0.4;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h3-font-size;
      }

      @include media-breakpoint-up(xxl) {
        line-height: 1.16;
        font-size: $h1-font-size;
      }
    }

    &__sub-title {
      font-size: $font-size-base;
      margin-bottom: 10px;
      font-weight: $font-weight-light;

      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
        font-size:  $h4-font-size;
      }

      &__item {
        display: inline-block;
        padding: 0 10px;
        position: relative;

        @include media-breakpoint-up(lg) {
          padding: 0 15px;
        }

        &::after {
          position: absolute;
          content: "";
          display: block;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 2px;
          height: 52%;
          background: $gray-dark;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }

    &__desc {
      // max-width: 500px;
      font-size: $font-size-base;
      font-weight: $font-weight-light;
      // font-size: $font-size-large;
      // margin-top: 12px;
      margin-top: 20px;

      @include media-breakpoint-up(md) {
        font-size: $font-size-small;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
        margin-top: 20px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
        font-size: $font-size-large;
        line-height: 1.2;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h4-font-size;
        // margin-top: 24px;
      }
    }

    &__btn {
      margin-top: 16px;

      @include media-breakpoint-up(xxl) {
        margin-top: 32px;
      }

      &__item {
        @include media-breakpoint-up(xxl) {
          min-width: 200px;
        }
      }

      .btn-play-video {
        display:flex;
        align-items: center;

        &__icon {
          margin: 0 12px 0 0;
        }

        &__text {
          font-size: $font-size-smaller - 0.1;

          @include media-breakpoint-up(md) {
            font-size: $font-size-smaller;
          }
        }
      }
    }

    .btn-link {
      margin: 12px auto 0;

      @include media-breakpoint-up(xxl) {
        margin: 24px auto 0 0;
      }
    }
  }

  .content-position {
    display: flex;
    height: 100%;
  
    @include media-breakpoint-up(md) {
      align-items: center;
    }
  
    .content-wrapper {
      @include media-breakpoint-up(md) {
        max-width: 50%;
        padding-right: 40px;
      }
  
      @include media-breakpoint-up(xl) {
        padding-right: 70px;
      }
    }
    
    // position desktop
    &.content-center {
      @include media-breakpoint-up(md) {
        justify-content: center;
        padding-top: 0;
        align-items: center;
      }
  
      .content-wrapper {
        @include media-breakpoint-up(md) {
          align-items: center;
          text-align: center;
          padding: 0 10px;
        }
  
        @include media-breakpoint-up(lg) {
          padding: 0 30px;
        }

        @include media-breakpoint-up(xl) {
          padding: 0 60px;
        }
      }

      .btn-link {
        margin-left: auto;
      }
    }
  
    &.content-right {
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
        padding-left: 30px;
      }
  
      .content-wrapper {
        @include media-breakpoint-up(md) {
          padding-right: 0;
          padding-left: 40px;
        }
  
        @include media-breakpoint-up(xl) {
          padding-left: 70px;
        }
      }
    }
  
    &.content-top-center {
      @include media-breakpoint-up(md) {
        justify-content: center;
        align-items: flex-start;
        padding-top: 50px;
      }
  
      .content-wrapper {
        @include media-breakpoint-up(md) {
          align-items: center;
          text-align: center;
          padding: 0 10px;
        }
  
        @include media-breakpoint-up(lg) {
          padding: 0 30px;
        }
      }

      .btn-link {
        margin-left: auto;
      }
    }
  
    &.content-bottom-center {
      @include media-breakpoint-up(md) {
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 50px;
      }
  
      .content-wrapper {
        @include media-breakpoint-up(md) {
          align-items: center;
          text-align: center;
          padding: 0 10px;
        }
  
        @include media-breakpoint-up(lg) {
          padding: 0 30px;
        }
      }

      .btn-link {
        margin-left: auto;
      }
    }

    &.content-top-left,
    &.content-top-right {
      @include media-breakpoint-up(md) {
        align-items: flex-start;
      }
    }

    &.content-bottom-left,
    &.content-bottom-right {
      @include media-breakpoint-up(md) {
        align-items: flex-end;
      }
    }

    &.content-top-right,
    &.content-bottom-right {
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
        padding-left: 30px;
      }
    }

    &.content-center,
    &.content-top-center,
    &.content-bottom-center {
      .content-wrapper {
        @include media-breakpoint-up(md) {
          max-width: 70%;
        }
      }
    }

    // position mobile
    &.content-top {
      .content-wrapper {
        @include media-breakpoint-down(sm) {
          align-items: center;
          text-align: center;
          padding: 0;

          .btn-link {
            margin-left: auto;
          }
        }
      }
    }

    &.content-middle {
      @include media-breakpoint-down(sm) {
        justify-content: center;
        padding-top: 0;
        align-items: center;
      }

      .content-wrapper {
        @include media-breakpoint-down(sm) {
          align-items: center;
          text-align: center;
          padding: 0;
        }
      }

      .btn-link {
        margin-right: auto;
      }
    }
  
    &.content-bottom {
      @include media-breakpoint-down(sm) {
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 87px;
      }
  
      .content-wrapper {
        @include media-breakpoint-down(sm) {
          align-items: center;
          text-align: center;
          padding: 0;

          .btn-link {
            margin-left: auto;
          }
        }
      }
    }
  }

  // Category Banner
  &.category-banner {
    .main-banner__background {
      padding-bottom: 100%;
  
      @include media-breakpoint-up(md) {
        padding-bottom: 750/1920 * 100%;
      }
    }
  }

  &.skeleton__item {
    border-bottom: 1px solid $gray-200;

    .loading__default {
      @include centerer(true, true)
    }
  }
}

.countdown-banner {
  &__container {
    width: 100%;
  }

  &__title {
    line-height: 1.28;
    display: block;
    margin-bottom: 8px;
    font-weight: $font-weight-light;
  }

  &__time {
    line-height: 1;
    display: flex;

    .time-number {
      position: relative;
      padding: 0 5px 10px 5px;
      font-weight: $font-weight-normal;
    }

    .time-unit {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      line-height: 1;
      letter-spacing: 1.28px;
      font-weight: $font-weight-light;
    }
  }
}