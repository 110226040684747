@import '@assets/reuse-mixin.scss';

.select-free-gift {
  &__content {
    .modal-content {
      .main-banner__background {
        max-height: 100vh;
        height: 100%;
        padding-bottom: unset !important;
        @include media-breakpoint-up(md) {
          //padding-bottom: calc(800 / 740 * 100%) !important;
          max-height: 90vh;
        }
      }
    }
  }

  &__body {
    padding: 24px 0;
    //position: absolute;
    //left: 0;
    //top: 0;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(md) {
      padding: 32px 0;
    }

    .popup {
      &-title {
        & > .title {
          text-transform: uppercase;
          text-align: center;
          color: $gray;
          font-weight: $font-weight-bold;
          margin-bottom: 0;
          font-size: $h6-font-size-mobile;
          line-height: 2.6rem;
          @include media-breakpoint-up(md) {
            font-size: $font-size-base;
          }
        }
      }

      &-description {
        min-height: calc(100% - 26px); // INFO: 26px is title line-height
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        .description {
          &-body {
            max-width: 560px;
            width: 100%;
            margin: 24px auto auto;
            padding: 0 16px;
            @include media-breakpoint-up(sm) {
              margin: 32px auto auto;
              padding: 0;
            }

            & > .content {
              text-align: center;
              color: $gray-darker;
              font-size: $h5-font-size-mobile;
              line-height: 2.4rem;
              font-weight: $font-weight-lighter;
              margin-bottom: 0;
              @include media-breakpoint-up(sm) {
                font-size: $h5-font-size;
                line-height: 3.2rem;
              }

              & > p {
                margin-bottom: 0;
              }
            }

            .free-gift {
              &__selection {
                // Re-set max height content free gift
                max-height: calc(90vh - 326px);
                margin: 20px auto auto;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                @include media-breakpoint-up(sm) {
                  margin-right: unset;
                  margin-left: unset;
                  // max-height: 550px;
                }

                &.no-max-width {
                  max-width: unset;
                }

                /* width */
                &::-webkit-scrollbar {
                  width: 2px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  background: $os-brown-lighter;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: $gray-light;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                  background: $gray;
                }

                &-container {
                  &:not(:last-child) {
                    margin-bottom: 32px;
                  }
                }

                &-item {
                  &:not(:last-child) {
                    margin-bottom: 12px;
                    @include media-breakpoint-up(sm) {
                      margin-bottom: 24px;
                    }
                  }
                }

                &-title {
                  color: $gray-darker;
                  font-weight: $font-weight-normal;
                  font-size: $h5-font-size-mobile;
                  line-height: 2.2rem;
                  margin-bottom: 16px;
                  @include media-breakpoint-up(md) {
                    font-size: $h5-font-size;
                    line-height: 3.2rem;
                    margin-bottom: 18px;
                  }
                }

                &-variant {
                  display: grid;
                  grid-template-columns: repeat(2, 80px);
                  column-gap: 40px;
                  row-gap: 16px;
                  justify-content: center;
                  //align-items: center;
                  @include media-breakpoint-up(sm) {
                    grid-template-columns: repeat(4, 120px);
                    column-gap: 24px;
                  }

                  &__item {
                    width: 100%;
                    cursor: pointer;

                    &:hover {
                      .variant-image {
                        border-color: $gray;
                      }
                    }

                    .variant {
                      &-image {
                        width: 100%;
                        padding-bottom: 100%;
                        position: relative;
                        border: 1px solid transparent;

                        &:hover {
                          border-color: $gray;
                        }

                        & > img {
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          left: 0;
                          top: 0;
                          object-fit: cover;
                        }

                        &__active {
                          border-color: $gray;
                        }
                      }

                      &-name {
                        margin-top: 8px;
                        color: $gray-darker;
                        font-weight: $font-weight-lighter;
                        margin-bottom: 0;
                        font-size: $font-size-smaller;
                        line-height: 1.6rem;
                        @include media-breakpoint-up(md) {
                          font-size: $font-size-small;
                          line-height: 1.6rem;
                          margin-top: 12px;
                        }
                      }
                    }
                  }
                }

                &-condition__title > p {
                  color: $gray-darker;
                  font-weight: $font-weight-normal;
                  font-size: $h4-font-size-mobile;
                  line-height: 2.2rem;
                  text-align: center;
                  @include media-breakpoint-up(md) {
                    font-size: $h4-font-size;
                    line-height: 2.8rem;
                    margin-bottom: 32px;
                  }
                }
              }

              &__accordion {
                &:not(:last-child) {
                  margin-bottom: 32px;
                }

                @include media-breakpoint-up(md) {
                  padding-top: 0;
                }

                &-header {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: start;
                }

                &-body {
                  max-width: 220px;
                  margin: 0 auto;
                  @include media-breakpoint-up(md) {
                    max-width: unset;
                    margin: unset;
                  }
                }

                &-title {
                  & > p {
                    margin-bottom: 0;
                  }

                  .title {
                    line-height: 1.8rem;
                    font-size: $h5-font-size-mobile;
                    color: $gray-darker;
                    margin-bottom: 8px;
                    font-weight: $font-weight-normal;
                    @include media-breakpoint-up(md) {
                      font-size: $h5-font-size;
                      line-height: 2.4rem;
                    }
                  }

                  .gift-name {
                    color: $gray-dark;
                    font-size: $h6-font-size-mobile;
                    line-height: 1.8rem;
                    @include media-breakpoint-up(md) {
                      font-size: $font-size-large;
                      line-height: 2.2rem;
                    }
                  }
                }

                &-btn {
                  border: none;
                  outline: none;
                  color: $link-color;
                  background-color: transparent;
                  font-size: $h6-font-size-mobile;
                  @include media-breakpoint-up(md) {
                    font-size: $font-size-large;
                  }
                }
              }
            }
          }

          &-cta {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
