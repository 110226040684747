@import '@assets/reuse-mixin.scss';

.confirm-demo-product {
  
  .osim-checkbox {
    padding-top: 24px;
  }

  .error-validate {
    display: none;
    text-align: center;
  
    &.active {
      display: block;
    }
  }
}
