@import '../../../../assets/reuse-mixin.scss';

.category-product {
  &__wrap {
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      background-color: $white;

      &:hover {
        background-color: #f9f9f9;

        .category-product__image__item {
          transform: scale(1.1);
        }

        .category-product__main__icon,
        .category-product__main__btn {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    display: block;

    &:before {
      content: "";
      padding-bottom: 100%;
      display: block;
    }

    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease;
    }
  }

  &__list-tag {
    width: 35%;
    position: absolute;
    left: 0;
    top: 0;

    @include media-breakpoint-up(lg) {
      top: 32px;
    }
  }

  &__tag {
    margin-bottom: 10px;
    max-width: 104px;
    // display: block;

    @include media-breakpoint-up(lg) {
      max-width: 130px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__main {
    padding: 0 5px;

    @include media-breakpoint-up(md) {
      padding: 0 10px;
    }

    &__name {
      font-size: $h4-font-size;
      margin-bottom: 18px;
      color: $gray-darker;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
        font-weight: $font-weight-light;
        margin-bottom: 16px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h2-font-size;
      }

      &__link {
        color: $gray-darker;
      }
    }

    &__deposit {
      font-size: $font-size-small;

      @include media-breakpoint-up(xl) {
        font-size: $font-size-large;
      }
    }

    &__sort-decs {
      font-size: $font-size-base;
      margin-top: 24px;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        margin-top: 16px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
      }
    }

    &__price {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      color: $black;

      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
      }
    }

    &__old-price {
      font-size: $font-size-smaller;
      color: $gray;
      margin-top: 4px;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
      
      .product-price__amount {
        text-decoration: line-through;
      }
    }

    &__btn {
      @include media-breakpoint-up(lg) {
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.3s ease;
      }
    }

    &__btn-link {
      width: 100%;
      margin-top: 24px;

      .category-product__main__link {
        width: fit-content;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        &:first-child {
          margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
          display: block;
          margin-left: 0;
        }
      }
    }

    &__link {
      margin-top: 24px;
      display: block;
      text-align: center;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }

    &__learn-more-btn {
      margin-bottom: 30px;
      display: none;

      &__item {
        padding: 0;
        padding-right: 15px;
      }
    }

    &__btn {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding: 0 6px 28px;
      margin-top: 24px;
      @include media-breakpoint-up(xl) {
        flex-direction: row;
        margin-top: 32px;
      }

      &:has(.btn-book-experience) {
        width: fit-content;
        margin-right: auto;
        margin-left: auto;

        .btn {
          @include media-breakpoint-up(lg) {
            width: 100%;
            &:first-child {
              margin-right: 0;
            }
            &:last-child {
              margin-bottom: 16px;
            }
          }
          @include media-breakpoint-up(xl) {
            min-width: 240px;
            margin: 0 20px 0 0;
            &:first-child {
              margin-right: 20px;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }

      .btn {
        min-width: 200px;
        margin-bottom: 16px;

        &:first-child {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
          min-width: 240px;
          margin: 0 20px 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }

      &__experience {
        display: block;
        padding: 6.5px 8px;

        @include media-breakpoint-up(xl) {
          margin-right: 20px;
          padding: 7px 8px;
        }
      }

      &__buy-options {
        text-align: center;
        margin-bottom: 16px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 0;
        }
      }
    }

    &__countdown {
      margin-top: 8px;
      margin-bottom: 8px;

      .countdown-timer {
        &__container {
          @include media-breakpoint-up(sm) {
            padding: 10px 20px;
          }
          @include media-breakpoint-up(lg) {
            padding: 20px 40px;
          }
        }
      }
    }
  }

  .product-price {
    &__currency {
      display: inline-block;
      margin-right: 5px;

      &--old-price {
        text-decoration: line-through;
        margin-right: 0;
      }
    }
  }
}