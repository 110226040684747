@import '@assets/reuse-mixin.scss';

.productReviewListing {
    position: relative;
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 74px;
    }

    &__link {
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        .my-account-section__back-link {
            width: fit-content;
            font-size: 14px;
            letter-spacing: 2.24px;
            //color: $secondary  !important;
            color: $link-color  !important;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                letter-spacing: 2.56px;
            }

            &:hover {
                cursor: pointer;
                text-decoration: underline !important;
            }
        }
    }

    &__content {
        max-width: 1333px;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        .reviews-summary {
            flex: 1;

            @include media-breakpoint-up(lg) {
                min-width: 348px;
                padding: 0;
                width: fit-content;
            }
        }

        .review-listing {
            max-width: 985px;
            flex: 1;

            @include media-breakpoint-up(lg) {
                //width: 68%;
                padding-left: 57px;
            }

            @include media-breakpoint-up(xxl) {
                padding-left: 77px;
            }

            &__list {
                padding-left: 33px;

                @include media-breakpoint-up(lg) {
                    padding-left: 60px;
                }
            }

            &__btn__item {
                box-shadow: none;
            }

            .product-review-content__review-detail {
                &__quote-icon {
                    @include media-breakpoint-up(lg) {
                        left: -60px;
                    }
                }
            }
        }
    }

    &__info {
        margin-bottom: 36px;

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 41px;
        }

        &__image {
            width: 38%;
            max-width: 128px;
            margin: 0 auto;

            @include media-breakpoint-up(lg) {
                margin: 0 16px 0 0;
                max-width: 160px;
            }

            @include media-breakpoint-up(xxl) {
                margin: 0 16px 0 0;
                max-width: 200px;
            }
        }

        &__name {
            font-size: $font-size-base;
            line-height: 1.5;
            letter-spacing: 0.8px;
            text-align: center;
            color: $gray-900;

            p {
                margin: 0;
            }

            @include media-breakpoint-up(lg) {
                font-size: 2rem;
                line-height: 1.2;
                letter-spacing: 1px;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 2.4rem;
                line-height: 1;
                letter-spacing: 1.2px;
            }
        }
    }

}