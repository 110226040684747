@import '@assets/reuse-mixin.scss';

.product-review-view-all {
  .product-review {
    &__body {
      width: 100%;
      padding-bottom: 30px;

      &-container {
        max-width: 1333px;
        margin: 0 auto;
        @include media-breakpoint-up(lg) {
          display: flex;
        }

        &:not(:first-child) {
          margin-top: 48px;
        }

        .review-listing {
          @include media-breakpoint-up(lg) {
            margin-left: 32px;
            padding-top: 0;
            padding-left: 0;
          }

          &__list {
            padding-left: 0;
          }

          &__item {
            .product-review-content__review-detail {
              word-break: break-all;
            }
          }
        }
      }

      &-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @include media-breakpoint-up(lg) {
          max-width: 348px;
          padding: 0;
          width: fit-content;
        }

        .product-image {
          max-width: 200px;
          width: 100%;

          &__wrapper {
            padding-bottom: 100%;
            width: 100%;
            position: relative;

            & > img {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              object-fit: contain;
            }
          }
        }

        .product-name {
          text-align: center;
          font-weight: $font-weight-bold;
          color: $gray-darker;
          margin-top: 12px;
        }
      }

      .review-listing__btn {
        margin-top: 40px;
      }
    }
  }
}
