@import "@assets/reuse-mixin.scss";

.product-warranty__detail {
  padding-left: 0;
  padding-right: 0;

  &-header {
    &__text {
      color: $mine-shaft;
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
      margin-bottom: 0;
      //line-height: 1.8rem;
      @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
        //line-height: 2.2rem;
      }
    }

    &__description {
      color: $gray-darker;
      font-size: $h5-font-size-mobile;
      margin-bottom: 0;
      margin-top: 8px;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }
  }

  &-extend {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 7px;
    padding-top: 24px;
    @include media-breakpoint-up(xl) {
      padding-top: 114px;
      padding-bottom: 25px;
    }

    .btn {
      min-width: calc(100% / 2 - 8.5px);

      &:not(:first-child) {
        margin-left: 8.5px;
      }

      @include media-breakpoint-up(sm) {
        min-width: 240px;
        &:not(:first-child) {
          margin-left: 24px;
        }
      }
      @include media-breakpoint-up(md) {
        min-width: calc(100% / 2 - 24px);
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &-item {
    width: 100%;

    &:not(:first-child) {
      .product-warranty__detail-widget {
        border-top: 0;
      }
    }

    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(4) {
      order: 4;
    }

    @include media-breakpoint-up(sm) {
      &:not(:first-child) {
        .product-warranty__detail-widget {
          border-top: 1px solid $gray-light;
        }
      }
      &:nth-child(2) {
        order: unset;
      }
      &:nth-child(4) {
        order: unset;
      }
    }
    @include media-breakpoint-up(md) {
      width: calc(100% / 2 - 20px);
    }
  }

  &-widget {
    border: 1px solid $gray-light;
    border-top: 0;
    display: flex;
    flex-direction: column;
    border-left: 0;
    border-right: 0;
    padding: 24px;
    @include media-breakpoint-up(md) {
      border: 1px solid $gray-light;
      margin-bottom: 40px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 300px;
      //height: 100%;
      padding: 24px 40px 30px;
      border: 1px solid $gray-light;
    }
  }

  &-order {
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include media-breakpoint-up(xxl) {
        align-items: center;
        flex-direction: row;
      }
    }

    &__number, &__placed, &__divider {
      font-size: $font-size-base;
      margin-bottom: 0;
      @include media-breakpoint-up(xxl) {
        font-size: $h5-font-size;
      }
    }

    &__number {
      .order-number {
        color: $os-blue;
      }
    }

    &__divider {
      margin-right: 8px;
      margin-left: 8px;
      display: none;
      @include media-breakpoint-up(xxl) {
        display: block;
      }
      @include media-breakpoint-up(xl) {
        margin-right: 16px;
        margin-left: 16px;
      }
    }
  }

  &-product {
    &__info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      margin-top: 24px;
      @include media-breakpoint-up(xxl) {
        gap: 59px;
        margin-top: 16px;
      }
    }

    &__image {
      width: 120px;
      height: 120px;
      @include media-breakpoint-up(xl) {
        width: 200px;
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__detail {
      flex: 1;

      .product {
        &-title {
          color: $mine-shaft;
          font-size: $h5-font-size;
          font-weight: $font-weight-normal;
          margin-bottom: 18px;
          @include media-breakpoint-up(xxl) {
            font-size: 3rem;
            margin-bottom: 24px;
          }
        }

        &-color {
          margin-bottom: 0;
          color: $gray-darker;
          font-size: $font-size-mobile;
          @include media-breakpoint-up(lg) {
            font-size: $h5-font-size;
          }
        }
      }
    }
  }

  &-review {
    &__container {
      margin-top: 8px;
      padding-right: 14px;
      padding-left: 28px;
      @include media-breakpoint-up(lg) {
        padding-right: 59px;
        padding-left: 59px;
      }
    }

    &__rate {
      @include media-breakpoint-up(md) {
        margin-top: 26px;
      }
    }

    &__title {
      color: $gray-darker;
      font-size: $h3-font-size-mobile;
      margin-top: 8px;
      font-weight: $font-weight-normal;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: $h1-font-size-mobile;
        margin-top: 9px;
      }
    }

    &__detail {
      margin-top: 8px;
      color: $gray-darker;

      &-created {
        font-size: $font-size-large;
        position: relative;
        @include media-breakpoint-up(md) {
          font-size: $h2-font-size-mobile;
        }
      }

      &-content {
        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
          margin-top: 18px;
        }
      }
    }
  }

  &-remove__item {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .remove-item-btn {
      color: $secondary;
      font-weight: $font-weight-normal;
      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &-shimmer {
    &.my-account-section {
      padding: 16px 0 16px;
    }

    @include media-breakpoint-up(lg) {
      &.my-account-section {
        padding: 24px 24px 24px;
      }
    }
  }
}