@import '@assets/reuse-mixin.scss';

.cookie-banner {
  background-color: $os-orange;
  padding: 24px 0;
  position: sticky;
  top: 0;
  z-index: 10;

  //&:after {
  //  content: '';
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  left: 0;
  //  top: 0;
  //  background-color: rgba(0, 0, 0, .1);
  //}

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__content {
    flex: 1;
    color: $gray-darker;
    position: relative;
    z-index: 1;

    .title {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      margin-bottom: 12px;
      color: inherit;
    }

    .description {
      font-size: $h6-font-size-mobile;
      text-align: justify;
      @include media-breakpoint-up(md) {
        //text-align: left;
        font-size: $font-size-base;
        margin-bottom: 0;
      }
    }
  }

  &__cta {
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(md) {
      margin-left: 20px;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 40px;
    }
  }
}