@import "@assets/reuse-mixin.scss";

.cart-milestone {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 15px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;
  }

  &__message {
    font-size: $font-size-base;
    text-align: center;
    color: $gray-darker;
    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }
  }

  &__progress {
    &-container {
      width: 100%;
      background-color: $gray-light;
      height: 8px;
    }

    &-body {
      background-color: $secondary;
      height: 100%;
      width: var(--progressWidth);
      transition: width 200ms ease-in-out;
    }
  }
}
