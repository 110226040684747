@import'@assets/reuse-mixin.scss';

.review-media {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 50px;
    height: 50px;
    margin-right: 12px;
    cursor: pointer;
    position: relative;
    // margin-bottom: 12px;

    @include media-breakpoint-up(lg) {
      width: 60px;
      height: 60px;
      margin-right: 16px;
      // margin-bottom: 16px;
    }

    &__number {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.6);
      color: $white;
      font-size: $font-size-base;
      z-index: 2;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
      }
    }

    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.media-item--video-type {
  position: relative;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background: url('../../../assets/images/icons/icon-video.png') 0 0 no-repeat;
    background-size: cover;
    z-index: 1;
  }
}