@import "@assets/reuse-mixin.scss";

.abandoned-card-widget {
  &__heading {
    &.my-account-section__heading {
      margin-bottom: 24px;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }
  }

  &__content {
    max-width: 955px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      &-image {
        max-width: 120px;
        width: 100%;
        @include media-breakpoint-up(md) {
          max-width: 250px;
        }

        &__container {
          padding-bottom: 100%;
          position: relative;

          & > img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: contain;
          }
        }
      }

      &-wrapper {
        width: 100%;
        margin-left: 12px;
        @include media-breakpoint-up(md) {
          margin-left: 90px;
        }

        & > .product-name {
          font-weight: $font-weight-base;
          line-height: 2.6rem;
          font-size: $h5-font-size;
          @include media-breakpoint-up(lg) {
            line-height: 4.6rem;
            font-size: $h4-font-size;
          }
          @include media-breakpoint-up(xl) {
            font-size: $h4-font-size + 0.4;
          }

          @include media-breakpoint-up(xxl) {
            font-size: $h3-font-size;
          }
        }
      }
    }

    &-description {
      text-align: left;
      color: $gray-dark;
      font-size: $h6-font-size-mobile;
      line-height: 1.8rem;
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        margin-top: 24px;
        font-size: $h5-font-size;
        line-height: 2.8rem;
      }

      &.mt-8px {
        margin-top: 8px;
      }
    }
  }

  .view-cart-button {
    &.btn {
      max-width: 240px;
      width: 100%;
      color: $white;
      font-weight: $font-weight-bold;
      text-decoration: none;
      margin-top: 32px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(md) {
        margin-top: 54px;
        margin-right: unset;
        margin-left: unset;
      }
    }
  }
}