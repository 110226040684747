@import '@assets/reuse-mixin.scss';

.spin-form {
  width: 100%;
  max-width: 320px;
  margin: 24px auto 0;

  @include media-breakpoint-up(lg) {
    max-width: 400px;
  }

  @include media-breakpoint-up(xxl) {
    margin: 32px auto 0;
  }

  &__input {
    width: 100%;
    
    &__group {
      width: 100%;
      position: relative;
      
      input {
        padding-right: 55px;
      }
    }

    &__edit {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__info {
    margin-top: 12px;
    min-height: 2px;
    
    @include media-breakpoint-up(lg) {
      margin-top: 16px;
      min-height: 6px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 0;
    }

    &__line {
      display: block;
      font-size: $font-size-large;
      line-height: 1.1;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }
    }
  }

  &__button {
    padding-top: 8px;

    @include media-breakpoint-up(xxl) {
      padding-top: 24px;
    }

    .btn {
      min-width: 200px;

      @include media-breakpoint-up(lg) {
        min-width: 240px;
      }
    }
  }
}