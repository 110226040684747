@import '../../../assets/reuse-mixin.scss';

.product-item-view {
	text-align: center;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include media-breakpoint-up(xl) {
		padding: 0 20px;
	}

	&__thumb {
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin-bottom: 15px;

		@include media-breakpoint-up(md) {
			height: 170px;
		}

		@include media-breakpoint-up(lg) {
			height: 220px;
			margin-bottom: 20px;
		}
		
		img {
			height: auto;
			width: auto;
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__name {
		@include media-breakpoint-up(md) {
			margin-bottom: 10px;
		}
	}

	&__selling-price {
		font-size: $font-size-base;
		color: $gray-darker;

		@include media-breakpoint-up(lg) {
			font-size: $font-size-large;
		}
	}

	&__list-price {
		text-decoration: line-through;
		font-size: $font-size-small;
		margin-bottom: 5px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 10px;
		}
	}

	&__buy-now {
		display: block;
		max-width: 240px;
		margin: 0 auto;
	}
}