@import '../../../../../assets/reuse-mixin.scss';

.product-variant-info {
  color: $gray-darker;

  &__content {
    padding-bottom: 36px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }

    &__name {
      font-size: $h1-font-size-mobile;
      margin-bottom: 18px;

      @include media-breakpoint-up(lg) {
        font-size: $h4-font-size;
      }

      @include media-breakpoint-between(lg, xl) {
        margin-bottom: 12px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h3-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h1-font-size;
        line-height: 1.16;
      }
    }

    &__countdown {
      //order: 2;
      margin-bottom: 12px;
      @include media-breakpoint-up(lg) {
        //order: 1;
        //margin-bottom: 12px;
      }
      @include media-breakpoint-between(lg, xl) {
        margin-bottom: 12px;
      }
      @include media-breakpoint-up(xxl) {
        margin-bottom: 24px;
      }

      .countdown-timer__custom {
        @include media-breakpoint-between(lg, xl) {
          .countdown-timer__container {
            padding: 5px 20px;

            .countdown-timer__title {
              @include media-breakpoint-between(lg, xl) {
                margin-bottom: 10px;
              }
            }

            .countdown-timer__detail {
              gap: 15px;

              .countdown-timer__detail-item {
                &:not(:last-child) {
                  .timer-box:after {
                    width: 15px;
                  }
                }

                .timer-box {
                  @include media-breakpoint-between(lg, xl) {
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__price {
      margin-bottom: 14px;
      //order: 1;
      @include media-breakpoint-up(lg) {
        //order: 2;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: 6px;
      }
    }

    &__depposit {
      &__price {
        .item {
          padding-bottom: 8px;
          color: $gray-500;
          font-size: $font-size-smaller;
          display: flex;
          align-items: center;
          
          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
            color: $gray-c2;
          }
          
          &:last-child {
            padding-bottom: 0px;
          }

          &__description {
            &--usual {
              padding-right: 58px;
            }

            &--pre-order {
              padding-right: 28px;
            }

            &--deposit {
              padding-right: 35px;
            }
          }

          &__value {
            padding-right: 15px;
            
            &:last-child {
              padding-right: 0px;
            }

            &--pre-order {
              font-size: $h2-font-size-mobile;
              color: $gray-900;
              font-weight: $font-weight-normal;
              
              @include media-breakpoint-up(lg) {
                font-size: $h3-font-size;
                color: $white;
              }
            }

            &--deposit {
              color: $gray-900;

              @include media-breakpoint-up(lg) {
                color: $white;
              }
            }

            &--fontWeightBold {
              font-weight: $font-weight-bold;
            }
          }
        }
      }

      &__info {
        padding-top: 24px;
        overflow: hidden;

        .item {
          padding-bottom: 16px;
          display: flex;
          align-items: center;
          
          @include media-breakpoint-up(lg) {
            padding-bottom: 22px;
          }

          &:last-child {
            padding-bottom: 0;
          }

          &__number {
            font-size: $font-size-smaller;
            height: 16px;
            width: 16px;
            display: inline-block;
            border-radius: 50%;
            background: $white;
            display: flex;
            align-items: center;
            color: $secondary;
            justify-content: center;
            border: 1px solid $secondary;
            margin-right: 8px;
            position: relative;
            z-index: 2;
            
            @include media-breakpoint-up(lg) {
              font-size: $font-size-small;
              height: 24px;
              width: 24px;
            }

            &--before::before {
              content: '';
              background: $os-blue;
              width: 1px;
              height: 40px;
              position: absolute;
              left: 7px;
              bottom: -40px;
              z-index: 1;
              
              @include media-breakpoint-up(lg) {
                left: 11px;
                height: 70px;
                bottom: -70px;
              }
            }
          }

          &__box {
            width: calc(90% - 16px);

            @include media-breakpoint-up(lg) {
              width: calc(90% - 24px);
            }
          }
          
          &__description {
            font-size: $font-size-smaller;
            
            @include media-breakpoint-up(lg) {
              font-size: $font-size-base;
            }

            &--fontWeightRegular {
              font-weight: $font-weight-normal;
            }
            
            // &--colorGreen {
            //   color: $os-green;
            //   font-weight: $font-weight-bold;
            // }
          }

          .promotion-detail-bundle__header__text__name {
            .countdowntimer--number {
              font-weight: $font-weight-bold;
              font-size: $font-size-smaller;

              @include media-breakpoint-up(lg) {
                font-size: $font-size-base;
              }
            }
          }
        }

      }
      
      &__link {
        padding-top: 24px;
        
        @include media-breakpoint-up(lg) {
          padding-top: 24px;
        }

        span {
          color: $link-color;
          font-size: $font-size-smaller;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
          
          @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
          }
        }
      }
    }

    &__selling-price {
      display: block;
      font-size: $h4-font-size;
      font-weight: $font-weight-normal;
      @include media-breakpoint-between(lg, xl) {
        font-size: 2.2rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h3-font-size;
      }

      &.has-member-text {
        position: relative;
        display: flex;
      }
    }

    &__list-price,
    &__installment {
      font-size: $font-size-smaller;
      color: $gray;
      @include media-breakpoint-between(lg, xl) {
        font-size: $font-size-small;
      }
      @include media-breakpoint-up(xxl) {
        font-size: $font-size-base;
      }
    }

    &__installment {
      display: block;
      color: $gray-darker;
      @include media-breakpoint-between(lg, xl) {
        font-size: $font-size-smaller;
      }
    }

    &__deposit {
      font-size: $font-size-small;

      @include media-breakpoint-up(xl) {
        font-size: $font-size-large;
      }
    }

    &__description,
    &__see-detail {
      font-size: $font-size-base;

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__description {
      display: block;
      padding: 4px 0;
    }
  }
}