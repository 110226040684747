@import '@assets/reuse-mixin.scss';

.my-reward-widget {
  &__heading {
    .my-account-section {
      &__heading__title {
        font-size: $h4-font-size-mobile;
        line-height: 1;
        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
        }

        @include media-breakpoint-up(xl) {
          font-size: $h4-font-size + 0.4;
        }

        @include media-breakpoint-up(xxl) {
          font-size: $h3-font-size;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &-left, &-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //padding: 24px 0;

      @include media-breakpoint-up(md) {
        //padding: 28px 0;
      }

      & > p {
        line-height: 2rem;
        font-size: $h6-font-size-mobile;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          font-size: $h5-font-size;
          line-height: 2.4rem;
        }
      }
    }

    &-left {
      color: $gray-1000;
      padding-bottom: 24px;
      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }

      .member {
        &-elite {
          text-transform: uppercase;
          font-weight: $font-weight-bold;
          margin-bottom: 12px;
          line-height: 2.2rem;
          font-size: $h4-font-size-mobile;
          @include media-breakpoint-up(md) {
            font-size: $h4-font-size;
          }
        }

        &-id {
          line-height: 2rem;
          font-size: $font-size-small;

          @include media-breakpoint-up(md) {
            line-height: 2.4rem;
            font-size: $font-size-large;
          }

          &__number {
            font-weight: $font-weight-normal;
          }
        }
      }

      .reward-icon {
        width: 100px;
        margin-bottom: 8px;
        @include media-breakpoint-up(md) {
          width: 120px;
          margin-bottom: 10px;
        }

        &__container {
          width: 100%;
          padding-bottom: 100%;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: contain;
          }
        }
      }
    }

    &-right {
      border-top: 1px solid $gray-light;
      padding-bottom: 0;
      align-items: start;
      padding-top: 24px;
      @include media-breakpoint-up(md) {
        //padding-bottom: 28px;
        padding-top: 0;
        padding-left: 28px;
        border-left: 1px solid $gray-light;
        border-top: unset;
      }

      &__title {
        font-weight: $font-weight-normal;
        margin-bottom: 16px;
        text-align: left;
        @include media-breakpoint-up(md) {
          margin-bottom: 24px;
          text-align: center;
        }
      }

      .content {
        margin-bottom: 12px;
        @include media-breakpoint-up(md) {
          margin-bottom: 16px;
        }

        & > span {
          font-weight: $font-weight-normal;
        }
      }

      .link {
        margin-top: 6px;
        text-align: left;
        width: 100%;
        @include media-breakpoint-up(md) {
          margin-top: 8px;
        }
      }
    }
  }
}