@import '@assets/reuse-mixin.scss';

.buying-option {
  color: $gray-darker;
  padding-top: 32px;

  @include media-breakpoint-up(lg) {
    // display: flex;
    padding-top: 48px;
  }

  .accordion {
    padding-top: 0;
  }

  &__bundle-wrap {
    width: 100%;

    // &__item {
    //   display: flex;
    // }
  }

  &__group-info {
    &__line-bottom {
      border-bottom: 1px solid $gray;

      &:last-child {
        border-bottom: none;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 30px;
        }
      }
    }
  }

  &__left {
    margin-bottom: 16px;

    // Customize ShippingInfo Component
    .shipping-info {
      &__wrap {
        padding: 0;
        // justify-content: flex-start;

        // @include media-breakpoint-up(md) {
        // }

        // @include media-breakpoint-up(xxl) {
        //   flex-wrap: nowrap;
        // }
      }

      &__item {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 50%;
        }

        @include media-breakpoint-up(lg) {
          width: 100%;
        }

        @include media-breakpoint-up(xxl) {
          margin-top: 32px;

          &:nth-child(2) {
            margin-top: 32px;
          }
        }
        // @include media-breakpoint-up(md) {
        //   margin-right: 85px;
        // }

        // @include media-breakpoint-up(lg) {
        //   margin-right: 25px;
        // }

        // @include media-breakpoint-up(xl) {
        //   margin-right: 25px;
        // }

        // @include media-breakpoint-up(xxl) {
        //   margin-right: 30px;
        // }

        // &:last-child {
        //   margin-right: 0;

        //   @include media-breakpoint-up(xxl) {
        //     margin-top: 0;
        //   }
        // }
      }
    }
  }

  &__right {
    // padding: 30px $grid-gutter-width/2 0;

    &__productname {
      font-size: $h4-font-size;
      margin-bottom: 14px;

      @include media-breakpoint-up(lg) {
        font-size: $h2-font-size;
        margin-bottom: 22px;
      }

      .free-text {
        color: $os-green;
        visibility: hidden;
        opacity: 0;
        transition: all 0.35s;
        margin-left: 5px;

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  // ProductImage Component
  .bo-product-image {
    position: relative;
    margin-bottom: 24px;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;

    // @include media-breakpoint-up(md) {
    //   margin-left: calc(-1 * (((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2}));
    //   margin-right: calc(-1 * (((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2}));
    // }

    @include media-breakpoint-up(lg) {
      max-width: 610px;
      margin: 0 auto 24px;
      background-color: $white;
    }

    &__label {
      position: absolute;
      top: 20px;
      left: 0;

      &__item {
        display: block;
        margin-bottom: 8px;
      }
    }

    &__add-wishlist {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      transition: all 0.2s;
      padding: 10px;

      &:hover {
        .bo-product-image__add-wishlist__tooltip {
          opacity: 1;
        }

        .bo-product-image__add-wishlist__icon {
          // font-size: 1.7em;
          color: $os-orange;
        }
      }

      &__wrap {
        position: relative;
      }

      &__tooltip {
        display: none;
        opacity: 0;
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        color: $white;
        pointer-events: none;
        white-space: nowrap;
        background-color: $gray-dark;
        padding: 3px 10px;
        transition: all 0.3s ease-out;
        font-size: $font-size-small;

        @include media-breakpoint-up(lg) {
          display: block;
        }

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          right: auto;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid $gray-dark;
        }
      }

      &__icon {
        font-size: 1.5em;
        color: $gray;
      }
    }

    &__picture {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      padding-bottom: 100%;
      height: 0;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  // ChooseColor, Warranty Component
  .bo-choose-color,
  .bo-warranty {
    padding-top: 32px;

    &__list {
      padding-bottom: 10px;
      border-bottom: 1px solid $gray;

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }

    &__item-wrap {
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }
    }

    &__item {
      display: flex;
      border: 1px solid $gray-light;
      padding: 0 16px 0 12px;
      transition: all 0.3s;
      height: 100%;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 5px;
      }

      &:hover {
        border: 1px solid $gray-darker;
      }

      &.active {
        border: 1px solid $gray-darker;
      }

      &.disable {
        color: $gray-light;
      }

      &__group {
        width: calc(100% - 56px);
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-up(lg) {
          flex-direction: column;
          width: auto;
        }
      }

      &__image {
        width: 56px;

        @include media-breakpoint-up(lg) {
          width: 96px;
        }
      }

      &__color {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
        }
      }

      &__price {
        text-align: right;

        @include media-breakpoint-up(lg) {
          text-align: center;
        }

        &__current {
          display: block;
          font-weight: bold;
          font-size: $font-size-base;

          @include media-breakpoint-up(lg) {
            font-size: $h5-font-size;
          }
        }

        &__old {
          font-size: $font-size-smaller;
          color: $gray;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-small;
          }
        }
      }

      &__status {
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
        }
      }
    }
  }

  // Warranty Component
  .bo-warranty {
    @include media-breakpoint-up(lg) {
      padding-top: 16px;
    }

    &__item-wrap {
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      @include media-breakpoint-up(lg) {
        padding: 30px;
      }

      &__time {
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $h3-font-size;
        }
      }

      &__expenses {
        font-weight: bold;
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
        }
      }
    }
  }

  // TradeIn Component
  .bo-trade-in {
    padding-top: 18px;

    @include media-breakpoint-up(lg) {
      padding-top: 16px;
    }

    &__title {
      margin-bottom: 6px;
    }

    &__desc {
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }

      &__link {
        text-decoration: underline;
        display: inline-block;
        margin-left: 4px;
      }
    }

    &__btn {
      padding-bottom: 16px;
      border-bottom: 1px solid $gray;

      &__item {
        width: 200px;
        height: 36px;
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          width: 240px;
          height: 40px;
          padding: 10px;
        }
      }
    }
  }

  // FreeGift Component
  .bo-free-gift {
    padding-top: 18px;
    margin-bottom: 25px;

    &__wrap {
      padding-left: $grid-gutter-width / 2;

      @include media-breakpoint-up(md) {
        padding-left: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
      }

      @include media-breakpoint-up(lg) {
        padding: 0 $grid-gutter-width / 2;
      }
    }

    &__group {
      margin-bottom: 18px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &__title {
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base;
        }

        @include media-breakpoint-up(lg) {
          font-size: $font-size-large;
        }
      }
    }

    &__item {
      cursor: pointer;

      &:hover,
      &.active {
        .bo-free-gift__item__image {
          border-color: $gray-darker;
        }
      }

      &__image {
        width: 100%;
        border: 1px solid $gray-light;
        transition: all 0.3s;
        margin-bottom: 4px;
        height: 0;
        padding-bottom: calc(100% - 2px);
        position: relative;

        @include media-breakpoint-up(lg) {
          margin-bottom: 8px;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__name {
        font-size: $font-size-smaller;

        @include line-clamp(2);

        @include media-breakpoint-up(lg) {
          font-size: $font-size-small;
        }
      }
    }
  }

  // PaymentInfo Component
  .bo-payment-info {
    background-color: #feeee4;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      margin-left: calc(-1 * (((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2}));
      margin-right: calc(-1 * (((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2}));
    }

    @include media-breakpoint-up(lg) {
      background-color: $white;
      // padding: 0 $grid-gutter-width / 2;
      margin-left: 0;
      margin-right: 0;
    }

    &__wrap {
      background-color: #feeee4;
      padding: 16px $grid-gutter-width / 2 24px;

      @include media-breakpoint-up(md) {
        padding: 16px calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2}) 24px;
      }

      @include media-breakpoint-up(lg) {
        padding: 20px 33px 32px;
      }

      @include media-breakpoint-up(xl) {
        padding: 20px 58px 32px 33px;
      }
    }

    &__price {
      margin-bottom: 8px;

      &__current {
        font-size: $h4-font-size;
        display: block;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(xl) {
          font-size: $h3-font-size + 0.3;
        }

        &.with-tooltip {
          display: flex;

          .question-tooltip-icon {
            margin-left: 4px;

            &__wrapper {
              position: relative;
            }
          }
        }
      }

      &__old {
        color: $gray;

        @include media-breakpoint-up(xl) {
          font-size: $font-size-large;
        }
      }
    }

    &__calculation {
      padding-bottom: 10px;
      border-bottom: 1px solid $gray-dark;
      font-size: $font-size-large;
    }

    &__fulfillment {
      padding-top: 12px;

      @include media-breakpoint-up(lg) {
        padding-top: 16px;
      }

      &__title {
        &.bo-title-section {
          margin-bottom: 12px;

          @include media-breakpoint-up(lg) {
            margin-bottom: 8px;
          }
        }
      }
    }

    &__installment {
      &__text {
        display: block;
      }
    }

    &__bundle__link {
      display: block;
      width: fit-content;
      margin-bottom: 8px;
    }
  }

  &__btn {
    padding: 24px 0;

    &__item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__add-cart,
    .buying-option-header__btn__item {
      width: 200px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        width: 240px;
        height: 40px;
        padding: 10px;
      }
    }

    &__continue-shopping {
      text-transform: uppercase;
      //color: $os-blue;
      color: $link-color;
      font-weight: bold;
    }
  }

  .bo-title-section {
    font-size: $font-size-large;
    color: $gray-darker;
    margin-bottom: 0;
    font-weight: $font-weight-normal;
    max-width: calc(100% - 64px);

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
    }

    @include media-breakpoint-up(xl) {
      font-size: $h5-font-size + 0.2;
      max-width: calc(100% - 72px);
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h4-font-size;
    }
  }

  &.skeleton__item {
    .buying-option__left {
      .image-wrap {
        padding-bottom: 100%;
      }
    }

    .buying-option__right {
      .buying-option__shimmer-item {
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
        }

        &.bo-payment-info {
          background-color: transparent;

          .bo-payment-info__wrap {
            background-color: transparent;
            padding-bottom: 50%;
          }
        }
      }

      .item__content {
        &__title {
          @include skeleton($lines: 1, $line-height: 30px, $line-gap: 0);
        }

        .item__content__image {
          padding-bottom: 15%;

          @include media-breakpoint-up(lg) {
            padding-bottom: 60%;
          }
        }

        &.bo-gift-shimmer {
          display: flex;
          justify-content: space-between;

          .bo-gift-shimmer__item {
            width: calc(100% / 5 - 12px);

            .image-wrap {
              padding-bottom: 100%;
            }
          }
        }
      }
    }
  }

  // Override Accordion
  .accordion {
    margin: 0;
    padding: 0;
  }
}

.buying-option-cp {
  padding-top: 24px;

  &__wrapper {
    &__img {
      align-items: center;

      .bo-product-image {
        &__box {
          display: flex;
          flex-direction: column-reverse;
          @include media-breakpoint-up(xl) {
            flex-direction: row;
          }
        }

        &__picture {
          @include media-breakpoint-up(xl) {
            margin-right: 16px;
            width: 50%;
          }
        }

        &__info {
          display: flex;
          justify-content: center;
          color: $gray-900;
          flex-direction: column;
          @include media-breakpoint-up(md) {
            flex-direction: row;
          }
          @include media-breakpoint-up(xl) {
            flex-direction: column;
            width: 50%;
          }

          &__title {
            font-size: $font-size-base;
            margin-bottom: 3px;
            font-weight: $font-weight-normal;
            flex: 1;
            @include media-breakpoint-up(md) {
              font-size: $h4-font-size;
            }
            @include media-breakpoint-up(xl) {
              font-size: $h5-font-size;
              margin-bottom: 0;
              flex: unset;
            }
          }

          &__price-curent {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            @include media-breakpoint-up(md) {
              font-size: $h4-font-size;
            }
            @include media-breakpoint-up(xl) {
              font-weight: $font-weight-bold;
              font-size: $h5-font-size;
              padding-top: 8px;
            }
          }

          &__price-old {
            font-size: $font-size-small;
            color: $gray-500;
            padding-top: 8px;
          }
        }
      }
    }

    &__content {
      .bo-payment-info {
        background-color: #feeee4;
        padding: 24px;

        &__price {
          &__current {
            font-size: $h4-font-size;
            color: $gray-1000;
            font-weight: $font-weight-normal;
            display: block;
          }

          &__old {
            font-size: $font-size-small;
            color: $gray-500;
          }
        }

        &__calculation {
          padding-bottom: 10px;
          border-bottom: 1px solid $gray-dark;
          font-size: $font-size-large;
        }

        &__fulfillment {
          padding-top: 12px;

          &__title {
            &.bo-title-section {
              margin-bottom: 12px;
              font-size: $h4-font-size;
            }
          }
        }

        &__installment {
          &__text {
            display: block;
          }
        }

        &__btn {
          &__item {
            padding-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .item-btn {
              width: 300px;
              font-size: $font-size-small;
            }
          }

          &__link {
            padding-top: 24px;
            text-align: center;

            a {
              font-size: $font-size-small;
            }
          }
        }
      }
    }
  }

  .bo-free-gift {
    &__item {
      cursor: pointer;

      &:hover,
      &.active {
        .bo-free-gift__item__image {
          border-color: $gray-darker;
        }
      }

      &__image {
        width: 100%;
        border: 1px solid $gray-light;
        transition: all 0.3s;
        margin-bottom: 4px;
        height: 0;
        padding-bottom: calc(100% - 2px);
        position: relative;

        @include media-breakpoint-up(lg) {
          margin-bottom: 8px;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__name {
        font-size: $font-size-smaller;

        @include line-clamp(2);

        @include media-breakpoint-up(lg) {
          font-size: $font-size-small;
        }
      }
    }
  }
}

//BuyingOptionHeader Component
.buying-option-header {
  width: 100%;
  z-index: $PRODUCT_DETAIL_HEADER_ZINDEX;
  background-color: $white;
  color: $gray-darker;
  // border-bottom: 1px solid $gray-lighter;
  position: relative;
  box-shadow: 0px 3px 6px rgba($gray-light, 0.25);

  &.buying-option-header-sticky {
    position: sticky;
    top: 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    min-height: 64px;

    // height: 64px;

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }

    @include media-breakpoint-up(md) {
      padding-right: $grid-gutter-width/2;
    }

    @include media-breakpoint-up(lg) {
      min-height: 56px;
    }
  }

  &__text {
    padding: 8px 0;

    @include media-breakpoint-up(md) {
      padding: 5px 0;
      margin-right: 20px;
      &:not(:has(.with-promotion-text)) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }

    @include media-breakpoint-up(xl) {
      &:has(.with-promotion-text) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
      }
    }

    &__name {
      font-size: $font-size-base;
      margin-bottom: 3px;
      font-weight: $font-weight-normal;
      // @include line-clamp(1);

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
      }
    }

    &__price {
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: baseline;
      }

      &:has(.with-promotion-text) {
        @include media-breakpoint-up(md) {
          flex-direction: column-reverse;
        }
        @include media-breakpoint-up(xxl) {
          flex-direction: row;
        }

        .with-promotion-text {
          line-height: 1;
          @include media-breakpoint-up(md) {
            margin: 0;
          }
        }
      }

      &__current {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        display: inline-block;
        margin-right: 5px;
        display: flex;

        @include media-breakpoint-up(md) {
          font-size: $h4-font-size;
          order: 2;
          margin: 0 0 0 10px;
        }

        @include media-breakpoint-up(lg) {
          font-size: $h3-font-size;
        }

        &.disable {
          color: $gray;
        }
      }

      &__old {
        color: $gray;

        @include media-breakpoint-up(lg) {
          order: 1;
        }
      }
    }
  }

  &__btn {
    &__item {
      height: 100%;
      padding: 20px 10px;
      width: 148px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      text-transform: uppercase;
      opacity: 0.5;

      @include media-breakpoint-up(lg) {
        width: 240px;
        padding: 15px 10px;
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.btn-link-back {
  text-transform: uppercase;
  //color: $os-blue;
  color: $link-color;
  font-weight: bold;
  margin-bottom: 12px;
  display: inline-block;

  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
  }
}
