@import '../../../assets/reuse-mixin.scss';

.social-navigation {
  background: $os-brown-lightest;
  display: inline-block;
  padding: 18px 0 24px;
  width: 100%;

  @include media-breakpoint-up(lg) {
    padding: 0 0 24px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }

  &__item {
    display: block;
    margin-right: 30px;
    width: 48px;

    @include media-breakpoint-up(lg) {
      margin-right: 25px;
    }

    &:last-child {
      margin-right: 0;
    }

    &__image {
      width: 100%;
      height: auto;
    }
  }
}