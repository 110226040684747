@import "@assets/reuse-mixin.scss";

.searchbox-article {
	&__title {
		margin-top: 40px;

		@include media-breakpoint-up(xl) {
			margin-top: 72px;
		}
	}
}

.article-search-box {
	&__search-box {
		margin-bottom: 12px;
		border: 1px solid $gray;
		padding: 12px 15px 12px 24px;
		justify-content: space-between;

		@include media-breakpoint-up(md) {
			padding: 8px 22px 8px 28px;
		}

		.featured-article-item__tags__item {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			color: $gray-darker;

			&--input {
				padding-right: 0;
				margin-right: 8px;

				@include media-breakpoint-up(md) {
					margin-right: 12px;
				}
			}
		}

		.tag-x-icon {
			display: inline-block;
			width: 8px;
			margin: 0 6px;

			@include media-breakpoint-up(md) {
				margin: 0 12px;
				width: 12px;
			}
		}

		&__input {
			flex-grow: 1;

			input {
				border: none;
				outline: none;
				width: 100%;
				max-width: 158px;
				font-size: $font-size-base;
				letter-spacing: 0.8px;

				@include media-breakpoint-up(md) {
					padding: 6px 0;
					font-size: $h5-font-size;
					letter-spacing: 1px;
					max-width: 100%;
				}
			}

			::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $gray;
				opacity: 1; /* Firefox */
				font-weight: $font-weight-light;
			}
			
			:-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: $gray;
				font-weight: $font-weight-light;
			}
			
			::-ms-input-placeholder { /* Microsoft Edge */
				color: $gray;
				font-weight: $font-weight-light;
			}
		}

		&__icon {
			width: 24px;
			height: 24px;
			transition: 0.3s;
			display: flex;
			align-items: center;

			&:hover {
				transform: scale(1.2);
			}
		}
	}

	&__list-filter {
		padding-bottom: 20px;

		@include media-breakpoint-up(md) {
			display: flex;
			align-items: center;
		}

		&__text {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
				padding-bottom: 15px;
				white-space: nowrap;
			}
		}

		&__list-tag {
			display: flex;
			overflow-y: hidden;
			overflow-x: auto;
			padding-bottom: 15px;

			@include media-breakpoint-up(md) {
				margin-left: 30px;
			}

			li {
				white-space: nowrap;
			}
		}
	}
}
