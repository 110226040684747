@import "../SupportCentre";

.product-accessories {
  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    &.support-centre-box__wrapper {
      max-width: unset;
    }
  }

  &__content {
    width: 100%;

    &-header {
      max-width: 630px;
      width: 100%;
      margin: auto;

      &__icon {
        max-width: 70px;
        width: 100%;
        margin: auto;
        @include media-breakpoint-up(md) {
          max-width: 90px;
        }

        &-wrapper {
          width: 100%;
          padding-bottom: 100%;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            object-fit: contain;
          }
        }
      }

      &__text {
        margin-top: 20px;
        @include media-breakpoint-up(md) {
          margin-top: 32px;
        }
      }
    }

    &-body {
      transform: scale(1);
      margin-top: 24px;
      @include media-breakpoint-up(md) {
        margin-top: 32px;
      }

      .product-accessories__swiper {
        &-item {
          &__wrapper {
            max-width: 240px;
            width: 100%;
            margin: auto;
          }

          &__image {
            border: none;
          }

          &__name {
            display: block;
            text-align: center;
            line-height: 2.4rem;
            font-size: $h4-font-size-mobile;
            font-weight: $font-weight-normal;
            margin-top: 18px;
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
              font-size: $h4-font-size;
              line-height: 3.2rem;
              margin-top: 24px;
            }
          }

          &__price {
            text-align: center;
            margin-top: 18px;
            @include media-breakpoint-up(md) {
              margin-top: 24px;
            }

            .selling-price {
              font-size: $h4-font-size-mobile;
              line-height: 2.4rem;
              font-weight: $font-weight-base;
              color: $gray-darker;
              margin-bottom: 4px;
              @include media-breakpoint-up(md) {
                font-size: $h4-font-size;
                line-height: 3.2rem;
                margin-bottom: 8px;
              }
            }

            .old-price {
              font-size: $font-size-smaller;
              line-height: 2.4rem;
              color: $gray-dark;
              text-decoration: line-through;
              margin-bottom: 0;
              @include media-breakpoint-up(md) {
                line-height: 1.6rem;
                font-size: $font-size-base;
              }
            }
          }

          &__cta {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include media-breakpoint-up(md) {
              margin-top: 32px;
            }

            .buy-now-btn {
              width: 100%;
              color: $white;
            }

            .learn-more-btn {
              margin-top: 18px;
              text-align: center;
              @include media-breakpoint-up(md) {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}

