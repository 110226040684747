@import '@assets/reuse-mixin.scss';

.fulfillment-options {
  &__button {
    margin: 24px 0 18px;

    @include media-breakpoint-up(xl) {
      margin: 32px 0 24px;
    }
  }
}