@import '@assets/reuse-mixin.scss';

.wellness-contents {
  padding: 17px 0 32px 0;
  
  @include media-breakpoint-up(md) {
    padding: 40px 0 32px 0;
  }
  
  &__title {
    text-transform: uppercase;
    font-size: $font-size-small;
    letter-spacing: 2.52px;
    color: $gray;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
      letter-spacing: 2.88px;
    }
  }
}