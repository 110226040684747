@import '../../../../../assets/reuse-mixin';

.list-icon {
  color: $gray-darker;
  padding: 56px 0 34px;

  @include media-breakpoint-up(xl) {
    padding: 60px 0 45px;
  }

  &::before {
    display: none;
  }

  &.bg-sand {
    background-color: $os-orange-lightest;
  }

  .experience-title {
    margin-bottom: 32px;
  
    @include media-breakpoint-up(md) {
      margin-bottom: 48px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  
    @include media-breakpoint-up(md) {
      justify-content: center;
      flex-wrap: nowrap;
    }
  }

  &__item {
    padding: 0 $grid-gutter-width/2;
    width: 20%;

    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }

    &__image {
      height: 68px;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(lg) {
        // margin-bottom: 30px;
        height: 120px;
      }

      img {
        display: block;
        width: auto;
        max-height: 36px;

        @include media-breakpoint-up(lg) {
          max-height: 64px;
        }
      }

      &--richtext {
        flex-direction: column;

        img {
          max-height: 32px;
  
          @include media-breakpoint-up(lg) {
            max-height: 50px;
          }

          & + img {
            margin-top: 16px;
          }
        }
      }
    }
    
    &.skeleton__item {
      .list-icon__item__text {
        .text-line {
          @include skeleton($lines: 1, $line-height: 21.5px, $line-gap: 0, $background: transparent);
        }
      }

      .list-icon__item__image {
        .image-line {
          @include skeleton($lines: 1, $line-height: 100%, $line-gap: 0, $background: transparent);
        }
      }
    }

    &__text {
      text-align: center;
      margin: 0 -15px;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-large;
        margin: 0;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h4-font-size;
      }
    }

    &__title {
      display: block;
    }

    &__content {
      display: block;
      margin-bottom: 5px;
      color: $gray-darker;
    }
  }

  // one a row
  &.one-a-row {
    padding: 40px 0;

    .list-icon__item {
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
      }

      &:last-child {
        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }
      }

      &__image {
        height: auto;
        width: 32px;
        margin-bottom: 0;
  
        @include media-breakpoint-up(md) {
          width: auto;
          height: 50px;
          margin-bottom: 8px;
        }

        @include media-breakpoint-up(lg) {
          height: 72px;
          margin-bottom: 16px;
        }
      }

      &__text {
        text-align: left;
        padding-left: 10px;
        margin: 0;
        width: calc(100% - 32px);

        @include media-breakpoint-up(sm) {
          text-align: center;
          padding-left: 0;
          width: auto;
        }
      }
    }
  }

  // two a row
  &.two-a-row {
    padding: 32px 0;

    @include media-breakpoint-up(xl) {
      padding: 60px 0 45px;
    }

    .list-icon__item {
      @include media-breakpoint-down(sm) {
        width: 50%;
        margin-bottom: 5px;
      }

      &__image {
        @include media-breakpoint-down(sm) {
          align-items: flex-end;
        }

        &.list-icon__item__image--richtext {
          @include media-breakpoint-down(sm) {
            align-items: center;
          }
        }
      }
    }
  }

  // three a row
  &.three-a-row {
    padding: 30px 0 10px;
    
    .list-icon__wrap {
      flex-wrap: nowrap;
      justify-content: center;
    }

    .list-icon__item {
      @include media-breakpoint-down(sm) {
        width: 33.33%;
      }

      @include media-breakpoint-up(lg) {
        width: 25%;
      }

      &__image {
        @include media-breakpoint-up(lg) {
          height: 68px;
        }
      }
    }
  }
}