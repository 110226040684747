@import '@assets/reuse-mixin.scss';

.payment-fail {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  
  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }

  &__content {
    text-align: center;

    &__icon {
      height: 49px;
      width: 49px;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        height: 77px;
        width: 77px;
        margin-bottom: 24px;
      }
    }

    &__text {
      color: #F37021;
      letter-spacing: 1.6px;
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        margin-bottom: 32px;
      }
    }

    &__instruction-message {
      letter-spacing: 1.6px;
      margin-bottom: 24px;
      font-weight: 400;

      @include media-breakpoint-up(md) {
        margin-bottom: 32px;
      }
    }
  }

  &__btn {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 18px;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }

    &__continue-payment {
      margin-bottom: 16px;
      padding: 11px 23px;

      @include media-breakpoint-up(md) {
        padding: 7px 32px;
      }
    }

    &__back-homepage {
      letter-spacing: 2.24px;

      @include media-breakpoint-up(md) {
      letter-spacing: 2.56px;
      }
    }
  }
}
