@import '@assets/reuse-mixin.scss';

.featured-product-item {
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    height: 100%;
    margin-bottom: 0;
  }

  &__wrap {
    position: relative;
    transition: all 0.3s ease;
    padding: 0 $grid-gutter-width/2;

    &:not(:has(.product-card-price__member)) {
      overflow: hidden;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    @include media-breakpoint-up(lg) {
      background-color: $white;
      padding: 0;

      &:hover {
        background-color: $os-brown-lightest;

        .featured-product-item__image__item {
          transform: scale(1.1);
        }

        .featured-product-item__main__icon {
          opacity: 1;
          // transform: translateY(0);
        }

        // .heart {
        //   opacity: 1;
        // }
      }
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 53px;
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    margin-top: 24px;
    display: block;

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }

    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease;
    }
  }

  // .heart {
  //   opacity: 1;

  //   @include media-breakpoint-up(lg) {
  //     opacity: 0;
  //   }
	// }

  &__main {
    text-align: center;
    padding: 0 10px;

    &__name,
    &__price {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size;
      }
    }

    &__name {
      padding: 0 15px;
      margin-bottom: 15px;
      color: $gray-darker;

      &__link {
        color: $gray-darker;

        @include media-breakpoint-up(md) {
          @include line-clamp(1);
        }
      }
    }

    &__sort-decs {
      display: none;
    }

    &__price {
      // color: $black;
      .product-card-price__member-wrapper {
        display: flex;
        justify-content: center;
      }
    }

    &__old-price {
      color: $gray;
      font-size: $font-size-smaller;
      text-decoration: line-through;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-small;
      }

      @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
      }
    }

    &__icon {
      @include media-breakpoint-up(lg) {
        opacity: 0;
        // transform: translateY(100%);
        transition: all 0.5s;
      }
    }

    &__icon {
      &__item {
        display: inline-block;
        margin: 10px 30px 20px;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        i {
          font-size: 1.5em;
        }
      }
    }
  }

  &__learn-more-btn {
    margin-bottom: 30px;
    display: none;

    &__item {
      padding: 0;
      padding-right: 15px;
    }
  }

  &__btn {
    padding: 24px 6px 52px;

    @include media-breakpoint-up(md) {
      padding: 24px 6px 40px;
    }

    @include media-breakpoint-up(lg) {
      padding: 32px 6px 40px;
    }

    .btn {
      display: block;
      max-width: 200px;
      margin: 0 auto;

      @include media-breakpoint-up(xl) {
        max-width: 240px;
      }

      & + .btn {
        margin-top: 16px;
      }
    }
  }
}