@import'@assets/reuse-mixin.scss';

.two-col-image {
  display: flex;
  flex-direction: column;
  // margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &.text-align-left {
    text-align: left;
  }

  &.text-align-right {
    text-align: right;
  }

  &.text-align-center {
    text-align: center;
  }

  &.no-img-bg {
    .two-col-image {
      &__item {
        &__wrap {
          position: relative;
        }
      }
    }
  }

  &__item {
    width: 100%;
    position: relative;
    // height: 0;
    // padding-top: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      // padding-top: 50%;
    }

    // &::before {
    //   content: '';
    //   display: block;
    //   padding-top: 100%;
    // }

    img {
      // position: absolute;
      // left: 0;
      // top: 0;
      width: 100%;
      // height: 100%;
      // object-fit: cover;
    }

    &__background {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: auto;
        // height: 100%;
        // object-fit: cover;
      }
    }

    &:nth-child(even) {
      .two-col-image__item__wrap {
        @include media-breakpoint-up(md) {
          padding-right: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(lg) {
          padding-right: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xl) {
          padding-right: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xxl) {
          padding-right: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
        }
      }
    }

    &:nth-child(odd) {
      .two-col-image__item__wrap {
        @include media-breakpoint-up(md) {
          padding-left: calc(((100vw - #{$container-md}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(lg) {
          padding-left: calc(((100vw - #{$container-lg}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xl) {
          padding-left: calc(((100vw - #{$container-xl}) / 2) + #{$grid-gutter-width/2});
        }

        @include media-breakpoint-up(xxl) {
          padding-left: calc(((100vw - #{$container-xxl}) / 2) + #{$grid-gutter-width/2});
        }
      }
    }

    &__wrap {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 12px $grid-gutter-width/2;
      color: #736769;
      // line-height: 24px;
      // letter-spacing: 5px;
  
      @include media-breakpoint-up(md) {
        padding: 30px $grid-gutter-width/2;
      }

      @include media-breakpoint-up(xl) {
        padding: 50px;
      }
    }

    &__title {
      font-weight: $font-weight-bold;
      font-size: $h5-font-size - 0.2;
      margin-bottom: 0;
      line-height: inherit;
    }

    &__img {
      height: 100px;
      margin-top: 30px;

      @include media-breakpoint-up(lg) {
        height: 200px;
      }

      img {
        height: 100%;
        width: auto;
      }

      &--left {
        text-align: left;
      }

      &--center {
        text-align: center;
      }

      &--right {
        text-align: right;
      }
    }

    &__content {
      margin-top: 30px;

      &__sub-title {
        font-size: $h5-font-size;
        line-height: inherit;
        margin-bottom: 0;
      }
  
      &__rich-text {
        margin-top: 20px;
        font-size: $font-size-base;
      }
    }
  }

  &.text-align-center {
    text-align: center;
  }

  &.not-full-width {
    .two-col-image__item__wrap {
      padding: 30px;

      @include media-breakpoint-up(xl) {
        padding: 50px;
      }
    }
  }

  &.has-top-padding {
    margin-top: 50px;
  }

  &.has-bottom-padding {
    margin-bottom: 50px;
  }

  &.subtitle-bold {
    .two-col-image__item__content__sub-title {
      font-weight: $font-weight-bold;
    }
  }
}