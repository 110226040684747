@import "@assets/reuse-mixin.scss";

.phone-number-select {
  &__disabled {
    .customization-dropdown {
      &__control {
        height: calc(2.4rem + 3.2rem);
        border-radius: unset;
        border-color: $gray-300;
        background-color: $gray-lighter;
      }
    }
  }
}
