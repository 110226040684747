@import "@assets/reuse-mixin.scss";

.searchbox {
	&--autocomplete {
    &--wrap {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 12px;
      border: 1px solid $gray;
      padding: 11px 16px 11px 24px;
      justify-content: flex-start;
      height: 48px;
  
      @include media-breakpoint-up(lg) {
        padding: 12px 24px;
        height: 56px;
        max-width: 80%;
        margin: 0 auto 24px;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 100%;
      }

      .autocomplete {
        &--form {
          width: 100%;
          height: 100%;
        }
        
        &--input {
          border: none;
          outline: none;
          width: 100%;
          // max-width: 158px;
          font-size: $font-size-base;
          letter-spacing: 0.8px;
  
          @include media-breakpoint-up(lg) {
            padding: 0;
            font-size: $h5-font-size;
            letter-spacing: 1px;
            max-width: 100%;
          }
  
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $gray;
            opacity: 1; /* Firefox */
            font-weight: $font-weight-light;
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $gray;
            font-weight: $font-weight-light;
          }
          
          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $gray;
            font-weight: $font-weight-light;
          }
        }
  
        &--suggestion {
          background-color: $white;
          z-index: 1;
          margin-top: 1px;
          padding: 15px;
          visibility: hidden;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: auto;
          text-align: left;
          box-shadow: 0 1px 5px rgba($black,0.1);
  
          @include media-breakpoint-up(lg) {
            padding: 15px 0;
          }
  
          &.visible {
            visibility: visible;
          }

          &--list {
            &__item {
              padding: 8px 16px;
              color: $gray-dark;
              cursor: pointer;
              
              @include media-breakpoint-up(lg) {
                padding: 10px 24px;
              }
              
              &.highlighted,
              &:hover {
                color: $black;
                background-color: $os-brown-lighter;
              }
            }
          }

        }
      }

      .searchbox--autocomplete--tag {
        padding-right: 0;
				margin-right: 8px;

				@include media-breakpoint-up(md) {
					margin-right: 12px;
        }

        .x-close-tags {
          margin: 0 6px;

          @include media-breakpoint-up(lg) {
            margin: 0 12px;
          }
        }
      }
    }

    &--input {
      width: 100%;
      justify-content: space-between;

      .x-icon-search {
        display: inline-block;
        cursor: pointer;
        background: url('../../../assets/images/icons/icon-header.png') 0 -37px no-repeat;
        background-size: cover;
        width: 24px;
        height: 24px;
        transition: 0.3s;
        // display: flex;
        // align-items: center;
        margin-left: 8px;

        @include media-breakpoint-up(md) {
          margin-left: 12px;
          background-position: 0 -41px;
        }

        @include media-breakpoint-up(xxl) {
          background-position: 0 -44px;
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.2);
        }
      }

      .x-icon-clear {
        font-size: 14px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
        
        &.visible {
          opacity: 1;
          visibility: visible;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &--tag {
      white-space: nowrap;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 2px 14px;
      border: 1px solid $os-orange;
      border-radius: 40px;
      margin-right: 15px;
      font-size: $font-size-smaller;
      letter-spacing: 0.6px;
      cursor: pointer;
      text-align: center;

      @include media-breakpoint-up(md) {
        font-size: $font-size-small;
        letter-spacing: 0.7px;
        padding: 5px 30px;
      }

      &.selected {
        background-color: $os-orange;
      }

      .x-close-tags {
        font-size: 6px;
        margin-left: 10px;

        @include media-breakpoint-up(md) {
          font-size: 10px;
        }
      }
    }
	}
}