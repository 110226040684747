@import '@assets/reuse-mixin.scss';

.activity-log {
  width: 50%;
  
  &__title {
    font-weight: $font-weight-normal;
    margin-bottom: 24px;
  }

  &__content {
    padding: 16px 18px 24px 16px;
    background: $os-brown-lightest;
    color: $cp-gray;

    &__group {
      border-bottom: 1px solid $gray;
      padding-bottom: 18px;
      margin-bottom: 18px;

      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &__line {
      margin-bottom: 2px;
    }
  }

  .text-bold {
    color: $gray-darker;
    font-weight: $font-weight-normal;
  }
}