@import '@assets/reuse-mixin.scss';

.delivery-component {
  padding-bottom: 32px;
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(lg) {
    padding-bottom: 70px;
  }

  &:first-child {
    padding-top: 33px;

    @include media-breakpoint-up(lg) {
      padding-top: 50px;
    }
  }

  &__header {
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }

    &__icon {
      width: 30px;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        width: 45px;
      }
    }

    &__text {
      .orders-widget__content__status {
        padding-left: 8px;
        margin-top: 4px;

        @include media-breakpoint-up(md) {
          padding-left: 16px;
          margin-top: 8px;
        }
      }
    }

    &__title {
      // width: calc(100% - 30px - 16px);
      margin: 0;
      font-size: $h5-font-size;
      padding-left: 8px;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(md) {
        font-size: $h4-font-size;
        padding-left: 16px;
      }

      @include media-breakpoint-up(xl) {
        font-size: $h4-font-size + 0.4;
      }

      @include media-breakpoint-up(xxl) {
        font-size: $h3-font-size;
      }
    }

    &__icon-collapse {
      display: none;
      align-items: center;
      width: 16px;

      @include media-breakpoint-up(md) {
        width: 28px;
        margin-right: 30px;
      }

      i {
        font-size: $font-size-base;
        transition: all 0.3s;

        @include media-breakpoint-up(md) {
          font-size: 2.8rem;
        }
      }
    }
  }

  &__wrapper {
    margin: 0 -20px;
    padding: 25px 20px;
    border: 1px solid $gray-lighter;
    border-left-width: 0;
    border-right-width: 0;
    transition: all 0.3s;

    @include media-breakpoint-up(md) {
      border-left-width: 1px;
      border-right-width: 1px;
      margin: 0;
      padding: 25px 0;
    }
  }

  &.review-payment {
    .delivery-component {
      &__header {
        cursor: pointer;

        &__title {
          width: calc(100% - 30px - 16px);
        }

        &__icon-collapse {
          display: flex;
        }
      }

      &__wrapper {
        padding: 0 20px;
        border: none;
        border-top: 1px solid $gray-lighter;

        @include media-breakpoint-up(md) {
          padding: 0;
        }
      }
    }
  }

  &.is-collapsed {
    .delivery-component {
      &__header {
        &__icon-collapse {
          i {
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      &__wrapper {
        padding: 25px 20px;
        border: 1px solid $gray-lighter;
        border-left-width: 0;
        border-right-width: 0;

        @include media-breakpoint-up(md) {
          border-left-width: 1px;
          border-right-width: 1px;
          padding: 25px 0;
        }
      }
    }
  }
}

.delivery-container {
  // max-width: 1264px;
  max-width: 1318px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding: 0 30px;
  }
}

.delivery-row {
  display: flex;
  flex-wrap: wrap;
}

.delivery-col-left, .delivery-col-right {
  .error-message {
    text-align: left;

    &.invalid-address-msg {
      margin-top: 12px;
    }
  }
}

.delivery-col-left {
  width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 450px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 520px;
  }
}

.delivery-col-right {
  width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: calc(100% - 450px);
  }

  @include media-breakpoint-up(xl) {
    max-width: calc(100% - 520px);
  }

  > * {
    &:last-child {
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: 48px;
      }
    }
  }

  &--padding-left {
    @include media-breakpoint-up(lg) {
      padding-left: 50px;
    }

    @include media-breakpoint-up(xxl) {
      padding-left: 100px;
    }
  }

  .sf-express {
    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    .store-locator__modal {
      max-width: none;
    }
  }
}

.delivery-item-title {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid $gray;
  color: $gray-darker;

  @include media-breakpoint-up(md) {
    font-size: $h5-font-size;
  }

  @include media-breakpoint-up(xl) {
    font-size: $h4-font-size;
  }
}