@import "@assets/reuse-mixin.scss";

.partner-special-detail {
  &__title {
    color: $gray-darker;
    font-weight: $font-weight-normal;
    
    @include media-breakpoint-up(md) {
      text-align: center;
      margin-bottom: 40px;
    }
  }

  &__logo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
      margin-bottom: 72px;
    }

    .logo-wrapper {
      width: 100%;
      padding-bottom: calc(72 / 108 * 100%);
      position: relative;
      
      @include media-breakpoint-up(md) {
        padding-bottom: calc(232 / 350 * 100%);
      }

      & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
      }
    }
  }

  &__accordion {
    &-item {
      &:not(:last-child) {
        margin-bottom: 16px;
        @include media-breakpoint-up(md) {
          margin-bottom: 24px;
        }
      }

      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-bottom: 8px;
        border-bottom: 1px solid $gray-dark;

        & > .title {
          color: $gray-darker;
          font-weight: $font-weight-normal;
        }

        & > .icon-chevron-down-big {
          transition: all .5s ease-in-out;
          font-size: $h4-font-size;
          color: $gray-darker;
        }

        & > .accordion-active {
          transform: rotate(-180deg);
        }
      }

      .brand-banner {
        &__container {
          width: 100%;
          padding: 16px 0;
          @include media-breakpoint-up(md) {
            padding: 48px 0;
          }
        }

        &__wrapper {
          width: 100%;
          padding-bottom: calc(228 / 343 * 100%);
          position: relative;
          @include media-breakpoint-up(md) {
            padding-bottom: calc(633 / 1520 * 100%);
          }

          & > img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}