@import "src/assets/reuse-mixin";

.edit-appointment-form {
  width: 100%;
  flex-direction: column;

  .appointments-infor {
    width: 100%;

    &__item {
      &__label {
        display: flex;
        align-items: center;
      }

      &__value {
        &--date-edit {
          .react-datepicker__input-container {
            &::before {
              content: '';
              border-bottom: 1px solid $gray-800;
              border-right: 1px solid $gray-800;
              width: 10px;
              height: 10px;
              position: absolute;
              top: 35%;
              right: 10px;
              transform: translateY(-35%) rotate(45deg);
              cursor: pointer;
            }

            input {
              border: 1px solid $gray-400;
              padding: 4px 8px;
              cursor: pointer;

              &:focus-visible {
                border-color: $gray-darker;
                outline: none;
              }
            }
          }
        }

        .osim-checkbox {
          & > .osim-checkbox-input:disabled {
            color: #acacac;
            cursor: default;
            pointer-events: none;
            background-color: #e6e6e6;
            border-color: #e6e6e6;
          }
        }

        .osim-radiobox {
          & > .osim-radio-input:disabled {
            & ~ .osim-radio-label {
              color: #acacac;
              cursor: default;
              pointer-events: none;
            }
          }
        }

        & > .select-store__content__question__item__answer {
          margin-left: 0;
        }
      }

      .appointments-infor__item__option {
        .radio-box__input:disabled {
          & ~ .radio-box__label {
            background-color: #e6e6e6;
            border-color: #e6e6e6;
            color: #acacac;
            cursor: default;
            pointer-events: none;
          }
        }
      }
    }
  }

  &__item {
    &-textarea {
      .textarea-box {
        width: 100%;
        padding: 14px 24px;
      }
    }

    &-checkbox {
      .osim-checkbox {
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }

    &-radio {
      .osim-radiobox {
        &:last-child {
          margin-bottom: 0;
        }
      }

      .osim-radio {
        &-input {
          &:checked + .osim-radio-label {
            &:after {
              background-color: $gray-darker;
              box-shadow: inset 0 0 0 5px $white;
            }
          }
        }

        &-label {
          width: auto;
          border-radius: unset;
          border: unset;
          padding-left: 36px;

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            transform: translateY(-50%);
            background-color: $white;
            border: 1px solid $gray-darker;
          }
        }
      }
    }
  }

  &__button-group {
    margin-top: 12px;

    button {
      display: block;
      margin: 0 auto 12px;
    }
  }
}
