@import '@assets/reuse-mixin';

.skeleton {
  &__item {
    .image-wrap {
      position: relative;
      border-color: transparent!important;
    }

    .image-line {
      @include centerer(true, true);
      
      @include skeleton($lines: 1, $line-height: 100%, $line-gap: 0);
    }
    
    .text-line {
      @include skeleton($lines: 1, $line-height: 20px, $line-gap: 0);

      @include media-breakpoint-up(md) {
        @include skeleton($lines: 1, $line-height: 30px, $line-gap: 0);
      }

      &-center {
        @include skeleton($lines: 1, $line-height: 20px, $line-gap: 0);

        @include media-breakpoint-up(md) {
          @include skeleton($lines: 1, $line-height: 30px, $line-gap: 0);
        }
        
        margin: 0 auto;
      }

      &-heading {
        @include skeleton($lines: 1, $line-height: 30px, $line-gap: 0);

        @include media-breakpoint-up(md) {
          @include skeleton($lines: 1, $line-height: 50px, $line-gap: 0);
        }

        &-center {
          @include skeleton($lines: 1, $line-height: 30px, $line-gap: 0);

          @include media-breakpoint-up(md) {
            @include skeleton($lines: 1, $line-height: 50px, $line-gap: 0);
          }
          margin: 0 auto;
        }
      }

      &-small {
        @include skeleton($lines: 1, $line-height: 20px, $line-gap: 0);

        @include media-breakpoint-up(md) {
          @include skeleton($lines: 1, $line-height: 20px, $line-gap: 0);
        }

        &-center {
          @include skeleton($lines: 1, $line-height: 20px, $line-gap: 0);

          @include media-breakpoint-up(md) {
            @include skeleton($lines: 1, $line-height: 20px, $line-gap: 0);
          }
          margin: 0 auto;
        }
      }
    }

    .button-line {
      @include skeleton($lines: 1, $line-height: 30px, $line-gap: 0);

      @include media-breakpoint-up(md) {
        @include skeleton($lines: 1, $line-height: 40px, $line-gap: 0);
      }

      &-center {
        @include skeleton($lines: 1, $line-height: 30px, $line-gap: 0);

        @include media-breakpoint-up(md) {
          @include skeleton($lines: 1, $line-height: 40px, $line-gap: 0);
        }
        
        margin: 0 auto;
      }

    }

    .transparent-wrap {
      opacity: 0;
    }

    @for $i from 1 through 10 {
      .w-#{$i}0 {
        width: #{$i}0%!important;
      }
    }

    @for $i from 1 through 100 {
      .clear-#{$i} {
        display: block;
        width: 100%;
        height: calc(#{$i}px/2);
        @include media-breakpoint-up(md) {
          height: #{$i}px;
        }
      }
    }

    @for $h from 10 through 100 {
      $desktopValue: $h * 1px;
      $mobileValue: $desktopValue - 10px;

      .text-line-#{$h} {
        @include skeleton($lines: 1, $line-height: $mobileValue, $line-gap: 0);

        @include media-breakpoint-up(md) {
          @include skeleton($lines: 1, $line-height: $desktopValue, $line-gap: 0);
        }

        &-center {
          @include skeleton($lines: 1, $line-height: $mobileValue, $line-gap: 0);

          @include media-breakpoint-up(md) {
            @include skeleton($lines: 1, $line-height: $desktopValue, $line-gap: 0);
          }
          margin: 0 auto;
        }
      }
    }

    @each $bp in map-keys($grid-breakpoints) {
      .hidden-#{$bp}-up {
        @include media-breakpoint-up($bp) {
          display: none !important;
        }
      }
      .hidden-#{$bp}-down {
        @include media-breakpoint-down($bp) {
          display: none !important;
        }
      }
    }
  }

}