@import '../../../../assets/reuse-mixin';

.header-menu {
  .main-navigation {
    list-style: none;
    margin: 0;

    &__name-account {
      font-size: $font-size-large;
      letter-spacing: 0.9px;
      padding: 26px 0 48px 0;
      color: $gray-darker;

      @include media-breakpoint-up(md) {
        font-size: $h5-font-size;
        letter-spacing: 1px;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__note {
      color: $gray-darker;
      margin-bottom: 8px;
    }

    &__item {
      margin-bottom: 24px;

      &--sub-item {
        font-weight: bold;
        position: relative;

        &:first-child {
          margin-top: 24px;
        }
      }

      &__link {
        display: block;
      }
    }

    .vertical-tab {
      color: $gray-darker;
      padding-top: 24px;

      &__title {
        margin: 0;
      }

      &__collapse {
        border-bottom: 1px solid $gray;
      }
    }

    .icon-dropdown {
      width: 20px;
      display: inline-block;
      font-size: 19px;
      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
  }
}