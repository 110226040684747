@import '@assets/reuse-mixin.scss';

.product-catelogue {
  padding: 50px 0 30px;

  &__description {
    text-align: center;

    @include media-breakpoint-up(xl) {
      padding: 0 220px;
    }

    &--title {
      text-transform: uppercase;
      letter-spacing: 2.88px;
      color: $page-title;
      margin-bottom: 15px;
    }

    &--detail {
      letter-spacing: 1.6px;
    }
  }

  &__shop-by-categories {
    padding-top: 30px;

    .header__sub-menu-nav__item:hover {
      .product-catelogue__shop-by-categories--title {
        color: #0273BD;
      }
    }

    &--title {
      letter-spacing: 2.56px;
      text-transform: uppercase;
      font-weight: $font-weight-normal;
    }

    .header__sub-menu-nav__contents {
      flex-direction: row;
    }

    .header__sub-menu-nav__item {
      height: auto;
    }
  }

  &__shop-by-needs {
    .help-me-choose__list {
      &__item {
        position: relative;
        margin-bottom: 10px;
        width: 45%;
        display: flex;
        align-items: center;
        min-height: 150px;

        @include media-breakpoint-up(sm) {
          margin-bottom: 25px;
          min-height: 305px;
        }

        @include media-breakpoint-up(lg) {
          width: 30%;
          min-height: 265px;
        }

        a {
          position: absolute;
        }
      }
    }
  }

  .header__sub-menu-nav__item {
    @include media-breakpoint-up(lg) {
      max-width: 25%;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 20%;
    }
  }
}