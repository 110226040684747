@import '../../../../assets/reuse-mixin.scss';

.contact-icon-list {
  padding: 24px 0;

  @include media-breakpoint-up(lg) {
    padding: 55px 0 0;
  }

  &__wrap {
    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 80px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0;
      max-width: 1260px;
      margin: 0 auto;
    }
  }

  &__heading {
    text-align: center;
    margin-bottom: 27px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 31px;
    }
  }

  .col-md-6 {
    &:last-child {
      .contact-icon-list__item {
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          margin-bottom: 55px;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: baseline;
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 55px;
    }

    &__image {
      flex: 0 0 80px;
      max-width: 80px;
      text-align: center;
      transform: translateY(3px);

      @include media-breakpoint-up(lg) {
        transform: translateY(0);
      }

      img {
        max-width: 36px;
      }
    }

    &__text {
      padding-left: 24px;
      color: $gray-darker;

      p:first-child {
        font-size: $font-size-large;

        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
        }
      }
    }
  }

  &.skeleton__item {
    .contact-icon-list__item {
      &__text {
        width: 100%;
      }

      &__image {
        .image-wrap {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}