@import '../../../assets/reuse-mixin.scss';

.osim-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(0,0,0,.7);
  z-index: $POPUP_ZINDEX;
  align-items: center;
  transition: all .5s;
  overflow-y: scroll;
  // padding: 15px 0;
  display: none;

  &__wrap {
    background: #fff;
    min-height: calc(100vh - 30px);
    padding: 15px;
    position: relative;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__close-btn {
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;

    &:hover {
      color: $gray-darker;
    }

    @include media-breakpoint-up(md) {
      top: 32px;
      right: 32px;
    }
  }

  &.show-popup {
    display: block;
  }
}