@import '@assets/reuse-mixin.scss';

.spin-and-win-popup {
  .modal-content {
    background: none;
    width: 100%;
    padding: 0;

    .osim-popup__header {
      margin-bottom: 0;
      min-height: 0;
      padding: 0;
    }

    .osim-popup__close-btn {
      top: 24px;
      right: 24px;
      z-index: 1;
    }
  }

  .spin-win-banner {
    &__background {
      padding-bottom: 193.6%;
    }

    &__content {
      &__wrap {
        padding: 0 0 30px 0;
      }
      
      .container {
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin: 0;
      }
    }
  }

  .spin-win-heading {
    display: flex;
    justify-content: center;

    &__icon {
      max-width: 320px;
      left: 50%;
      transform: translateX(-50%);
  
      @include media-breakpoint-up(lg) {
        max-width: 400px;
      }
    }

    &__title {
      font-size: $h1-font-size-mobile;
      line-height: 1.2;
      letter-spacing: 1.4px;
      max-width: 260px;
      padding: 0 30px
    }
  }

  .main-banner__content {
    &__title {
      color: inherit;
    }
    
    &__desc {
      margin-bottom: 0;
      color: inherit;
    }

    &__group-button {
      display: flex;
      flex-direction: column;

      .btn-link {
        margin: 20px 0 0 0;
      }
    }
  }

  .skeleton__item {
    position: relative;
  }
}

@include media-breakpoint-up(xl) {
  .spin-and-win-popup {
    .modal-content {
      max-width: 954px;
      min-height: 495px;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      .osim-popup__close-btn {
        top: 72px;
        right: 24px;
        z-index: 1;
      }
    }
  
    .spin-win-banner {
      &__background {
        padding-bottom: 42%;
        max-width: 740px;
        margin-left: auto;
        display: block;
      }

      &__content {
        &__wrap {
          max-width: 100%;
          flex-direction: row;
    
          .spin-wheel {
            margin-top: 0;
          }
    
          .spin-win-popup-form {
            width: calc(100% - 1px);
    
            .spin-win-heading {
              &__title {
                font-size: $h3-font-size;
                line-height: 36px;
                letter-spacing: 1.6px;
                max-width: 300px;
              }
            }
          }
        }
      }
    }
  }
  
}
