@import '@assets/reuse-mixin.scss';

.notify-me-pop-up {
  max-width: 480px;
  margin: 0 auto;

  @include media-breakpoint-up(xl) {
    padding-top: 24px;
  }

  &__sub-title {
    font-size: $font-size-base;
    color: #000;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      font-size: $h5-font-size;
      margin: 32px 0 24px;
    }
  }

  .notify-me__form {
    width: 100%;
  }

  .notify-me__form-input {
    margin-bottom: 34px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 77px;
    }
  }

  .notify-me__form__btn__item {
    min-width: 200px;

    @include media-breakpoint-up(lg) {
      min-width: 240px;
    }
  }

  .notify-success,
  .notify-faild {
    text-align: center;
    margin-bottom: 18px;
    font-size: $font-size-smaller;

    @include media-breakpoint-up(lg) {
      margin-bottom: 48px;
      font-size: $font-size-base;
    }
  }

  .notify-success {
    color: $os-green;
  }

  .notify-faild {
    color: $primary;
  }
}