@import '@assets/reuse-mixin.scss';

.my-orders {
  margin-bottom: 24px;

  &__tabs {
    @include media-breakpoint-up(lg) {
      width: 80%;
      max-width: 1000px;
      margin: 0 auto;
    }

    &__content {
      padding: 18px 0;
      border-bottom: 1px solid $gray-lighter;

      @include media-breakpoint-up(lg) {
        padding: 32px 0;
        border: 1px solid $gray-lighter;
        border-top: none;
      }

      &__item {
        &-wrapper {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          &.line-indent {
            padding-left: 20px;
            @include media-breakpoint-up(md) {
              padding-left: 40px;

            }
          }
        }


        &.my-account-section__content {
          @include media-breakpoint-up(lg) {
            padding: 0 70px;
          }
        }
      }
    }
  }
}