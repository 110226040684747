@import '../../../assets/reuse-mixin.scss';

.rental-buying {
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  &-list {
    &__list {
      padding-bottom: 10px;
      border-bottom: 1px solid $gray;

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }
    
    &__item-wrap {
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      border: 1px solid $gray-light;
      padding: 16px;
      transition: all 0.3s;
      height: 100%;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 30px;
      }

      &:hover {
        border: 1px solid $gray-darker;
      }

      &.active {
        border: 1px solid $gray-darker;
      }

      &.disable {
        color: $gray-light;
      }

      &__name {
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $h4-font-size;
        }
      }

      &__price {
        font-weight: bold;
        font-size: $font-size-base;

        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
        }
      }
    }
  }

  .rental-buying-list {
    padding-bottom: 8px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }
}