@import '@assets/reuse-mixin.scss';

.write-review-form {
  color: $gray-darker;
  @include media-breakpoint-up(sm) {
    margin-top: 40px;
  }

  &__form {
    max-width: 480px;
    margin: 0 auto;

    &__rating {
      .review-star-rating {
        justify-content: center;
      }
    }

    &__rating-detail {
      margin-top: 24px;
      display: none;

      @include media-breakpoint-up(lg) {
        margin-top: 32px;
      }

      &__label {
        font-weight: $font-weight-normal;
      }

      &__list {
        margin: 24px auto 0;

        @include media-breakpoint-up(lg) {
          width: 75%;
        }
      }

      .rating-detail {
        &__container {
          margin-bottom: 12px;

          @include media-breakpoint-up(lg) {
            margin-bottom: 18px;
          }
        }

        &-item {
          display: flex;

          &:last-child {
            margin-bottom: 0;
          }

          &__text {
            width: 50%;
            font-size: $font-size-base;
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
              font-size: $h5-font-size;
            }
          }

          &__star {
            width: 50%;
          }
        }
      }

      &.show {
        display: block;
      }
    }

    &__upload-media {
      &__wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -4px;

        @include media-breakpoint-up(lg) {
          margin: 0 -3px;
        }
      }

      &__info {
        font-size: $font-size-smaller;
        margin-top: 12px;
        padding: 0 24px;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-base;
          margin-top: 16px;
        }
      }
    }

    &__btn {
      .btn {
        min-width: 200px;
      }

      .btn-link-text {
        display: block;
        margin-top: 8px;

        @include media-breakpoint-up(lg) {
          margin-top: 16px;
        }
      }
    }
    // .customization-dropdown{
    //   div.customization-dropdown__option{
    //     &:last-child{
    //       color: $gray-900;
    //       text-align: center;
    //       justify-content: center;
    //       font-size: $font-size-small;
    //     }
    //     &:hover {
    //       &:last-child{
    //         cursor: default;
    //         color: unset;
    //         background-color: $white;
    //         pointer-events: none;
    //         font-weight: unset;
    //       }
    //     }
    //   }
    // }
  }

  .label-note {
    display: block;
    margin: 8px 0 0;

    @include media-breakpoint-up(lg) {
      margin: 16px 0 0;
    }

    &--no-mb {
      margin-bottom: -8px;

      @include media-breakpoint-up(lg) {
        margin-bottom: -16px;
      }
    }
  }

  label {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 18px;
    }
  }

  .upload-media {
    position: relative;
    width: 25%;
    padding: 0 4px;
    margin-bottom: 6px;
    
    @include media-breakpoint-up(lg) {
      padding: 0 3px;
    }

    &__remove-btn {
      position: absolute;
      top: 6px;
      right: 6px;
      color: $gray-darker;
      font-size: $font-size-smaller;
      cursor: pointer;
      z-index: $Z_INDEX_NORMAL;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
        top: 8px;
        right: 8px;
      }
    }

    &__box {
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid $gray-light;
      }

      &__icon {
        width: 24px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(lg) {
          width: 34px;
          height: auto;
        }
      }

      &__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &__number {
        position: absolute;
        left: 50%;
        bottom: 4px;
        transform: translateX(-50%);
      }
    }
  }

  .media-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    padding: 0 3px;

    @include media-breakpoint-up(lg) {
      height: 27px;
      padding: 0 8px;
    }

    &__icon {
      width: 18px;
      height: 9px;
      display: inline-block;
      background: url('../../../assets/images/icons/video-camera-icon.png') 0 0 no-repeat;
      background-size: cover;

      @include media-breakpoint-up(lg) {
        width: 26px;
        height: 14px;
      }
    }

    &__time {
      font-size: $font-size-smaller;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }
    }
  }
}

.submit-error {
  word-break: break-word;

  &__btn {
    margin-top: 30px;

    .btn {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
