@import'../../../../assets/reuse-mixin.scss';

.cart-head-info {
  text-align: center;
  color: $gray-darker;
  font-size: $font-size-base;
  padding-top: 25px;
  border-bottom: 1px solid $gray;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    border-bottom: none;
  }

  &__container {
    @include media-breakpoint-up(md) {
      border-bottom: 1px solid $gray;
    }
  }

  &__heading {
    margin-bottom: 15px;
    color: $gray;
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: bold;
    letter-spacing: 1px;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
      margin-bottom: 32px;
    }
  }

  &__infor {
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }

    &__label {
      font-size: $font-size-base;

      @include media-breakpoint-up(lg) {
        font-size: $h5-font-size;
        margin-bottom: 5px;
      }
    }

    &__price {
      font-size: $h4-font-size;
      display: block;
      padding-right: 10px;
      font-weight: bold;

      @include media-breakpoint-up(lg) {
        font-size: 3.5rem;
        letter-spacing: 1.75px;
      }
    }

    &__quantity {
      font-size: $font-size-small;
    }
  }

  &__note {
    padding: 0 15px;
    font-size: $font-size-base;
    margin-bottom: 18px;
  }

  &__button {
    margin-bottom: 16px;

    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }

    &__item {
      min-width: 240px;
      text-transform: uppercase;
    }

    .btn {
      width: 200px;

      @include media-breakpoint-up(lg) {
        width: 240px;
      }
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 24px;
  }
}